export function formatNumber(locale = 'en', value = 0, maximumFractionDigits = 1): string {
	const opts: Intl.NumberFormatOptions = { maximumFractionDigits };

	return new Intl.NumberFormat([locale], opts).format(value);
}

export function formatPercent(locale = 'en', value = 0): string {
	const opts: Intl.NumberFormatOptions = { style: 'percent' };

	return new Intl.NumberFormat([locale], opts).format(value);
}

// TODO use site weightSystem
export function formatWeight(locale = 'en', grams = 0): string {
	const unit = grams < 1000 ? 'gram' : 'kilogram';

	const denom = unit === 'gram' ? 1 : 1000;

	const opts: Intl.NumberFormatOptions = {
		maximumFractionDigits: 1,
		style: 'unit',
		unit,
	};

	return new Intl.NumberFormat([locale], opts).format(grams / denom);
}

// TODO use site dimensionSystem
export function formatLength(locale = 'en', millis = 0): string {
	const unit = millis >= 1000 ? 'meter' : millis < 10 ? 'millimeter' : 'centimeter';

	const denom = {
		centimeter: 10,
		meter: 1000,
		millimeter: 1,
	}[unit];

	const opts: Intl.NumberFormatOptions = {
		maximumFractionDigits: 1,
		style: 'unit',
		unit,
	};

	return new Intl.NumberFormat([locale], opts).format(millis / denom);
}

// TODO use site dimensionSystem
export function formatVolume(locale = 'en', cubicMillis = 0): string {
	const unit = cubicMillis >= 1000 ? 'meter' : cubicMillis < 10 ? 'millimeter' : 'centimeter';

	const denom = {
		centimeter: 10,
		meter: 1000,
		millimeter: 1,
	}[unit];

	const opts: Intl.NumberFormatOptions = {
		maximumFractionDigits: 1,
		style: 'unit',
		unit,
	};

	return new Intl.NumberFormat([locale], opts).format(cubicMillis / denom) + '\u00B3';
}
