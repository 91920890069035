import { LineV2 } from '@sixriver/fulfillment-api-schema';
import { IndexTable, TextStyle } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../../hooks/useLocalization';

interface Props {
	line: LineV2;
}

export function PickingUnitsCell({ line }: Props): JSX.Element {
	const { messages, translate } = useLocalization();
	return (
		<IndexTable.Cell>
			<TextStyle>
				{translate(messages.pickedOfTotal, {
					picked: line.doneQuantity,
					total: line.quantity,
				})}
			</TextStyle>
		</IndexTable.Cell>
	);
}
