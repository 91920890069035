import { Link, TextStyle } from '@sixriver/lighthouse-web-community';

import { AvailableMfp } from '../../api';
import * as routes from '../../routes';

interface RelatedJobsProps {
	device: AvailableMfp;
}

export default function RelatedJobs({ device }: RelatedJobsProps): JSX.Element {
	if (device.workspaceReservations.length > 0) {
		const params = new URLSearchParams();
		device.workspaceReservations.forEach((workspaceReservation) => {
			params.append('ids', workspaceReservation.jobId);
		});
		const url = `${routes.outboundJobs()}?${params.toString()}`;
		return (
			<Link url={url} removeUnderline>
				{device.workspaceReservations.length}
			</Link>
		);
	}

	return <TextStyle variation="subdued">0</TextStyle>;
}
