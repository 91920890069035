import { InventoryConflictReason } from '@sixriver/fulfillment-api-schema';

import { useLocalization } from '../../hooks/useLocalization';

export function useLocationDiscrepancies() {
	const { messages } = useLocalization();

	const translatedLocationDiscrepancies: Record<InventoryConflictReason, string> = {
		Miscount: messages.discrepancyTypes.Miscount,
		MixedExpirationDates: messages.discrepancyTypes.MixedExpirationDates,
		MixedLotCodes: messages.discrepancyTypes.MixedLotCodes,
		NegativeQuantity: messages.discrepancyTypes.NegativeQuantity,
		Other: messages.discrepancyTypes.Other,
		PickShort: messages.discrepancyTypes.PickShort,
		UnknownExpirationDate: messages.discrepancyTypes.UnknownExpirationDate,
		UnknownLotCode: messages.discrepancyTypes.UnknownLotCode,
		WrongExpirationDate: messages.discrepancyTypes.WrongExpirationDate,
		WrongLotCode: messages.discrepancyTypes.WrongLotCode,
	};

	return translatedLocationDiscrepancies;
}
