import { OrderV2 } from '@sixriver/fulfillment-api-schema';
import { Card, Stack, TextStyle } from '@sixriver/lighthouse-web-community';

import { ProgressBar } from '../../components/ProgressBar';
import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	order: OrderV2;
}

export function Progress({ order }: Props) {
	const { messages, translate } = useLocalization();

	const getProgressPercentage = (units: number): number => {
		return (units / order?.totalLineQuantities?.totalUnits) * 100;
	};

	return (
		<Card title={messages.progress} footerActionAlignment="left" sectioned>
			<Stack vertical={false} distribution={'fillEvenly'}>
				<Stack vertical={true}>
					<TextStyle>{messages.unassigned}</TextStyle>
					<div>
						<ProgressBar
							progress={getProgressPercentage(order?.totalLineQuantities?.unassignedUnits)}
							color={'primary'}
						></ProgressBar>
					</div>
					{order?.totalLineQuantities?.unassignedUnits > 0 ? (
						<TextStyle variation="warning">
							{translate(messages.countUnits, {
								count: order?.totalLineQuantities?.unassignedUnits,
							})}
						</TextStyle>
					) : (
						<TextStyle>
							{translate(messages.countUnits, {
								count: order?.totalLineQuantities?.unassignedUnits,
							})}
						</TextStyle>
					)}
				</Stack>
				<Stack vertical={true}>
					<TextStyle>{messages.inProgress}</TextStyle>
					<div>
						<ProgressBar
							progress={getProgressPercentage(order?.totalLineQuantities?.inProgressUnits)}
							color={'primary'}
						></ProgressBar>
					</div>
					<TextStyle>
						{translate(messages.countUnits, {
							count: order?.totalLineQuantities?.inProgressUnits,
						})}
					</TextStyle>
				</Stack>
				<Stack vertical={true}>
					<TextStyle>{messages.completed}</TextStyle>
					<div>
						<ProgressBar
							// packedUnits = completeUnits for FES sites
							progress={getProgressPercentage(order?.totalLineQuantities?.completedUnits)}
							color={'primary'}
						></ProgressBar>
					</div>
					<TextStyle>
						{translate(messages.countUnits, {
							count: order?.totalLineQuantities?.completedUnits,
						})}
					</TextStyle>
				</Stack>
			</Stack>
		</Card>
	);
}
