import {
	MutationCreateUserArgs,
	MutationResponse,
	UserAuthMethod,
} from '@sixriver/fulfillment-api-schema';
import { Page } from '@sixriver/lighthouse-web-community';
import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'urql';

import { EmployeeForm, EmployeeFormData } from './Employee.form';
import { getRolesFromFormData } from './Employee.utils';
import { CREATE_NEW_EMPLOYEE_MUTATION } from './Employees.graphql';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import { BusyIndicatorContext } from '../../providers/BusyIndicatorProvider';
import * as routes from '../../routes';

export function AddEmployee(): JSX.Element {
	const history = useHistory();
	const { showToast } = useToast();
	const { messages } = useLocalization();
	const { lookBusy } = useContext(BusyIndicatorContext);

	// Mutations
	const [{ error: addEmployeeError }, addEmployeeMutation] = useMutation<
		{ createUser: MutationResponse },
		MutationCreateUserArgs
	>(CREATE_NEW_EMPLOYEE_MUTATION);

	// Methods
	const onSubmit = useCallback(
		async (formData: EmployeeFormData) => {
			lookBusy(true);

			const { data } = await addEmployeeMutation({
				input: {
					authMethod: UserAuthMethod.Ldap,
					badgeBarcode: formData.badgeBarcode || null,
					email: formData.email || null,
					locale: formData.locale || null,
					name: formData.name || null,
					roles: getRolesFromFormData(formData).map((name) => ({ name })),
					tags: formData.tags.map((tag) => ({ name: tag })),
				},
			});

			if (!data?.createUser?.success || !data.createUser?.referenceId) {
				lookBusy(false);
				showToast(messages.errorToast, true);
				return;
			}

			setTimeout(() => {
				lookBusy(false);
				history.push(routes.employee(data.createUser.referenceId));
			}, 500);
		},
		[addEmployeeMutation, history, messages, showToast, lookBusy],
	);

	// Render
	return (
		<Page
			title={messages.addEmployee}
			breadcrumbs={[
				{
					content: messages.employees,
					url: routes.employees(),
				},
			]}
			fullWidth
		>
			<EmployeeForm
				mode="add"
				onSubmit={onSubmit}
				error={addEmployeeError}
				data={
					{
						badgeBarcode: '',
						email: '',
						isBridgeAccessAllowed: false,
						isDeviceAccessAllowed: false,
						locale: 'en-US',
						name: '',
						phoneNumber: '',
						tags: [],
					} as EmployeeFormData
				}
			/>
		</Page>
	);
}
