import { Stack, Select, Badge } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../../hooks/useLocalization';
import { Mfa, useMfas } from '../providers/Mfas';
import { useReservations } from '../providers/Reservations';
import { useResources } from '../providers/Resources';

interface MfaSelectorProps {
	mfa?: Mfa;
	setMfa: (mfa: string) => void;
}

export function MfaSelector({ mfa, setMfa }: MfaSelectorProps) {
	const { resources } = useResources();
	const { reservations } = useReservations();
	const { mfas } = useMfas();
	const { messages, translate } = useLocalization();

	const mfaOptions = mfas
		.map((d) => ({ label: d.name, value: d.deviceId }))
		// If a resource already has a reservation, add a note to the label
		.map((d) => {
			const reservation = reservations.find((res) => res.deviceId === d.value);
			if (reservation) {
				return {
					...d,
					label: `⚠ ${d.label}`,
				};
			}
			return d;
		})
		.sort((a, b) => a.label.localeCompare(b.label));

	const currentReservation = reservations.find((res) => res.deviceId === mfa?.deviceId);
	const currentResource = resources.find((r) => r.id === currentReservation?.resource?.id);

	return (
		<Stack vertical spacing="tight">
			<Select
				placeholder={messages.mfas.assignLicenseModal.device.placeholder}
				label={messages.mfas.assignLicenseModal.device.label}
				options={mfaOptions}
				onChange={setMfa}
				value={mfa?.deviceId}
			/>
			{mfa && currentResource ? (
				<span>
					<Badge status="warning">{messages.mfas.assignLicenseModal.device.warning.badge}</Badge>{' '}
					{translate(messages.mfas.assignLicenseModal.device.warning.message, {
						resource: currentResource.name,
					})}
				</span>
			) : null}
		</Stack>
	);
}
