import { AlertMinor, TickMinor } from '@sixriver/lighthouse-icons';
import { Icon, Stack, TextStyle } from '@sixriver/lighthouse-web-community';

interface Props {
	success: boolean;
	successText: string;
	failureText: string;
}

export function PasswordIndicator({ success, successText, failureText }: Props) {
	return (
		<Stack spacing="tight">
			<Icon source={success ? TickMinor : AlertMinor} color={success ? 'success' : 'critical'} />
			<TextStyle variation={success ? 'positive' : 'negative'}>
				{success ? successText : failureText}
			</TextStyle>
		</Stack>
	);
}
