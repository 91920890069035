import { IndexTable } from '@sixriver/lighthouse-web-community';

import { NoData } from '../../../components/NoData';
import { useLocalization } from '../../../hooks/useLocalization';
import { useUniqueCustomerName } from '../../../hooks/useUniqueCustomerName';

interface StoreCellProps {
	totalLineCustomers: string[];
}

export function StoreCell({ totalLineCustomers }: StoreCellProps) {
	const { messages } = useLocalization();
	const customers = useUniqueCustomerName(totalLineCustomers);
	const customerName = customers.size > 1 ? messages.multiple : customers.values().next().value;
	return <IndexTable.Cell>{customerName ?? <NoData />}</IndexTable.Cell>;
}
