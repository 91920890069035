import { gql } from 'graphql-tag';

export const CANCEL_ORDERS_MUTATION = gql`
	mutation cancelOrders($ids: [String!]!) {
		cancelOrders(ids: $ids) {
			success
			referenceId
		}
	}
`;
