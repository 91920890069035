import { OrderV2Status } from '@sixriver/fulfillment-api-schema';
import { ChoiceList } from '@sixriver/lighthouse-web-community';
import { noop } from 'lodash';

import { useLocalization } from '../../../hooks/useLocalization';

interface StatusesFilterProps {
	selected?: OrderV2Status[];
	onChange?: (selected: OrderV2Status[], name: string) => void;
}

export function StatusesFilter({ selected = [], onChange = noop }: StatusesFilterProps) {
	const { messages } = useLocalization();

	return (
		<ChoiceList
			title={messages.orderStatus}
			titleHidden
			choices={[
				{
					label: messages.unassigned,
					value: OrderV2Status.Unassigned,
				},
				{
					label: messages.assignedToWall,
					value: OrderV2Status.AssignedToSortWall,
				},
				{
					label: messages.inProgress,
					value: OrderV2Status.InProgress,
				},
				{
					label: messages.interrupted,
					value: OrderV2Status.Interrupted,
				},
				{
					label: messages.completed,
					value: OrderV2Status.Completed,
				},
				{
					label: messages.canceled,
					value: OrderV2Status.Canceled,
				},
			]}
			selected={selected}
			onChange={onChange}
			allowMultiple
		/>
	);
}
