import { OrderV2 } from '@sixriver/fulfillment-api-schema';
import { IndexTable, Stack } from '@sixriver/lighthouse-web-community';

import { OrderStatusBadgeV2 } from '../../../components/OrderStatusBadge/OrderStatusBadgeV2';
import { ResolutionCountText } from '../../../components/ResolutionCountText';

interface StatusCellProps {
	order: OrderV2;
}

export function StatusCell({ order }: StatusCellProps) {
	return (
		<IndexTable.Cell>
			<Stack vertical spacing="extraTight">
				<OrderStatusBadgeV2 order={order} />
				<ResolutionCountText order={order} />
			</Stack>
		</IndexTable.Cell>
	);
}
