import { noop } from 'lodash';

import { ViewTabs, Tab } from '../../components/ViewTabs';
import { useLocalization } from '../../hooks/useLocalization';

export enum OutboundJobsTab {
	All = 'all',
	Late = 'late',
	Exceptions = 'exceptions',
	Interrupted = 'interrupted',
	AwaitingInventory = 'awaiting-inventory',
}

export interface OutboundJobsTabsProps {
	tabs: OutboundJobsTab[];
	lateCount: number;
	exceptionsCount: number;
	interruptedCount: number;
	awaitingInventoryCount: number;
	selected: string;
	onSelect?: (tab: OutboundJobsTab) => void;
}

export function OutboundJobsTabs({
	lateCount,
	exceptionsCount,
	interruptedCount,
	awaitingInventoryCount,
	selected,
	tabs,
	onSelect = noop,
}: OutboundJobsTabsProps) {
	const { messages } = useLocalization();
	const showTabs = tabs
		.map((tab) => {
			switch (tab) {
				case OutboundJobsTab.All:
					return {
						id: OutboundJobsTab.All,
						label: messages.all,
					};
				case OutboundJobsTab.Late:
					return {
						id: OutboundJobsTab.Late,
						label: messages.late,
						metaLabel: lateCount,
					};
				case OutboundJobsTab.Exceptions:
					return {
						id: OutboundJobsTab.Exceptions,
						label: messages.exceptions,
						metaLabel: exceptionsCount,
					};
				case OutboundJobsTab.Interrupted:
					return {
						id: OutboundJobsTab.Interrupted,
						label: messages.interrupted,
						metaLabel: interruptedCount,
					};
				case OutboundJobsTab.AwaitingInventory:
					return {
						id: OutboundJobsTab.AwaitingInventory,
						label: messages.awaitingInventory,
						metaLabel: awaitingInventoryCount,
					};
				default:
					return null;
			}
		})
		.filter((tab) => !!tab);

	return <ViewTabs tabs={showTabs as Tab[]} selected={selected} onSelect={onSelect} />;
}
