import {
	OrderV2Status,
	OrderV2,
	WorkOrderType,
	PickStrategy,
} from '@sixriver/fulfillment-api-schema';
import { Card, Link } from '@sixriver/lighthouse-web-community';

import { Equipment } from './Equipment';
import { CardDetails } from '../../components/CardDetails';
import { DateTimeWithRelativeTime, DateTime } from '../../components/DateTime';
import { NoData } from '../../components/NoData';
import { useIsSortationEnabled } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';
import { usePickStrategyName } from '../../hooks/usePickStrategies';
import * as routes from '../../routes';
import { sortWall as sortWallRoute } from '../../routes';

interface Props {
	order: OrderV2 & { allowedDeviceClasses: string[]; usedDeviceClasses: string[] };
}

export function Details({ order }: Props) {
	const { messages } = useLocalization();
	const isSortationEnabled = useIsSortationEnabled();
	const pickedAt = order.tasks.find((task) => task.type === 'batchPick')?.completedAt ?? null;
	const sortWallJob = (order as any)?.servicedBySortations[0];
	const getPickStrategyName = usePickStrategyName();
	const packouts: { node: any }[] = (order as any)?.packouts.edges ?? [];
	const primary = [];

	if (order?.workOrderTypes?.includes(WorkOrderType.InventoryTransferToNode)) {
		primary.push({
			content: order?.destinationNode || <NoData />,
			label: messages.destination,
		});
	}

	if (isSortationEnabled && sortWallJob) {
		const sortWallId = order?.stagingIdentifier ?? '';
		const sortWallJobId = sortWallJob?.id ?? '';
		const sortWallBatchId = sortWallJob?.externalContainerId ?? '';

		const sortWallContentLinkOrText = (routeId: string, text: string): string | JSX.Element => {
			if (routeId && text) {
				return (
					<Link url={sortWallRoute(routeId)} removeUnderline>
						{text}
					</Link>
				);
			} else {
				return text;
			}
		};

		primary.push(
			{
				content: sortWallContentLinkOrText(sortWallJobId, sortWallBatchId),
				label: messages.sortWallBatchId,
			},
			{
				content: sortWallContentLinkOrText(sortWallJobId, sortWallId),
				label: messages.stagingId,
			},
		);
	} else if (order.stagingIdentifier) {
		// Default scenario
		primary.push({
			content: order.stagingIdentifier,
			label: messages.stagingId,
		});
	}

	primary.push(
		{
			content: <DateTimeWithRelativeTime date={order.createdAt} />,
			label: messages.received,
		},
		{
			content: order.pickStrategy ? getPickStrategyName(order.pickStrategy) : null,
			label: messages.pickStrategy,
		},
		...(order?.pickStrategy === PickStrategy.Bulk && order.bulkOrder?.id
			? [
					{
						content: (
							<Link
								url={routes.bulkOrder(order?.bulkOrder.id as string | undefined)}
								removeUnderline
							>
								{order?.bulkOrder.lpn}
							</Link>
						),
						label: messages.batchId,
					},
			  ]
			: []),
		{
			content: (
				<>
					<DateTimeWithRelativeTime
						date={order.expectedShipDate}
						mode={order.status === OrderV2Status.Completed ? undefined : 'deadline'}
					/>
				</>
			),
			label: messages.carrierCutoff,
		},
	);

	const secondary = [
		{
			content: <DateTime date={order.updatedAt} />,
			label: messages.updatedAt,
		},
		{
			content: <DateTime date={pickedAt} />,
			label: messages.pickingCompletedAt,
		},
	];

	if (packouts.length > 0) {
		secondary.push({
			content: <DateTime date={packouts[0].node.completedAt} />,
			label: messages.packoutCompletedAt,
		});
	}

	return (
		<Card title={messages.details} sectioned>
			<Card.Section>
				<CardDetails primary={primary} secondary={secondary} />
			</Card.Section>
			<Card.Section>
				<Equipment order={order} />
			</Card.Section>
		</Card>
	);
}
