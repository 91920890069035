import {
	FormLayout,
	Form,
	Button,
	Layout,
	Banner,
	Page,
	Card,
	TextField,
	Link,
	Heading,
} from '@sixriver/lighthouse-web-community';
import { useState } from 'react';

import styles from './Login.module.css';
import sixrslogo from './sixrslogo.png';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

export function ForgotPassword(): JSX.Element {
	// localization
	const { messages, translate } = useLocalization();

	// router / params
	const params = new URLSearchParams(document.location.search);
	const emailAddressParam: string | null = params.get('email');

	// state
	const [emailAddress, setEmailAddress] = useState(emailAddressParam || '');
	const [wasEmailSent, setWasEmailSent] = useState<boolean>(false);
	const [emailError, setEmailError] = useState<string | undefined>(undefined);
	const [error, setError] = useState<string | undefined>(undefined);

	const onSubmit = async () => {
		setError(undefined);
		setEmailError(undefined);
		try {
			const response = await fetch(
				`${import.meta.env.VITE_GATEKEEPER_API_URL}/users/forgot-my-password/`,
				{
					body: JSON.stringify({ email: emailAddress }),
					headers: {
						'Content-Type': 'application/json',
					},
					method: 'POST',
				},
			);

			if (response.ok) {
				const result = await response.text();
				const ok = result === 'true';
				setWasEmailSent(ok);

				if (!ok) {
					setEmailError(translate(messages.emailNotRecognized, { emailAddress }) as string);
				}
			}
		} catch (error: any) {
			setError(error.message as string);
		}
	};

	const showInputFields = !(error || emailError || wasEmailSent);

	// render
	return (
		<Page>
			<Layout>
				<div className={styles.container}>
					<Card sectioned>
						<Form onSubmit={() => void onSubmit()}>
							<FormLayout>
								<img src={sixrslogo} className={styles.logo} alt="6 River Systems" />
								<Heading element="h1">{messages.enterEmailToResetPassword}</Heading>
								{error || emailError ? (
									<Banner status="critical" title={emailError ? messages.invalidEmail : undefined}>
										{error || emailError}
									</Banner>
								) : null}
								{wasEmailSent ? (
									<Banner title={messages.checkYourEmail} status="success">
										{translate(messages.resetPasswordEmailSent, { email: emailAddress })}
									</Banner>
								) : null}
								{showInputFields ? (
									<TextField
										autoComplete="on"
										name="emailAddress"
										label={messages.email}
										value={emailAddress}
										onChange={setEmailAddress}
									/>
								) : null}
								{showInputFields ? (
									<Button submit primary>
										{messages.resetPassword}
									</Button>
								) : null}
								<Link removeUnderline url={routes.login()}>
									{messages.back}
								</Link>
							</FormLayout>
						</Form>
					</Card>
					<div className={styles.version}>
						<small>{APP_VERSION}</small>
					</div>
				</div>
			</Layout>
		</Page>
	);
}
