import * as Sentry from '@sentry/react';

if (process.env.NODE_ENV !== 'test' && window.location.href.includes('6river.org')) {
	Sentry.init({
		dsn: 'https://63e55efc87c2a475e10f29155bd67163@o1146722.ingest.us.sentry.io/4507785175367680',
		integrations: [Sentry.replayIntegration()],
		replaysOnErrorSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
	});

	Sentry.setTag('site-environment', 'unknown');
	Sentry.setTag('site-name', window.location.hostname.split('.')[0]);
}
