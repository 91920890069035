import { Badge } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../hooks/useLocalization';
import { NoData } from '../NoData';

interface Props {
	sortationStatus?: any;
	isEmptyIfNoData?: boolean;
}

export function SortationStatusBadge({ sortationStatus, isEmptyIfNoData }: Props) {
	const { messages } = useLocalization();

	if (sortationStatus === 'Unassigned' || sortationStatus === 'Assigned') {
		return <Badge status="warning">{messages.unassigned}</Badge>;
	}

	if (sortationStatus === 'Ready' || sortationStatus === 'InProgress') {
		return <Badge status="info">{messages.inProgress}</Badge>;
	}

	if (sortationStatus === 'Done') {
		return <Badge>{messages.sorted}</Badge>;
	}

	if (sortationStatus === 'Canceled') {
		return <Badge>{messages.canceled}</Badge>;
	}

	if (!isEmptyIfNoData) {
		return <NoData />;
	}

	return null;
}
