import { DataTable, Card, Pagination } from '@sixriver/lighthouse-web-community';
import { ReactNode, useState } from 'react';

import styles from './DataTable.module.css';

interface SimpleDataTableProps {
	rows: string[][] | ReactNode[][];
	columns: string[];
	itemsPerPage?: number;
}

export const SimpleDataTable = ({ rows, columns, itemsPerPage }: SimpleDataTableProps) => {
	const [currentPage, setCurrentPage] = useState(1);

	const handlePageChange = (newPage: number) => {
		setCurrentPage(newPage);
	};

	return (
		<Card>
			<div className={styles.TableContainer}>
				<DataTable
					columnContentTypes={new Array(columns.length).fill('text')}
					headings={columns}
					rows={rows.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)}
				/>
			</div>
			<div className={styles.PaginationContainer}>
				<Pagination
					hasPrevious={currentPage > 1}
					hasNext={currentPage < Math.ceil(rows.length / itemsPerPage)}
					onPrevious={() => handlePageChange(currentPage - 1)}
					onNext={() => handlePageChange(currentPage + 1)}
				/>
			</div>
		</Card>
	);
};
