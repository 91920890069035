import { Card, DisplayText, Layout, Page, TextContainer } from '@sixriver/lighthouse-web-community';
// import { useLocalization } from 'hooks/useLocalization';

import Lock from './Lock.svg';
import styles from './NoAccess.module.css';

export function NoAccess() {
	// const { messages } = useLocalization();

	return (
		<Page>
			<Layout.Section>
				<Card>
					<div className={styles.container}>
						<img src={Lock} alt="Lock" width="200" height="200" />
						<div className={styles.textContainer}>
							<TextContainer>
								<DisplayText size="small">Not Authorized</DisplayText>
								<p>Contact Administrator</p>
							</TextContainer>
						</div>
					</div>
				</Card>
			</Layout.Section>
		</Page>
	);
}
