import { Banner, Modal, Text } from '@sixriver/lighthouse-web-community';
import Countdown from 'react-countdown';
import QRCode from 'react-qr-code';

import styles from './ScanQrCodeModal.module.css';
import { useLocalization } from '../../../hooks/useLocalization';

interface ScanQrCodeModalProps {
	open: boolean;
	id: string;
	siteName: string;
	mfaName: string;
	onDismiss: () => void;
	expiresAt: Date | null;
}

export function ScanQrCodeModal({
	open,
	onDismiss,
	id,
	siteName,
	mfaName,
	expiresAt,
}: ScanQrCodeModalProps) {
	const { messages, translate } = useLocalization();
	const payload = JSON.stringify({
		data: {
			device_name: mfaName,
			id,
			site_name: siteName,
		},
		meta: {
			type: '6RS Provisioning QR Code',
			version: 1,
		},
	});

	return (
		<Modal
			open={open}
			onClose={onDismiss}
			title={messages.newMfa.qrCode.title}
			primaryAction={{
				content: messages.newMfa.qrCode.close,
				onAction: onDismiss,
			}}
			large
			sectioned
			footer={messages.newMfa.qrCode.footer}
		>
			{expiresAt && (
				<Banner status="warning">
					{translate(messages.newMfa.qrCode.expiration, {
						remainingTime: (
							<Text color="critical" fontWeight="bold" as="span" variant="bodyMd">
								<Countdown
									date={expiresAt}
									renderer={({ minutes, seconds }) =>
										`${minutes}:${String(seconds).padStart(2, '0')}`
									}
									onComplete={onDismiss}
								/>
							</Text>
						),
					})}
				</Banner>
			)}
			<div className={styles.qrCode}>
				<QRCode value={payload} />
			</div>
		</Modal>
	);
}
