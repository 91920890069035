import { CircleInformationMajor } from '@sixriver/lighthouse-icons';
import { Stack, Icon, Caption } from '@sixriver/lighthouse-web-community';

import styles from './ResultSummary.module.css';
import { BulkCancelBatch, LineStatusSummary } from '../../api';
import { useLocalization } from '../../hooks/useLocalization';

interface ResultSummaryProps {
	loggedAction: BulkCancelBatch;
}

export function ResultSummary({ loggedAction }: ResultSummaryProps): JSX.Element {
	const { messages, translate } = useLocalization();

	const numberOfErrors: ErrorCounts = totalNumberErrors(loggedAction.lineStatusSummary);
	const successString = buildSuccessString(loggedAction.lineStatusSummary);

	function buildSuccessString(lineStatusSummary: LineStatusSummary): string {
		return translate(messages.countCanceled, { count: lineStatusSummary.success || 0 }) as string;
	}

	function buildCaptionText(counts: ErrorCounts): string {
		let caption = ``;
		if (counts.total) {
			const reasons = [];
			if (counts.alreadyCompleted) {
				reasons.push(
					`${messages.bulkCancelErrors.alreadyCompleted} (${translate(messages.countOrders, {
						count: counts.alreadyCompleted,
					})})`,
				);
			}
			if (counts.alreadyInducted) {
				reasons.push(
					`${messages.bulkCancelErrors.alreadyInducted} (${translate(messages.countOrders, {
						count: counts.alreadyInducted,
					})})`,
				);
			}
			if (counts.notFound) {
				reasons.push(
					`${messages.bulkCancelErrors.notFound} (${translate(messages.countOrders, {
						count: counts.notFound,
					})})`,
				);
			}
			if (counts.alreadyCancelled) {
				reasons.push(
					`${messages.bulkCancelErrors.alreadyCancelled} (${translate(messages.countOrders, {
						count: counts.alreadyCancelled,
					})})`,
				);
			}
			if (counts.unknown) {
				reasons.push(
					`${messages.bulkCancelErrors.unknown} (${translate(messages.countOrders, {
						count: counts.unknown,
					})})`,
				);
			}
			caption = `${messages.reasonForFailure}: ${reasons.join(`; `)}`;
		}
		return caption;
	}

	function buildErrorString(successString: string, errorCounts: ErrorCounts): string {
		return (
			successString + ', ' + translate(messages.countFailedToCancel, { count: errorCounts.total })
		);
	}

	return numberOfErrors.total === 0 ? (
		<span>{successString}</span>
	) : (
		<>
			<Stack spacing="tight">
				<Icon source={CircleInformationMajor} color="warning" />
				<div>
					<span className={styles.warning}>{buildErrorString(successString, numberOfErrors)}</span>
					{<Caption> {buildCaptionText(numberOfErrors)}</Caption>}
				</div>
			</Stack>
		</>
	);
}

interface ErrorCounts {
	alreadyCancelled: number;
	alreadyInducted: number;
	alreadyCompleted: number;
	notFound: number;
	unknown: number;
	total: number;
}

function totalNumberErrors(lineStatusSummary: LineStatusSummary): ErrorCounts {
	const alreadyCancelled = lineStatusSummary.CONTAINER_ALREADY_CANCELLED ?? 0;
	const alreadyInducted = lineStatusSummary.CONTAINER_ALREADY_INDUCTED ?? 0;
	const alreadyCompleted = lineStatusSummary.CONTAINER_ALREADY_COMPLETE ?? 0;
	const notFound = lineStatusSummary.CONTAINER_NOT_FOUND ?? 0;
	const unknown = lineStatusSummary.UNKNOWN_ERROR ?? 0;
	return {
		alreadyCancelled,
		alreadyCompleted,
		alreadyInducted,
		notFound,
		total: alreadyCancelled + alreadyCompleted + alreadyInducted + notFound + unknown,
		unknown,
	};
}
