import { ArrowDownMinor, ArrowUpMinor } from '@sixriver/lighthouse-icons';
import { Button, Stack } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	onMoveUp?(): void;
	onMoveDown?(): void;
}

export function PriorityControls({ onMoveUp, onMoveDown }: Props) {
	const { messages } = useLocalization();

	return (
		<Stack spacing="tight">
			<Button icon={ArrowUpMinor} disabled={onMoveUp === undefined} onClick={onMoveUp}>
				{messages.up}
			</Button>
			<Button icon={ArrowDownMinor} disabled={onMoveDown === undefined} onClick={onMoveDown}>
				{messages.down}
			</Button>
		</Stack>
	);
}
