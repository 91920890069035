import {
	FormLayout,
	Form,
	Button,
	Layout,
	Banner,
	Page,
	Card,
	TextField,
	Link,
	Heading,
} from '@sixriver/lighthouse-web-community';
import { useAuth } from '@sixriver/react-support';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import styles from './Login.module.css';
import sixrslogo from './sixrslogo.png';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

export function Login(): JSX.Element {
	const { messages } = useLocalization();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const { login } = useAuth();
	const history = useHistory();
	const location = useLocation<{ from?: Location }>();
	const [error, setError] = useState<string | undefined>(undefined);
	const from = location.state?.from || { pathname: '/' };

	const onSubmit = async () => {
		setError(undefined);

		try {
			await login(username, password);

			if (from.pathname.includes(routes.logout())) {
				history.push('/');
			} else {
				history.push(from);
			}
		} catch (error: any) {
			setError(error.message as string);
		}
	};

	return (
		<Page>
			<Layout>
				<div className={styles.container}>
					<Card sectioned>
						<Form onSubmit={() => void onSubmit()}>
							<FormLayout>
								<img src={sixrslogo} className={styles.logo} alt="6 River Systems" />
								<Heading element="h1">{messages.signInToTheBridge}</Heading>
								{error ? <Banner status="critical">{error}</Banner> : null}
								<TextField
									autoComplete="on"
									name="username"
									label={messages.email}
									value={username}
									onChange={setUsername}
								/>
								<TextField
									autoComplete="current-password"
									name="password"
									type="password"
									label={messages.password}
									value={password}
									onChange={setPassword}
								/>
								<Button submit primary>
									{messages.signIn}
								</Button>
								<Link removeUnderline url={routes.forgotPassword() + '?email=' + username}>
									{messages.forgotPassword}
								</Link>
							</FormLayout>
						</Form>
					</Card>
					<div className={styles.version}>
						<small>{APP_VERSION}</small>
					</div>
				</div>
			</Layout>
		</Page>
	);
}
