import { Count, LineQuantities } from '@sixriver/fulfillment-api-schema';
import { TextStyle, TextContainer } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../hooks/useLocalization';

interface OrderQuantityProps {
	totalLines: Count;
	totalLineQuantities: LineQuantities;
}

export function OrderQuantityV2({ totalLines, totalLineQuantities }: OrderQuantityProps) {
	const { messages, translate } = useLocalization();

	return (
		<TextContainer>
			<p>{translate(messages.countUnits, { count: totalLineQuantities?.totalUnits })}</p>
			<TextStyle variation="subdued">
				{translate(messages.countLines, { count: totalLines?.count })}
			</TextStyle>
		</TextContainer>
	);
}
