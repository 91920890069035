import { Job, Sortation } from '@sixriver/fulfillment-api-schema';
import { Card, Link } from '@sixriver/lighthouse-web-community';

import { CardDetails } from '../../components/CardDetails';
import { DateTime } from '../../components/DateTime';
import { NoData } from '../../components/NoData';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

interface Props {
	job?: Job;
	loading?: boolean;
	sortWall?: Sortation;
}

export function SortationDetails({ loading, sortWall, job }: Props) {
	const { messages, translate } = useLocalization();

	const pickedUnits = job?.totalLineQuantities?.pickedUnits;
	const totalUnits = job?.totalLineQuantities?.totalUnits;

	const completedLines = job?.totalLineQuantities?.completedLines;
	const totalLines = job?.totalLines?.count;

	return (
		<Card sectioned title={messages.sortationDetails}>
			<CardDetails
				loading={loading}
				primary={[
					{
						content: sortWall?.wall ? (
							<Link url={routes.sortWall(sortWall.jobId)}>{sortWall.wall}</Link>
						) : (
							<NoData />
						),
						label: messages.sortWall,
					},
					{
						content: translate(messages.pickedOfTotal, {
							picked: pickedUnits,
							total: totalUnits,
						}),
						label: messages.unitsPicked,
					},
					{
						content: translate(messages.pickedOfTotal, {
							picked: completedLines,
							total: totalLines,
						}),
						label: messages.linesPicked,
					},
				]}
				secondary={[
					{
						content: sortWall?.batchId || <NoData />,
						label: messages.batchId,
					},
					{
						content: sortWall?.kiosk || <NoData />,
						label: messages.assignedKiosk,
					},
					{
						content: <DateTime date={sortWall?.timeline?.completedAt} />,
						label: messages.completedAt,
					},
				]}
			/>
		</Card>
	);
}
