import { noop } from 'lodash';

import { DeviceType } from '../../api';
import { ViewTabs } from '../../components/ViewTabs';

export type DeviceViewTabType = 'All' | DeviceType;

export type DeviceViewTab = {
	id: DeviceViewTabType;
	label: string;
	count?: number;
};

export interface DeviceViewTabsProps {
	viewTabs: DeviceViewTab[];
	selected: string;
	onSelect?: (id: DeviceViewTabType) => void;
}

export function DevicesViewTabs({ viewTabs, selected, onSelect = noop }: DeviceViewTabsProps) {
	return (
		<ViewTabs
			tabs={viewTabs.map(({ id, label, count }) => {
				return {
					id,
					label,
					metaLabel: count,
				};
			})}
			selected={selected}
			onSelect={onSelect}
		/>
	);
}
