import { Button, Card, Layout, Page, Spinner, Stack } from '@sixriver/lighthouse-web-community';
import { DateTime } from '@sixriver/react-support';
import { useRouteMatch } from 'react-router-dom';

import { DeviceBadge } from './DeviceBadge';
import { DeviceBattery } from './DeviceBattery';
import RelatedJobs from './RelatedJobs';
import RelatedOrders from './RelatedOrders';
import { DeviceType } from '../../api';
import { CardDetails } from '../../components/CardDetails';
import { Error } from '../../components/Error';
import { NoData } from '../../components/NoData';
import { getDeviceType, useAvailableMfp } from '../../hooks/useAvailableMfps';
import { useIsWorkAreasEnabled, useMfpConfigForDevice, useSsid } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

export default function Device(): JSX.Element {
	const isWorkAreasEnabled = useIsWorkAreasEnabled();
	const { messages } = useLocalization();
	const {
		params: { deviceId },
	} = useRouteMatch<{ deviceId: string }>();
	const { fetching, data: device, error } = useAvailableMfp(deviceId);
	const { data: mfpConfig } = useMfpConfigForDevice(device);
	const ssid = useSsid();

	if (fetching && !device) {
		return (
			<Page title={deviceId || ''}>
				<Spinner />
			</Page>
		);
	}

	if (error) {
		return <Error message={error.message} />;
	}

	if (!device) {
		return <Error message={messages.deviceNotFound} />;
	}

	if (!mfpConfig) {
		return <Error message={messages.mfpConfigNotFound} />;
	}

	const deviceType = getDeviceType(mfpConfig);

	return (
		<Page title={mfpConfig.name}>
			<Layout>
				<Layout.Section></Layout.Section>
				<Layout.Section>
					<Card title={messages.profile}>
						<Card.Section>
							<CardDetails
								loading={fetching}
								primary={[
									{
										content: mfpConfig.name,
										label: messages.name,
									},
									{
										content: device ? (
											messages.deviceTypes[deviceType as keyof typeof messages.deviceTypes]
										) : (
											<NoData />
										),
										label: messages.model,
									},
									{
										content: ssid,
										label: messages.network,
									},
								]}
							/>
						</Card.Section>
						{deviceType === DeviceType.TerminalOnWheels ||
						deviceType === DeviceType.Handheld ? null : (
							<Card.Section>
								<CardDetails
									loading={fetching}
									primary={[
										{
											content: <DeviceBattery device={device} mfpConfig={mfpConfig} />,
											label: messages.batteryLevel,
										},
										{
											content: <DateTime date={mfpConfig.calibrationData.calibratedAt} />,
											label: messages.lastCalibrated,
										},
									]}
								/>
							</Card.Section>
						)}
					</Card>
				</Layout.Section>
				<Layout.Section>
					<Card title={messages.activity}>
						<Card.Section>
							<CardDetails
								loading={fetching}
								primary={[
									{
										content: <DeviceBadge device={device} />,
										label: messages.status,
									},
									{
										content: <DateTime date={device.updatedAt} />,
										label: messages.lastActive,
									},
									...(isWorkAreasEnabled
										? [
												{
													content: device.currentPose?.mapChunk.displayName,
													label: messages.workArea,
												},
										  ]
										: []),
									...(deviceType === DeviceType.TerminalOnWheels ||
									deviceType === DeviceType.Handheld
										? []
										: [
												{
													content: <RelatedOrders device={device} />,
													label: messages.orders,
												},
												{
													content: <RelatedJobs device={device} />,
													label: messages.jobs,
												},
										  ]),
								]}
							/>
						</Card.Section>
						<Card.Section>
							<Stack alignment="baseline">
								{
									// TODO: gimli too
									deviceType === DeviceType.Chuck ? (
										<Button plain url={`${routes.floorView()}/?chuck=${device.id}`}>
											{messages.findThisChuck}
										</Button>
									) : null
								}
							</Stack>
						</Card.Section>
					</Card>
				</Layout.Section>
			</Layout>
		</Page>
	);
}
