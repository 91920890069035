import { useFetch } from '@hyper-fetch/react';
import { ServiceConfig } from '@sixriver/config-schema';
import { HighPriorityReason } from '@sixriver/fulfillment-api-schema';

import { GlobalErrorType, getServiceConfig } from '../../api';

export function useServiceConfig(): {
	data: { config: ServiceConfig } | null;
	error?: GlobalErrorType | null;
	fetching: boolean;
} {
	const { loading, data, error } = useFetch(getServiceConfig);
	return { data, error, fetching: loading };
}

export function useIsExternalInventoryEnabled(): boolean {
	const { data: serviceConfig } = useServiceConfig();
	return serviceConfig?.config.wis?.northbound?.externalInventory?.enabled === true;
}

export function useIsSortationEnabled(): boolean {
	const { data: serviceConfig } = useServiceConfig();
	return serviceConfig?.config.sortationServices?.enabled === true;
}

export function useIsPackoutEnabled(): boolean {
	const { data: serviceConfig } = useServiceConfig();
	return serviceConfig?.config.packoutServices?.enabled === true;
}

export function useIsHealingEnabled(): boolean {
	const { data: serviceConfig } = useServiceConfig();
	return serviceConfig?.config.healingServices?.enabled === true;
}

export function useIsHighPriorityChuckEnabled(): boolean {
	const { data: serviceConfig } = useServiceConfig();
	return serviceConfig?.config.workflows?.highPriorityIndicator?.enabled === true;
}

export function useIsAutoHealingEnabled(): boolean {
	const healingStrategies = useHealingStrategies();
	return healingStrategies.includes(HealingStrategy.Automated);
}

export function useIsOrderTagEnabled(): boolean {
	const { data: serviceConfig } = useServiceConfig();
	return serviceConfig?.config.sortationServices?.customerBarcodes?.enabled === true;
}

export function useIsPutawayEnabled(): boolean {
	const { data: serviceConfig } = useServiceConfig();
	return serviceConfig?.config.workflows?.putaway?.enabled === true;
}

export function useEquipmentTypes(): string[] {
	const { data: serviceConfig } = useServiceConfig();
	const equipmentTypes = [];
	const jobAllocationMethods = serviceConfig?.config.jobAllocation?.methods;

	if (jobAllocationMethods) {
		equipmentTypes.push(Object.keys(jobAllocationMethods.consolidation?.equipment || {}));
		equipmentTypes.push(Object.keys(jobAllocationMethods.group?.equipment || {}));
		equipmentTypes.push(Object.keys(jobAllocationMethods.discrete?.equipment || {}));
		equipmentTypes.push(Object.keys(jobAllocationMethods.batch?.equipment || {}));
		equipmentTypes.push(Object.keys(jobAllocationMethods.slsu?.equipment || {}));
		equipmentTypes.push(Object.keys(jobAllocationMethods.slmu?.equipment || {}));
	}

	return Array.from(new Set(equipmentTypes.flat().sort()));
}

export enum HealingStrategy {
	Manual = 'Manual',
	Automated = 'Automated',
}

export function useHealingStrategies(): HealingStrategy[] {
	const { data: serviceConfig } = useServiceConfig();
	const strategies: HealingStrategy[] = [];
	const healingStrategyConditions =
		serviceConfig?.config.healingServices?.healingStrategyConditions ?? [];
	healingStrategyConditions.map((healingStrategyCondition: any) => {
		if (healingStrategyCondition.config.includes('MANUAL_PICK')) {
			strategies.push(HealingStrategy.Manual);
		}
		if (
			healingStrategyCondition.config.includes('DISCRETE_HEAL_JOB') ||
			healingStrategyCondition.config.includes('AUTO_HEAL_JOB')
		) {
			strategies.push(HealingStrategy.Automated);
		}
	});
	return strategies;
}

export function useDeviceOfflineThreshold(): number {
	const { data: serviceConfig } = useServiceConfig();
	return serviceConfig?.config.floorView?.offlineTime ?? 120;
}

export function useDeviceDwellThreshold(): number {
	const { data: serviceConfig } = useServiceConfig();
	/**
	 * There is a known tech debt that "unset defaults" from the GRM are copy/pasted around in multiple
	 * code repos. For dwell time, the current value (7/7/2022) is 30 mins (1800 seconds).
	 */
	return serviceConfig?.config.floorView?.dwellTime ?? 1800;
}

export type BatteryThresholds = {
	viable?: number;
	critical?: number;
};

export function useBatteryThresholds(): BatteryThresholds {
	const { data: serviceConfig } = useServiceConfig();
	return {
		critical: serviceConfig?.config.charge?.criticalBatteryLevel,
		viable: serviceConfig?.config.charge?.viableBatteryLevel,
	};
}

export function useHighPriorityChuckReasonsOrdered(): HighPriorityReason[] {
	const { data: serviceConfig } = useServiceConfig();
	const highPriorityScores =
		serviceConfig?.config.workflows?.highPriorityIndicator?.scoreCriteria ?? [];
	const sortByPriority = (
		a: {
			condition?:
				| {
						[k: string]: unknown;
				  }
				| undefined;
			config?: number | undefined;
		},
		b: {
			condition?:
				| {
						[k: string]: unknown;
				  }
				| undefined;
			config?: number | undefined;
		},
	) => {
		if (!a.config) {
			return 1;
		}

		if (!b.config) {
			return -1;
		}

		return a.config - b.config;
	};
	const scoreConditionsNotNull = highPriorityScores
		.filter((scoreCriteria) => !!scoreCriteria.condition)
		.map((scoreCriteria) => ({
			condition: { ...scoreCriteria.condition },
			config: scoreCriteria.config,
		}));
	return scoreConditionsNotNull
		.sort(sortByPriority)
		.flatMap((sortedScores) =>
			sortedScores.condition ? (Object.keys(sortedScores.condition) as HighPriorityReason[]) : [],
		);
}

export function useIsMapPointQueueDepthEditable(): boolean {
	const { data: serviceConfig } = useServiceConfig();
	return serviceConfig?.config.floorView?.queueDepthToggleEnabled === true;
}

export function useIsMapPointStatusEditable(): boolean {
	const { data: serviceConfig } = useServiceConfig();
	return serviceConfig?.config.floorView?.workflowStatusToggleEnabled === true;
}

export enum JobAllocationMethod {
	Discrete = 'Discrete',
	Group = 'Group',
	Consolidation = 'Consolidation',
	Batch = 'Batch',
	MobileSortWall = 'MobileSortWall',
	SingleLineSingleUnit = 'SingleLineSingleUnit',
	SingleLineMultiUnit = 'SingleLineMultiUnit',
	BulkOrders = 'BulkOrders',
}

export function useJobAllocationMethods(): JobAllocationMethod[] {
	const jobAllocationMethods: JobAllocationMethod[] = [];
	const { data: serviceConfig } = useServiceConfig();
	const methods = serviceConfig?.config.jobAllocation?.methods;

	if (methods?.consolidation?.enabled) {
		jobAllocationMethods.push(JobAllocationMethod.Consolidation);
	}

	if (methods?.group?.enabled) {
		jobAllocationMethods.push(JobAllocationMethod.Group);
	}

	if (methods?.discrete?.enabled) {
		jobAllocationMethods.push(JobAllocationMethod.Discrete);
	}

	if (methods?.batch?.enabled) {
		jobAllocationMethods.push(JobAllocationMethod.Batch);
	}

	if (methods?.mobileSortWall?.enabled) {
		jobAllocationMethods.push(JobAllocationMethod.MobileSortWall);
	}

	if (methods?.slsu?.enabled) {
		jobAllocationMethods.push(JobAllocationMethod.SingleLineSingleUnit);
	}

	if (methods?.slmu?.enabled) {
		jobAllocationMethods.push(JobAllocationMethod.SingleLineMultiUnit);
	}

	if (methods?.bulk?.enabled) {
		jobAllocationMethods.push(JobAllocationMethod.BulkOrders);
	}

	return jobAllocationMethods;
}

export function useExperimentalFlags() {
	const { data: serviceConfig } = useServiceConfig();
	return serviceConfig?.config.managementConsole?.experimentalFlags ?? [];
}

export function useContainerMaterialTypes(): string[] {
	const { data: serviceConfig } = useServiceConfig();
	return serviceConfig?.config.inventoryService?.containerTypes?.packageMaterialTypes ?? [];
}
