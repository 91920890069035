import { OrderV2Connection } from '@sixriver/fulfillment-api-schema';
import { gql } from 'graphql-tag';

import { PAGE_INFO_FRAGMENT } from '../../graphql';

export interface ExceptionsData {
	ordersV2: OrderV2Connection;
}

export const EXCEPTIONS_QUERY = gql`
	query Orders(
		$after: String
		$first: Int
		$searchText: String
		$statuses: [OrderV2Status!]
		$isShorted: Boolean
		$isHealable: Boolean
		$hasExceptions: Boolean
		$healPlanCompletedAtFrom: DateTime
		$healPlanCompletedAtTo: DateTime
	) {
		ordersV2(
			after: $after
			first: $first
			searchText: $searchText
			statuses: $statuses
			isShorted: $isShorted
			isHealable: $isHealable
			hasExceptions: $hasExceptions
			healPlanCompletedAtFrom: $healPlanCompletedAtFrom
			healPlanCompletedAtTo: $healPlanCompletedAtTo
		) {
			edges {
				node {
					id
					externalId
					status
					jobStatus
					createdAt
					expectedShipDate
					externalContainerId
					isShorted
					tasks {
						workArea {
							id
							name
						}
					}
					lines {
						quantity
						doneQuantity
						status
					}
					totalLineQuantities {
						pickedUnits
						totalUnits
					}
					exceptions {
						isHealable
					}
				}
			}
			pageInfo {
				...PageInfo
			}
		}
	}
	${PAGE_INFO_FRAGMENT}
`;
