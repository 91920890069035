import { PickWavesLogButton as PickWavesLogButtonComponent } from '@sixriver/integration-visibility-app';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import * as routes from '../../routes';

export function PickWavesLogButton() {
	const history = useHistory();
	const onClick = useCallback(() => {
		history.push(routes.pickWaves());
	}, [history]);
	return <PickWavesLogButtonComponent onClick={onClick} />;
}
