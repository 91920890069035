import { useAuth } from '@sixriver/react-support';
import { ReactNode } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { AccessControl, AccessControlProps } from './AccessControl';
import * as routes from '../../routes';

type ProtectedRouteProps = {
	children: ReactNode;
};

export function ProtectedRoute({
	children,
	...rest
}: ProtectedRouteProps & RouteProps & AccessControlProps) {
	const { isAuthenticated } = useAuth();

	return (
		<Route
			{...rest}
			render={({ location }) => {
				if (isAuthenticated()) {
					return <AccessControl {...rest}>{children}</AccessControl>;
				}

				return <Redirect to={{ pathname: routes.login(), state: { from: location } }} />;
			}}
		/>
	);
}
