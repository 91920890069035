import { DeviceOrderByFields, OrderByDirection } from '@sixriver/fulfillment-api-schema';
import {
	AppliedFilterInterface,
	ChoiceList,
	FilterInterface,
	Filters,
} from '@sixriver/lighthouse-web-community';
import { isEmpty } from 'lodash';

import { DeviceState, MapChunk } from '../../api';
import { SortBy, SortChoice } from '../../components/SortBy';
import { useLocalization } from '../../hooks/useLocalization';

export type DeviceOrderBy = {
	direction: OrderByDirection;
	field: DeviceOrderByFields;
};

const STATE_KEY = 'state';
const MAP_CHUNK_KEY = 'mapChunk';

type DevicesFiltersProps = {
	isWorkAreasEnabled?: boolean;
	mapChunks: MapChunk[];
	queryValue: string;
	onQueryValueChange: (queryValue: string) => void;
	selectedStates: DeviceState[];
	onSelectedStatesChange: (states: DeviceState[]) => void;
	selectedMapChunkIds: string[];
	onSelectedMapChunkIdsChange: (mapChunkIds: string[]) => void;
	selectedSort: DeviceOrderBy[];
	onSortChange: (value: DeviceOrderBy[]) => void;
	onClearAll: () => void;
};

export function DevicesFilters({
	isWorkAreasEnabled = false,
	mapChunks,
	queryValue,
	onQueryValueChange,
	selectedStates,
	onSelectedStatesChange,
	selectedMapChunkIds,
	onSelectedMapChunkIdsChange,
	selectedSort,
	onSortChange,
	onClearAll,
}: DevicesFiltersProps) {
	const { messages } = useLocalization();

	/**
	 * Filters
	 */
	const filters: FilterInterface[] = [
		{
			filter: (
				<ChoiceList
					title={messages.status}
					titleHidden
					choices={[
						{ label: messages.inUse, value: DeviceState.InUse },
						{ label: messages.notInUse, value: DeviceState.NotInUse },
						{ label: messages.disconnected, value: DeviceState.Disconnected },
					]}
					selected={selectedStates}
					onChange={onSelectedStatesChange}
					allowMultiple
				/>
			),
			key: STATE_KEY,
			label: messages.status,
			shortcut: true,
		},
		...(isWorkAreasEnabled
			? [
					{
						filter: (
							<ChoiceList
								title={messages.workAreas}
								titleHidden
								choices={mapChunks.map((mapChunk) => ({
									label: mapChunk.displayName || `Work area ${mapChunk.mapChunkId}`,
									value: mapChunk.mapChunkId,
								}))}
								selected={selectedMapChunkIds || []}
								onChange={onSelectedMapChunkIdsChange}
								allowMultiple
							/>
						),
						key: MAP_CHUNK_KEY,
						label: messages.workAreas,
						shortcut: true,
					},
			  ]
			: []),
	];
	const appliedFilters: AppliedFilterInterface[] = [];

	if (!isEmpty(selectedStates)) {
		const selectedStatesLabels = selectedStates.map((selectedState) => {
			switch (selectedState) {
				case DeviceState.Disconnected:
					return messages.disconnected;
				case DeviceState.InUse:
					return messages.inUse;
				case DeviceState.NotInUse:
					return messages.notInUse;
			}
		});
		appliedFilters.push({
			key: STATE_KEY,
			label: selectedStatesLabels.join(', '),
			onRemove: () => {
				onSelectedStatesChange([]);
			},
		});
	}

	if (!isEmpty(selectedMapChunkIds)) {
		const selectedMapChunkDisplayNames = selectedMapChunkIds.map((selectedMapChunkId) => {
			const mapChunk = mapChunks.find((mapChunk) => mapChunk.mapChunkId === selectedMapChunkId);
			return mapChunk?.displayName || `Work area ${selectedMapChunkId}`;
		});
		appliedFilters.push({
			key: MAP_CHUNK_KEY,
			label: selectedMapChunkDisplayNames.join(', '),
			onRemove: () => {
				onSelectedMapChunkIdsChange([]);
			},
		});
	}

	/**
	 * Sort choices
	 */
	const sortChoices: SortChoice[] = [
		{
			label: messages.sortOptions.batteryLevelAsc,
			value: DeviceOrderByFields.BatteryLevel + ' ' + OrderByDirection.Asc,
		},
		{
			label: messages.sortOptions.batteryLevelDesc,
			value: DeviceOrderByFields.BatteryLevel + ' ' + OrderByDirection.Desc,
		},
		{
			label: messages.sortOptions.lastUsedAsc,
			value: DeviceOrderByFields.LastUsed + ' ' + OrderByDirection.Asc,
		},
		{
			label: messages.sortOptions.lastUsedDesc,
			value: DeviceOrderByFields.LastUsed + ' ' + OrderByDirection.Desc,
		},
		{
			label: messages.sortOptions.nameAsc,
			value: DeviceOrderByFields.Name + ' ' + OrderByDirection.Asc,
		},
		{
			label: messages.sortOptions.nameDesc,
			value: DeviceOrderByFields.Name + ' ' + OrderByDirection.Desc,
		},
	];

	return (
		<Filters
			queryPlaceholder={messages.filterDevices}
			queryValue={queryValue}
			onQueryChange={onQueryValueChange}
			onQueryClear={() => onQueryValueChange('')}
			filters={filters}
			appliedFilters={appliedFilters}
			onClearAll={onClearAll}
		>
			<SortBy
				choices={sortChoices}
				selected={selectedSort.map((sort) => `${sort.field} ${sort.direction}`)}
				onChange={(selected) => onSortChange(convertToDeviceOrderBy(selected))}
			/>
		</Filters>
	);
}

function convertToDeviceOrderBy(sortStrings: string[]): DeviceOrderBy[] {
	return sortStrings.map((option) => {
		const [field, direction] = option.split(' ') as [DeviceOrderByFields, OrderByDirection];
		return { direction, field };
	});
}
