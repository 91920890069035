import { Job } from '@sixriver/fulfillment-api-schema';
import { Card } from '@sixriver/lighthouse-web-community';

import { CardDetails } from '../../components/CardDetails';
import { CopyButton } from '../../components/CopyButton';
import { isExceptionActionableV2 } from '../../helpers/exception';
import { useLocalization } from '../../hooks/useLocalization';
import { useShowExceptionPage } from '../../hooks/useShowExceptionPage';
import * as routes from '../../routes';
import { ActiveExceptions } from '../Order/ActiveExceptions';

const getParams = (jobs?: Array<Job>) => {
	const searchParams = new URLSearchParams();

	if (jobs) {
		jobs.forEach((job) => {
			searchParams.append('ids', job.id);
		});
	}

	return `?${searchParams.toString()}`;
};

interface Props {
	loading?: boolean;
	job?: Job;
}

export function PickingProgress({ loading, job }: Props) {
	const { messages, translate } = useLocalization();
	const showExceptionPage = useShowExceptionPage();

	const pickedUnits = job?.totalLineQuantities?.pickedUnits;
	const totalUnits = job?.totalLineQuantities?.totalUnits;

	const completedLines = job?.totalLineQuantities?.completedLines;
	const totalLines = job?.totalLines?.count;

	const lines = job ? (job.lines as any).edges : [];
	const numberOfLinesWithExceptions = lines.filter(({ node }) => node.isShorted).length;

	const servicingOrders = job?.orders ?? [];
	const exceptionOrders = servicingOrders.filter((order) => order.isShorted);

	const primaryListItems = [
		{
			content: translate(messages.pickedOfTotal, {
				picked: pickedUnits,
				total: totalUnits,
			}),
			label: messages.unitsPicked,
		},
		{
			content:
				lines.length > 0
					? translate(messages.pickedOfTotal, {
							picked: completedLines,
							total: totalLines,
					  })
					: null,
			label: messages.linesPicked,
		},
		{
			content: <ActiveExceptions lines={numberOfLinesWithExceptions} jobs={0} />,
			label: messages.activeExceptions,
		},
		...(job?.isHealJob && job?.externalContainerId
			? [
					{
						content: <CopyButton text={job.externalContainerId} />,
						label: messages.pickingLabel,
					},
			  ]
			: []),
	];

	return (
		<Card
			sectioned
			title={messages.pickingProgress}
			primaryFooterAction={{
				content: translate(messages.viewOrders, { count: servicingOrders.length }) as string,
				disabled: !servicingOrders.length,
				url:
					servicingOrders.length === 1
						? routes.order(servicingOrders[0]?.id)
						: `${routes.orders()}${getParams(servicingOrders)}`,
			}}
			secondaryFooterActions={
				showExceptionPage
					? [
							{
								content: messages.manageExceptions,
								disabled: numberOfLinesWithExceptions === 0 || !isExceptionActionableV2(job),
								url:
									exceptionOrders.length === 1
										? routes.exception(exceptionOrders[0]?.id)
										: `${routes.exceptions()}${getParams(exceptionOrders)}`,
							},
					  ]
					: []
			}
			footerActionAlignment="left"
		>
			<CardDetails
				loading={loading}
				primary={primaryListItems}
				secondary={[
					{
						content: servicingOrders.length,
						label: messages.orders,
					},
					{
						content:
							servicingOrders.length > 1 ? (
								messages.multiple
							) : servicingOrders.length === 1 ? (
								<CopyButton text={servicingOrders[0]?.externalId} />
							) : null,
						label: messages.orderId,
					},
				]}
			/>
		</Card>
	);
}
