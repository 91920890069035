const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;
const ONE_MONTH = ONE_DAY * 30;
const ONE_YEAR = ONE_DAY * 365;

export function formatDateDiff(locale = 'en', date: Date | string | number): string {
	const opts: any = {
		numeric: 'always',
		style: 'long',
	};

	const ms = new Date(date).getTime() - Date.now();
	const absMs = Math.abs(ms);

	let value: number;
	let unit: Intl.RelativeTimeFormatUnit;

	if (absMs < ONE_MINUTE) {
		value = Math.round(ms / ONE_SECOND);
		unit = 'seconds';
	} else if (absMs < ONE_HOUR) {
		value = Math.round(ms / ONE_MINUTE);
		unit = 'minutes';
	} else if (absMs < ONE_DAY) {
		value = Math.round(ms / ONE_HOUR);
		unit = 'hours';
	} else if (absMs < ONE_MONTH) {
		value = Math.round(ms / ONE_DAY);
		unit = 'days';
	} else if (absMs < ONE_YEAR) {
		value = Math.round(ms / ONE_MONTH);
		unit = 'months';
	} else {
		value = Math.round(ms / ONE_YEAR);
		unit = 'years';
	}

	return new Intl.RelativeTimeFormat(locale, opts).format(value, unit);
}
