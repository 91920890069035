import { Job, JobStatus, OrderV2 } from '@sixriver/fulfillment-api-schema';
import { PromoteMinor } from '@sixriver/lighthouse-icons';
import { Icon, Stack, TextStyle, DisplayText, Heading } from '@sixriver/lighthouse-web-community';
import { useHistory } from 'react-router-dom';

import styles from './BreakdownAndIssues.module.css';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

const getParams = (jobs?: (OrderV2 | Job)[]) => {
	const searchParams = new URLSearchParams();

	if (jobs) {
		jobs.forEach((job) => {
			searchParams.append('ids', job.id);
		});
	}

	return `?${searchParams.toString()}`;
};

interface Props {
	order: OrderV2;
}

export function BreakdownAndIssues({ order }: Props) {
	const { messages, translate } = useLocalization();

	const history = useHistory();
	const unassignedResolutionJobs = order.pickingJobs.filter(
		(job) => job.isHealJob && job.status === JobStatus.Unassigned,
	);
	const relatedJobs = [...unassignedResolutionJobs, ...order.pickingJobs];
	const disableTrackJobs = relatedJobs.length === 0;

	// const numberOfLineExceptions = countLineExceptionsV2(order.lines);
	const numberOfLineExceptions = 0;
	// const numberOfUnitExceptions = countUnitExceptionsV2(order.lines);
	const numberOfUnitExceptions = 0;
	const lines = order.lines ? (order.lines as any).edges : [];

	return (
		<div className={styles.cardSection}>
			<Stack vertical={false} distribution={'fillEvenly'}>
				<>
					<div className={styles.header}>
						<Heading>{messages.breakdown}</Heading>
					</div>
					<Stack vertical={false}>
						<Stack vertical={true}>
							<TextStyle variation="strong">{messages.totalUnits}</TextStyle>
							<DisplayText>
								{lines.reduce((acc, { node }) => (acc += node.quantity), 0)}
							</DisplayText>
						</Stack>
						<Stack vertical={true}>
							<TextStyle variation="strong">{messages.totalLines}</TextStyle>
							<DisplayText>{lines.length.toString()}</DisplayText>
						</Stack>
						<Stack vertical={true}>
							<TextStyle variation="strong">{messages.jobs}</TextStyle>
							<button
								className={styles.jobs}
								onClick={() => history.push(`${routes.outboundJobs()}${getParams(relatedJobs)}`)}
								disabled={disableTrackJobs}
							>
								<Stack vertical={false} alignment="center" spacing="tight">
									<DisplayText size="medium">{relatedJobs.length.toString()}</DisplayText>
									<Icon
										source={PromoteMinor}
										color={disableTrackJobs ? 'subdued' : 'primary'}
									></Icon>
								</Stack>
							</button>
						</Stack>
					</Stack>
				</>
				<>
					<div className={styles.header}>
						<Heading>{messages.issues}</Heading>
					</div>
					<Stack>
						<Stack vertical={true}>
							<TextStyle variation="strong">{messages.activeExceptions}</TextStyle>
							<TextStyle>
								{translate(messages.countUnits, {
									count: <b>{numberOfUnitExceptions}</b>,
								})}
							</TextStyle>
							<TextStyle>
								{translate(messages.countLines, {
									count: <b>{numberOfLineExceptions}</b>,
								})}
							</TextStyle>
						</Stack>
					</Stack>
				</>
			</Stack>
		</div>
	);
}
