import '@sixriver/lighthouse-web-community/dist/css/bundled.css';
import './CustomPropertyOverrides.css';

import { AppProvider, I18nProvider } from '@sixriver/lighthouse-web-community';
import enTranslations from '@sixriver/lighthouse-web-community/dist/locales/en.json';
import { PropsWithChildren } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { useLocalization } from '../../hooks/useLocalization';

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;

function Link({
	children,
	url = '',
	external,
	...rest
}: PropsWithChildren<{
	url: string;
	external?: boolean;
	target?: string;
	rel?: string;
}>) {
	// react-router only supports links to pages it can handle itself. It does not
	// support arbirary links, so anything that is not a path-based link should
	// use a reglar old `a` tag
	if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
		rest.target = '_blank';
		rest.rel = 'noopener noreferrer';
		return (
			<a href={url} {...rest}>
				{children}
			</a>
		);
	}

	return (
		<ReactRouterLink to={url} {...rest}>
			{children}
		</ReactRouterLink>
	);
}

export function LighthouseAppProvider({ children }: PropsWithChildren<Record<never, any>>) {
	// Use this commented code instead once upgraded to lighthouse 2.15.0
	// const { locale } = useLocalization();

	// return (
	// 	<I18nProvider>
	// 		<AppProvider locale={locale} linkComponent={Link}>
	// 			{children}
	// 		</AppProvider>
	// 	</I18nProvider>
	// );

	const { messages } = useLocalization();

	return (
		<I18nProvider extendedTranslations={messages}>
			<AppProvider i18n={enTranslations} linkComponent={Link}>
				{children}
			</AppProvider>
		</I18nProvider>
	);
}
