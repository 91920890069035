import { Chuck } from './Chuck.type';
import { ChuckArgs } from './ChuckArgs';
import { FeatureType, FeatureTypeMap } from './FeatureType';
import { Telemetry } from './Telemetry';

/**
 * There is a known tech debt that "unset defaults" from the GRM are copy/pasted around in multiple
 * code repos.   For dwell time, the current value (7/7/2022) is 30 mins (1800 seconds).
 */
export const DEFAULT_DWELL_TIME = 1800;

export function isVisible(chuck: Chuck): boolean {
	return chuck.telemetry !== undefined && chuck.online === true;
}

export function isPicking(chuck: Chuck): boolean {
	return isVisible(chuck) && !!chuck.badge && chuck.phase === 'picking';
}

export function isDwellingIdle(chuck: Chuck): boolean {
	return isVisible(chuck) && chuck.dwellingIdle === true && isHighPriority(chuck) === false;
}

export function isDwellingPaused(chuck: Chuck): boolean {
	return isVisible(chuck) && chuck.dwellingPaused === true && isHighPriority(chuck) === false;
}

export function isDwelling(chuck: Chuck): boolean {
	return isDwellingIdle(chuck) || isDwellingPaused(chuck);
}

export function isLowBattery(chuck: Chuck): boolean {
	return isVisible(chuck) && (chuck.telemetry?.batteryLevel || 0) < (chuck.batteryThreshold || 35);
}

export function isFaulty(chuck: Chuck): boolean {
	return isVisible(chuck) && (chuck.telemetry?.faults || []).length > 0;
}

export function isPaused(chuck: Chuck): boolean {
	return isVisible(chuck) && chuck.telemetry?.motionState === 'paused';
}

export function isCharging(chuck: Chuck): boolean {
	return isVisible(chuck) && chuck.telemetry?.motionState === 'charging';
}

export function isUpgrading(chuck: Chuck): boolean {
	return isVisible(chuck) && chuck.telemetry?.stageUpgradeInfo !== undefined;
}

export function isHighPriority(chuck: Chuck): boolean {
	return isVisible(chuck) && !!chuck.telemetry && chuck.telemetry.highPriorityReasons.length > 0;
}

export function isDestinationOfType(
	chuck: Chuck,
	type: FeatureType,
	typeMap: FeatureTypeMap,
): boolean {
	const destination = chuck.telemetry?.destination;

	return isVisible(chuck) && type === typeMap.get(destination || '');
}

export function durationIdle(chuck: Chuck, now = Date.now()): number {
	return now - (chuck.telemetry?.idleAt?.getTime() || now);
}

export function durationPaused(chuck: Chuck, now = Date.now()): number {
	return now - (chuck.telemetry?.pausedAt?.getTime() || now);
}

export function durationIdleOrPaused(chuck: Chuck, now = Date.now()): number {
	return durationIdle(chuck, now) || durationPaused(chuck, now);
}

export function durationFaulty(chuck: Chuck, threshold = 0, now = Date.now()): number {
	const duration = now - (chuck.telemetry?.faultyAt?.getTime() || now);

	return duration >= threshold ? duration : 0;
}

export function distanceTraveled(t1: Telemetry, t2: Telemetry) {
	return Math.sqrt(Math.pow(t1.x - t2.x, 2) + Math.pow(t1.y - t2.y, 2));
}

export function matchChucks(chucks: Chuck[], args: ChuckArgs): Chuck[] {
	const { searchText, statuses } = args;
	const normalInput = searchText.trim().toLowerCase();

	if (!(statuses.length || normalInput.length)) {
		return chucks;
	}

	return chucks.map((chuck) => {
		const normalName = (chuck.name || '').toLowerCase();

		let matched = false;

		if (statuses.length) {
			if (statuses.includes('priority')) {
				matched = matched || isHighPriority(chuck);
			}
			if (statuses.includes('offline')) {
				matched = matched || chuck.online === false;
			}
			if (statuses.includes('faulty')) {
				matched = matched || isFaulty(chuck);
			}
			if (statuses.includes('lowBattery')) {
				matched = matched || isLowBattery(chuck);
			}
			if (statuses.includes('dwellingIdle')) {
				matched = matched || isDwellingIdle(chuck);
			}
			if (statuses.includes('dwellingPaused')) {
				matched = matched || isDwellingPaused(chuck);
			}
			if (statuses.includes('charging')) {
				matched = matched || isCharging(chuck);
			}
			if (statuses.includes('paused')) {
				matched = matched || isPaused(chuck);
			}
			if (normalInput.length) {
				matched = matched && normalName.includes(normalInput);
			}
		} else if (normalInput.length) {
			matched = normalName.includes(normalInput);
		}

		return { ...chuck, matched };
	});
}
