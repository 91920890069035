import { PropsWithChildren, ReactElement } from 'react';

import { Column } from '../components/DataTable';

interface ColumnRow<T> extends Partial<Column> {
	render: React.FC<T>;
}

export function createColumnsAndRows<T>(
	columnRows: ColumnRow<T>[],
	data: PropsWithChildren<T>[],
): {
	columns: Column[];
	rows: (ReactElement<any, any> | null)[][];
} {
	return {
		columns: columnRows.map((cr) => ({
			heading: cr.heading || '',
			sortable: cr.sortable,
			type: cr.type || 'text',
		})),
		rows: data.map((item) => columnRows.map(({ render }) => render(item))),
	};
}
