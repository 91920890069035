import { ReactElement, ReactNode, cloneElement } from 'react';

export interface Tokens {
	[k: string]: string | number | ReactNode;
}

export interface PluralForms {
	zero?: string;
	one?: string;
	two?: string;
	few?: string;
	many?: string;
	other: string;
}

export function translate(
	locale = 'en',
	message: string | PluralForms,
	tokens: Tokens = {},
	count?: number,
): ReactNode[] | string {
	if (typeof message === 'string') {
		return tokenize(message, tokens);
	}

	const rule = new Intl.PluralRules([locale]);
	const key = rule.select(count || parseInt(tokens.count as string));

	return tokenize(message[key] as string, tokens);
}

function tokenize(message: string, tokens: Tokens): ReactNode[] | string {
	const parts: Array<ReactNode | string> = [];

	let str = message || '???';

	while (str.length) {
		const match = /{\w+}/.exec(str);

		if (match) {
			const key = match[0].substring(1, match[0].length - 1);
			let token = tokens[key];

			if (typeof token === 'object') {
				// adding required key to react node
				token = cloneElement(token as ReactElement, { key });
			}

			parts.push(str.substring(0, match.index));
			parts.push(token ?? '???');
			str = str.substring(match.index + key.length + 2);
		} else {
			parts.push(str);
			break;
		}
	}

	if (parts.some((p) => typeof p === 'object')) {
		return parts;
	}

	return parts.join('');
}
