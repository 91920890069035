import { Stack, EmptySearchResult, Button } from '@sixriver/lighthouse-web-community';

import styles from './SpecialProjects.module.css';
import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	onClick: () => void;
}

export function WorkLogEmptyState({ onClick }: Props) {
	const { messages } = useLocalization();

	return (
		<div className={styles.workLogEmptyStateContainer}>
			<Stack vertical distribution="center" alignment="center">
				<EmptySearchResult
					title={messages.noWorkLogged}
					description={messages.workNotStarted}
					withIllustration
				/>

				<Button primary onClick={onClick}>
					{messages.logWork}
				</Button>
			</Stack>
		</div>
	);
}
