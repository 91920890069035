import { Filters } from '@sixriver/lighthouse-web-community';
import { noop } from 'lodash';

import { useLocalization } from '../../../hooks/useLocalization';

interface FiltersProps {
	queryValue?: string;
	onQueryChange?: (value: string) => void;
}

export function ExceptionsFilters({ queryValue, onQueryChange = noop }: FiltersProps) {
	const { messages } = useLocalization();

	return (
		<Filters
			queryValue={queryValue}
			queryPlaceholder={messages.filterExceptions}
			onQueryChange={onQueryChange}
			onQueryClear={() => onQueryChange('')}
			filters={[]}
			onClearAll={noop}
		></Filters>
	);
}
