import { DateTime } from './DateTime';
import { RelativeDateTime } from '../RelativeDateTime';

interface Props {
	date?: Date | string | number;
	mode?: 'deadline' | 'expectation';
	severity?: boolean;
}

export function DateTimeWithRelativeTime({ date, mode, severity = true }: Props) {
	return (
		<>
			<DateTime date={date} />
			<RelativeDateTime date={date} mode={mode} severity={severity} />
		</>
	);
}
