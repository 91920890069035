import { Badge } from '@sixriver/lighthouse-web-community';

import { AvailableMfp, DeviceState } from '../../api';
import { NoData } from '../../components/NoData';
import { getDeviceState } from '../../hooks/useAvailableMfps';
import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	device: AvailableMfp;
}

export function DeviceBadge({ device }: Props): JSX.Element {
	const { messages } = useLocalization();
	const deviceState = getDeviceState(device);

	if (deviceState === DeviceState.InUse) {
		return <Badge status="success">{messages.inUse}</Badge>;
	}

	if (deviceState === DeviceState.NotInUse) {
		return <Badge>{messages.notInUse}</Badge>;
	}

	if (deviceState === DeviceState.Disconnected) {
		return <Badge>{messages.disconnected}</Badge>;
	}

	return <NoData />;
}
