import { Badge, IndexTable, Stack } from '@sixriver/lighthouse-web-community';

import { DateTime } from '../../../components/DateTime';
import { useLocalization } from '../../../hooks/useLocalization';

interface ExpectedShipDateCellProps {
	expectedShipDate?: Date;
	isLate: boolean;
}

export function ExpectedShipDateCell({ expectedShipDate, isLate }: ExpectedShipDateCellProps) {
	const { messages } = useLocalization();
	return (
		<IndexTable.Cell>
			<Stack>
				<DateTime date={expectedShipDate} />
				{isLate ? <Badge status="critical">{messages.late}</Badge> : null}
			</Stack>
		</IndexTable.Cell>
	);
}
