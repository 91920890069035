import { Button, Card, Layout, Page, Stack, TextStyle } from '@sixriver/lighthouse-web-community';

import { InlineFrame } from '../../components/InlineFrame';
import { useIsSupportEnabled } from '../../hooks/useConfig/useSiteConfig';
import { useLocalization } from '../../hooks/useLocalization';

export function Support() {
	const { messages } = useLocalization();
	const isSupportEnabled = useIsSupportEnabled();

	return (
		<Page>
			<Layout>
				<Layout.Section>
					<div style={{ height: '90vh' }}>
						{isSupportEnabled ? (
							<Card>
								<h2
									style={{
										fontSize: '16px',
										fontWeight: 'bold',
										padding: '20px 20px 0px',
									}}
								>
									{messages.support}
								</h2>
								<Card.Section fullWidth={false}>
									<Stack vertical>
										<TextStyle>{messages.supportSubheading}</TextStyle>
										<Button
											external
											url="https://6river.atlassian.net/servicedesk/customer/portal/90"
										>
											{messages.support}
										</Button>
									</Stack>
								</Card.Section>
							</Card>
						) : (
							<div style={{ height: '70%' }}>
								<InlineFrame title={messages.support} src={`/6support/`} />
							</div>
						)}
						<div style={{ margin: '3em 0' }}>
							<Layout>
								<Layout.Section oneHalf>
									<Card title={messages.feedbackHeading}>
										<Card.Section fullWidth={false}>
											<Stack vertical wrap={true}>
												<TextStyle>{messages.feedbackRequest}</TextStyle>
												<Button external url="https://www.surveymonkey.com/r/6RSBridgeFeedback">
													{messages.feedbackAction}
												</Button>
											</Stack>
										</Card.Section>
									</Card>
								</Layout.Section>

								<Layout.Section oneHalf>
									<Card title={messages.chuck5Documentation}>
										<Card.Section fullWidth={false}>
											<Stack vertical>
												<TextStyle>
													<br />
													&nbsp;
												</TextStyle>
												<Button
													external
													url="https://drive.google.com/file/d/1czGGPaGEMjGJkt2Hfer9qJtuvq2ZIOgp/view"
												>
													{messages.download}
												</Button>
											</Stack>
										</Card.Section>
									</Card>
								</Layout.Section>
							</Layout>
						</div>
					</div>
				</Layout.Section>
			</Layout>
		</Page>
	);
}
