import { LineQuantities } from '@sixriver/fulfillment-api-schema';
import { IndexTable, TextContainer, TextStyle } from '@sixriver/lighthouse-web-community';

export function UnitsCell({ totalLineQuantities }: { totalLineQuantities: LineQuantities }) {
	return (
		<IndexTable.Cell>
			<TextContainer>
				<TextStyle>
					{totalLineQuantities?.pickedUnits} of {totalLineQuantities?.totalUnits}
				</TextStyle>
			</TextContainer>
		</IndexTable.Cell>
	);
}
