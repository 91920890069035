import { IndexTable } from '@sixriver/lighthouse-web-community';
import { NoData } from '@sixriver/react-support';

export function NoDataCell() {
	return (
		<IndexTable.Cell>
			<NoData />
		</IndexTable.Cell>
	);
}
