import { JobAllocationMethod, PickStrategy } from '@sixriver/fulfillment-api-schema';

import { useLocalization } from '../../hooks/useLocalization';

export const usePickStrategies = (methods: JobAllocationMethod[]): PickStrategy[] => {
	const strategies = new Set<PickStrategy>();

	methods.forEach((method) => {
		switch (method) {
			case JobAllocationMethod.Discrete:
				strategies.add(PickStrategy.Discrete); // "Single order picking"
				break;
			case JobAllocationMethod.Batch:
			case JobAllocationMethod.SingleLineSingleUnit:
			case JobAllocationMethod.SingleLineMultiUnit:
				strategies.add(PickStrategy.Singles); // "Single-line batch picking"
				break;
			case JobAllocationMethod.MobileSortWall:
				strategies.add(PickStrategy.Sortation); // "Sort wall picking"
				break;
			case JobAllocationMethod.Consolidation:
				strategies.add(PickStrategy.Consolidation); // "Must ship complete"
				break;
			case JobAllocationMethod.Group:
				strategies.add(PickStrategy.Group); // "Allow split shipment"
				break;
			case JobAllocationMethod.BulkOrders:
				strategies.add(PickStrategy.Bulk);
				break;
		}
	});

	return Array.from(strategies);
};

export const usePickStrategyName = () => {
	const { messages } = useLocalization();

	const pickStrategyNames: Record<PickStrategy, string> = {
		[PickStrategy.Bulk]: messages.pickStrategies.bulk,
		[PickStrategy.Consolidation]: messages.pickStrategies.consolidation,
		[PickStrategy.Discrete]: messages.pickStrategies.discrete,
		[PickStrategy.Group]: messages.pickStrategies.group,
		[PickStrategy.Singles]: messages.pickStrategies.singles,
		[PickStrategy.Sortation]: messages.pickStrategies.sortation,
	};

	return (pickStrategy: PickStrategy) => pickStrategyNames[pickStrategy];
};
