import { ChoiceList, Filters } from '@sixriver/lighthouse-web-community';
import { useLayoutEffect } from 'react';

import { ChuckArgs, ChuckStatus } from './ChuckArgs';
import {
	useBatteryThresholds,
	useDeviceDwellThreshold,
	useIsHighPriorityChuckEnabled,
} from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	args: ChuckArgs;
	onFilter(args: ChuckArgs): void;
}

/**
 * This component displays a set of filters. Unlike other filters throughout
 * the UI, this component does not trigger additional queries to the server. All
 * filtering is performed against the data set in memory.
 */
export function ChuckFilters({ args, onFilter }: Props) {
	const { messages, translate } = useLocalization();
	const { searchText, statuses } = args;
	const deviceDwellThreshold = useDeviceDwellThreshold();
	const tokens = {
		minutes: Math.round(deviceDwellThreshold / 60),
	};
	const isHighPriorityChuckEnabled = useIsHighPriorityChuckEnabled();
	const batteryThresholds = useBatteryThresholds();

	// booooooo
	useLayoutEffect(() => {
		document.querySelector('.Polaris-TextField__Input')?.setAttribute('autocomplete', 'off');
	}, []);

	const choices = (
		[
			'faulty',
			...(isHighPriorityChuckEnabled ? ['priority'] : []),
			'dwellingIdle',
			'dwellingPaused',
			'charging',
			'lowBattery',
			'offline',
		] as ChuckStatus[]
	).map((key) => {
		let label = messages.chuckStates[key];
		if (key === 'dwellingIdle' || key === 'dwellingPaused') {
			label = translate(messages.chuckStates[key], {
				minutes: Math.round(deviceDwellThreshold / 60),
			}) as string;
		}

		if (key === 'lowBattery') {
			label += ' (' + (batteryThresholds.critical ?? 35) + '%)';
		}
		return { label, value: key };
	});

	const filters = [
		{
			filter: (
				<ChoiceList
					title={messages.status}
					titleHidden
					allowMultiple
					choices={choices}
					selected={statuses}
					onChange={(values) => onFilter({ ...args, statuses: values as ChuckStatus[] })}
				/>
			),
			key: 'status',
			label: messages.status,
			shortcut: true,
		},
	];

	const appliedFilters = [];

	if (statuses.length) {
		appliedFilters.push({
			key: 'status',
			label: statuses.map((s) => translate(messages.chuckStates[s], tokens)).join(', '),
			onRemove: () => onFilter({ ...args, statuses: [] }),
		});
	}

	return (
		<Filters
			filters={filters}
			appliedFilters={appliedFilters}
			queryPlaceholder={messages.searchFloorView}
			queryValue={searchText}
			onQueryClear={() => onFilter({ ...args, searchText: '' })}
			onQueryChange={(searchText) => onFilter({ ...args, searchText })}
			onClearAll={() => onFilter({ searchText: '', statuses: [] })}
		/>
	);
}
