import { jsx, Fragment, jsxs } from "react/jsx-runtime";
import { TextStyle, DescriptionList, TopBar } from "@sixriver/lighthouse-web-community";
import * as React from "react";
import { createContext, useCallback, useEffect, useContext, useState, useRef, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
const cardDetails = "_cardDetails_q0ruk_1";
const styles$1 = {
  cardDetails
};
const progressbar = "_progressbar_1i979_1";
const styles = {
  progressbar
};
function NoData() {
  return /* @__PURE__ */ jsx("span", { "data-testid": "no-data", children: /* @__PURE__ */ jsx(TextStyle, { variation: "subdued", children: "—" }) });
}
function PendingText({ value, loading, empty }) {
  if (loading) {
    return /* @__PURE__ */ jsx("div", { role: "progressbar", "aria-busy": true, className: styles.progressbar });
  }
  if (empty || !value && value !== 0) {
    return /* @__PURE__ */ jsx(NoData, {});
  }
  return /* @__PURE__ */ jsx(Fragment, { children: value });
}
const mapItems = (items, loading) => {
  return items.map(({ label, content }) => {
    return { term: label + ":", description: /* @__PURE__ */ jsx(PendingText, { loading, value: content }) };
  });
};
function CardDetails({ loading, primary, secondary }) {
  return /* @__PURE__ */ jsxs("div", { className: styles$1.cardDetails, children: [
    /* @__PURE__ */ jsx(DescriptionList, { items: mapItems(primary, loading) }),
    secondary ? /* @__PURE__ */ jsx(DescriptionList, { items: mapItems(secondary, loading) }) : null
  ] });
}
function readCookie(key) {
  var _a;
  return (_a = new RegExp("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)").exec(document.cookie)) == null ? void 0 : _a.pop();
}
function deleteCookies(keys = []) {
  keys.forEach((key) => {
    document.cookie = `${key}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  });
}
function e(e2) {
  this.message = e2;
}
e.prototype = new Error(), e.prototype.name = "InvalidCharacterError";
var r = "undefined" != typeof window && window.atob && window.atob.bind(window) || function(r2) {
  var t2 = String(r2).replace(/=+$/, "");
  if (t2.length % 4 == 1)
    throw new e("'atob' failed: The string to be decoded is not correctly encoded.");
  for (var n2, o2, a = 0, i = 0, c = ""; o2 = t2.charAt(i++); ~o2 && (n2 = a % 4 ? 64 * n2 + o2 : o2, a++ % 4) ? c += String.fromCharCode(255 & n2 >> (-2 * a & 6)) : 0)
    o2 = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=".indexOf(o2);
  return c;
};
function t(e2) {
  var t2 = e2.replace(/-/g, "+").replace(/_/g, "/");
  switch (t2.length % 4) {
    case 0:
      break;
    case 2:
      t2 += "==";
      break;
    case 3:
      t2 += "=";
      break;
    default:
      throw "Illegal base64url string!";
  }
  try {
    return function(e3) {
      return decodeURIComponent(r(e3).replace(/(.)/g, function(e4, r2) {
        var t3 = r2.charCodeAt(0).toString(16).toUpperCase();
        return t3.length < 2 && (t3 = "0" + t3), "%" + t3;
      }));
    }(t2);
  } catch (e3) {
    return r(t2);
  }
}
function n(e2) {
  this.message = e2;
}
function o(e2, r2) {
  if ("string" != typeof e2)
    throw new n("Invalid token specified");
  var o2 = true === (r2 = r2 || {}).header ? 0 : 1;
  try {
    return JSON.parse(t(e2.split(".")[o2]));
  } catch (e3) {
    throw new n("Invalid token specified: " + e3.message);
  }
}
n.prototype = new Error(), n.prototype.name = "InvalidTokenError";
var UserRole = /* @__PURE__ */ ((UserRole2) => {
  UserRole2["Admin"] = "admin";
  UserRole2["BridgeAssociate"] = "cfs_read_only";
  UserRole2["WarehouseManager"] = "cfs_operator";
  UserRole2["EmployeeManager"] = "user_admin";
  UserRole2["Integration"] = "integration";
  UserRole2["ChuckOperator"] = "chuck_operator";
  UserRole2["User"] = "user";
  return UserRole2;
})(UserRole || {});
function getJwtPayload() {
  const jwtPayload = readCookie("6RS-JWT") || readCookie("SSO-JWT");
  if (jwtPayload) {
    return o(jwtPayload);
  }
  return void 0;
}
function getSessionExpiration() {
  const jwtPayload = getJwtPayload();
  if (jwtPayload) {
    return new Date(jwtPayload.exp * 1e3);
  }
  return void 0;
}
function createUser(payload) {
  return {
    email: payload.email,
    id: payload.email,
    locale: payload.locale,
    name: payload.name,
    preferences: [],
    roles: payload.roles
  };
}
const AuthContext = createContext({
  getUser: () => null,
  isAuthenticated: () => false,
  isSessionAboutToExpire: () => false,
  isUserAllowed: () => false,
  login: async () => {
    return;
  },
  logout: async () => {
    return;
  },
  refresh: async () => {
    return;
  }
});
function AuthProvider({
  children,
  autoRefresh = true,
  refreshInterval = 60 * 1e3,
  // Default to 1 minute
  sessionExpireThreshold = 60 * 1e3 * 5
  // Default to 5 minutes
}) {
  const getUser = useCallback(() => {
    const jwtPayload = getJwtPayload();
    if (jwtPayload) {
      return createUser(jwtPayload);
    }
    return null;
  }, []);
  const login = useCallback(
    async (email, password, signal) => {
      try {
        const response = await fetch("cfs/gatekeeper/auth/login/", {
          body: JSON.stringify({ email, password, skipRedirect: true }),
          credentials: "include",
          headers: {
            "Content-Type": "application/json"
          },
          method: "POST",
          signal
        });
        if (!response.ok) {
          throw new Error("Login failed. Please try again.");
        }
      } catch (err) {
        console.error(err);
        throw new Error("Login failed. Please try again.");
      }
    },
    []
  );
  const logout = useCallback(async (signal) => {
    const jwtPayload = getJwtPayload();
    if (jwtPayload && jwtPayload.authMethod === "OIDC") {
      window.location.href = "cfs/gatekeeper/auth/logout/";
      return;
    }
    await fetch("cfs/gatekeeper/auth/logout/", {
      credentials: "include",
      method: "POST",
      signal
    });
    deleteCookies(["6RS-JWT-REFRESH", "6RS-JWT", "SSO-JWT", "SSO-JWT-REFRESH"]);
  }, []);
  const refresh = useCallback(async (signal) => {
    const response = await fetch("cfs/gatekeeper/auth/refresh/", {
      credentials: "include",
      method: "POST",
      signal
    });
    if (!response.ok) {
      throw new Error("Refresh failed. Please login again.");
    }
  }, []);
  const isUserAllowed = useCallback((allowedRoles) => {
    const jwtPayload = getJwtPayload();
    if (jwtPayload && jwtPayload.roles.length > 0) {
      return jwtPayload.roles.some((role) => allowedRoles.includes(role));
    }
    return true;
  }, []);
  const isAuthenticated = useCallback(() => {
    const expires = getSessionExpiration();
    if (expires) {
      return expires > /* @__PURE__ */ new Date();
    }
    return false;
  }, []);
  const isSessionAboutToExpire = useCallback(
    (threshold = 60 * 1e3 * 5) => {
      if (isAuthenticated()) {
        const expires = getSessionExpiration();
        if (expires) {
          expires.setTime(expires.getTime() - threshold);
          return expires > /* @__PURE__ */ new Date() === false;
        }
      }
      return false;
    },
    [isAuthenticated]
  );
  useEffect(() => {
    if (autoRefresh) {
      const controller = new AbortController();
      const { signal } = controller;
      let timeoutId = void 0;
      const tryToRefresh = async (signal2) => {
        if (isSessionAboutToExpire(sessionExpireThreshold)) {
          await refresh(signal2);
        }
        timeoutId = setTimeout(() => {
          void tryToRefresh(signal2);
        }, refreshInterval);
      };
      void tryToRefresh(signal);
      return () => {
        controller.abort();
        clearTimeout(timeoutId);
      };
    }
    return () => {
      return;
    };
  }, [autoRefresh, isSessionAboutToExpire, refresh, sessionExpireThreshold, refreshInterval]);
  return /* @__PURE__ */ jsx(
    AuthContext.Provider,
    {
      value: {
        getUser,
        isAuthenticated,
        isSessionAboutToExpire,
        isUserAllowed,
        login,
        logout,
        refresh
      },
      children
    }
  );
}
const defaultJwtPayload = {
  name: "User",
  roles: [],
  email: "user@6river.com",
  locale: "en-US",
  exp: 0,
  authMethod: ""
};
function MockAuthProvider({
  children,
  jwtPayload = defaultJwtPayload
}) {
  const getUser = useCallback(() => {
    return createUser(jwtPayload);
  }, [jwtPayload]);
  const login = useCallback(
    async (_email, _password, _signal) => {
      return;
    },
    []
  );
  const logout = useCallback(async (_signal) => {
    return;
  }, []);
  const refresh = useCallback(async (_signal) => {
    return;
  }, []);
  const isUserAllowed = useCallback((_allowedRoles) => {
    return true;
  }, []);
  const isAuthenticated = useCallback(() => {
    return true;
  }, []);
  const isSessionAboutToExpire = useCallback((_threshold = 60 * 1e3 * 5) => {
    return false;
  }, []);
  return /* @__PURE__ */ jsx(
    AuthContext.Provider,
    {
      value: {
        getUser,
        login,
        logout,
        refresh,
        isUserAllowed,
        isAuthenticated,
        isSessionAboutToExpire
      },
      children
    }
  );
}
function useAuth() {
  const context = useContext(AuthContext);
  if (context === void 0) {
    throw new Error("useAuth must be used within a <AuthProvider/>");
  }
  return context;
}
function formatDateTime(locale = "en", date, options) {
  const opts = {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    ...(options == null ? void 0 : options.includeSecondsAndMilliseconds) ? { second: "numeric", fractionalSecondDigits: 3 } : {}
  };
  return new Intl.DateTimeFormat([locale], opts).format(new Date(date));
}
function formatDate(locale = "en", date) {
  const opts = {
    month: "short",
    day: "numeric",
    year: "numeric"
  };
  return new Intl.DateTimeFormat([locale], opts).format(new Date(date));
}
function formatTime(locale = "en", date, options) {
  const opts = {
    hour: "numeric",
    minute: "numeric",
    ...(options == null ? void 0 : options.includeSecondsAndMilliseconds) ? { second: "numeric", fractionalSecondDigits: 3 } : {}
  };
  return new Intl.DateTimeFormat([locale], opts).format(new Date(date));
}
function formatIsoDate(date) {
  return new Date(date).toISOString().substring(0, 10);
}
const ONE_SECOND = 1e3;
const ONE_MINUTE = ONE_SECOND * 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;
const ONE_MONTH = ONE_DAY * 30;
const ONE_YEAR = ONE_DAY * 365;
function formatDateDiff(locale = "en", date) {
  const opts = {
    style: "long",
    numeric: "always"
  };
  const ms = new Date(date).getTime() - Date.now();
  const absMs = Math.abs(ms);
  let value;
  let unit;
  if (absMs < ONE_MINUTE) {
    value = Math.round(ms / ONE_SECOND);
    unit = "seconds";
  } else if (absMs < ONE_HOUR) {
    value = Math.round(ms / ONE_MINUTE);
    unit = "minutes";
  } else if (absMs < ONE_DAY) {
    value = Math.round(ms / ONE_HOUR);
    unit = "hours";
  } else if (absMs < ONE_MONTH) {
    value = Math.round(ms / ONE_DAY);
    unit = "days";
  } else if (absMs < ONE_YEAR) {
    value = Math.round(ms / ONE_MONTH);
    unit = "months";
  } else {
    value = Math.round(ms / ONE_YEAR);
    unit = "years";
  }
  return new Intl.RelativeTimeFormat(locale, opts).format(value, unit);
}
function formatNumber(locale = "en", value = 0, maximumFractionDigits = 1) {
  const opts = { maximumFractionDigits };
  return new Intl.NumberFormat([locale], opts).format(value);
}
function formatPercent(locale = "en", value = 0) {
  const opts = { style: "percent" };
  return new Intl.NumberFormat([locale], opts).format(value);
}
function formatWeight(locale = "en", grams = 0) {
  const unit = grams < 1e3 ? "gram" : "kilogram";
  const denom = unit === "gram" ? 1 : 1e3;
  const opts = {
    maximumFractionDigits: 1,
    style: "unit",
    unit
  };
  return new Intl.NumberFormat([locale], opts).format(grams / denom);
}
function formatLength(locale = "en", millis = 0) {
  const unit = millis >= 1e3 ? "meter" : millis < 10 ? "millimeter" : "centimeter";
  const denom = {
    millimeter: 1,
    centimeter: 10,
    meter: 1e3
  }[unit];
  const opts = {
    maximumFractionDigits: 1,
    style: "unit",
    unit
  };
  return new Intl.NumberFormat([locale], opts).format(millis / denom);
}
function formatVolume(locale = "en", cubicMillis = 0) {
  const unit = cubicMillis >= 1e3 ? "meter" : cubicMillis < 10 ? "millimeter" : "centimeter";
  const denom = {
    millimeter: 1,
    centimeter: 10,
    meter: 1e3
  }[unit];
  const opts = {
    maximumFractionDigits: 1,
    style: "unit",
    unit
  };
  return new Intl.NumberFormat([locale], opts).format(cubicMillis / denom) + "³";
}
const SUPPORTED_LOCALES = ["en-US", "es-MX", "cs", "nl", "pl"];
function useLocale() {
  const { getUser } = useAuth();
  const user = getUser();
  const userLocale = (user == null ? void 0 : user.locale) || "";
  const locale = SUPPORTED_LOCALES.includes(userLocale) ? userLocale : "en-US";
  const formatNumber$1 = formatNumber.bind(null, locale);
  const formatPercent$1 = formatPercent.bind(null, locale);
  const formatWeight$1 = formatWeight.bind(null, locale);
  const formatLength$1 = formatLength.bind(null, locale);
  const formatVolume$1 = formatVolume.bind(null, locale);
  const formatDateTime$1 = formatDateTime.bind(null, locale);
  const formatDate$1 = formatDate.bind(null, locale);
  const formatTime$1 = formatTime.bind(null, locale);
  const formatDateDiff$1 = formatDateDiff.bind(null, locale);
  return {
    formatDate: formatDate$1,
    formatDateDiff: formatDateDiff$1,
    formatDateTime: formatDateTime$1,
    formatLength: formatLength$1,
    formatNumber: formatNumber$1,
    formatPercent: formatPercent$1,
    formatTime: formatTime$1,
    formatVolume: formatVolume$1,
    formatWeight: formatWeight$1,
    locale
  };
}
const MILLISECONDS_PER_HOUR = 36e5;
const MILLISECONDS_PER_DAY = 864e5;
function startOfDay(date = /* @__PURE__ */ new Date()) {
  const copy = new Date(date);
  copy.setHours(0, 0, 0, 0);
  return copy;
}
function endOfDay(date = /* @__PURE__ */ new Date()) {
  const copy = new Date(date);
  copy.setHours(23, 59, 59, 999);
  return copy;
}
function addDays(date, days) {
  const copy = new Date(date);
  copy.setDate(copy.getDate() + days);
  return copy;
}
const getMidnight = (offsetDays = 0) => {
  return addDays(startOfDay(), offsetDays);
};
function isYesterday(date) {
  return startOfDay(date).getTime() === addDays(startOfDay(), -1).getTime();
}
function isToday(date) {
  return startOfDay(date).getTime() === startOfDay().getTime();
}
function isTomorrow(date) {
  return startOfDay(date).getTime() === addDays(startOfDay(), 1).getTime();
}
function getDateDiffInDays(date1, date2) {
  return Math.round((date1.getTime() - date2.getTime()) / MILLISECONDS_PER_DAY);
}
function DateTime({ date, includeSecondsAndMilliseconds }) {
  const { formatTime: formatTime2, formatDateTime: formatDateTime2 } = useLocale();
  if (date) {
    const str = isToday(new Date(date)) ? formatTime2(date, { includeSecondsAndMilliseconds }) : formatDateTime2(date, { includeSecondsAndMilliseconds });
    const iso = new Date(date).toISOString();
    return /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx("time", { dateTime: iso, children: str }) });
  }
  return /* @__PURE__ */ jsx(NoData, {});
}
const SvgLogOutMinor = (props) => React.createElement(
  "svg",
  { viewBox: "0 0 20 20", role: "img", ...props },
  React.createElement("path", { d: "M10 19a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM9.3 8.7a1 1 0 0 1 1.4-1.4l3 3a1 1 0 0 1 0 1.4l-3 3a1 1 0 0 1-1.4-1.4l1.3-1.3H7a1 1 0 1 1 0-2h3.6L9.3 8.7z" })
);
function UserMenu() {
  const { getUser } = useAuth();
  const history = useHistory();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const toggleIsUserMenuOpen = useCallback(
    () => setIsUserMenuOpen((isUserMenuOpen2) => !isUserMenuOpen2),
    []
  );
  const user = getUser();
  const name = (user == null ? void 0 : user.name) || "Account";
  return /* @__PURE__ */ jsx(
    TopBar.UserMenu,
    {
      actions: [
        {
          items: [
            {
              content: "Sign out",
              icon: SvgLogOutMinor,
              onAction: () => {
                history.push("/logout");
              }
            }
          ]
        }
      ],
      name,
      detail: (user == null ? void 0 : user.email) || "",
      initials: name.charAt(0).toUpperCase(),
      open: isUserMenuOpen,
      onToggle: toggleIsUserMenuOpen
    }
  );
}
function useInterval(callback, delay) {
  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);
  useEffect(() => {
    if (!delay) {
      return;
    }
    function tick() {
      callbackRef.current();
    }
    const id = setInterval(tick, delay);
    return () => {
      clearInterval(id);
    };
  }, [delay]);
}
function isLocalhost(hostname) {
  return hostname === "localhost" || hostname === "127.0.0.1" || hostname === "::1" || hostname === "host.docker.internal" || hostname === "frontend-apps.6river.org" || // 127.0.0.1/8 is considered localhost for IPv4.
  /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/.test(hostname);
}
const siteName = isLocalhost(window.location.hostname) ? "development" : window.location.hostname.split(".")[0];
function useSiteName() {
  return siteName;
}
function useUrlState(initialState, paramName, serialize = (state) => String(state), deserialize = (value) => value) {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const existingValue = searchParams.get(paramName);
  const [state, setState] = useState(existingValue ? deserialize(existingValue) : initialState);
  useEffect(() => {
    if (existingValue && deserialize(existingValue) !== state) {
      setState(deserialize(existingValue));
    }
  }, [deserialize, existingValue, state]);
  const onChange = useCallback(
    (s) => {
      setState(s);
      const searchParams2 = new URLSearchParams(history.location.search);
      searchParams2.set(paramName, serialize(s));
      const pathname = history.location.pathname;
      history.push({ pathname, search: searchParams2.toString() });
    },
    [history, paramName, serialize]
  );
  return [state, onChange];
}
function useQueryState(key, initialState, parse, serialize) {
  const location = useLocation();
  const history = useHistory();
  const isArray = Array.isArray(initialState);
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const queryParam = isArray ? queryParams.getAll(key) : queryParams.get(key);
  const value = parse && typeof queryParam === "string" ? parse(queryParam) : queryParam;
  const setQuery = useCallback(
    (value2) => {
      const queryParams2 = new URLSearchParams(location.search);
      queryParams2.delete(key);
      if (Array.isArray(value2)) {
        value2.forEach((v) => queryParams2.append(key, String(v)));
      } else if (value2 !== void 0) {
        const resolvedValue = serialize ? serialize(value2) : value2;
        if (resolvedValue !== void 0) {
          queryParams2.set(key, resolvedValue);
        }
      }
      history.push(`${location.pathname}?${queryParams2.toString()}`);
    },
    [history, key, location.pathname, location.search, serialize]
  );
  return [value ?? initialState, setQuery];
}
function useArrayQueryState(key, defaultState) {
  return useQueryState(key, defaultState);
}
function useArrayOfObjectsQueryState(key, defaultValue, parse, serialize, objectFieldsInOrder) {
  const { replace } = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const queryParam = queryParams.getAll(key);
  const value = queryParam.map((p) => parse(p, objectFieldsInOrder));
  const setValue = useCallback(
    (newValue) => {
      const queryParams2 = new URLSearchParams(window.location.search);
      queryParams2.delete(key);
      newValue.forEach((v) => queryParams2.append(key, serialize(v, objectFieldsInOrder)));
      replace({ search: queryParams2.toString() });
    },
    [key, objectFieldsInOrder, replace, serialize]
  );
  return [value.length > 0 ? value : defaultValue, setValue];
}
function useSortQueryState(key, defaultValue) {
  return useArrayOfObjectsQueryState(key, defaultValue, parseObject, serializeObject, [
    "field",
    "direction"
  ]);
}
function useDateQueryState(key, defaultState) {
  return useQueryState(key, defaultState, parseDate, serializeDate);
}
function useNumberQueryState(key, defaultState) {
  return useQueryState(key, defaultState, parseNumber, serializeNumber);
}
function serializeDate(date) {
  return date ? date.toISOString() : void 0;
}
function parseDate(value) {
  return new Date(value);
}
function serializeNumber(number) {
  return number !== void 0 ? number.toString() : void 0;
}
function parseNumber(value) {
  return parseFloat(value);
}
function serializeObject(obj, fieldsWithOrder) {
  return fieldsWithOrder.map((key) => obj[key]).join(" ");
}
function parseObject(value, fieldsWithOrder) {
  const values = value.split(" ");
  const sortObject = {};
  fieldsWithOrder.forEach((key, i) => {
    sortObject[key] = values[i];
  });
  return sortObject;
}
function translate(locale = "en", message, tokens = {}, count) {
  if (typeof message === "string") {
    return tokenize(message, tokens);
  }
  const rule = new Intl.PluralRules([locale]);
  const key = rule.select(count || parseInt(tokens.count));
  return tokenize(message[key], tokens);
}
function tokenize(message, tokens) {
  const parts = [];
  let str = message;
  while (str.length) {
    const token = /{\w+}/.exec(str);
    if (token) {
      const key = token[0].substring(1, token[0].length - 1);
      parts.push(str.substring(0, token.index));
      parts.push(tokens[key] ?? "???");
      str = str.substring(token.index + key.length + 2);
    } else {
      parts.push(str);
      break;
    }
  }
  if (parts.some((p) => typeof p === "object")) {
    return parts;
  }
  return parts.join("");
}
export {
  AuthContext,
  AuthProvider,
  CardDetails,
  DateTime,
  MILLISECONDS_PER_DAY,
  MILLISECONDS_PER_HOUR,
  MockAuthProvider,
  NoData,
  PendingText,
  SUPPORTED_LOCALES,
  UserMenu,
  UserRole,
  addDays,
  endOfDay,
  formatDate,
  formatDateDiff,
  formatDateTime,
  formatIsoDate,
  formatLength,
  formatNumber,
  formatPercent,
  formatTime,
  formatVolume,
  formatWeight,
  getDateDiffInDays,
  getMidnight,
  isLocalhost,
  isToday,
  isTomorrow,
  isYesterday,
  siteName,
  startOfDay,
  translate,
  useArrayOfObjectsQueryState,
  useArrayQueryState,
  useAuth,
  useDateQueryState,
  useInterval,
  useLocale,
  useNumberQueryState,
  useQueryState,
  useSiteName,
  useSortQueryState,
  useUrlState
};
