import { MutationCreateTagArgs, Tag as TagEntity } from '@sixriver/fulfillment-api-schema';
import { useState, useCallback } from 'react';
import { useMutation, useQuery } from 'urql';

import { CREATE_NEW_TAG_MUTATION, GET_TAGS_QUERY } from './Employees.graphql';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';

interface UseEmployeeTagsHookOptions {
	defaultSelectedTags: string[];
}

export function useEmployeeTags({ defaultSelectedTags }: UseEmployeeTagsHookOptions): any {
	const { messages } = useLocalization();

	// External hooks
	const { showToast } = useToast();

	// State
	const [selectedTags, setSelectedTags] = useState<string[]>(defaultSelectedTags);

	// GQL Mutations
	const [{ error: addNewTagMutationError, fetching: addNewTagFetching }, addNewTagMutation] =
		useMutation<{ createTag: TagEntity }, MutationCreateTagArgs>(CREATE_NEW_TAG_MUTATION);

	// GQL Queries
	const [{ fetching: fetchingTags, data: tagsData, error: tagsError }] = useQuery<{
		searchTags: Array<{ name: string }>;
		getPopularTags: Array<{ name: string }>;
	}>({ query: GET_TAGS_QUERY });

	// Query Results
	const allTags = tagsData?.searchTags?.map((tag) => tag.name) || [];
	const popularTags = tagsData?.getPopularTags?.map((tag) => tag.name) || [];

	// Methods
	const onNewTag = useCallback(
		async (newTag: string): Promise<void> => {
			try {
				await addNewTagMutation(
					{
						input: {
							name: newTag,
						},
					},
					{ additionalTypenames: ['Tag'] },
				);
				setSelectedTags((prev) => [...prev, newTag]);
			} catch (ex) {
				showToast(messages.errorToast, true);
			}
		},
		[addNewTagMutation, showToast, messages],
	);

	// Computed Values
	const allTagOptions = allTags.map((tag) => ({
		label: tag,
		value: tag,
	}));
	const allTagNames = allTagOptions.map((t) => t.value);
	const availableTagOptions = allTagNames
		.filter((tn) => !selectedTags.includes(tn))
		.map((tagName) => ({ label: tagName, value: tagName }));

	return {
		addNewTagFetching,
		addNewTagMutationError,
		allTagOptions,
		allTags,
		availableTagOptions,
		fetchingTags,
		onNewTag,
		popularTags,
		selectedTags,
		setSelectedTags,
		tagsError,
	};
}
