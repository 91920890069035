import { useEffect, useState } from 'react';

import { parseTelemetryData, Telemetry } from './Telemetry';

/**
 * Interval in ms to throttle telemetry events to.  Lowering this too far may
 * lead to performance issues in sites with many chucks or on computers with
 * lower specs.
 */
const EVENT_INTERVAL = 250;

/**
 * This hook listens for messages on the Map Manager's Socket.IO endpoint and
 * returns all telemetry events received during at a set interval. (It does NOT use the
 * Socket.IO client library.) This data is then merged with the known list of
 * devices provided by the Fulfillment API.
 */
export function useTelemetry(): { telemetry: Telemetry[]; connected: boolean } {
	const [telemetry, setTelemetry] = useState<Telemetry[]>([]);
	const [connected, setConnected] = useState(true);
	const url = String(import.meta.env.VITE_FLOOR_VIEW_SOCKET_URL);

	useEffect(() => {
		let eventSource: EventSource;
		let eventList: Telemetry[] = [];

		// try to reconnect when re-entering the view
		const onVisibilityChange = () => {
			if (document.visibilityState === 'visible') {
				if (eventSource.readyState !== eventSource.OPEN) {
					connectSocket();
				}
			}
		};

		const connectSocket = () => {
			eventSource = new EventSource(url.replace('{HOSTNAME}', window.location.hostname));

			eventSource.onmessage = (evt: MessageEvent) => {
				// sample message: 'data: {"id":"mfp-debug","name": ...'
				const evtData = JSON.parse(evt.data);
				const telemetry = parseTelemetryData(evtData);
				if (telemetry.x && telemetry.y) {
					eventList.push(telemetry);
				}
			};

			eventSource.onopen = () => {
				setConnected(true);
			};

			eventSource.onerror = () => {
				// https://developer.mozilla.org/en-US/docs/Web/API/EventSource/error_event
				// try to reconnect immediately if the socket closed unexpectedly.
				setConnected(false);
				eventSource.close();
				// Add debounce and backoff to this
				connectSocket();
			};
		};

		connectSocket();

		document.addEventListener('visibilitychange', onVisibilityChange);

		const interval = setInterval(() => {
			if (eventList.length > 0) {
				setTelemetry(eventList);
				eventList = [];
			}
		}, EVENT_INTERVAL);

		return () => {
			document.removeEventListener('visibilitychange', onVisibilityChange);

			if (eventSource) {
				eventSource.close();
			}

			clearInterval(interval);
		};
	}, [url]);

	return { connected, telemetry };
}
