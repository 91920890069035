import { SpecialProject } from '@sixriver/fulfillment-api-schema';
import { TextStyle } from '@sixriver/lighthouse-web-community';

import { NoData } from '../../components/NoData';

interface Props {
	project?: SpecialProject;
}

export default function Merchant({ project }: Props): JSX.Element {
	return project ? <TextStyle>{project.merchant}</TextStyle> : <NoData />;
}
