import { IndexTable } from '@sixriver/lighthouse-web-community';

import { RelativeDateTime } from '../../../components/RelativeDateTime';

export function CreatedAtCell({ createdAt }: { createdAt: Date }) {
	return (
		<IndexTable.Cell>
			<RelativeDateTime date={createdAt} />
		</IndexTable.Cell>
	);
}
