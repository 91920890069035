export function formatDateTime(locale = 'en', date: Date | number | string): string {
	const opts: any = {
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		month: 'short',
	};

	return new Intl.DateTimeFormat([locale], opts).format(new Date(date));
}

export function formatDate(locale = 'en', date: Date | number | string): string {
	const opts: any = {
		day: 'numeric',
		month: 'short',
		year: 'numeric',
	};

	return new Intl.DateTimeFormat([locale], opts).format(new Date(date));
}

export function formatTime(locale = 'en', date: Date | number | string) {
	const opts: any = {
		hour: 'numeric',
		minute: 'numeric',
	};

	return new Intl.DateTimeFormat([locale], opts).format(new Date(date));
}

export function formatIsoDate(date: Date | number | string) {
	return new Date(date).toISOString().substring(0, 10);
}
