import { MutationResponse, MutationCancelOrderV2Args } from '@sixriver/fulfillment-api-schema';
import { gql } from 'graphql-tag';
import { useState } from 'react';
import { useMutation } from 'urql';

const CANCEL_ORDER_MUTATION = gql`
	mutation CancelOrder($id: String!) {
		cancelOrder(id: $id) {
			success
		}
	}
`;

export const useCancelOrder: any = ({
	orderId,
	onSuccessfulCancel,
	onError,
	onBefore,
}: {
	orderId: string;
	onSuccessfulCancel?: () => void;
	onError?: () => void;
	onBefore?: () => void;
}) => {
	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

	const closeCancelModal = () => {
		setIsCancelModalOpen(false);
	};

	const openCancelModal = () => {
		setIsCancelModalOpen(true);
	};

	const [{ fetching: cancelOrderLoading, error: cancelOrderError }, cancelOrderMutation] =
		useMutation<{ cancelOrderV2: MutationResponse }, MutationCancelOrderV2Args>(
			CANCEL_ORDER_MUTATION,
		);

	const cancelOrder = async () => {
		try {
			if (onBefore) {
				onBefore();
			}

			const { error } = await cancelOrderMutation({ id: orderId });
			closeCancelModal();

			if (error) {
				throw error;
			}

			if (onSuccessfulCancel) {
				onSuccessfulCancel();
			}
		} catch (e) {
			if (onError) {
				onError();
			}
		}
	};

	return {
		cancelOrder,
		cancelOrderError,
		cancelOrderLoading,
		closeCancelModal,
		isCancelModalOpen,
		openCancelModal,
	};
};
