import { JobFlowRule, JobFlowRuleFilter, JobFlowRuleInput } from '@sixriver/fulfillment-api-schema';

export type JFRDimension = 'job' | 'line';
export type JFRJoin = 'and' | 'or';
export type JFRConditionValue = string | number;
export type JFRConditionDataType = 'string' | 'number';
export type JFRConditionFieldType = 'text' | 'search' | 'select';
export type JFRConditionSuffix = 'mm' | 'g' | null;

// A rule may have many conditions, e.g. "weight is greater than 10000" and "address equals 'A-123'"
export interface JFRCondition {
	attribute: JFRConditionAttribute;
	operator: JFRConditionOperator;
	value: JFRConditionValue;
}

// This UI supports only the following attributes, including any attribute starting with "data."
const ATTRIBUTES = [
	'sourceLoc.address',
	'sourceLoc.externalAisleId',
	'sourceLoc.mapChunkId',
	'sourceLoc.requiredReach',
	'productType.length',
	'productType.width',
	'productType.height',
	'productType.weight',
	'quantity',
	'weight',
	'containerType.externalId',
	// "data.*"
] as const;

// This UI supports only the following operators
const OPERATORS = ['eq', 'neq', 'gt', 'gte', 'lt', 'lte', 'ilike', 'nilike'] as const;

export type JFRConditionAttribute = (typeof ATTRIBUTES)[number];

export type JFRConditionOperator = (typeof OPERATORS)[number];

/**
 * This class accepts a JobFlowRule from the Fulfillment API, parses its `rule`
 * field, and exposes methods for editing the rule. If the rule cannot be
 * parsed, the constructor throws an error. This may happen if the JFR was
 * created outside this UI.
 */
export class JobFlowRuleCriteria {
	// These filters come from the GRM and define "custom" attributes
	private filters: JobFlowRuleFilter[];

	// A rule may be job-related or line-related, but not both
	private dimension: JFRDimension = 'job';

	// A rule may have many conditions
	private conditions: JFRCondition[] = [];

	// This UI supports basic logic only: all conditions joined by AND or OR
	private join: JFRJoin = 'and';

	// The constructor throws an error if the rule cannot be handled by this UI
	constructor(jfr: JobFlowRule | JobFlowRuleInput, filters: JobFlowRuleFilter[]) {
		const rule = jfr.rule;

		this.dimension = rule.job ? 'job' : 'line';
		this.join = (rule.job || rule.line).and ? 'and' : 'or';
		this.filters = filters;
		this.conditions = this.parse((rule.job || rule.line)[this.join]);
	}

	getDimension() {
		return this.dimension;
	}

	getConditions() {
		return this.conditions;
	}

	getJoin() {
		return this.join;
	}

	getConditionConstraints(idx: number) {
		const fieldType = this.getConditionFieldType(idx);
		const dataType = this.getConditionDataType(idx);
		const operators = this.getConditionOperators(idx);
		const suffix = this.getConditionSuffix(idx);

		return {
			dataType,
			fieldType,
			operators,
			suffix,
		};
	}

	setDimension(dimension: JFRDimension) {
		this.dimension = dimension;
		this.conditions = [];
	}

	setJoin(join: JFRJoin) {
		this.join = join;
	}

	setConditionAttribute(idx: number, attribute: JFRConditionAttribute | string) {
		const condition = this.conditions[idx];

		condition.attribute = attribute as JFRConditionAttribute;

		// reset operator?
		if (!this.getConditionOperators(idx).includes(condition.operator)) {
			condition.operator = 'eq';
		}

		// reset value?
		if (this.getConditionDataType(idx) !== typeof condition.value) {
			condition.value = this.getConditionDataType(idx) === 'string' ? '' : 0;
		}
	}

	setConditionOperator(idx: number, operator: JFRConditionOperator) {
		const condition = this.conditions[idx];
		if (condition) {
			condition.operator = operator;
		}
	}

	setConditionValue(idx: number, value: JFRConditionValue) {
		const condition = this.conditions[idx];
		if (condition) {
			condition.value = value;
		}
	}

	addCondition(condition: JFRCondition): void {
		this.conditions.push(condition);
	}

	deleteCondition(idx: number) {
		this.conditions.splice(idx, 1);
	}

	private getFilter(key: string): JobFlowRuleFilter | undefined {
		return this.filters.find((f) => 'data.' + f.key === key);
	}

	private getFilterType(key: string): JFRConditionDataType {
		return this.getFilter(key)?.type === 'number' ? 'number' : 'string';
	}

	private getFilterValues(key: string): string[] {
		return this.getFilter(key)?.values || [];
	}

	private getConditionDataType(idx: number): JFRConditionDataType {
		const condition = this.conditions[idx];

		if (condition) {
			if (this.getFilterType(condition.attribute) === 'number') {
				return 'number';
			}

			if (/address|Id|^data\./.test(condition.attribute)) {
				return 'string';
			}
		}

		return 'number';
	}

	private getConditionSuffix(idx: number): 'mm' | 'g' | null {
		const condition = this.conditions[idx];

		if (condition) {
			if (!condition.attribute.startsWith('data.')) {
				if (/length|width|height/.test(condition.attribute)) {
					return 'mm';
				}

				if (/weight/.test(condition.attribute)) {
					return 'g';
				}
			}
		}

		return null;
	}

	private getConditionFieldType(idx: number): JFRConditionFieldType {
		const condition = this.conditions[idx];

		if (condition) {
			if (this.getFilterValues(condition.attribute).length > 0) {
				return 'select';
			}

			if (['sourceLoc.mapChunkId'].includes(condition.attribute)) {
				return 'select';
			}

			if (
				['sourceLoc.address', 'sourceLoc.externalAisleId', 'containerType.externalId'].includes(
					condition.attribute,
				)
			) {
				return 'search';
			}
		}

		return 'text';
	}

	private getConditionOperators(idx: number): JFRConditionOperator[] {
		if (this.getConditionFieldType(idx) === 'select') {
			return ['eq', 'neq'];
		}

		if (this.getConditionDataType(idx) === 'string') {
			return ['eq', 'neq', 'ilike', 'nilike'];
		}

		return ['eq', 'gt', 'gte', 'lt', 'lte'];
	}

	private validate(condition: any): JFRCondition {
		// This UI supports only one expression per condition
		if (Object.keys(condition).length !== 1) {
			throw new Error();
		}

		const attributeKey = Object.keys(condition)[0];
		const attribute = attributeKey as JFRConditionAttribute;

		// This UI supports only certain attributes
		if (!attributeKey?.startsWith('data.') && !ATTRIBUTES.includes(attribute)) {
			throw new Error();
		}

		// Custom attributes must be defined in GRM filters
		if (attributeKey?.startsWith('data.')) {
			const keys = this.filters.map((f) => f.key);

			if (!keys.includes(attributeKey.substring(5))) {
				throw new Error();
			}
		}

		const operator = Object.keys(condition[attribute])[0] as JFRConditionOperator;

		// This UI supports only certain operators
		if (!OPERATORS.includes(operator)) {
			throw new Error();
		}

		const value = condition[attribute][operator];

		// This UI supports only scalar values (numbers and strings)
		if (value && !['number', 'string'].includes(typeof value)) {
			throw new Error();
		}

		return {
			attribute,
			operator,
			value,
		};
	}

	private parse(conditions: any[]): JFRCondition[] {
		return conditions.map((condition: any) => {
			return this.validate(condition);
		});
	}

	// Serialize the rule for submission to the API
	toObject(): unknown {
		return {
			[this.dimension]: {
				[this.join]: this.conditions.map((c) => {
					return {
						[c.attribute]: {
							[c.operator]: c.value,
						},
					};
				}),
			},
		};
	}
}
