import { IndexTable, Link, Stack, TextStyle } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../../hooks/useLocalization';
import * as routes from '../../../routes';

interface LicensePlateCellProps {
	jobId: string;
	licensePlate?: string | null;
	destinationNode: string;
	isHealJob?: boolean;
}

export function LicensePlateCell({
	jobId,
	licensePlate,
	destinationNode,
	isHealJob,
}: LicensePlateCellProps) {
	const { messages } = useLocalization();

	return (
		<IndexTable.Cell>
			<Stack>
				<Link url={routes.outboundJob(jobId)} removeUnderline>
					{licensePlate ?? messages.unknown}
				</Link>
				{destinationNode ? (
					<div>
						<TextStyle variation="subdued">{`${messages.destination} ${destinationNode}`}</TextStyle>
					</div>
				) : null}
				{isHealJob ? (
					<TextStyle variation="subdued">{`${messages.resolutionShortHand}`}</TextStyle>
				) : null}
			</Stack>
		</IndexTable.Cell>
	);
}
