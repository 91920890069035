import { IframeHTMLAttributes } from 'react';

import styles from './InlineFrame.module.css';

/**
 * Customized iFrame element for embedding other applications as full pages.
 */
export function InlineFrame({
	title,
	src,
}: Required<Pick<IframeHTMLAttributes<Record<never, any>>, 'title' | 'src'>>) {
	return <iframe className={styles.iframe} title={title} src={src}></iframe>;
}
