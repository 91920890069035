import { ChuckProps, ChuckEffect, ChuckStatus } from '@sixriver/map-element';

import { Chuck } from './Chuck.type';
import {
	isCharging,
	isDwelling,
	isFaulty,
	isHighPriority,
	isLowBattery,
	isPaused,
	isPicking,
} from './ChuckUtils';

/**
 * This function converts a Chuck into an object suitable for the <Map> component.
 */
export function asChuckIcon(chuck: Chuck, fullscreen = false): ChuckProps {
	let effect = undefined;
	let status = undefined;

	if (chuck.matched === true) {
		effect = 'emphasize';
	} else if (chuck.matched === false) {
		effect = 'subdue';
	} else {
		// no effect
	}

	if (isFaulty(chuck)) {
		status = 'faulty';
	} else if (isCharging(chuck)) {
		status = 'charging';
	} else if (isDwelling(chuck) || isHighPriority(chuck)) {
		// Both states should show the same color on the chuck on the map
		status = 'dwelling';
	} else if (isPaused(chuck)) {
		status = 'paused';
	} else if (isLowBattery(chuck)) {
		status = 'low-battery';
	} else {
		status = 'okay';
	}

	return {
		// if the chuck moved more than 2 meters since the last update, do not animate
		animationDuration: (chuck.distance || 0) > 2 ? undefined : 1000,
		effect: effect as ChuckEffect,
		id: chuck.id,
		name: chuck.name,
		orientation: chuck.telemetry?.orientation,
		picker: isPicking(chuck),
		scalingFn: fullscreen ? 'scaleMid' : undefined,
		showName: fullscreen ? false : undefined,
		status: status as ChuckStatus,
		x: chuck.telemetry?.x,
		y: chuck.telemetry?.y,
	};
}
