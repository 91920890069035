import { gql } from 'graphql-tag';

export const JOB_FLOW_RULES_QUERY = gql`
	query JobFlowRules {
		jobFlowRules {
			id
			ruleSubType
			ruleDescription
			rule
		}
	}
`;

export const JOB_FLOW_RULE_QUERY = gql`
	query JobFlowRule($id: String!) {
		jobFlowRule(id: $id) {
			id
			ruleSubType
			ruleDescription
			rule
		}
	}
`;

export const ADD_JOB_FLOW_RULE_MUTATION = gql`
	mutation AddJobFlowRule($input: JobFlowRuleInput!) {
		addJobFlowRule(input: $input) {
			success
			referenceId
		}
	}
`;

export const EDIT_JOB_FLOW_RULE_MUTATION = gql`
	mutation EditJobFlowRule($input: JobFlowRuleInput!) {
		editJobFlowRule(input: $input) {
			success
			referenceId
		}
	}
`;

export const CLOSE_JOB_FLOW_RULE_MUTATION = gql`
	mutation AddJobFlowRule($id: String!) {
		closeJobFlowRule(id: $id) {
			success
			referenceId
		}
	}
`;

export const PRIORITIZE_JOB_FLOW_RULES_MUTATION = gql`
	mutation PrioritizeJobFlowRules($input: JobFlowRulePrioritiesInput!) {
		prioritizeJobFlowRules(input: $input) {
			success
		}
	}
`;
