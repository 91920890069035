import {
	KittingProject as KittingProjectApi,
	SpecialProjectType,
	WorkOrderProject as WorkOrderProjectApi,
} from '@sixriver/fulfillment-api-schema';
import { NoteMinor } from '@sixriver/lighthouse-icons';
import { Banner, Button, Stack } from '@sixriver/lighthouse-web-community';
import { useMemo, useState } from 'react';

import { KittingInstructionsModal } from './KittingInstructionsModal';
import { WorkOrderInstructionsModal } from './WorkOrderInstructionsModal';
import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	project: KittingProjectApi | WorkOrderProjectApi;
}

export function Instructions({ project }: Props) {
	const { messages } = useLocalization();

	const [isInstructionsModalOpen, setIsInstructionModalOpen] = useState<boolean>(false);

	const onButtonClick = () => {
		setIsInstructionModalOpen(true);
	};

	const onModalClose = () => {
		setIsInstructionModalOpen(false);
	};

	const hasInstructions = project.instructions.length > 0;

	const instructionModal = useMemo(() => {
		switch (project.type) {
			case SpecialProjectType.Kitting:
				return (
					<KittingInstructionsModal
						isOpen={isInstructionsModalOpen}
						onClose={onModalClose}
						project={project as KittingProjectApi}
					/>
				);
			default:
				return (
					<WorkOrderInstructionsModal
						isOpen={isInstructionsModalOpen}
						onClose={onModalClose}
						project={project as WorkOrderProjectApi}
					/>
				);
		}
	}, [isInstructionsModalOpen, project]);

	return (
		<>
			<Stack vertical>
				{hasInstructions ? undefined : (
					<Banner status="warning">{messages.instructionsNotFound}</Banner>
				)}
				<Button plain icon={NoteMinor} onClick={onButtonClick} disabled={!hasInstructions}>
					{messages.viewInstructions}
				</Button>
			</Stack>
			{instructionModal}
		</>
	);
}
