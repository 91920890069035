import {
	Job,
	LinesViews,
	OrderV2,
	QueryJobsLinesV2Args,
	QueryJobsLinesV2CountArgs,
} from '@sixriver/fulfillment-api-schema';
import { Card, Heading, Pagination, Stack } from '@sixriver/lighthouse-web-community';
import { useCallback, useMemo, useState } from 'react';

import { LinesFilter } from './LinesFilter';
import { LinesProvider } from './LinesProvider';
import { LinesTable, LinesTableColumn } from './LinesTable';
import { COUNTS_QUERY, JobLineCounts } from './LinesTableCounts.graphql';
import { LINES_QUERY } from './LinesTables.graphql';
import { LinesTab, LinesTabs } from './LinesTabs';
import { getPageSize } from '../../helpers/page-size';
import { useLocalization } from '../../hooks/useLocalization';
import { usePolling } from '../../hooks/usePolling';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import { useQueryState } from '../../hooks/useQueryState';

const SEARCH_TEXT_KEY = 'linesQuery';
const SELECTED_TAB = 'selectedTab';

export interface LinesProps {
	job: Job | OrderV2;
	columns: (LinesTableColumn | null)[];
	tabs?: LinesTab[];
	loading?: boolean;
}

export function Lines({ job, columns, tabs = [], loading }: LinesProps) {
	const { queryPollInterval } = usePolling();
	const { messages } = useLocalization();
	const [selectedTab, setSelectedTab] = useQueryState<LinesViews>(SELECTED_TAB, LinesViews.All);
	const [searchText, setSearchText] = useQueryState(SEARCH_TEXT_KEY, '');
	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	const [{ data: countsData }] = usePollingQuery<JobLineCounts, QueryJobsLinesV2CountArgs>({
		pollInterval: queryPollInterval,
		query: COUNTS_QUERY,
		variables: {
			id: job.id,
			searchText: searchText.length >= 3 ? searchText : undefined,
		},
	});
	const [{ fetching, data }, refetch] = usePollingQuery<{ job: Job }, QueryJobsLinesV2Args>({
		context: useMemo(
			() => ({
				useWarehouseApi: true,
			}),
			[],
		),
		pollInterval: queryPollInterval,
		query: LINES_QUERY,
		variables: {
			after: paginationCursors[0],
			first: getPageSize(),
			id: job.id,
			searchText: searchText.length >= 3 ? searchText : undefined,
			view: selectedTab,
		},
	});
	const isLoading = loading ?? fetching;

	const lines = data ? (data.job.lines as any).edges : [];
	const linesPageInfo = data
		? (data.job.lines as any).pageInfo
		: { endCursor: null, hasNextPage: false, hasPreviousPage: false };
	const counts = {
		all: countsData?.All.count,
		complete: countsData?.Completed.count,
		exceptions: countsData?.Exceptions.count,
		inProgress: countsData?.InProgress.count,
		unassigned: countsData?.Unassigned.count,
	};
	const totalLinesCount = counts.all ?? 0;
	const totalLinesPages = Math.max(Math.trunc(totalLinesCount / getPageSize()), 1);

	const handleOnNextPage = useCallback(() => {
		const endCursor = linesPageInfo?.endCursor ?? undefined;
		if (endCursor) {
			setPaginationCursors((current) => [endCursor].concat(current));
			refetch();
		}
	}, [linesPageInfo?.endCursor, refetch]);

	const handleOnPreviousPage = useCallback(() => {
		setPaginationCursors((current) => current.slice(1));
		refetch();
	}, [refetch]);

	return (
		<LinesProvider job={job}>
			<Card>
				<Card.Section>
					<Heading>{messages.lines}</Heading>
				</Card.Section>
				<Card.Section fullWidth flush>
					<LinesTabs tabs={tabs} counts={counts} selected={selectedTab} onSelect={setSelectedTab} />
					<LinesFilter queryValue={searchText} onQueryChange={(value) => setSearchText(value)} />
					<LinesTable loading={isLoading} data={lines} columns={columns} />
				</Card.Section>
				<Card.Section>
					<Stack distribution="center">
						<Pagination
							label={`${paginationCursors.length + 1} ${messages.of} ${totalLinesPages}`}
							hasNext={linesPageInfo?.hasNextPage}
							hasPrevious={linesPageInfo?.hasPreviousPage}
							onNext={handleOnNextPage}
							onPrevious={handleOnPreviousPage}
						/>
					</Stack>
				</Card.Section>
			</Card>
		</LinesProvider>
	);
}
