import { Link, TextStyle } from '@sixriver/lighthouse-web-community';
import { useMemo } from 'react';
import { useQuery } from 'urql';

import { GET_ORDERS_QUERY } from './Orders.graphql';
import { AvailableMfp } from '../../api';
import * as routes from '../../routes';

interface RelatedOrdersProps {
	device: AvailableMfp;
}

export default function RelatedOrders({ device }: RelatedOrdersProps): JSX.Element {
	const ids = device.workspaceReservations.map(
		(workspaceReservation) => workspaceReservation.jobId,
	);
	const [{ data }] = useQuery<
		{
			jobs: {
				edges: {
					node: {
						orders: {
							id: string;
						}[];
					};
				}[];
			};
		},
		{ ids: string[] }
	>({
		context: useMemo(
			() => ({
				useWarehouseApi: true,
			}),
			[],
		),
		pause: ids.length === 0,
		query: GET_ORDERS_QUERY,
		variables: {
			ids,
		},
	});

	if (data) {
		const orderIds = data.jobs.edges.reduce<Set<string>>((orderIds, edge) => {
			edge.node.orders.forEach((order) => {
				orderIds.add(order.id);
			});
			return orderIds;
		}, new Set());

		if (orderIds.size > 0) {
			const params = new URLSearchParams();
			orderIds.forEach((orderId) => {
				params.append('ids', orderId);
			});
			const url = `${routes.orders()}?${params.toString()}`;
			return (
				<Link url={url} removeUnderline>
					{orderIds.size}
				</Link>
			);
		}
	}

	return <TextStyle variation="subdued">0</TextStyle>;
}
