import { gql } from 'graphql-tag';

export const PUTAWAY_JOB_QUERY = gql`
	query PutawayJob($id: String!) {
		putawayJob(id: $id) {
			id
			type
			state
			externalId
			createdAt
			endedAt
			lastEventPersistedAt
			currentActor {
				user {
					type
					id
				}
				device {
					type
					id
				}
			}
			inputs {
				containerLabels {
					default
				}
				containerKind
				customerData
			}
			outputs {
				collectedContainerLabel
				takeoffDestination
				takenOffAt
			}
			lines {
				id
				type
				state
				externalId
				currentActor {
					user {
						type
						id
					}
					device {
						type
						id
					}
				}
				inputs {
					assetTypes
					amount
					customerData
					reservationSources
				}
				outputs {
					totalCompleted
					transfers {
						location
						assetTypeId
						completedAmount
						exceptions
						capturedData {
							group
						}
					}
				}
			}
		}
	}
`;
