import { BulkOrder } from '@sixriver/fulfillment-api-schema';
import { Button, Card } from '@sixriver/lighthouse-web-community';
import { useMutation } from 'urql';

import styles from './Details.module.css';
import { CardDetails } from '../../../components/CardDetails';
import { DateTime } from '../../../components/DateTime';
import { useLocalization } from '../../../hooks/useLocalization';
import { useToast } from '../../../hooks/useToast';
import { REPRINT_BATCH_BARCODE_MUTATION } from '../../BulkOrders/BulkOrders.graphql';

interface Props {
	loading?: boolean;
	order?: BulkOrder;
}

export function Details({ loading = false, order }: Props) {
	const { messages } = useLocalization();

	const { showToast } = useToast();

	const [{ fetching }, reprintBatchBarcodeMutation] = useMutation<
		{ reprintBatchBarcode: Response },
		{ id: string }
	>(REPRINT_BATCH_BARCODE_MUTATION);

	const reprintBatchBarcode = async () => {
		const { error } = await reprintBatchBarcodeMutation({ id: order!.jobFlowRuleId });
		if (!error) {
			showToast(messages.reprinted);
		} else {
			showToast(error?.message);
		}
	};

	return (
		<Card title={messages.details}>
			<Card.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							content: order?.lpn,
							label: 'ID',
						},
						{
							content: order?.merchant,
							label: messages.store,
						},
						{
							content: <DateTime date={order?.expectedShipDate}></DateTime>,
							label: messages.earliestCutoff,
						},
					]}
				/>
			</Card.Section>
			<Card.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							content: <DateTime date={order?.createdAt}></DateTime>,
							label: messages.createdAt,
						},
						{
							content: <DateTime date={order?.updatedAt}></DateTime>,
							label: messages.updatedAt,
						},
					]}
				/>

				<div className={styles.ButtonContainer}>
					<Button primary loading={fetching} onClick={() => void reprintBatchBarcode()}>
						{messages.reprintBatchBarcode}
					</Button>
				</div>
			</Card.Section>
		</Card>
	);
}
