import { useAuth } from '@sixriver/react-support';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';

export function LoginRoute({ children, ...rest }: RouteProps) {
	const { isAuthenticated } = useAuth();
	const location = useLocation<{ from?: Location }>();
	return (
		<Route
			{...rest}
			render={() => {
				if (!isAuthenticated()) {
					return children;
				}

				return <Redirect to={location.state?.from || '/'} />;
			}}
		/>
	);
}
