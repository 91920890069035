import { gql } from 'graphql-tag';

export const GET_ORDERS_QUERY = gql`
	query GetOrders($ids: [String!]) {
		jobs(ids: $ids, isOrder: true) {
			edges {
				node {
					orders {
						id
					}
				}
			}
		}
	}
`;
