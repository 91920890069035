import { gql } from 'graphql-tag';

import { PAGE_INFO_FRAGMENT } from '../../graphql';

// Queries
export const LOCATION_EVENTS_QUERY = gql`
	query GetLocationEvents($locationId: String!, $productId: String!, $limit: Int, $cursor: String) {
		inventoryEvents(
			productId: $productId
			locationId: $locationId
			limit: $limit
			cursor: $cursor
		) {
			results {
				__typename
				timestamp
				quantity
				quantityAttempted
				balance
				conflictReasons
				intent
				user {
					name
					id
					badge
				}
				... on InventoryPickedEvent {
					detail {
						projectionId
						containerId
					}
				}
				... on InventoryReplenishedEvent {
					detail {
						projectionId
						containerId
						sourceLocationId
						sourceLocationAddress
						destLocationId
						destLocationAddress
						replenishedQuantity
					}
				}
				... on InventoryMovedEvent {
					detail {
						sourceLocationId
						sourceLocationAddress
						destLocationId
						destLocationAddress
					}
				}
			}
			cursor
		}
	}
`;

export const GET_LOCATIONS_QUERY_V2 = gql`
	query locations(
		$cursor: String
		$after: String
		$limit: Float
		$first: Int
		$orderBy: StorageLocationOrderByFields
		$orderByDirection: OrderByDirection
		$type: StorageLocationType
		$searchText: String
	) {
		locations(
			cursor: $cursor
			limit: $limit
			after: $after
			first: $first
			orderBy: $orderBy
			orderByDirection: $orderByDirection
			type: $type
			searchText: $searchText
		) {
			edges {
				node {
					id
					type
					address
					description
					externalAisleId
					containerTypeId
					containerTypeName
				}
			}
			pageInfo {
				...PageInfo
			}
		}
	}
	${PAGE_INFO_FRAGMENT}
`;

export const GET_LOCATIONS_QUERY = gql`
	query locations(
		$cursor: String
		$limit: Int
		$orderBy: StorageLocationOrderByFields
		$orderByDirection: OrderByDirection
		$type: StorageLocationType
		$searchText: String
		$isConflicted: Boolean
		$fullness: Float
		$isSlotted: Boolean
		$workAreaIds: [String!]
	) {
		locations(
			cursor: $cursor
			limit: $limit
			orderBy: $orderBy
			orderByDirection: $orderByDirection
			type: $type
			searchText: $searchText
			isConflicted: $isConflicted
			fullness: $fullness
			isSlotted: $isSlotted
			workAreaIds: $workAreaIds
		) {
			results {
				id
				type
				address
				description
				externalAisleId
				containerTypeId
				containerTypeName
				conflicted
				liveQuantity
				totalPlannedAddQuantity
				totalPlannedRemoveQuantity
				fillFraction
				usedVolume
				numAssetTypes
				numSubAssetTypes
				numSubLocations
				inventories {
					assetTypeId
					assetTypeExternalId
					assetTypeName
					assetTypeDescription
					liveQuantity
					totalPlannedAddQuantity
					totalPlannedRemoveQuantity
					conflictReasons
					subLocationId
				}
				workArea {
					name
					id
				}
				inventoryTags {
					definition {
						condition
					}
				}
			}
			cursor
		}
	}
`;

export const GET_LOCATION_TYPE_COUNTS = gql`
	query locationTypeCounts(
		$containerTypeId: String
		$searchText: String
		$isConflicted: Boolean
		$fullness: Float
		$isSlotted: Boolean
		$workAreaIds: [String!]
	) {
		locationTypeCounts(
			containerTypeId: $containerTypeId
			searchText: $searchText
			isConflicted: $isConflicted
			fullness: $fullness
			isSlotted: $isSlotted
			workAreaIds: $workAreaIds
		) {
			type
			count
		}
	}
`;

export const GET_LOCATION_TYPE_COUNTS_V2 = gql`
	query locationTypeCounts($searchText: String) {
		locationTypeCounts(searchText: $searchText) {
			type
			count
		}
	}
`;

export const GET_CAPACITY_TYPES_QUERY = gql`
	query {
		containers {
			results {
				id
				type
				name
			}
		}
	}
`;

export const GET_LOCATION_QUERY = gql`
	query locationById($id: String!) {
		location(id: $id) {
			id
			address
			type
			description
			requiredReach
			externalAisleId
			containerType {
				id
				name
			}
			coordinates {
				x
				y
				z
			}
		}
	}
`;

export const LOCATION_QUERY = gql`
	query location($id: String!) {
		location(id: $id) {
			id
			address
			externalAisleId
			type
			description
			requiredReach
			containerType {
				id
				name
				width
				height
				length
				volume
			}
			coordinates {
				x
				y
				z
			}
			products {
				product {
					id
					name
					image
					description
					customerIdentifier
					width
					height
					length
					volume
					scanValues
				}
				inventory {
					assetTypeId
					conflicted
					conflictReasons
					totalPlannedRemoveQuantity # reserved
					totalPlannedAddQuantity
					liveQuantity # available
					subAssetDescriptorId
					subLocationDescriptorId
					subLocation {
						definition {
							externalId
						}
					}
					subAssetType {
						definition {
							condition
						}
					}
				}
			}
			workArea {
				id
				name
			}
		}
	}
`;

export const LOCATION_QUERY_V2 = gql`
	query storageLocation($id: String!) {
		storageLocation(id: $id) {
			id
			address
			externalAisleId
			type
			description
			containerType {
				id
				name
				width
				height
				length
				cubeVolume
			}
			coordinates {
				x
				y
				z
			}
		}
	}
`;

export const GET_AISLES_QUERY = gql`
	query {
		aisles {
			id
			externalId
		}
	}
`;

// Mutations
export const ADD_NEW_LOCATION_MUTATION = gql`
	mutation addNewLocation($input: StorageLocationInput!) {
		addLocation(input: $input) {
			success
			referenceId
		}
	}
`;

export const EDIT_LOCATION_MUTATION = gql`
	mutation editLocation($input: StorageLocationInput!) {
		editLocation(input: $input) {
			success
		}
	}
`;

export const DELETE_LOCATION_MUTATION = gql`
	mutation deleteLocation($id: String!) {
		deleteLocation(id: $id) {
			success
			referenceId
		}
	}
`;
