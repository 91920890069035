import { GoalStates } from '@sixriver/fulfillment-api-schema';
import { IndexTable } from '@sixriver/lighthouse-web-community';
import type { PutawayConnection } from '@sixriver/warehouse-api-graphql-types';

import { DateTime } from '../../../components/DateTime';
import { NoData } from '../../../components/NoData';
import { RelativeDateTime } from '../../../components/RelativeDateTime';

export function UpdatedAtCell({
	putawayJob,
}: {
	putawayJob: PutawayConnection['edges'][number]['node'];
}) {
	return (
		<IndexTable.Cell>
			{putawayJob?.state.includes(GoalStates.Running.toLowerCase()) && (
				<>
					<DateTime date={putawayJob?.lastEventPersistedAt} />
					<RelativeDateTime date={putawayJob?.lastEventPersistedAt} />
				</>
			)}
			{putawayJob?.state.includes(GoalStates.Cancelled.toLowerCase()) && (
				<>
					<DateTime date={putawayJob?.endedAt} />
					<RelativeDateTime date={putawayJob?.endedAt} />
				</>
			)}
			{putawayJob?.state.includes(GoalStates.Terminated.toLowerCase()) && (
				<>
					<DateTime date={putawayJob?.endedAt} />
					<RelativeDateTime date={putawayJob?.endedAt} />
				</>
			)}
			{putawayJob?.state.includes(GoalStates.Complete.toLowerCase()) && (
				<>
					<DateTime date={putawayJob?.endedAt} />
					<RelativeDateTime date={putawayJob?.endedAt} />
				</>
			)}
			{putawayJob?.state.includes(GoalStates.Ready.toLowerCase()) && <NoData />}
		</IndexTable.Cell>
	);
}
