import { KitComponentProductDetail } from '@sixriver/fulfillment-api-schema';
import { Stack, TextStyle } from '@sixriver/lighthouse-web-community';

import { NoData } from '../../components/NoData';
import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	componentProduct: KitComponentProductDetail;
}

export function Issues({ componentProduct }: Props): JSX.Element {
	const { messages } = useLocalization();

	return (
		<Stack vertical={true} spacing="extraTight">
			{componentProduct.exceptions ? (
				componentProduct.exceptions
					.filter((issue) => {
						return issue.eachQuantity > 0;
					})
					.map((issue) => {
						const reason = {
							damagedProduct: messages.kitIssues.damagedProduct,
							missingProduct: messages.kitIssues.missingProduct,
							other: messages.kitIssues.otherIssue,
						}[issue.reason];

						return (
							<TextStyle variation="negative" key={issue.reason}>
								{`${issue.eachQuantity} ${reason}`}
							</TextStyle>
						);
					})
			) : (
				<NoData />
			)}
		</Stack>
	);
}
