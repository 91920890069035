import { useDebouncedValue } from '@shopify/react-hooks';
import {
	SpecialProjectType,
	UserActivityType,
	UserConnection,
	WorkLogInput,
} from '@sixriver/fulfillment-api-schema';
import {
	Autocomplete,
	Form,
	FormLayout,
	Modal,
	Select,
	TextField,
} from '@sixriver/lighthouse-web-community';
import { useState } from 'react';
import { useQuery } from 'urql';

import { EMPLOYEES_QUERY } from './SpecialProjects.graphql';
import { FormFeedback } from '../../components/FormFeedback';
import { MILLISECONDS_PER_HOUR } from '../../helpers/time';
import { FormProps, useForm } from '../../hooks/useForm';
import { useLocalization } from '../../hooks/useLocalization';
import { formatIsoDate } from '../../utils';

interface WorkLogModalProps {
	projectType?: SpecialProjectType | null;
	userName?: string | null;
	onClose: () => void;
}

export function WorkLogAddEditModal({
	data,
	mode,
	error,
	onSubmit,
	projectType,
	userName,
	onClose,
}: FormProps<WorkLogInput> & WorkLogModalProps) {
	const { messages } = useLocalization();
	const [searchText, setSearchText] = useState(userName);
	const debouncedSearchText = useDebouncedValue(searchText);

	const { editForm, discardForm, input, dirty, feedback, validations } = useForm<WorkLogInput>(
		data,
		error,
	);

	// queries
	const [{ data: users }] = useQuery<{
		users: UserConnection;
	}>({
		query: EMPLOYEES_QUERY,
		variables: {
			searchText: debouncedSearchText,
			type: UserActivityType.Active,
		},
	});

	const tasks = (() => {
		switch (projectType) {
			case SpecialProjectType.Kitting: {
				return [
					messages.workLogTypes.inventoryMovement,
					messages.workLogTypes.stationSetup,
					messages.workLogTypes.sopReview,
					messages.workLogTypes.stationCleanup,
					messages.workLogTypes.otherType,
				].map((msg) => {
					return { label: msg, value: msg };
				});
			}

			default:
				return [];
		}
	})();

	const setUser = (userId: string) => {
		const edge = users?.users.edges.find((edge) => edge.node.id === userId);
		const user = edge?.node;

		if (user) {
			setSearchText(user.name || messages.unnamed);
			editForm({ userId });
		} else {
			// if the user mangles the search text without selecting another user, null it out
			setSearchText('');
			editForm({ userId: '' });
		}
	};

	const onCancel = () => {
		discardForm();
		onClose();
	};

	const revertSearchText = () => {
		setUser(input.userId || '');
	};

	return (
		<Modal
			open={true}
			onClose={onCancel}
			title={messages.logWork}
			primaryAction={{
				content: messages.save,
				disabled: !dirty,
				onAction: () => void onSubmit(input),
			}}
			secondaryActions={[
				{
					content: messages.cancel,
					onAction: onCancel,
				},
			]}
		>
			<Modal.Section>
				<Form onSubmit={() => void onSubmit(input)} noValidate>
					<FormFeedback feedback={feedback} />
					<FormLayout>
						<FormLayout.Group condensed>
							<Autocomplete
								options={(users?.users.edges || []).map((user) => {
									return {
										label: user.node.name ?? messages.unnamed,
										value: user.node.id,
									};
								})}
								selected={[input.userId || '']}
								onSelect={(values) => setUser(values[0])}
								textField={
									<Autocomplete.TextField
										name="associateName"
										label={messages.associate}
										disabled={mode === 'edit'}
										placeholder={messages.searchName}
										value={userName || searchText || ''}
										onChange={setSearchText}
										onBlur={revertSearchText}
										error={validations.userId}
										autoComplete="off"
									/>
								}
							/>
							<TextField
								autoComplete="on"
								name="date"
								type="date"
								label={messages.date}
								disabled={mode === 'edit'}
								value={formatIsoDate(input.dateLogged as string)}
								error={validations.dateLogged}
								onChange={(value) => editForm({ dateLogged: new Date(value) })}
							/>
						</FormLayout.Group>
						<FormLayout.Group>
							{tasks.length ? (
								<Select
									name="task"
									label={messages.task}
									disabled={mode === 'edit'}
									options={tasks}
									error={validations.task}
									placeholder={messages.select}
									value={input.task}
									onChange={(value) => editForm({ task: value })}
								/>
							) : (
								<TextField
									name="task"
									label={messages.task}
									disabled={mode === 'edit'}
									error={validations.task}
									autoComplete="off"
									maxLength={100}
									minLength={1}
									multiline={true}
									showCharacterCount
									placeholder={messages.workLogTaskHelperText}
									value={input.task}
									onChange={(value) => editForm({ task: value })}
								/>
							)}
							<TextField
								name="time"
								type="number"
								label={messages.timeSpent}
								suffix={messages.hours}
								autoComplete="off"
								inputMode="numeric"
								placeholder="0.00"
								min={0}
								step={0.1}
								value={(input.timeLogged / MILLISECONDS_PER_HOUR).toString()}
								onChange={(value) =>
									editForm({ timeLogged: Math.round(parseFloat(value) * MILLISECONDS_PER_HOUR) })
								}
								error={validations.timeLogged}
							/>
						</FormLayout.Group>
					</FormLayout>
				</Form>
			</Modal.Section>
		</Modal>
	);
}
