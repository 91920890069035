import { IndexTable } from '@sixriver/lighthouse-web-community';

import { DateTime } from '../../../components/DateTime';
import { RelativeDateTime } from '../../../components/RelativeDateTime';

export function CreatedAtCell({ createdAt }: { createdAt: Date }) {
	return (
		<IndexTable.Cell>
			<DateTime date={createdAt} />
			<RelativeDateTime date={createdAt} />
		</IndexTable.Cell>
	);
}
