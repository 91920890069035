import { StorageLocationSummary } from '@sixriver/fulfillment-api-schema';
import { DiamondAlertMajor } from '@sixriver/lighthouse-icons';
import { Icon, Stack, TextStyle, Tooltip } from '@sixriver/lighthouse-web-community';

import { Peek } from '../../components/Peek';
import { useLocalization } from '../../hooks/useLocalization';
import { useLocationDiscrepancies } from '../../hooks/useLocationDiscrepancies';
import * as routes from '../../routes';
import { NoData } from '../NoData';

interface PeekProps {
	summary: StorageLocationSummary;
}

export function LocationPeek({ summary }: PeekProps) {
	const { messages, translate } = useLocalization();

	const translatedLocationDiscrepancies = useLocationDiscrepancies();

	const count = summary.numAssetTypes;

	const activator = <div>{translate(messages.countSkus, { count })}</div>;

	const action =
		count >= 5
			? {
					content: messages.viewAllItems,
					url: routes.location(summary.id),
			  }
			: undefined;

	const title = (
		<>
			<TextStyle variation="subdued">
				<span>{messages.locationTypes[summary.type || 'unknown']}</span>
			</TextStyle>
			{summary.address}
		</>
	) as unknown as string;

	const rows = Array.from(summary.inventories).sort(
		(a, b) => (b.liveQuantity || 0) - (a.liveQuantity || 0),
	);

	return (
		<Peek activator={activator} title={title} action={action}>
			<table className="loc-peek">
				<style>
					{`
						.loc-peek th, .loc-peek td {
							padding: 0.35rem 2rem 0.35rem 0;
							text-align: left;
					  }
						.Polaris-Heading span:first-child {
							font-weight: normal;
							margin-right: 0.15em;
						}
					`}
				</style>
				<thead>
					<tr>
						<th>{messages.sku}</th>
						<th>{messages.quantity}</th>
					</tr>
				</thead>
				<tbody>
					{rows.slice(0, 5).map((row, idx) => {
						const conflictReason =
							row.conflictReasons.length > 1
								? translatedLocationDiscrepancies.Other
								: translatedLocationDiscrepancies[row.conflictReasons?.[0]] || undefined;

						return (
							<tr key={idx}>
								<td>{row.assetTypeName}</td>
								<td>
									<Stack>
										<Stack.Item>
											<TextStyle>{row.liveQuantity || <NoData />}</TextStyle>
										</Stack.Item>
										{conflictReason ? (
											<Stack.Item>
												<Tooltip content={conflictReason}>
													<Icon source={DiamondAlertMajor} color="critical" />
												</Tooltip>
											</Stack.Item>
										) : null}
									</Stack>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</Peek>
	);
}
