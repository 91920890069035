import { Modal, Text } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../../hooks/useLocalization';
import { Mfa } from '../providers/Mfas';

interface ConfirmRemoveMfaModalProps {
	open: boolean;
	onDismiss: () => void;
	onConfirm: () => void;
	mfa: Mfa;
}
export function ConfirmRemoveMfaModal({
	open,
	onDismiss,
	onConfirm,
	mfa,
}: ConfirmRemoveMfaModalProps) {
	const { messages, translate } = useLocalization();

	return (
		<Modal
			open={open}
			onClose={onDismiss}
			title={translate(messages.mfa.remove.title, { name: mfa.name })}
			primaryAction={{
				content: messages.mfa.remove.submit,
				destructive: true,
				onAction: onConfirm,
			}}
			secondaryActions={[
				{
					content: messages.mfa.remove.cancel,
					onAction: onDismiss,
				},
			]}
		>
			<Modal.Section>
				<Text as="p" variant="bodyMd">
					{messages.mfa.remove.details}
				</Text>
			</Modal.Section>
		</Modal>
	);
}
