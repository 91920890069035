import styles from './FulfillmentTracker.module.css';
import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	label: string;
	value: number;
	warn?: boolean;
}

export function CutoffStat({ label, value, warn = false }: Props) {
	const { formatNumber } = useLocalization();

	return (
		<div className={styles.stat}>
			<div>{label}</div>
			<div data-warn={warn && value > 0}>{formatNumber(value)}</div>
		</div>
	);
}
