import { JobEdge, PageInfo, WorkOrderType } from '@sixriver/fulfillment-api-schema';
import {
	EmptySearchResult,
	IndexTable,
	IndexTableProps,
	useIndexResourceState,
} from '@sixriver/lighthouse-web-community';

import { DateReceivedCell } from './DateReceivedCell';
import { DeviceCell } from './DeviceCell';
import { ExpectedShipDateCell } from './ExpectedShipDateCell';
import { LicensePlateCell } from './LicensePlateCell';
import { LinesCell } from './LinesCell';
import { PickStrategyCell } from './PickStrategyCell';
import { StatusCell } from './StatusCell';
import { UnitsCell } from './UnitsCell';
import { useLocalization } from '../../../hooks/useLocalization';
import { WorkOrderTypeCell } from '../../Orders/OrdersTable/WorkOrderTypeCell';

export interface OutboundJobsTableProps {
	loading: boolean;
	data?: JobEdge[];
	pageInfo?: PageInfo;
	showPickStrategyColumn?: boolean;
	showWorkOrderTypeColumn?: boolean;
	totalFilteredJobs?: number;
}

export function OutboundJobsTable({
	loading,
	data = [],
	pageInfo,
	showPickStrategyColumn = false,
	showWorkOrderTypeColumn = false,
}: OutboundJobsTableProps) {
	const { messages } = useLocalization();

	const {
		selectedResources: selectedOrderIds,
		allResourcesSelected,
		handleSelectionChange,
	} = useIndexResourceState(data, { resourceIDResolver: (edge) => edge.node.id });

	const resourceName = {
		plural: messages.orders.toLowerCase(),
		singular: messages.order.toLowerCase(),
	};
	const emptyStateMarkup = (
		<EmptySearchResult title={messages.noOutboundJobsFound} withIllustration />
	);
	const headings: IndexTableProps['headings'] = [
		{ title: messages.licensePlate },
		{ title: messages.createdAt },
		{ title: messages.carrierCutoff },
		{ title: messages.units },
		{ title: messages.lines },
		...(showWorkOrderTypeColumn ? [{ title: messages.workOrderType }] : []),
		{ title: messages.jobStatus },
		...(showPickStrategyColumn ? [{ title: messages.pickStrategy }] : []),
		{ title: messages.equipment },
	] as IndexTableProps['headings'];

	const rows = data.map(({ node }, index) => {
		const {
			id,
			pickStrategy,
			createdAt,
			expectedShipDate,
			externalContainerId,
			isLate,
			totalLines,
			totalLineQuantities,
			workOrderTypes,
			destinationNode,
			mfp,
			isHealJob,
			usedPickDeviceClasses,
		} = node;

		return (
			<IndexTable.Row
				id={id}
				key={id}
				position={index}
				selected={allResourcesSelected || selectedOrderIds.includes(id)}
			>
				<LicensePlateCell
					jobId={id}
					licensePlate={externalContainerId}
					destinationNode={destinationNode as string}
					isHealJob={isHealJob}
				/>
				<DateReceivedCell createdAt={createdAt} />
				<ExpectedShipDateCell expectedShipDate={expectedShipDate} isLate={isLate} />
				<UnitsCell totalLineQuantities={totalLineQuantities} />
				<LinesCell totalLines={totalLines} totalLineQuantities={totalLineQuantities} />
				{showWorkOrderTypeColumn ? (
					<WorkOrderTypeCell
						key={`${id}-workOrderTypes`}
						types={workOrderTypes as WorkOrderType[]}
					></WorkOrderTypeCell>
				) : undefined}
				<StatusCell job={node} />
				{showPickStrategyColumn ? (
					<PickStrategyCell pickStrategy={pickStrategy ?? undefined} />
				) : undefined}
				<DeviceCell deviceName={mfp?.name} deviceClasses={usedPickDeviceClasses} />
			</IndexTable.Row>
		);
	});

	return (
		<>
			<IndexTable
				emptyState={emptyStateMarkup}
				resourceName={resourceName}
				headings={headings}
				hasMoreItems={pageInfo?.hasPreviousPage || pageInfo?.hasNextPage}
				itemCount={data.length}
				loading={loading}
				selectedItemsCount={allResourcesSelected ? 'All' : selectedOrderIds.length}
				onSelectionChange={handleSelectionChange}
				selectable={false}
			>
				{rows}
			</IndexTable>
		</>
	);
}
