import { IndexTable, Link } from '@sixriver/lighthouse-web-community';

import { AvailableMfp } from '../../api';
import * as routes from '../../routes';

type NameCellProps = {
	device: AvailableMfp;
};

export function NameCell({ device }: NameCellProps) {
	return (
		<IndexTable.Cell>
			<Link url={routes.device(device.id)} removeUnderline>
				{device.id}
			</Link>
		</IndexTable.Cell>
	);
}
