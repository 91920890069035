import { IndexTable, Link, TextStyle } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../../hooks/useLocalization';
import * as routes from '../../../routes';

interface ExternalIdCellProps {
	orderId: string;
	externalId: string;
	destinationNode: string;
}

export function ExternalIdCell({ orderId, externalId, destinationNode }: ExternalIdCellProps) {
	const { messages } = useLocalization();

	return (
		<IndexTable.Cell>
			<Link url={routes.order(orderId)} removeUnderline>
				{externalId}
			</Link>
			{destinationNode ? (
				<div>
					<TextStyle variation="subdued">{`${messages.destination} ${destinationNode}`}</TextStyle>
				</div>
			) : null}
		</IndexTable.Cell>
	);
}
