import { IndexTable } from '@sixriver/lighthouse-web-community';

import { DeviceBadge } from './DeviceBadge';
import { AvailableMfp } from '../../api';

type StatusCellProps = {
	device: AvailableMfp;
};

export function StatusCell({ device }: StatusCellProps) {
	return (
		<IndexTable.Cell>
			<DeviceBadge device={device} />
		</IndexTable.Cell>
	);
}
