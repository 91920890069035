import { DateTime } from '@sixriver/react-support';
import { useMemo } from 'react';

import { SimpleDataTable } from '../../../components/DataTable';
import { useLocalization } from '../../../hooks/useLocalization';
import { Reservation } from '../providers/Reservations';

interface PastReservationsDetailProps {
	reservations: Reservation[];
}

export function PastReservations({ reservations }: PastReservationsDetailProps) {
	const { messages } = useLocalization();

	const rows = useMemo(
		() =>
			(reservations || []).map((mfa) => {
				return [
					mfa.resource.name,
					<DateTime key={`createdAt-${mfa.deviceId}`} date={mfa.createdAt * 1000} />,
					<DateTime key={`validUntil-${mfa.deviceId}`} date={mfa.validUntil * 1000} />,
				];
			}),
		[reservations],
	);

	return (
		<SimpleDataTable
			rows={rows}
			itemsPerPage={5}
			columns={[
				messages.mfa.license.list.name,
				messages.mfa.license.list.createdAt,
				messages.mfa.license.list.expiredAt,
			]}
		/>
	);
}
