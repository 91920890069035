import { JobStatus } from '@sixriver/fulfillment-api-schema';

export enum SortWallsViews {
	All = 'All',
	Unassigned = 'Unassigned',
	Picking = 'Picking',
	ReadyToSort = 'ReadyToSort',
	Sorting = 'Sorting',
	CompletedToday = 'CompletedToday',
	// Exceptions = 'Exceptions',
}

export const SortWallsViewStateMap: { [key in SortWallsViews]: Array<JobStatus> } = {
	[SortWallsViews.All]: [
		JobStatus.Unassigned,
		JobStatus.AssignedToSortWall,
		JobStatus.Picking,
		JobStatus.Picked,
		JobStatus.Sortable,
		JobStatus.Sorting,
		JobStatus.Sorted,
		// JobStatus.Exception,
		JobStatus.Canceled,
		// SortationStatus.CanceledOrphan,
	],
	[SortWallsViews.Unassigned]: [JobStatus.Unassigned, JobStatus.AssignedToSortWall],
	[SortWallsViews.Picking]: [JobStatus.Picking],
	[SortWallsViews.ReadyToSort]: [JobStatus.Picked],
	[SortWallsViews.Sorting]: [JobStatus.Sorting],
	[SortWallsViews.CompletedToday]: [JobStatus.Sorted],
	// [SortWallsViews.Exceptions]: [SortationStatus.Exception],
};
