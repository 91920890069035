import { DeviceType, MfpConfigExtended } from '../../api';
import { getDeviceType } from '../../hooks/useAvailableMfps';
import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	mfpConfig: MfpConfigExtended;
}

export function DeviceModel({ mfpConfig }: Props): JSX.Element {
	const { messages } = useLocalization();
	const deviceType = getDeviceType(mfpConfig);

	if (deviceType === DeviceType.Handheld) {
		return <>{messages.deviceTypes.Handheld}</>;
	}

	if (deviceType === DeviceType.PackoutTerminal) {
		<>{messages.deviceTypes.PackoutTerminal}</>;
	}

	if (deviceType === DeviceType.SortationKiosk) {
		return <>{messages.deviceTypes.SortationKiosk}</>;
	}

	if (deviceType === DeviceType.TerminalOnWheels) {
		return <>{messages.deviceTypes.TerminalOnWheels}</>;
	}

	if (deviceType === DeviceType.Chuck) {
		return <>{messages.deviceTypes.Chuck}</>;
	}

	return <>{messages.deviceTypes.Unknown}</>;
}
