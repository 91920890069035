import { Modal } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../hooks/useLocalization';

interface WorkLogDeleteModalProps {
	isOpen: boolean;
	onDelete: () => void;
	onClose: () => void;
}

export function WorkLogDeleteModal({
	isOpen: isModalOpen,
	onClose,
	onDelete,
}: WorkLogDeleteModalProps) {
	const { messages } = useLocalization();

	return (
		<Modal
			open={isModalOpen}
			onClose={onClose}
			title={messages.deleteWorkLog}
			primaryAction={{ content: messages.delete, onAction: onDelete }}
			secondaryActions={[
				{
					content: messages.cancel,
					onAction: onClose,
				},
			]}
		>
			<Modal.Section>
				<p>{messages.confirmDeleteWorkLog}</p>
			</Modal.Section>
		</Modal>
	);
}
