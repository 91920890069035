import { IndexTable, Link } from '@sixriver/lighthouse-web-community';
import type { PutawayConnection } from '@sixriver/warehouse-api-graphql-types';

import { NoData } from '../../../components/NoData';
import * as routes from '../../../routes';

export function DeviceCell({
	container,
}: {
	container: PutawayConnection['edges'][number]['node'];
}) {
	const currentActor = container.currentActor;
	const device =
		currentActor?.device?.type === 'Mfp'
			? { deviceId: currentActor?.device?.id, deviceType: 'mfp' }
			: undefined;

	return (
		<IndexTable.Cell>
			{device ? (
				<Link url={`${routes.floorView()}/?chuck=${device.deviceId}`} key={device.deviceId}>
					{device.deviceId}
				</Link>
			) : (
				<NoData />
			)}
		</IndexTable.Cell>
	);
}
