import { gql } from 'graphql-tag';

export const ORDER_QUERY = gql`
	query GetOrder($id: String!) {
		orderV2(id: $id) {
			id
			externalId
			expectedShipDate
			externalContainerId
			createdAt
			isShorted
			isCancelable
			jobStatus
			lines {
				quantity
				doneQuantity
			}
			exceptions {
				id
				availableHealStrategies
				isHealable
			}
			tasks {
				workArea {
					name
				}
			}
		}
	}
`;
