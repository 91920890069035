import {
	GoalStates,
	KittingProject as KittingProjectApi,
	MutationResponse,
	PreKitExceptionInput,
	PreKitExceptionReason,
	SpecialProjectIssuesInput,
} from '@sixriver/fulfillment-api-schema';
import {
	Card,
	ContextualSaveBar,
	Form,
	FormLayout,
	Page,
	TextField,
} from '@sixriver/lighthouse-web-community';
import { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

import { Product } from './Product';
import { RouteMatchParams } from './SpecialProject';
import { SpecialProjectReviewModal } from './SpecialProjectReviewModal';
import { REPORT_ISSUES_MUTATION, SPECIAL_PROJECT_QUERY } from './SpecialProjects.graphql';
import { FormFeedback } from '../../components/FormFeedback';
import { FormProps, useForm } from '../../hooks/useForm';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

export function ReportIssues() {
	const {
		params: { projectId },
	} = useRouteMatch<RouteMatchParams>();

	const { messages } = useLocalization();

	const history = useHistory();

	const [isReviewModalOpen, setIsReviewModalOpen] = useState<boolean>(false);

	// Data
	const [{ data }] = useQuery<{ specialProject: KittingProjectApi }, { id: string }>({
		query: SPECIAL_PROJECT_QUERY,
		variables: {
			id: projectId,
		},
	});

	// Mutations
	const [{ error: mutationError }, reportIssues] = useMutation<{ reportIssues: MutationResponse }>(
		REPORT_ISSUES_MUTATION,
	);

	const onSubmit = async (input: SpecialProjectIssuesInput) => {
		const { error } = await reportIssues(
			{ input },
			{ additionalTypenames: ['KittingProject', 'KitComponentProductDetail'] },
		);

		if (!error) {
			setIsReviewModalOpen(true);
		}
	};

	const project = data?.specialProject;

	if (!project) {
		return null;
	}

	const exceptions: PreKitExceptionInput[] = project?.componentProducts
		.map((product) => {
			return [
				PreKitExceptionReason.MissingProduct,
				PreKitExceptionReason.DamagedProduct,
				PreKitExceptionReason.Other,
			].map((reason) => {
				return {
					eachQuantity: product.exceptions.find((ex) => ex.reason === reason)?.eachQuantity || 0,
					productId: product.id,
					reason,
				};
			});
		})
		.flat();

	return (
		<Page
			fullWidth
			breadcrumbs={[
				{
					content: messages.specialProject,
					url: routes.specialProject(projectId),
				},
			]}
			title={messages.reportIssues}
		>
			<ExceptionForm
				project={project}
				mode="edit"
				data={{ exceptions, goalId: project?.id }}
				error={mutationError}
				onSubmit={onSubmit}
			/>
			<SpecialProjectReviewModal
				isOpen={isReviewModalOpen}
				onClose={(didMarkComplete) => {
					setIsReviewModalOpen(false);
					if (didMarkComplete) {
						history.push(routes.specialProject(projectId));
					}
				}}
				project={project}
			/>
		</Page>
	);
}

export function ExceptionForm({
	project,
	data,
	error,
	onSubmit,
}: { project: KittingProjectApi } & FormProps<SpecialProjectIssuesInput>): JSX.Element {
	const { messages } = useLocalization();

	const { editForm, discardForm, input, dirty, feedback } = useForm<SpecialProjectIssuesInput>(
		data,
		error,
	);

	const formEnabled =
		project?.status && [GoalStates.Ready, GoalStates.Running].includes(project?.status);

	const getEntry = (productId: string, reason: PreKitExceptionReason) => {
		return input.exceptions.find(
			(entry) => entry.productId === productId && entry.reason === reason,
		);
	};

	const getValue = (productId: string, reason: PreKitExceptionReason): number => {
		const entry = getEntry(productId, reason);

		return entry?.eachQuantity || 0;
	};

	const setValue = (productId: string, reason: PreKitExceptionReason, eachQuantity: number) => {
		const entry = getEntry(productId, reason);

		if (entry) {
			entry.eachQuantity = eachQuantity;
			editForm(input);
		}
	};

	return (
		<Form onSubmit={() => void onSubmit(input)} noValidate>
			<FormFeedback feedback={feedback} />
			<Card>
				{project?.componentProducts.map((product) => {
					return (
						<Card.Section key={product.id}>
							<FormLayout>
								<FormLayout.Group condensed>
									<Product product={product} />
									<TextField
										key="missing"
										type="number"
										label={messages.kitIssues.missingProduct}
										suffix={messages.units}
										min={0}
										autoComplete="off"
										inputMode="numeric"
										placeholder="0"
										value={getValue(product.id, PreKitExceptionReason.MissingProduct).toString()}
										onChange={(value) =>
											setValue(product.id, PreKitExceptionReason.MissingProduct, parseInt(value))
										}
										disabled={!formEnabled}
									/>
									<TextField
										key="damaged"
										type="number"
										label={messages.kitIssues.damagedProduct}
										suffix={messages.units}
										min={0}
										autoComplete="off"
										inputMode="numeric"
										placeholder="0"
										value={getValue(product.id, PreKitExceptionReason.DamagedProduct).toString()}
										onChange={(value) =>
											setValue(product.id, PreKitExceptionReason.DamagedProduct, parseInt(value))
										}
										disabled={!formEnabled}
									/>
									<TextField
										key="other"
										type="number"
										label={messages.kitIssues.otherIssue}
										suffix={messages.units}
										min={0}
										autoComplete="off"
										inputMode="numeric"
										placeholder="0"
										value={getValue(product.id, PreKitExceptionReason.Other).toString()}
										onChange={(value) =>
											setValue(product.id, PreKitExceptionReason.Other, parseInt(value))
										}
										disabled={!formEnabled}
									/>
								</FormLayout.Group>
							</FormLayout>
						</Card.Section>
					);
				})}
			</Card>
			{dirty ? (
				<ContextualSaveBar
					fullWidth={false}
					alignContentFlush={false}
					message={messages.unsavedChanges}
					saveAction={{
						content: messages.save,
						onAction: () => void onSubmit(input),
					}}
					discardAction={{
						content: messages.discard,
						onAction: discardForm,
					}}
				/>
			) : null}
		</Form>
	);
}
