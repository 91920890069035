import styles from './ProgressBar.module.css';

interface Props {
	progress: number;
	color: string;
}

export function ProgressBar({ progress, color }: Props) {
	const fillerRelativePercentage = (100 / progress) * 100;

	return (
		<div
			className={styles.wrapper}
			role="progressbar"
			aria-valuemin={0}
			aria-valuemax={100}
			aria-valuenow={progress}
		>
			<div className={styles.barContainer}>
				<div className={styles.filler} style={{ width: `${progress}%` }}>
					<div
						className={
							color === 'warning'
								? styles.warningFillerBackground
								: color === 'primary'
								? styles.primaryFillerBackground
								: color === 'success'
								? styles.successFillerBackground
								: styles.primaryFillerBackground
						}
						style={{ width: `${fillerRelativePercentage}%` }}
					/>
				</div>
			</div>
		</div>
	);
}
