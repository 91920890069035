import { DateInterval } from '@hyper-fetch/core';
import { useFetch } from '@hyper-fetch/react';

import { BulkCancelRequestsStatusResponse, GlobalErrorType, getBulkCancelBatches } from '../../api';

type UseActionLogProps = {
	updatedAtFrom?: Date;
	cursor?: string;
	limit?: number;
	poll?: boolean;
	pollInterval?: number;
};

const DateFilterOptions = {
	ANY: 'any',
	COMPLETED_AT: 'completedAt',
	CREATED_AT: 'createdAt',
	STARTED_AT: 'startedAt',
} as const;

export function useActionLog({
	updatedAtFrom,
	cursor,
	limit = 50,
	poll,
	pollInterval = DateInterval.second * 20,
}: UseActionLogProps = {}): {
	data?: BulkCancelRequestsStatusResponse | null;
	error?: GlobalErrorType | null;
	fetching: boolean;
} {
	const params = new URLSearchParams();
	params.append('limit', limit.toString());
	params.append('orderBy', 'startedAt');
	params.append('orderDirection', 'desc');
	params.append('dateFilterOn', DateFilterOptions.CREATED_AT);

	if (updatedAtFrom) {
		params.append('dateFilterFrom', updatedAtFrom.toISOString());
	}

	if (cursor) {
		params.append('cursor', cursor);
	}

	const request = getBulkCancelBatches.setQueryParams(params.toString());
	const { loading, data, error } = useFetch(request, {
		refresh: poll,
		refreshTime: pollInterval,
	});
	return { data, error, fetching: loading };
}
