import { Count, LineQuantities } from '@sixriver/fulfillment-api-schema';
import { IndexTable, TextContainer, TextStyle } from '@sixriver/lighthouse-web-community';

interface LinesCellProps {
	totalLines: Count;
	totalLineQuantities: LineQuantities;
}

export function LinesCell({ totalLines, totalLineQuantities }: LinesCellProps) {
	return (
		<IndexTable.Cell>
			<TextContainer>
				<TextStyle>
					{totalLineQuantities?.completedLines} of {totalLines?.count}
				</TextStyle>
			</TextContainer>
		</IndexTable.Cell>
	);
}
