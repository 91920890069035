import { useEffect, useState } from 'react';

export function usePolling() {
	const [pollingEnabled, setPollingEnabled] = useState(
		localStorage.getItem('POLLING_ENABLED') === 'true',
	);
	const [queryPollInterval, setQueryPollInterval] = useState(0);
	const togglePolling = () => {
		setPollingEnabled(!pollingEnabled);
	};

	useEffect(() => {
		localStorage.setItem('POLLING_ENABLED', pollingEnabled.toString());
		setQueryPollInterval(pollingEnabled ? 20000 : 0);
	}, [pollingEnabled]);

	return { pollingEnabled, queryPollInterval, togglePolling };
}
