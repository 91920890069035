import { CircleDisableMinor } from '@sixriver/lighthouse-icons';
import { Icon, Text } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../../../hooks/useLocalization';

export function Idle() {
	const { messages, translate } = useLocalization();
	return (
		<div className="mfaIdle">
			<Icon source={CircleDisableMinor} />

			<Text as="p" variant="headingLg">
				{translate(messages.mfa.preview.idle)}
			</Text>
		</div>
	);
}
