import { WorkOrderType } from '@sixriver/fulfillment-api-schema';
import { IndexTable } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../../hooks/useLocalization';

interface WorkOrderTypeCellProps {
	types: WorkOrderType[];
}

export function WorkOrderTypeCell({ types: [type] }: WorkOrderTypeCellProps) {
	const { messages } = useLocalization();
	return (
		<IndexTable.Cell>
			{type === WorkOrderType.InventoryTransferToNode
				? messages.workOrderTypes.transfer
				: messages.workOrderTypes.ecommerce}
		</IndexTable.Cell>
	);
}
