import { Dispatch, SetStateAction, useEffect, useState } from 'react';

function exclusive(
	semaphore: boolean,
	mutator: Dispatch<SetStateAction<boolean>>,
	action: () => Promise<void>,
): () => Promise<void> {
	return async () => {
		if (semaphore) {
			return; // already running
		}

		mutator(true);
		await action();
		mutator(false);
	};
}

export function useInterval(interval: number, action: () => Promise<void>): boolean {
	const [semaphore, setSemaphore] = useState(false);

	useEffect(() => {
		if (interval <= 0 || semaphore) {
			return; // nothing to do
		}

		return (
			(id) => () =>
				clearTimeout(id)
		)(setTimeout(exclusive(semaphore, setSemaphore, action), interval));
	}, [interval, action, semaphore]);

	return semaphore;
}
