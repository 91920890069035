import { gql } from 'graphql-tag';

export const PAGE_INFO_FRAGMENT = gql`
	fragment PageInfo on PageInfo {
		hasNextPage
		hasPreviousPage
		startCursor
		endCursor
	}
`;
