import { Job } from '@sixriver/fulfillment-api-schema';
import { AlertMinor, RiskMinor } from '@sixriver/lighthouse-icons';
import { Tooltip, Icon } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	job: Job;
}
export function OutboundJobExceptionIcon({ job }: Props): JSX.Element | null {
	const { messages } = useLocalization();

	if (job.isShorted) {
		const servicingOrders = job?.orders ?? [];

		const isSystemResolved = servicingOrders.find((order) => (order as any).isHealable);

		const isSystemSlxuResolved =
			(job.type === 'slsu' || job.type === 'slmu') &&
			servicingOrders.find((order) => order.pickingJobs.length > 1);
		if (isSystemResolved || isSystemSlxuResolved) {
			return (
				<Tooltip content={messages.exceptionsSystemManaged} dismissOnMouseOut>
					<Icon source={AlertMinor} color="base" />
				</Tooltip>
			);
		}
		return (
			<Tooltip content={messages.exceptionsExternallyManaged} dismissOnMouseOut>
				<Icon source={RiskMinor} color="critical" />
			</Tooltip>
		);
	}

	return null;
}
