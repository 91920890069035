import { useToggle } from '@shopify/react-hooks';
import { CaretDownMinor } from '@sixriver/lighthouse-icons';
import { Popover, Heading, Action, Portal, Button, Icon } from '@sixriver/lighthouse-web-community';
import { MouseEvent, PropsWithChildren, ReactNode } from 'react';

import styles from './Peek.module.css';

interface OverlayProps {
	onClick(event: MouseEvent<HTMLDivElement>): void;
}

function Overlay({ onClick }: OverlayProps) {
	return (
		<Portal>
			<div className={styles.overlay} onClick={onClick} />
		</Portal>
	);
}

interface Props {
	activator: ReactNode;
	title?: string;
	action?: Action;
	indicator?: boolean;
}

export function Peek({
	activator,
	children,
	title,
	action,
}: PropsWithChildren<Props>): JSX.Element {
	const { value: active, setTrue: openPopover, setFalse: closePopover } = useToggle(false);

	const handleActivatorClick = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		event.stopPropagation();
		openPopover();
	};

	const activatorMarkup = (
		<button className={styles.activator} aria-pressed={active} onClick={handleActivatorClick}>
			<div className={styles.activatorText}>{activator}</div>
			<div className={styles.activatorIcon}>
				<Icon source={CaretDownMinor} color="subdued" />
			</div>
		</button>
	);

	const titleMarkup = title ? (
		<Popover.Section>
			<Heading>{title}</Heading>
		</Popover.Section>
	) : null;

	const handleOverlayClick = (event: MouseEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.stopPropagation();
		closePopover();
	};

	const overlayMarkup = active ? <Overlay onClick={handleOverlayClick} /> : null;

	const stopClickPropogation = (event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
	};

	let actionMarkup;
	if (action) {
		const { content: actionContent, ...actionProps } = action;
		actionMarkup = (
			<Popover.Section>
				<Button fullWidth {...actionProps}>
					{actionContent}
				</Button>
			</Popover.Section>
		);
	}

	return (
		<div className={styles.peek}>
			<Popover active={active} activator={activatorMarkup} onClose={closePopover} fullHeight>
				<div onClick={stopClickPropogation}>
					{titleMarkup}
					<Popover.Section>{children}</Popover.Section>
					{actionMarkup}
				</div>
			</Popover>
			{overlayMarkup}
		</div>
	);
}

Peek.Section = Popover.Section;
