import {
	StorageLocationType,
	StorageLocationTypeCount as StorageLocationTypeCountFAPI,
} from '@sixriver/fulfillment-api-schema';
import type { StorageLocationTypeCount as StorageLocationTypeCountWAPI } from '@sixriver/warehouse-api-graphql-types';

import { Tab } from '../../components/ViewTabs';
import { useIsExternalInventoryEnabled } from '../useConfig';
import { useLocalization } from '../useLocalization';

export const LOCATION_TYPES = [
	StorageLocationType.Bin,
	StorageLocationType.Pallet,
	StorageLocationType.Cart,
	StorageLocationType.Damaged,
	StorageLocationType.Staging,
	StorageLocationType.Mfp,
];

// the two count types are functionally identical, but because they are "true"
// enums, typescript thinks they are incompatible.
export function useLocationTabs(
	counts: StorageLocationTypeCountFAPI[] | StorageLocationTypeCountWAPI[],
): Tab[] {
	const { messages } = useLocalization();
	const isExternalInventoryEnabled = useIsExternalInventoryEnabled();
	const types = Array.from(LOCATION_TYPES);

	if (isExternalInventoryEnabled) {
		types.push(StorageLocationType.Warehouse);
	}

	const tabs = [
		{
			id: 'all',
			label: messages.allLocations,
		},
	].concat(
		types.map((type) => {
			return {
				id: type,
				label: messages.locationTypes[type],
				metaLabel: counts.find((c) => c.type === type)?.count || 0,
			};
		}),
	);

	return tabs;
}
