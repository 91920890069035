import { DocumentNode } from 'graphql';
import { gql } from 'graphql-tag';

import { PAGE_INFO_FRAGMENT } from '../../graphql';

export const CREATE_WALL_MUTATION: DocumentNode = gql`
	mutation CreateWall {
		createSortWall {
			success
		}
	}
`;

export const SORT_WALLS_QUERY: DocumentNode = gql`
	query SortWalls(
		$after: String
		$before: String
		$first: Int
		$last: Int
		$statuses: [JobStatus!]!
		$searchText: String
		$completedAtFrom: DateTime
		$completedAtTo: DateTime
	) {
		sortWalls(
			after: $after
			before: $before
			first: $first
			last: $last
			statuses: $statuses
			searchText: $searchText
			completedAtFrom: $completedAtFrom
			completedAtTo: $completedAtTo
		) {
			edges {
				node {
					id
					wall
					status
					batchId
					expectedShipDate
					createdAt
					isShorted
					lines {
						edges {
							node {
								id
								status
								quantity
								doneQuantity
								isShorted
							}
						}
					}
					totalLines {
						count
					}
					totalLineQuantities {
						pickedUnits
						inProgressUnits
						unassignedUnits
						completedLines
						totalUnits
						unassignedUnits
					}
					pickingJobs {
						id
						status
						lines {
							edges {
								node {
									id
									status
								}
							}
						}
					}
					sortations {
						edges {
							node {
								mfpId
								userId
								createdAt
								updatedAt
								completedAt
								wallDisplayId
								wallExternalId
							}
						}
					}
					createdAt
				}
			}
			pageInfo {
				...PageInfo
			}
		}
	}
	${PAGE_INFO_FRAGMENT}
`;

export const SORT_WALLS_COUNTS_QUERY: DocumentNode = gql`
	query CountSortWalls($searchText: String, $completedAtFrom: DateTime, $completedAtTo: DateTime) {
		AllCount: sortationsCount(
			searchText: $searchText
			statuses: [Unassigned, Picking, Picked, Sorting, Sorted, Exception]
		) {
			count
		}

		UnassignedCount: sortationsCount(searchText: $searchText, statuses: [Unassigned]) {
			count
		}

		PickingCount: sortationsCount(searchText: $searchText, statuses: [Picking]) {
			count
		}

		ReadyToSortCount: sortationsCount(searchText: $searchText, statuses: [Picked]) {
			count
		}

		SortingCount: sortationsCount(searchText: $searchText, statuses: [Sorting]) {
			count
		}

		CompletedTodayCount: sortationsCount(
			searchText: $searchText
			statuses: [Unassigned, Picking, Picked, Sorting, Sorted, Exception]
			completedAtFrom: $completedAtFrom
			completedAtTo: $completedAtTo
		) {
			count
		}

		ExceptionsCount: sortationsCount(searchText: $searchText, statuses: [Exception]) {
			count
		}
	}
`;
