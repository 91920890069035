import { EmptySearchResult, IndexTable, IndexTableProps } from '@sixriver/lighthouse-web-community';

import { BatteryCell } from './BatteryCell';
import { LastActiveCell } from './LastActiveCell';
import { ModelCell } from './ModelCell';
import { NameCell } from './NameCell';
import { NoDataCell } from './NoDataCell';
import { StatusCell } from './StatusCell';
import { WorkAreaCell } from './WorkAreaCell';
import { AvailableMfp, MfpConfigExtended } from '../../api';
import { useLocalization } from '../../hooks/useLocalization';

export type DeviceAndConfig = {
	device: AvailableMfp;
	mfpConfig?: MfpConfigExtended;
};

type DeviceTableProps = {
	isWorkAreasEnabled: boolean;
	loading: boolean;
	devicesAndConfigs: DeviceAndConfig[];
};

export function DevicesTable({ isWorkAreasEnabled, loading, devicesAndConfigs }: DeviceTableProps) {
	const { messages } = useLocalization();
	const resourceName = {
		plural: messages.devices.toLowerCase(),
		singular: messages.device.toLowerCase(),
	};
	const emptyStateMarkup = <EmptySearchResult title={messages.noDevicesFound} withIllustration />;
	const headings: IndexTableProps['headings'] = [
		{ title: messages.name },
		...(isWorkAreasEnabled ? [{ title: messages.workArea }] : []),
		{ title: messages.model },
		{ title: messages.lastActive },
		{ title: messages.battery },
		{ title: messages.status },
	];
	let rows: JSX.Element[] = [];

	if (!loading) {
		rows = devicesAndConfigs.map(({ device, mfpConfig }, index) => {
			return (
				<IndexTable.Row id={device.id} key={device.id} position={index}>
					<NameCell device={device} />
					{isWorkAreasEnabled ? <WorkAreaCell device={device} /> : null}
					{mfpConfig ? <ModelCell mfpConfig={mfpConfig} /> : <NoDataCell />}
					<LastActiveCell device={device} />
					<BatteryCell device={device} />
					<StatusCell device={device} />
				</IndexTable.Row>
			);
		});
	}

	return (
		<IndexTable
			emptyState={emptyStateMarkup}
			resourceName={resourceName}
			headings={headings}
			hasMoreItems={false}
			itemCount={devicesAndConfigs.length}
			loading={loading}
			selectable={false}
		>
			{rows}
		</IndexTable>
	);
}
