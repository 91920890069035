import { WorkOrderProject as WorkOrderProjectApi } from '@sixriver/fulfillment-api-schema';
import { Modal } from '@sixriver/lighthouse-web-community';

import { InstructionalStep } from './InstructionalStep';
import { useLocalization } from '../../hooks/useLocalization';

export interface WorkOrderInstructionsModalProps {
	isOpen: boolean;
	onClose: () => void;
	project: WorkOrderProjectApi;
}

export function WorkOrderInstructionsModal({
	isOpen: isModalOpen,
	onClose,
	project,
}: WorkOrderInstructionsModalProps) {
	const { messages } = useLocalization();

	const { instructions } = project;

	// Render
	return (
		<Modal
			large
			open={isModalOpen}
			onClose={onClose}
			title={messages.instructions}
			titleHidden
			secondaryActions={[
				{
					content: messages.close,
					onAction: onClose,
				},
			]}
		>
			{instructions.map((instruction, index) => (
				<InstructionalStep key={index} stepNumber={index + 1} step={instruction} />
			))}
		</Modal>
	);
}
