import { PriorityReasons } from '@sixriver/cfs_models';
import { Badge } from '@sixriver/lighthouse-web-community';
import { camelCase } from 'lodash';

import { useHighPriorityChuckReasonsOrdered } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	highPriorityReasons?: PriorityReasons[];
}

type DisplayablePriorityReasons = Exclude<PriorityReasons, PriorityReasons.TimeSinceInduct>;

export function ChuckHighPriorityBadge({ highPriorityReasons = [] }: Props): JSX.Element {
	const { messages } = useLocalization();
	let priorityReasons = undefined;

	const reasons: string[] = [];
	const highPriorityChuckReasonsOrdered = useHighPriorityChuckReasonsOrdered();
	highPriorityChuckReasonsOrdered.forEach((priority) => {
		const camelCasePriority = camelCase(priority);

		if (highPriorityReasons.includes(camelCasePriority as PriorityReasons)) {
			reasons.push(
				messages.chuckHighPriorityReasons[camelCasePriority as DisplayablePriorityReasons],
			);
		}
	});

	priorityReasons = `${messages.chuckStates.priority}: ${reasons.join(', ')}`;

	return <Badge status="info">{priorityReasons}</Badge>;
}
