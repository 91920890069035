import { usePrevious } from '@shopify/react-hooks';
import {
	DataTable as PolarisDataTable,
	Spinner,
	EmptyState,
	ColumnContentType,
	Card,
	Filters,
	FilterInterface,
	AppliedFilterInterface,
	DisableableAction,
} from '@sixriver/lighthouse-web-community';
import { ReactNode, useEffect } from 'react';

import styles from './DataTable.module.css';
import { Pagination, Cursors, SetCursors } from './Pagination';
import { MIN_QUERY_LENGTH } from '../../helpers/table';
import {
	SetFilters,
	DEFAULT_VIEW_KEY,
	DEFAULT_QUERY_KEY,
	DEFAULT_SORT_KEY,
} from '../../hooks/useFilters';
import { useLocalization } from '../../hooks/useLocalization';
import noDataImage from '../../images/no-data.svg';
import { SortBy, SortChoice } from '../SortBy';
import { Tab, ViewTabs } from '../ViewTabs';

export type PaginationCursors = Cursors;
export type SetPaginationCursors = SetCursors;
export type DataTableView = Tab;

export interface Column {
	type: ColumnContentType;
	heading: React.ReactNode;
	sortable?: boolean;
}

interface Props {
	loading?: boolean;
	columns: Column[];
	rows?: ReactNode[][];
	emptyStateHeading?: string;
	emptyStateMessage?: string;
	emptyStateMarkup?: ReactNode;
	pageInfo?: { endCursor?: string | null; hasNextPage?: boolean };
	paginationCursors?: PaginationCursors;
	setPaginationCursors?: SetPaginationCursors;
	views?: DataTableView[];
	/** value of the query in the search/filter bar */
	query?: string;
	/** overwrite the default key for the query value passed to setFilters  */
	queryKey?: string;
	/** overwrite the default key for the selected view value passed to setFilters  */
	viewKey?: string;
	/** overwrite the default key for the sorting value passed to setFilters  */
	sortKey?: string;
	queryPlaceholder?: string;
	filters?: FilterInterface[];
	appliedFilters?: AppliedFilterInterface[];
	disableFilters?: boolean;
	setFilters?: SetFilters;
	selectedView?: string;
	sortChoices?: SortChoice[];
	sortValue?: string;
	title?: string;
	hideFilters?: boolean;
	totals?: ReactNode[];
	showTotalsInFooter?: boolean;
	totalsName?: {
		singular: string;
		plural: string;
	};
	cardActions?: DisableableAction[];
	totalCount?: number;
}

export function DataTable({
	loading,
	columns,
	rows,
	emptyStateHeading,
	emptyStateMessage,
	emptyStateMarkup,
	pageInfo,
	paginationCursors,
	setPaginationCursors,
	query,
	queryKey = DEFAULT_QUERY_KEY,
	queryPlaceholder,
	filters,
	appliedFilters,
	disableFilters = false,
	setFilters,
	views,
	viewKey = DEFAULT_VIEW_KEY,
	selectedView,
	sortChoices,
	sortValue,
	sortKey = DEFAULT_SORT_KEY,
	title,
	hideFilters,
	totals,
	showTotalsInFooter,
	totalsName,
	cardActions,
	totalCount,
}: Props) {
	const { messages, translate } = useLocalization();
	const columnContentTypes: ColumnContentType[] = [];
	const headings: React.ReactNode[] = [];
	const sortable: boolean[] = [];
	for (const { type, heading, sortable: columnSortable = false } of columns) {
		columnContentTypes.push(type);
		headings.push(heading);
		sortable.push(columnSortable);
	}

	const onClearAll = () => {
		if (setFilters) {
			setFilters([
				...(filters || []).map((filter) => ({ key: filter.key, value: '' })),
				{ key: queryKey, value: '' },
			]);
		}
	};

	const prevSelectedView = usePrevious(selectedView);
	const prevQuery = usePrevious(query);
	const prevAppliedFilters = usePrevious(appliedFilters);

	// Reset pagination when changing views or filters
	useEffect(() => {
		const hasQueryChanged =
			((query?.length || 0) >= MIN_QUERY_LENGTH || (prevQuery?.length || 0) >= MIN_QUERY_LENGTH) &&
			prevQuery !== query;

		const haveFiltersChanged = (appliedFilters || []).some((af) => {
			const prev = prevAppliedFilters?.find((paf) => paf.key === af.key);
			return !prev || prev?.label !== af.label;
		});

		if (
			setPaginationCursors &&
			(prevSelectedView !== selectedView || hasQueryChanged || haveFiltersChanged)
		) {
			setPaginationCursors(() => []);
		}
	});

	return (
		<Card title={title} actions={cardActions}>
			{setFilters && (views || !hideFilters) && (
				<Card.Section>
					{views && (
						<div className={!hideFilters ? styles.ViewsContainer : ''}>
							<ViewTabs
								tabs={views}
								selected={selectedView || (views?.[0]?.id as string)}
								onSelect={(id) => setFilters([{ key: viewKey, value: id }])}
							/>
						</div>
					)}
					{!hideFilters && (
						<>
							<Filters
								queryValue={query || undefined}
								queryPlaceholder={queryPlaceholder}
								onQueryChange={(value) => setFilters([{ key: queryKey, value }])}
								onQueryClear={() => setFilters([{ key: queryKey, value: '' }])}
								filters={filters || []}
								appliedFilters={appliedFilters}
								onClearAll={onClearAll}
								disabled={disableFilters}
							>
								{sortChoices ? (
									<SortBy
										choices={sortChoices}
										selected={sortValue ? [sortValue] : []}
										onChange={(selected) => setFilters([{ key: sortKey, value: selected[0] }])}
									/>
								) : null}
							</Filters>
							{(appliedFilters as AppliedFilterInterface[])?.length > 0 &&
								totalCount !== undefined && (
									<div style={{ padding: '1rem' }}>{`${translate(messages.countResults, {
										count: totalCount,
									})}`}</div>
								)}
						</>
					)}
				</Card.Section>
			)}
			<div className={styles.TableContainer}>
				<PolarisDataTable
					columnContentTypes={columnContentTypes}
					headings={headings}
					sortable={sortable}
					rows={rows || []}
					verticalAlign="top"
					totals={totals || []}
					showTotalsInFooter={showTotalsInFooter}
					totalsName={totalsName}
				/>
			</div>
			{loading ? (
				<div className={styles.SpinnerContainer} data-testid="spinner">
					<Spinner accessibilityLabel={messages.loading} size="large" />
				</div>
			) : (rows || []).length === 0 ? (
				<div className={styles.EmptyStateContainer}>
					{emptyStateMarkup ? (
						emptyStateMarkup
					) : (
						<EmptyState
							heading={emptyStateHeading || messages.noDataFound}
							image={noDataImage}
							imageContained
						>
							<p>{emptyStateMessage || messages.changeFilters}</p>
						</EmptyState>
					)}
				</div>
			) : pageInfo && paginationCursors && setPaginationCursors ? (
				<Card.Section>
					<div className={styles.PaginationContainer}>
						<Pagination
							endCursor={pageInfo?.hasNextPage !== false ? pageInfo?.endCursor : undefined}
							cursors={paginationCursors}
							setCursors={setPaginationCursors}
							loading={loading}
						/>
					</div>
				</Card.Section>
			) : null}
		</Card>
	);
}
