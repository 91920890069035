import { IndexTable } from '@sixriver/lighthouse-web-community';

import { DeviceModel } from './DeviceModel';
import { MfpConfigExtended } from '../../api';

type ModelCellProps = {
	mfpConfig: MfpConfigExtended;
};

export function ModelCell({ mfpConfig }: ModelCellProps) {
	return (
		<IndexTable.Cell>
			<DeviceModel mfpConfig={mfpConfig} />
		</IndexTable.Cell>
	);
}
