import {
	OrderV2,
	PickStrategy,
	WorkOrderType,
	QueryOrderV2Args,
	WorkflowEvent,
	QueryOrderEventsV2Args,
} from '@sixriver/fulfillment-api-schema';
import { DuplicateMinor } from '@sixriver/lighthouse-icons';
import {
	Page,
	Layout,
	Stack,
	Tabs,
	ComplexAction,
	MenuGroupDescriptor,
	Loading,
	Frame,
	Card,
} from '@sixriver/lighthouse-web-community';
import { UserRole, useAuth } from '@sixriver/react-support';
import { useMemo, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { BreakdownAndIssues } from './BreakdownAndIssues';
import { Details } from './Details';
import { ORDER_EVENTS_QUERY } from './JobEvents.graphql';
import { MarkAsCompleteModal } from './MarkAsCompleteModal';
import { ORDER_QUERY } from './Order.graphql';
import { Progress } from './Progress';
import { ResolutionSummaryCalloutCard } from './ResolutionSummaryCalloutCard';
import { ShippedShortBanner } from './ShippedShortBanner';
import { AutoRefresh } from '../../components/AutoRefresh';
import { CancelOrderModal } from '../../components/CancelOrderModal';
import { Error } from '../../components/Error';
import { ErrorBanner } from '../../components/ErrorBanner';
import { Lines } from '../../components/Lines';
import { LinesTableColumn } from '../../components/Lines/LinesTable';
import { LinesTab } from '../../components/Lines/LinesTabs';
import { OrderStatusBadgeV2 } from '../../components/OrderStatusBadge/OrderStatusBadgeV2';
import { ResolutionCountText } from '../../components/ResolutionCountText';
import { Timeline } from '../../components/Timeline';
import { useWorkflowEvents } from '../../components/Timeline/Timeline.hooks';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { useCancelOrder } from '../../hooks/useCancelOrder';
import { useIsWorkAreasEnabled } from '../../hooks/useConfig';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import { useSetFilters, useFilters } from '../../hooks/useFilters';
import { useLocalization } from '../../hooks/useLocalization';
import { usePolling } from '../../hooks/usePolling';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import * as routes from '../../routes';

export interface RouteMatchParams {
	orderId: string;
}

export function Order() {
	const {
		params: { orderId },
	} = useRouteMatch<RouteMatchParams>();

	const [isBannerErrorVisible, setIsBannerErrorVisible] = useState(false);
	const [isReviewModalOpen, setIsReviewModalOpen] = useState<boolean>(false);
	const { messages } = useLocalization();
	const { isUserAllowed } = useAuth();
	const { copyToClipboard } = useCopyToClipboard();
	const history = useHistory();
	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();
	const setFilters = useSetFilters();
	const { isCancelModalOpen, openCancelModal, closeCancelModal, cancelOrder, cancelOrderLoading } =
		useCancelOrder({
			onBefore: () => {
				setIsBannerErrorVisible(false);
			},
			onError: () => {
				setIsBannerErrorVisible(true);
			},
			onSuccessfulCancel: () => {
				history.push(routes.orders());
			},
			orderId,
		});
	const tabs = [
		{
			content: messages.overview,
			id: 'overview',
		},
		{
			content: messages.history,
			id: 'history',
		},
	];

	const { tab } = useFilters(['tab']);

	const tabIndex = tab === tabs[1]?.id ? 1 : 0;

	const [{ fetching: orderFetching, error: orderError, data: orderData }] = usePollingQuery<
		{ job: OrderV2 },
		QueryOrderV2Args
	>({
		context: useMemo(
			() => ({
				useWarehouseApi: true,
			}),
			[],
		),
		pollInterval: queryPollInterval,
		query: ORDER_QUERY,
		variables: {
			id: orderId,
		},
	});

	const [{ fetching: eventsFetching, error: eventsError, data: eventsData }] = usePollingQuery<
		{ orderEventsV2: WorkflowEvent[] },
		QueryOrderEventsV2Args
	>({
		pause: tab !== 'history',
		pollInterval: queryPollInterval,
		query: ORDER_EVENTS_QUERY,
		variables: {
			id: orderId,
		},
	});

	const { job: order } = orderData || {};
	const fetching = orderFetching || eventsFetching;
	const error = orderError;

	const events = useWorkflowEvents(
		eventsData?.orderEventsV2 || [],
		'ORDER',
		order?.pickStrategy === PickStrategy.Sortation,
	);

	const copyOrderNumberButton = {
		content: messages.copyOrderNumber,
		disabled: !order?.externalId,
		icon: DuplicateMinor,
		onAction: () => copyToClipboard(order?.externalId),
	};

	const cancelOrderButton = {
		content: messages.cancelOrder,
		disabled: !isUserAllowed([UserRole.Admin, UserRole.WarehouseManager]),
		onAction: orderData ? openCancelModal : undefined,
	};

	const disableMarkAsCompleteButton =
		!isUserAllowed([UserRole.Admin, UserRole.WarehouseManager]) ||
		!order?.canShipShort ||
		order?.isShippedShort;

	const markAsCompleteButton = {
		content: messages.markAsComplete,
		disabled: disableMarkAsCompleteButton,
		onAction: () => setIsReviewModalOpen(true),
	};

	const isWorkAreaEnabled = useIsWorkAreasEnabled();
	const isInventoryTransferOrder = order?.workOrderTypes?.includes(
		WorkOrderType.InventoryTransferToNode,
	);
	const secondaryActions: ComplexAction[] = [];
	const actionGroups: MenuGroupDescriptor[] = [];

	if (isInventoryTransferOrder) {
		secondaryActions.push(copyOrderNumberButton);
		actionGroups.push({
			actions: [cancelOrderButton, markAsCompleteButton],
			title: messages.moreActions,
		});
	} else {
		secondaryActions.push(copyOrderNumberButton);
		secondaryActions.push(cancelOrderButton);
	}

	if (fetching) {
		return (
			<Frame>
				<Loading />
			</Frame>
		);
	}
	if (error) {
		return <Error graphQLError={error} />;
	}

	if (!order) {
		return <Error heading={messages.orderNotFound} />;
	}

	return (
		<Page
			title={order.externalId}
			titleMetadata={
				<div data-testid="header">
					<Stack spacing="extraTight">
						<OrderStatusBadgeV2 order={order} />
					</Stack>
				</div>
			}
			additionalMetadata={<ResolutionCountText order={order} />}
			secondaryActions={secondaryActions}
			actionGroups={actionGroups}
		>
			<AutoRefresh
				pollingEnabled={pollingEnabled}
				togglePolling={togglePolling}
				discriminatorData={orderData}
			/>
			<Tabs
				tabs={tabs}
				selected={tabIndex}
				onSelect={(tabIndex) => {
					setFilters([{ key: 'tab', value: tabs[tabIndex]?.id }]);
				}}
			>
				<Layout>
					<Layout.Section>
						<Stack vertical>
							<ErrorBanner
								isVisible={isBannerErrorVisible}
								onDismiss={() => {
									setIsBannerErrorVisible(false);
								}}
							/>
							<ShippedShortBanner order={order} />
							<ResolutionSummaryCalloutCard order={order} />
						</Stack>
					</Layout.Section>
					{tab === 'history' ? (
						<Layout.Section>
							<Timeline
								title={messages.orderHistory}
								events={events}
								loading={eventsFetching}
								error={eventsError}
							/>
						</Layout.Section>
					) : (
						<>
							<Layout.Section>
								<div data-testid="details">
									<Details order={order as any} />
								</div>
							</Layout.Section>
							<Layout.Section key={order.id}>
								<Card key={order.id}>
									<BreakdownAndIssues order={order}></BreakdownAndIssues>
									<Progress order={order} />
								</Card>
							</Layout.Section>
							<Layout.Section>
								<div data-testid="lines">
									<Lines
										job={order}
										columns={[
											LinesTableColumn.id,
											LinesTableColumn.name,
											LinesTableColumn.pickingUnits,
											LinesTableColumn.progress,
											LinesTableColumn.exceptions,
											isWorkAreaEnabled ? LinesTableColumn.workArea : null,
										]}
										tabs={[LinesTab.all, LinesTab.exceptions]}
									/>
								</div>
							</Layout.Section>
						</>
					)}
					<Layout.Section>
						<TimezoneFooter />
					</Layout.Section>
				</Layout>
			</Tabs>

			<CancelOrderModal
				isOpen={isCancelModalOpen}
				onClose={closeCancelModal}
				onConfirm={cancelOrder}
				loading={cancelOrderLoading}
			/>
			<MarkAsCompleteModal
				isOpen={isReviewModalOpen}
				onClose={() => setIsReviewModalOpen(false)}
				order={order}
			/>
		</Page>
	);
}
