import { Card } from '@sixriver/lighthouse-web-community';

import { ErrorBanner } from '../../components/ErrorBanner';

interface BannerProps {
	isVisible?: boolean;
	onDismiss: () => void;
}

export function BulkCancelFailedBanner({
	isVisible = false,
	onDismiss,
}: BannerProps): JSX.Element | null {
	if (!isVisible) {
		return null;
	}

	return (
		<Card.Section>
			<ErrorBanner isVisible onDismiss={onDismiss} />
		</Card.Section>
	);
}
