import { OrderV2, JobStatus, PickStrategy } from '@sixriver/fulfillment-api-schema';
import { RiskMinor } from '@sixriver/lighthouse-icons';
import { Tooltip, Icon } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	order: OrderV2;
}

export function OrderExceptionIcon({ order }: Props): JSX.Element | null {
	const { messages } = useLocalization();

	if (order.isShorted) {
		const unassignedResolutionJobs = order.pickingJobs.filter(
			(job) => job.isHealJob && job.status === JobStatus.Unassigned,
		);
		const relatedJobs = [...unassignedResolutionJobs, ...order.pickingJobs];

		const isSystemSlxuResolved =
			order.pickStrategy === PickStrategy.Singles && relatedJobs.length > 1;

		let isMostRecentPickingJobSystemResolveable = false;
		if (relatedJobs.length > 0) {
			const mostRecentPickingJob = relatedJobs.sort((a, b) => {
				const aDate = new Date(a.createdAt as string);
				const bDate = new Date(b.createdAt as string);
				return bDate.getTime() - aDate.getTime();
			})[0];
			const hasExceptionsAndSystemResolveable = (order as any).isHealable;
			const isInProgress = mostRecentPickingJob?.status === JobStatus.Picking;

			isMostRecentPickingJobSystemResolveable =
				isInProgress || hasExceptionsAndSystemResolveable || false;
		}

		const isSystemResolved =
			unassignedResolutionJobs.length > 0 ||
			isSystemSlxuResolved ||
			isMostRecentPickingJobSystemResolveable;

		if (isSystemResolved === false) {
			return (
				<Tooltip content={messages.exceptionsExternallyManaged} dismissOnMouseOut>
					<Icon source={RiskMinor} color="critical" />
				</Tooltip>
			);
		}
	}

	return null;
}
