import { JobFlowRuleInput, MutationResponse } from '@sixriver/fulfillment-api-schema';
import { Page } from '@sixriver/lighthouse-web-community';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'urql';

import JobFlowRuleForm from './JobFlowRuleForm';
import { ADD_JOB_FLOW_RULE_MUTATION } from './JobFlowRules.graphql';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import * as routes from '../../routes';

export function AddJobFlowRule() {
	const { messages } = useLocalization();

	const history = useHistory();

	const { showToast } = useToast();

	const [{ error: addJobFlowRuleError }, addJobFlowRule] = useMutation<
		{ addJobFlowRule: MutationResponse },
		{ input: JobFlowRuleInput }
	>(ADD_JOB_FLOW_RULE_MUTATION);

	const onSubmit = async (input: JobFlowRuleInput) => {
		const { error } = await addJobFlowRule({ input });

		if (!error) {
			showToast(messages.dataSaved);

			history.push(routes.jobFlowRules());
		}
	};

	const input: JobFlowRuleInput = {
		rule: {
			line: {
				and: [
					{
						'sourceLoc.address': {
							eq: '',
						},
					},
				],
			},
		},
		ruleDescription: '',
		ruleSubType: [],
	};

	return (
		<Page
			fullWidth
			title={messages.addJobFlowRule}
			breadcrumbs={[{ content: messages.jobFlowRules, url: routes.jobFlowRules() }]}
		>
			<JobFlowRuleForm mode="add" data={input} error={addJobFlowRuleError} onSubmit={onSubmit} />
		</Page>
	);
}
