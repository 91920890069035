import { SpecialProject, GoalStates } from '@sixriver/fulfillment-api-schema';
import { Badge, Stack } from '@sixriver/lighthouse-web-community';

import { IssuesBadge } from './IssuesBadge';
import { NoData } from '../../components/NoData';
import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	project: SpecialProject;
	showIssuesIcon?: boolean;
}

export default function Status({ project, showIssuesIcon }: Props): JSX.Element {
	const { messages } = useLocalization();

	switch (project.status) {
		case GoalStates.Ready:
			return <Badge status="warning">{messages.new}</Badge>;
		case GoalStates.Running:
			return <Badge status="info">{messages.inProgress}</Badge>;
		case GoalStates.Complete:
			return (
				<Stack spacing="extraTight">
					<Badge>{messages.completed}</Badge>
					{project.hasIssues ? <IssuesBadge showIcon={showIssuesIcon} /> : ''}
				</Stack>
			);
		default:
			return <NoData />;
	}
}
