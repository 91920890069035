import { Product, ProductInput, MutationResponse } from '@sixriver/fulfillment-api-schema';
import { Modal, Page, PageActions } from '@sixriver/lighthouse-web-community';
import { useContext, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useQuery, useMutation } from 'urql';

import {
	DELETE_PRODUCT_MUTATION,
	EDIT_PRODUCT_MUTATION,
	GET_PRODUCT_QUERY,
} from './EditProduct.graphql';
import { ProductForm } from './ProductForm';
import { Error } from '../../components/Error';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import { BusyIndicatorContext } from '../../providers/BusyIndicatorProvider';
import * as routes from '../../routes';

export function EditProduct() {
	const history = useHistory();
	const { showToast } = useToast();
	const { messages } = useLocalization();
	const { lookBusy } = useContext(BusyIndicatorContext);
	const [modalVisible, setModalVisible] = useState(false);

	const {
		params: { productId },
	} = useRouteMatch<{ productId: string }>();

	const [{ fetching, data, error: getProductError }] = useQuery<
		{ product: Product },
		{ id: string }
	>({
		query: GET_PRODUCT_QUERY,
		requestPolicy: 'network-only',
		variables: {
			id: productId,
		},
	});

	const [{ error: editProductError }, editProductMutation] = useMutation<
		{ editProduct: MutationResponse },
		{ input: ProductInput }
	>(EDIT_PRODUCT_MUTATION);

	const [{ error: deleteProductError, fetching: isDeleting }, deleteProductMutation] = useMutation<
		{ deleteProduct: MutationResponse },
		{ id: string }
	>(DELETE_PRODUCT_MUTATION);

	const onEdit = async (input: ProductInput) => {
		lookBusy(true);

		const { error } = await editProductMutation({ input });

		if (error) {
			lookBusy(false);
			return;
		}

		setTimeout(() => {
			showToast(messages.dataSaved);
			history.push(routes.product(productId));
		}, 500);
	};

	const onDelete = async () => {
		lookBusy(true);
		setModalVisible(false);

		const { error } = await deleteProductMutation({ id: productId });

		if (error) {
			lookBusy(false);
			showToast(messages.dataNotSaved, true);
			return;
		}

		setTimeout(() => {
			showToast(messages.dataSaved);
			history.push(routes.products());
		}, 500);
	};

	if (lookBusy(fetching && !data?.product)) {
		return null;
	}

	if (getProductError) {
		return <Error graphQLError={getProductError} />;
	}

	return (
		<Page
			fullWidth
			title={data.product.customerIdentifier}
			breadcrumbs={[
				{
					url: routes.product(productId),
				},
			]}
		>
			<ProductForm
				mode="edit"
				onSubmit={onEdit}
				error={editProductError || deleteProductError}
				data={
					{
						customerIdentifier: data.product.customerIdentifier || '',
						description: data.product.description || '',
						height: data.product.height || 0,
						id: data.product.id,
						image: data.product.image,
						length: data.product.length || 0,
						name: data.product.name || '',
						weight: data.product.weight || 0,
						width: data.product.width || 0,
					} as ProductInput
				}
			/>
			<br />
			<PageActions
				secondaryActions={[
					{
						content: messages.deleteProduct,
						destructive: true,
						onAction: () => setModalVisible(true),
						outline: true,
					},
				]}
			/>
			<Modal
				open={modalVisible}
				title={messages.deleteProduct}
				onClose={() => setModalVisible(false)}
				primaryAction={{
					content: messages.deleteProduct,
					destructive: true,
					loading: isDeleting,
					onAction: () => void onDelete(),
				}}
				secondaryActions={[
					{
						content: messages.keepEditing,
						onAction: () => setModalVisible(false),
					},
				]}
			>
				<Modal.Section>{messages.confirmDeleteProduct}</Modal.Section>
			</Modal>
		</Page>
	);
}
