import { useDebouncedValue, useToggle } from '@shopify/react-hooks';
import { Count, UserActivityType, UserConnection, User } from '@sixriver/fulfillment-api-schema';
import { AddMajor, FraudProtectMinor } from '@sixriver/lighthouse-icons';
import {
	Page,
	Layout,
	Icon,
	Link,
	Stack,
	Button,
	Tag,
	Badge,
	Popover,
	Card,
} from '@sixriver/lighthouse-web-community';
import { UserRole, useAuth } from '@sixriver/react-support';
import { useState } from 'react';
import { useQuery } from 'urql';

import { COUNTS_QUERY, EMPLOYEES_QUERY } from './Employees.graphql';
import { DataTable } from '../../components/DataTable';
import { DateTime } from '../../components/DateTime';
import { Error } from '../../components/Error';
import { NoData } from '../../components/NoData';
import { RelativeDateTime } from '../../components/RelativeDateTime';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { getPageSize } from '../../helpers/page-size';
import { MIN_QUERY_LENGTH } from '../../helpers/table';
import { useFilters, useSetFilters } from '../../hooks/useFilters';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';
import { createColumnsAndRows } from '../../utils';

const defaultView = UserActivityType.Active;

export function Employees() {
	const { messages } = useLocalization();

	// State
	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	const { isUserAllowed } = useAuth();

	// Filters
	const { view = defaultView, query } = useFilters([]);
	const setFilters = useSetFilters();
	const searchText = useDebouncedValue(query) || '';

	// Queries
	const [{ fetching: countsFetching, data: countsData, error: countsError }] = useQuery<{
		ActiveCount: Count;
		InactiveCount: Count;
	}>({
		query: COUNTS_QUERY,
	});

	const counts = {
		active: countsData?.ActiveCount.count,
		inactive: countsData?.InactiveCount.count,
	} || {
		active: undefined,
		inactive: undefined,
	};

	const [{ fetching: employeesFetching, data: employeesData, error: employeesError }] = useQuery<{
		users: UserConnection;
	}>({
		query: EMPLOYEES_QUERY,
		variables: {
			after: paginationCursors[0],
			first: getPageSize(),
			searchText: searchText.length >= MIN_QUERY_LENGTH ? searchText : '',
			type: view as UserActivityType,
			// orderBy: sort ? (sort.split(' ')[0] as UserOrderByFields) : undefined,
			// orderByDirection: sort ? (sort.split(' ')[1] as OrderByDirection) : undefined,
		},
	});

	// Views
	const views = [
		{
			id: UserActivityType.Active,
			label: messages.activeEmployees,
			metaLabel: counts.active,
		},
		{
			id: UserActivityType.Inactive,
			label: messages.inactiveEmployees,
			metaLabel: counts.inactive,
		},
	];

	const { pageInfo, edges = [] } = employeesData?.users || {};

	// DataTable values
	const { columns, rows } = createColumnsAndRows<User>(
		[
			{
				heading: messages.employeeName,
				render(user) {
					return <UserNameColumn user={user} />;
				},
			},
			{
				heading: messages.badgeBarcode,
				render(user) {
					return <UserBadgeBarcodeColumn user={user} />;
				},
			},
			{
				heading: messages.email,
				render(user) {
					return <UserEmailColumn user={user} />;
				},
			},
			{
				heading: messages.lastScan,
				render(user) {
					return <UserLastScanColumn user={user} />;
				},
			},
			{
				heading: messages.tags,
				render(user) {
					return <UserTagsColumn user={user} />;
				},
			},
		],
		(edges || []).map((edge) => edge.node),
	);

	// Guards
	const error = employeesError || countsError;
	const fetching = employeesFetching || countsFetching;

	if (error) {
		return <Error graphQLError={error} />;
	}

	// Render
	return (
		<Page fullWidth title={messages.employees}>
			<Layout>
				<Layout.Section>
					<Stack distribution="trailing" alignment="trailing" spacing="extraLoose">
						<Button
							plain
							monochrome
							removeUnderline
							disabled={!isUserAllowed([UserRole.Admin, UserRole.EmployeeManager])}
							icon={<Icon source={AddMajor} />}
							url={routes.addEmployee()}
						>
							{messages.addEmployee}
						</Button>
					</Stack>
				</Layout.Section>
				<Layout.Section>
					<DataTable
						loading={fetching}
						columns={columns}
						rows={rows}
						pageInfo={pageInfo}
						paginationCursors={paginationCursors}
						setPaginationCursors={setPaginationCursors}
						views={views}
						query={query}
						emptyStateHeading={messages.employeesNotFound}
						queryPlaceholder={messages.filterEmployees}
						setFilters={setFilters}
						selectedView={view}
						hideFilters={false}
					/>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}

interface ColumnProps {
	user: User;
}

function UserNameColumn({ user }: ColumnProps) {
	const { messages } = useLocalization();
	const { value: isPopoverOpen, setTrue: openPopover, setFalse: closePopover } = useToggle(false);

	return (
		<Stack vertical={false}>
			<Link url={routes.employee(user.id)} removeUnderline key={`${user.id}-name`}>
				{user.name ? user.name : messages.unnamed}
			</Link>
			{user.roles?.includes(UserRole.Admin) && (
				<Popover
					preferredAlignment="left"
					active={isPopoverOpen}
					activator={
						<div onMouseEnter={openPopover} onMouseLeave={closePopover}>
							<Icon source={FraudProtectMinor} color={'primary'} />
						</div>
					}
					onClose={closePopover}
				>
					<Card sectioned>{messages.thisEmployeeHasBridgeAccess}</Card>
				</Popover>
			)}
		</Stack>
	);
}

function UserBadgeBarcodeColumn({ user }: ColumnProps) {
	return <div key={user.id}>{user.badge || <NoData />}</div>;
}

function UserEmailColumn({ user }: ColumnProps) {
	return <div key={user.id}>{user.email || <NoData />}</div>;
}

function UserLastScanColumn({ user }: ColumnProps) {
	return (
		<div key={user.id}>
			{user.lastLogin ? (
				<>
					<DateTime date={user.lastLogin} />
					<RelativeDateTime date={user.lastLogin} />
				</>
			) : (
				<NoData />
			)}
		</div>
	);
}

function UserTagsColumn({ user }: ColumnProps) {
	return (
		<Stack key={user.id} alignment="center">
			{(user.tags || []).slice(0, 2).map(({ name }) => (
				<Tag key={name}>{name}</Tag>
			))}
			{(user.tags?.length || 0) > 2 ? (
				<Badge size="medium">{`+${(user.tags?.length || 0) - 2}`}</Badge>
			) : null}
		</Stack>
	);
}
