import { BulkOrderProgress } from '@sixriver/fulfillment-api-schema';
import { Badge } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../hooks/useLocalization';
import { NoData } from '../NoData';

interface Props {
	progress?: BulkOrderProgress | null;
}

export function BulkOrderProgressBadge({ progress }: Props) {
	const { messages } = useLocalization();

	// Render
	return progress === BulkOrderProgress.Unassigned ? (
		<Badge status="warning">{messages.unassigned}</Badge>
	) : progress === BulkOrderProgress.InProgress ? (
		<Badge status="info">{messages.inProgress}</Badge>
	) : progress === BulkOrderProgress.Complete ? (
		<Badge status="new">{messages.completed}</Badge>
	) : (
		<NoData />
	);
}
