import {
	MutationResponse,
	SpecialProjectType,
	WorkLog as WorkLogApi,
	WorkLogInput,
	WorkLogPage,
} from '@sixriver/fulfillment-api-schema';
import { DeleteMinor, EditMinor } from '@sixriver/lighthouse-icons';
import { Button, Layout, TextStyle, Tooltip } from '@sixriver/lighthouse-web-community';
import { useEffect, useState } from 'react';
import { CombinedError, useMutation } from 'urql';

import {
	ADD_TIME_LOG_MUTATION,
	EDIT_TIME_LOG_MUTATION,
	WORK_LOG_QUERY,
} from './SpecialProjects.graphql';
import { TimeLogged } from './TimeLogged';
import { WorkLogAddEditModal } from './WorkLogAddEditModal';
import { WorkLogDeleteModal } from './WorkLogDeleteModal';
import { WorkLogEmptyState } from './WorkLogEmptyState';
import { Column, DataTable } from '../../components/DataTable';
import { DateTime } from '../../components/DateTime';
import { Error } from '../../components/Error';
import { getPageSize } from '../../helpers/page-size';
import { useLocalization } from '../../hooks/useLocalization';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import { useToast } from '../../hooks/useToast';

interface Props {
	queryPollInterval: number;
	queryPause?: boolean;
	projectId: string;
	projectType?: SpecialProjectType | null;
	isProjectComplete?: boolean;
	totalTime: number;
}

export function WorkLog({
	queryPollInterval,
	queryPause,
	projectId,
	projectType,
	totalTime,
	isProjectComplete,
}: Props) {
	const { messages } = useLocalization();

	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [selectedWorkLogItem, setSelectedWorkLogItem] = useState<WorkLogApi | undefined>(undefined);
	const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
	const [formError, setFormError] = useState<CombinedError | undefined>(undefined);

	const { showToast } = useToast();

	// Data
	const [{ fetching, data, error: queryError }] = usePollingQuery<{ getWorkLog: WorkLogPage }>({
		pause: queryPause,
		pollInterval: queryPollInterval,
		query: WORK_LOG_QUERY,
		variables: {
			cursor: paginationCursors[0],
			id: projectId,
			limit: getPageSize(),
		},
	});

	const { cursor, results } = data?.getWorkLog || { results: [] };

	// Mutations
	const [{ error: editTimeLogError }, editTimeLogMutation] = useMutation<
		{ editTimeLog: MutationResponse },
		{ input: WorkLogInput }
	>(EDIT_TIME_LOG_MUTATION);

	const [{ error: addTimeLogError }, addTimeLogMutation] = useMutation<
		{ addTimeLog: MutationResponse },
		{ input: WorkLogInput }
	>(ADD_TIME_LOG_MUTATION);

	// Blow away form errors so we don't show stale information. This sucks
	useEffect(() => {
		setFormError(addTimeLogError || editTimeLogError);
	}, [addTimeLogError, editTimeLogError]);

	// Functions
	const onDeleteClick = (logItem: WorkLogApi) => {
		setSelectedWorkLogItem(logItem);
		setIsDeleteModalOpen(true);
	};

	const onDeleteModalClose = () => {
		setSelectedWorkLogItem(undefined);
		setIsDeleteModalOpen(false);
	};

	const onAdd = async (input: WorkLogInput) => {
		const { error } = await addTimeLogMutation(
			{ input },
			{ additionalTypenames: ['WorkLogPage', 'WorkLog', 'KittingProject', 'WorkOrderProject'] },
		);

		if (!error) {
			showToast(messages.dataSaved);
			setSelectedWorkLogItem(undefined);
			setIsEditModalOpen(false);
		}
	};

	const onEdit = async (input: WorkLogInput) => {
		// we are actually creating a new entry with the time diff
		const { error } = await editTimeLogMutation(
			{
				input: {
					...input,
					timeLogged: input.timeLogged - (selectedWorkLogItem?.timeLogged || 0),
				},
			},
			{ additionalTypenames: ['WorkLogPage', 'WorkLog', 'KittingProject', 'WorkOrderProject'] },
		);

		if (!error) {
			showToast(messages.dataSaved);
			setSelectedWorkLogItem(undefined);
			setIsEditModalOpen(false);
		}
	};

	const onDelete = async () => {
		// we are actually creating a new entry with the time diff
		if (selectedWorkLogItem) {
			const input: WorkLogInput = {
				dateLogged: selectedWorkLogItem.dateLogged,
				goalId: projectId,
				id: selectedWorkLogItem.id,
				task: selectedWorkLogItem.task,
				timeLogged: selectedWorkLogItem.timeLogged * -1,
				userId: selectedWorkLogItem.user?.id || '',
			};

			const { data } = await editTimeLogMutation(
				{ input },
				{ additionalTypenames: ['WorkLogPage', 'WorkLog', 'KittingProject', 'WorkOrderProject'] },
			);

			onDeleteModalClose();

			if (data?.editTimeLog.success) {
				showToast(messages.workLogDeleted);
			}
		}
	};

	const onEditClick = (logItem: WorkLogApi) => {
		setSelectedWorkLogItem(logItem);
		setIsEditModalOpen(true);
	};

	const onAddClick = () => {
		setSelectedWorkLogItem(undefined);
		setIsEditModalOpen(true);
	};

	const onEditClose = () => {
		setSelectedWorkLogItem(undefined);
		setIsEditModalOpen(false);
		setFormError(undefined);
	};

	// Table setup
	const columns: Column[] = [
		{ heading: messages.associate, type: 'text' },
		{ heading: messages.date, type: 'text' },
		{ heading: messages.task, type: 'text' },
		{ heading: messages.timeLogged, type: 'numeric' },
		{ heading: messages.edit, type: 'numeric' },
		{ heading: messages.delete, type: 'numeric' },
	];

	const rows = results
		.filter((item) => item.timeLogged > 0)
		.map((logItem) => {
			const { id, user, dateLogged, task, timeLogged } = logItem;
			return [
				<TextStyle key={id}>{user?.name || user?.id || '—'}</TextStyle>,
				<DateTime key={id} date={dateLogged} />,
				<TextStyle key={id}>{task}</TextStyle>,
				<TimeLogged key={id} timeInMs={timeLogged} />,
				<EditButton
					key={id}
					action={onEditClick}
					logItem={logItem}
					isProjectComplete={isProjectComplete}
				/>,
				<DeleteButton
					key={id}
					action={onDeleteClick}
					logItem={logItem}
					isProjectComplete={isProjectComplete}
				/>,
			];
		});

	const footer = ['', '', '', <TimeLogged key="totalTime" timeInMs={totalTime} />, '', ''];

	const totalTitle = {
		plural: messages.totalTimeLogged,
		singular: messages.totalTimeLogged,
	};

	const error = queryError;

	const cardActions = [
		{ content: messages.logWork, disabled: isProjectComplete, onAction: onAddClick },
	];

	const mode = selectedWorkLogItem?.id ? 'edit' : 'add';

	if (error) {
		return <Error graphQLError={error} />;
	}

	return (
		<Layout>
			<Layout.Section>
				<DataTable
					cardActions={cardActions}
					columns={columns}
					rows={rows}
					emptyStateMarkup={<WorkLogEmptyState onClick={onAddClick} />}
					title={messages.workLog}
					paginationCursors={paginationCursors}
					setPaginationCursors={setPaginationCursors}
					pageInfo={{ endCursor: cursor }}
					loading={fetching}
					totals={rows.length ? footer : []}
					showTotalsInFooter={!!rows.length}
					totalsName={totalTitle}
				/>
			</Layout.Section>
			<WorkLogDeleteModal
				isOpen={isDeleteModalOpen}
				onClose={onDeleteModalClose}
				onDelete={() => void onDelete()}
			/>
			{isEditModalOpen ? (
				<WorkLogAddEditModal
					data={{
						dateLogged: new Date((selectedWorkLogItem?.dateLogged as string) || Date.now()),
						goalId: projectId,
						id: selectedWorkLogItem?.id || null,
						task: selectedWorkLogItem?.task || '',
						timeLogged: selectedWorkLogItem?.timeLogged || 0,
						userId: selectedWorkLogItem?.user?.id || '',
					}}
					mode={mode}
					onSubmit={mode === 'edit' ? onEdit : onAdd}
					onClose={onEditClose}
					error={formError}
					projectType={projectType}
					userName={selectedWorkLogItem?.user?.name}
				/>
			) : null}
		</Layout>
	);
}

interface IconActionProps {
	logItem: WorkLogApi;
	action: (logItem: WorkLogApi) => void;
	isProjectComplete?: boolean;
}

function EditButton({ logItem, action, isProjectComplete }: IconActionProps): JSX.Element {
	const { messages } = useLocalization();

	return (
		<Button
			icon={EditMinor}
			plain
			onClick={() => action(logItem)}
			disabled={isProjectComplete}
			accessibilityLabel={messages.edit}
		/>
	);
}

function DeleteButton({ logItem, action, isProjectComplete }: IconActionProps): JSX.Element {
	const { messages } = useLocalization();

	const buttonMarkup: JSX.Element = (
		<Button
			icon={DeleteMinor}
			plain
			onClick={() => action(logItem)}
			disabled={logItem.isSystemGenerated || isProjectComplete}
			accessibilityLabel={messages.delete}
		/>
	);

	if (logItem.isSystemGenerated) {
		return (
			<Tooltip content={messages.deleteDisabledWorkLogHelpText} active={false}>
				{buttonMarkup}
			</Tooltip>
		);
	}

	return buttonMarkup;
}
