import {
	IcqaReasonCodes,
	InventoryAdjustedEvent,
	InventoryAmnestyPutEvent,
	InventoryConflictReason,
	InventoryInspectedEvent,
	InventoryMovedEvent,
	InventoryPickedEvent,
	OrderCancelledEvent,
	OrderCompletedEvent,
	OrderCreatedEvent,
	PackoutCompletedEvent,
	PackoutInductedEvent,
	PickingCancelledEvent,
	PickingCompletedEvent,
	PickingCreatedEvent,
	PickingInductedEvent,
	PickingPickedEvent,
	SortationPickedEvent,
	SortationSortedEvent,
} from '@sixriver/fulfillment-api-schema';
import { Link } from '@sixriver/lighthouse-web-community';

import { TimelineEvent } from './Timeline';
import { getMfpUrl } from '../../helpers/mfp';
import { useLocalization } from '../../hooks/useLocalization';
import { useShowExceptionPage } from '../../hooks/useShowExceptionPage';
import * as routes from '../../routes';

type InventoryEventType =
	| InventoryPickedEvent
	| InventoryMovedEvent
	| InventoryInspectedEvent
	| InventoryAdjustedEvent
	| InventoryAmnestyPutEvent;

type WorkflowEventType =
	| OrderCreatedEvent
	| OrderCancelledEvent
	| OrderCompletedEvent
	| PickingCreatedEvent
	| PickingInductedEvent
	| PickingPickedEvent
	| PickingCompletedEvent
	| PickingCancelledEvent
	| PackoutInductedEvent
	| PackoutCompletedEvent
	| SortationPickedEvent
	| SortationSortedEvent;

const ChuckLink = (props: { deviceId?: string | null }) => {
	if (props.deviceId) {
		return <Link url={getMfpUrl(props.deviceId)}>{props.deviceId}</Link>;
	}

	return <span>{'—'}</span>;
};

const ProductLink = (props: { id?: string | null; externalId?: string | null }) => {
	if (props.id && props.externalId) {
		return <Link url={routes.product(props.id)}>{props.externalId}</Link>;
	}

	return <span>{props.externalId || '—'}</span>;
};

const LicensePlateLink = (props: { lpn?: string | null; id?: string | null }) => {
	if (props.lpn && props.id) {
		return <Link url={routes.outboundJob(props.id)}>{props.lpn}</Link>;
	}

	return <span>{props.lpn || '–'}</span>;
};

const LocationLink = (props: { id?: string | null; address?: string | null }) => {
	if (props.id && props.address) {
		return <Link url={routes.location(props.id)}>{props.address}</Link>;
	}

	return <span>{props.address || '—'}</span>;
};

export const useWorkflowEvents = (
	events: WorkflowEventType[],
	mode: 'JOB' | 'ORDER',
	isSortation = false, // TODO this should be a property of the event
): TimelineEvent[] => {
	const { messages, translate } = useLocalization();
	const showExceptionPageLink = useShowExceptionPage();

	const getExceptionReason = (reason?: string) => {
		if (reason?.includes('DAMAGED_PRODUCT')) {
			return messages.exceptionReasons.damaged;
		} else if (reason?.includes('MISSING_PRODUCT')) {
			return messages.exceptionReasons.missing;
		} else if (reason?.includes('TOTE_TOO_FULL')) {
			return messages.exceptionReasons.full;
		} else if (reason?.includes('MFP_OFFLINE')) {
			return messages.exceptionReasons.offline;
		}

		return messages.exceptionReasons.otherReason;
	};

	return (events || [])
		.map((event) => {
			const id = event.timestamp;
			const timestamp = new Date(event.timestamp as string);

			switch (event.__typename) {
				case 'OrderCreatedEvent':
					return {
						id,
						timestamp,
						title: isSortation ? messages.orderAccepted : messages.orderReceived,
					};

				case 'OrderCancelledEvent':
					return {
						id,
						timestamp,
						title: messages.orderCancelled,
					};

				case 'OrderCompletedEvent':
					return {
						id,
						timestamp,
						title: messages.orderCompleted,
					};

				case 'PickingCreatedEvent':
					return mode === 'JOB'
						? {
								id,
								timestamp,
								title: messages.jobCreated,
						  }
						: undefined;

				case 'PickingInductedEvent': {
					const msg = event.isHealing ? messages.resolutionJobAssigned : messages.jobAssigned;

					return {
						details: [
							...(event.isHealing
								? [
										{
											label: messages.originalJob,
											value: event.healedJobExternalContainerId,
										},
								  ]
								: []),
							...(event.equipmentType
								? [
										{
											label: messages.handheld,
											value: event.deviceId,
										},
										{
											label: messages.equipmentType,
											value: event.equipmentType || '-',
										},
										{
											label: messages.equipmentId,
											value: event?.equipmentId || '-',
										},
								  ]
								: [
										{
											label: messages.chuck,
											value: <ChuckLink deviceId={event.deviceId} />,
										},
								  ]),
							{
								label: messages.associate,
								value: event.user?.name || event.user?.badge || '—',
							},
						],
						id,
						timestamp,
						title: translate(msg, {
							jobId: <LicensePlateLink lpn={event.externalContainerId} id={event.projectionId} />,
						}),
						variation: event.isHealing ? 'positive' : undefined,
					};
				}

				case 'PickingPickedEvent': {
					let msg = event.isHealing ? messages.itemRepicked : messages.itemPicked;

					if (event.exception) {
						msg = event.isHealing
							? messages.itemRepickedWithException
							: messages.itemPickedWithException;
					}

					return {
						actionButton:
							event.exception && event.orderProjectionId && showExceptionPageLink
								? {
										content: messages.manageExceptions,
										url: routes.exception(event.jobId),
								  }
								: undefined,
						details: [
							...(event.exception
								? [
										{
											label: messages.exceptionReason,
											value: getExceptionReason(event.exception),
										},
										{
											label: messages.unitsWithException,
											value: event.quantity - event.doneQuantity,
										},
								  ]
								: []),
							{
								label: messages.unitsPicked,
								value: translate(messages.xOfY, {
									x: event.doneQuantity,
									y: event.quantity,
								}),
							},
							{
								label: messages.licensePlate,
								value: <LicensePlateLink lpn={event.externalContainerId} id={event.jobId} />,
							},
							...(event.equipmentType
								? [
										{
											label: messages.handheld,
											value: event.deviceId,
										},
										{
											label: messages.equipmentType,
											value: event.equipmentType || '-',
										},
										{
											label: messages.equipmentId,
											value: event?.equipmentId || '-',
										},
								  ]
								: [
										{
											label: messages.chuck,
											value: <ChuckLink deviceId={event.deviceId} />,
										},
								  ]),
							{
								label: messages.associate,
								value: event.user?.name || event.user?.badge || '—',
							},
						],
						id,
						timestamp,
						title: translate(msg, {
							address: (
								<LocationLink address={event.storageLocationAddress} id={event.storageLocationId} />
							),
							externalId: <ProductLink id={event.productId} externalId={event.productExternalId} />,
						}),
						variation: event.exception ? 'negative' : event.isHealing ? 'positive' : undefined,
					};
				}

				case 'PickingCompletedEvent':
					return {
						details: [
							{
								label: messages.associate,
								value: event.user?.name || event.user?.badge || '—',
							},
						],
						id,
						timestamp,
						title: translate(messages.toteRemoved, {
							takeoffDestination: event.takeoffDestination || '—',
						}),
					};

				case 'PickingCancelledEvent':
					return {
						id,
						timestamp,
						title: messages.jobCanceled,
					};

				case 'PackoutInductedEvent':
					return {
						details: [
							{
								label: messages.licensePlate,
								value: event.externalContainerId,
							},
							{
								label: messages.associate,
								value: event.user?.name || event.user?.badge || '—',
							},
						],
						id,
						timestamp,
						title: translate(messages.toteScanned, {
							mfpId: <ChuckLink deviceId={event?.deviceId} />,
						}),
					};

				case 'PackoutCompletedEvent':
					return {
						details: [
							{
								label: messages.licensePlate,
								value: event.externalContainerId,
							},
							{
								label: messages.associate,
								value: event.user?.name || event.user?.badge || '—',
							},
						],
						id,
						timestamp,
						title: messages.packoutCompleted,
					};

				case 'SortationPickedEvent':
					return {
						id,
						timestamp,
						title: messages.orderPicked,
					};

				case 'SortationSortedEvent':
					return {
						id,
						timestamp,
						title: messages.orderSorted,
					};

				default:
					return undefined;
			}
		})
		.filter((event) => event !== undefined) as TimelineEvent[];
};

export const useLocationInventoryEvents = (events: InventoryEventType[]): TimelineEvent[] => {
	const { messages, translate, formatNumber } = useLocalization();

	const getDiscrepancyReason = (reason?: InventoryConflictReason) => {
		switch (reason) {
			case InventoryConflictReason.Miscount:
				return messages.exceptionReasons.miscount;
			case InventoryConflictReason.PickShort:
				return messages.exceptionReasons.pickShort;
			case InventoryConflictReason.NegativeQuantity:
				return messages.exceptionReasons.negativeQuantity;
			case InventoryConflictReason.Other:
			default:
				return messages.exceptionReasons.otherReason;
		}
	};

	return (events || [])
		.map((event) => {
			const id = event.timestamp;
			const timestamp = new Date(event.timestamp as string);
			const conflicted = event.conflictReasons.length > 0;
			const variation = conflicted ? 'negative' : undefined;

			const title = (str: string) => {
				if (conflicted) {
					if (str) {
						return str + '. ' + messages.suspectedDiscrepancyFlagged;
					}
					return messages.suspectedDiscrepancyFlagged;
				}

				const resolution = event.intent === IcqaReasonCodes.IcqaResolutionCount;
				if (resolution) {
					return str + '. ' + messages.suspectedDiscrepancyCleared;
				}

				return str;
			};

			const conflictReasons = event.conflictReasons
				.map((reason) => getDiscrepancyReason(reason))
				.join(', ');

			switch (event.__typename) {
				case 'InventoryAdjustedEvent': {
					const intentReason =
						event.intent === IcqaReasonCodes.IcqaResolutionCount
							? messages.auditCountReasons.resolution
							: event.intent === IcqaReasonCodes.IcqaMiscAdjustment
							? messages.auditCountReasons.otherIssue
							: undefined;
					return {
						details: [
							{
								label: messages.workflow,
								value: messages.auditCount,
							},
							{
								label: messages.reason,
								value: intentReason || '-',
							},
							{
								label: messages.associate,
								value: event.user?.name || event.user?.badge || '—',
							},
						],
						id,
						timestamp,
						title: title(
							translate(messages.quantityAdjusted, {
								quantity: event.quantity,
							}) as string,
						),
						variation: 'positive',
					};
				}

				case 'InventoryInspectedEvent':
					return {
						details: [
							{
								label: messages.workflow,
								value: messages.inspectionCount,
							},

							...(conflicted
								? [
										{
											label: messages.conflictState,
											value: conflictReasons,
										},
								  ]
								: []),

							{
								label: messages.associate,
								value: event.user?.name || event.user?.badge || '—',
							},
						],
						id,
						timestamp,
						title: title(
							typeof event.quantity === 'number'
								? (translate(messages.countUnitsCounted, {
										count: event.quantity,
								  }) as string)
								: '',
						),
						variation,
					};

				case 'InventoryMovedEvent': {
					const msg =
						event.quantity && event.quantity >= 0
							? messages.countUnitsAdded
							: messages.countUnitsRemoved;

					return {
						details: [
							{
								label: messages.workflow,
								value: messages.workflowTypes.move,
							},
							event.quantity && event.quantity > -1
								? {
										label: messages.originLocation,
										value: (
											<LocationLink
												address={event.detail?.sourceLocationAddress}
												id={event.detail?.sourceLocationId}
											/>
										),
								  }
								: {
										label: messages.destination,
										value: (
											<LocationLink
												address={event.detail?.destLocationAddress}
												id={event.detail?.destLocationId}
											/>
										),
								  },
							...(conflicted
								? [
										{
											label: messages.conflictState,
											value: conflictReasons,
										},
								  ]
								: []),
							{
								label: messages.associate,
								value: event.user?.name || event.user?.badge || '—',
							},
							event.balance && {
								label: messages.balance,
								value: formatNumber(event.balance),
							},
						],
						id,
						timestamp,
						title: title(
							translate(msg, {
								count: event.quantity ? Math.abs(event.quantity) : '-',
							}) as string,
						),
						variation,
					};
				}

				case 'InventoryAmnestyPutEvent':
					return {
						details: [
							{
								label: messages.workflow,
								value: messages.workflowTypes.amnesty,
							},
							...(conflicted
								? [
										{
											label: messages.conflictState,
											value: conflictReasons,
										},
								  ]
								: []),
							{
								label: messages.associate,
								value: event.user?.name || event.user?.badge || '—',
							},
							event.balance && {
								label: messages.balance,
								value: formatNumber(event.balance),
							},
						],
						id,
						timestamp,
						title: title(
							translate(messages.countUnitsAdded, {
								count: event.quantity ? Math.abs(event.quantity) : '-',
							}) as string,
						),
						variation,
					};

				case 'InventoryPickedEvent':
					return {
						details: [
							{
								label: messages.workflow,
								value: messages.workflowTypes.picking,
							},
							{
								label: messages.unitsPicked,
								value: translate(messages.xOfY, {
									x: event.quantity ? Math.abs(event.quantity) : '-',
									y: Math.abs(event.quantityAttempted || 0),
								}),
							},
							...(conflicted
								? [
										{
											label: messages.conflictState,
											value: conflictReasons,
										},
								  ]
								: []),
							{
								label: messages.lpn,
								value: event.detail?.projectionId ? (
									<Link url={routes.outboundJob(event.detail?.projectionId)}>
										{event.detail?.containerId}
									</Link>
								) : (
									'—'
								),
							},
							{
								label: messages.associate,
								value: event.user?.name || event.user?.badge || '—',
							},
							event.balance && {
								label: conflicted ? messages.expectedBalance : messages.balance,
								value: formatNumber(event.balance),
							},
						],
						id,
						timestamp,
						title: title(
							translate(messages.countUnitsRemoved, {
								count: event.quantity ? Math.abs(event.quantity) : '-',
							}) as string,
						),
						variation,
					};

				default:
					return undefined;
			}
		})
		.filter((event) => event !== undefined) as TimelineEvent[];
};
