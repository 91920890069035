import { Job, JobStatus, OrderV2 } from '@sixriver/fulfillment-api-schema';
import { TextStyle } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../hooks/useLocalization';

const sortByDateDesc = (a: Job, b: Job) => {
	const aDate = new Date(a.createdAt as string).getTime();
	const bDate = new Date(b.createdAt as string).getTime();
	return bDate - aDate;
};

interface Props {
	order: OrderV2;
}

export function ResolutionCountText({ order }: Props): JSX.Element | null {
	const { messages, translate } = useLocalization();
	const batchJobTypes = ['moso', 'consolidation', 'group'];

	/* How to determine if we need to show 'resolution pending'
	 * Following scenarios is when that is true
	 * 1. A healing discrete job that doesn't have a pick strategy (aka no pick_type in the db yet)
	 * 2. When a batch job has been created, but it hasn't been inducted yet (no chunks yet) and
	 *    isn't cancelled and hasn't be force completed
	 */

	const healJobs = order.pickingJobs.filter((job) => job.isHealJob);
	const mostRecentHealJob = healJobs.sort(sortByDateDesc).shift();
	const hasPendingDiscreteHealJob =
		mostRecentHealJob?.type === 'ingested' &&
		!mostRecentHealJob.pickStrategy &&
		mostRecentHealJob.status !== JobStatus.Canceled;

	const hasPendingBatchHealJob =
		mostRecentHealJob &&
		batchJobTypes.includes(mostRecentHealJob.type) &&
		mostRecentHealJob.status !== JobStatus.Canceled &&
		mostRecentHealJob?.completedAt === null; // This covers if a batch job was force completed (i.e. ship short)

	if (hasPendingDiscreteHealJob || hasPendingBatchHealJob) {
		const resolutionBlockedText = (
			<TextStyle variation="subdued">{messages.resolutionBlocked}</TextStyle>
		);
		const resolutionAwaitingInductText = (
			<TextStyle variation="subdued">{messages.resolutionAwaitingInduct}</TextStyle>
		);
		return order.isAwaitingInventory ? resolutionBlockedText : resolutionAwaitingInductText;
	}

	const countPickingHealJobs = order.pickingJobs.filter(
		(pickingJob) => pickingJob.isHealJob,
	).length;

	if (countPickingHealJobs > 0) {
		return (
			<TextStyle variation="subdued">
				{translate(messages.resolutions, { count: countPickingHealJobs })}
			</TextStyle>
		);
	}

	return null;
}
