import { gql } from 'graphql-tag';

export const JOB_QUERY = gql`
	query GetJob($id: String!) {
		job(id: $id) {
			id
			mfpId
			externalId
			pickStrategy
			stagingIdentifier
			status
			externalContainerId
			isOrder
			externalId
			lines {
				edges {
					node {
						id
						quantity
						doneQuantity
						status
						completedAt
						isShorted
						productId
						productName
					}
				}
			}
			totalLines {
				count
			}
			totalLineQuantities {
				pickedUnits
				totalUnits
				completedLines
			}
			expectedShipDate
			createdAt
			updatedAt
			completedAt
			isAwaitingInventory
			isHealJob
			isShorted
			orders {
				id
				externalId
				isShorted
				isHealable
				lines {
					edges {
						node {
							id
							status
						}
					}
				}
				pickStrategy
				pickingJobs {
					id
					isHealJob
					lines {
						edges {
							node {
								id
								quantity
								doneQuantity
								status
								completedAt
							}
						}
					}
				}
			}
			servicingSortations {
				id
				wall
				batchId
				status
				stagingIdentifier
				pickingJobs {
					id
				}
			}
			tasks {
				id
				type
				completedAt
				takeoffDestination
				container {
					name
				}
			}
			packouts {
				edges {
					node {
						id
						mfpId
						userId
						startedAt
						completedAt
						createdAt
						updatedAt
					}
				}
			}
			type
			workflows {
				type
				status
			}
		}
	}
`;
