import { Modal } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../hooks/useLocalization';

export interface BulkOrderCancelConfirmationModalProps {
	isOpen: boolean;
	onClose: () => void;
	submitBulkCancelRequest: () => Promise<void>;
	isBulkCancelInProgress?: boolean;
	selectedOrderIds: string[];
}

export function BulkOrderCancelConfirmationModal({
	isOpen: isBulkCancelConfirmModalOpen,
	selectedOrderIds,
	onClose,
	submitBulkCancelRequest,
}: BulkOrderCancelConfirmationModalProps) {
	const { messages, translate } = useLocalization();

	const count = selectedOrderIds.length || 0;

	return (
		<Modal
			open={isBulkCancelConfirmModalOpen}
			onClose={onClose}
			title={messages.cancelOrders}
			primaryAction={{
				content: messages.cancelOrders,
				destructive: true,
				onAction: () => void submitBulkCancelRequest(),
			}}
			secondaryActions={[
				{
					content: messages.keepOrders,
					onAction: onClose,
				},
			]}
		>
			<Modal.Section>
				{translate(messages.confirmBulkCancelOrders, { count })} {messages.confirmWait}
			</Modal.Section>
		</Modal>
	);
}
