import { MfaContextProvider } from './providers/Mfas';
import { ReservationContextProvider } from './providers/Reservations';
import { ResourceContextProvider } from './providers/Resources';

export function MfaDataProvider({ children }: { children: React.ReactNode }) {
	return (
		<ResourceContextProvider>
			<ReservationContextProvider>
				<MfaContextProvider>{children}</MfaContextProvider>
			</ReservationContextProvider>
		</ResourceContextProvider>
	);
}
