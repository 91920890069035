import { useDebouncedValue, usePrevious } from '@shopify/react-hooks';
import {
	SpecialProject,
	SpecialProjectPage,
	GoalStates,
	Count,
	SpecialProjectsOrderByFields,
	OrderByDirection,
	SpecialProjectType,
} from '@sixriver/fulfillment-api-schema';
import {
	AppliedFilterInterface,
	Card,
	ChoiceList,
	EmptySearchResult,
	FilterInterface,
	Filters,
	IndexTable,
	Layout,
	Link,
	Page,
	Pagination,
	useIndexResourceState,
} from '@sixriver/lighthouse-web-community';
import { useEffect, useMemo, useState } from 'react';

import Merchant from './Merchant';
import { COUNTS_QUERY, SPECIAL_PROJECTS_QUERY } from './SpecialProjects.graphql';
import styles from './SpecialProjects.module.css';
import Status from './Status';
import Type from './Type';
import { AutoRefresh } from '../../components/AutoRefresh';
import { DateTime } from '../../components/DateTime';
import { Error } from '../../components/Error';
import { SortBy } from '../../components/SortBy';
import { Toteboard } from '../../components/Toteboard';
import { getPageSize } from '../../helpers/page-size';
import { MIN_QUERY_LENGTH } from '../../helpers/table';
import { addDays, endOfDay } from '../../helpers/time';
import {
	useFilters,
	useSetFilters,
	DEFAULT_QUERY_KEY,
	DEFAULT_SORT_KEY,
} from '../../hooks/useFilters';
import { useLocalization } from '../../hooks/useLocalization';
import { usePolling } from '../../hooks/usePolling';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import * as routes from '../../routes';

export interface Heading {
	title: string;
}

enum DateReceivedOptionValues {
	'last30Days' = 'last30Days',
	'last60Days' = 'last60Days',
	'last90Days' = 'last90Days',
}

const STATUS_FILTER_KEY = 'statuses';
const DATE_FROM_FILTER_KEY = 'dateReceivedFrom';
const SERVICE_FILTER_KEY = 'services';
const DEFAULT_STATUES = [GoalStates.Ready, GoalStates.Running, GoalStates.Complete];

export function SpecialProjects() {
	const { messages } = useLocalization();

	const { pollingEnabled, queryPollInterval, togglePolling } = usePolling();
	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	// Filters
	const {
		query,
		sort = SpecialProjectsOrderByFields.DateReceived + ' ' + OrderByDirection.Desc,
		[STATUS_FILTER_KEY]: statusFilterValue,
		[DATE_FROM_FILTER_KEY]: dateFromFilterValue,
		[SERVICE_FILTER_KEY]: serviceFilterValue,
	} = useFilters([STATUS_FILTER_KEY, DATE_FROM_FILTER_KEY, SERVICE_FILTER_KEY]);
	const setFilters = useSetFilters();

	const searchText = useDebouncedValue(query) || '';
	const selectedStatuses = statusFilterValue ? statusFilterValue.split(' ') : undefined;
	const selectedServices = serviceFilterValue ? serviceFilterValue.split(' ') : undefined;
	const selectedDateReceived = dateFromFilterValue ? dateFromFilterValue.split(',') : undefined;

	const filters: FilterInterface[] = [
		{
			filter: (
				<ChoiceList
					title={messages.service}
					titleHidden
					choices={[
						{ label: messages.specialProjectTypes.kitting, value: SpecialProjectType.Kitting },
						{
							label: messages.specialProjectTypes.relabelling,
							value: SpecialProjectType.Relabelling,
						},
						{
							label: messages.specialProjectTypes.cycleCount,
							value: SpecialProjectType.CycleCount,
						},
						{ label: messages.specialProjectTypes.returns, value: SpecialProjectType.Returns },
						{
							label: messages.specialProjectTypes.inventoryTransferToMerchant,
							value: SpecialProjectType.InventoryTransferToMerchant,
						},
						{
							label: messages.specialProjectTypes.repackaging,
							value: SpecialProjectType.Repackaging,
						},
						{
							label: messages.specialProjectTypes.qualityControl,
							value: SpecialProjectType.QualityControl,
						},
						{ label: messages.specialProjectTypes.otherType, value: SpecialProjectType.Other },
					]}
					selected={selectedServices || []}
					onChange={(selected) => {
						setFilters([{ key: SERVICE_FILTER_KEY, value: selected.join(' ') }]);
					}}
					allowMultiple
				/>
			),
			key: SERVICE_FILTER_KEY,
			label: messages.service,
			shortcut: true,
		},
		{
			filter: (
				<ChoiceList
					title={messages.status}
					titleHidden
					choices={[
						{ label: messages.new, value: GoalStates.Ready },
						{ label: messages.inProgress, value: GoalStates.Running },
						{ label: messages.completed, value: GoalStates.Complete },
					]}
					selected={selectedStatuses || []}
					onChange={(selected) => {
						setFilters([{ key: STATUS_FILTER_KEY, value: selected.join(' ') }]);
					}}
					allowMultiple
				/>
			),
			key: STATUS_FILTER_KEY,
			label: messages.status,
			shortcut: true,
		},
		{
			filter: (
				<ChoiceList
					title={messages.receivedAt}
					titleHidden
					choices={[
						{ label: messages.last30Days, value: DateReceivedOptionValues.last30Days },
						{ label: messages.last60Days, value: DateReceivedOptionValues.last60Days },
						{ label: messages.last90Days, value: DateReceivedOptionValues.last90Days },
					]}
					selected={selectedDateReceived || []}
					onChange={(selected) => {
						setFilters([{ key: DATE_FROM_FILTER_KEY, value: selected.join(',') }]);
					}}
				/>
			),
			key: DATE_FROM_FILTER_KEY,
			label: messages.receivedAt,
			shortcut: true,
		},
	];

	const appliedFilters: AppliedFilterInterface[] = useMemo(
		() => [
			...(selectedServices
				? [
						{
							key: SERVICE_FILTER_KEY,
							label: selectedServices
								.map((service) => {
									switch (service) {
										case SpecialProjectType.Kitting:
											return messages.specialProjectTypes.kitting;
										case SpecialProjectType.Relabelling:
											return messages.specialProjectTypes.relabelling;
										case SpecialProjectType.CycleCount:
											return messages.specialProjectTypes.cycleCount;
										case SpecialProjectType.Returns:
											return messages.specialProjectTypes.returns;
										case SpecialProjectType.InventoryTransferToMerchant:
											return messages.specialProjectTypes.inventoryTransferToMerchant;
										case SpecialProjectType.Repackaging:
											return messages.specialProjectTypes.repackaging;
										case SpecialProjectType.QualityControl:
											return messages.specialProjectTypes.qualityControl;
										case SpecialProjectType.Other:
											return messages.specialProjectTypes.otherType;
										default:
											return 'unknown';
									}
								})
								.join(', '),
							onRemove: () => {
								setFilters([{ key: SERVICE_FILTER_KEY, value: '' }]);
							},
						},
				  ]
				: []),
			...(selectedStatuses
				? [
						{
							key: STATUS_FILTER_KEY,
							label: selectedStatuses
								.map((status) => {
									switch (status) {
										case GoalStates.Ready:
											return messages.new;
										case GoalStates.Running:
											return messages.inProgress;
										case GoalStates.Complete:
											return messages.completed;
										default:
											return 'unknown';
									}
								})
								.join(', '),
							onRemove: () => {
								setFilters([{ key: STATUS_FILTER_KEY, value: '' }]);
							},
						},
				  ]
				: []),
			...(selectedDateReceived
				? [
						{
							key: DATE_FROM_FILTER_KEY,
							label: selectedDateReceived
								.map((dateReceived) => messages[dateReceived as DateReceivedOptionValues])
								.join(', '),
							onRemove: () => {
								setFilters([{ key: DATE_FROM_FILTER_KEY, value: '' }]);
							},
						},
				  ]
				: []),
		],
		[selectedDateReceived, selectedServices, selectedStatuses, setFilters, messages],
	);

	const sortChoices = [
		{
			label: messages.sortOptions.projectIdAsc,
			value: SpecialProjectsOrderByFields.Id + ' ' + OrderByDirection.Asc,
		},
		{
			label: messages.sortOptions.projectIdDesc,
			value: SpecialProjectsOrderByFields.Id + ' ' + OrderByDirection.Desc,
		},
		{
			label: messages.sortOptions.dateReceivedDesc,
			value: SpecialProjectsOrderByFields.DateReceived + ' ' + OrderByDirection.Desc,
		},
		{
			label: messages.sortOptions.dateReceivedAsc,
			value: SpecialProjectsOrderByFields.DateReceived + ' ' + OrderByDirection.Asc,
		},
	];

	// DATA
	const [{ fetching: projectsFetching, data: projectsData, error: projectsError }] =
		usePollingQuery<{
			specialProjects: SpecialProjectPage;
		}>({
			pollInterval: queryPollInterval,
			query: SPECIAL_PROJECTS_QUERY,
			variables: {
				cursor: paginationCursors[0],
				dateReceivedFrom: calculateDateFrom(selectedDateReceived),
				limit: getPageSize(),
				orderBy: sort ? (sort.split(' ')[0] as SpecialProjectsOrderByFields) : undefined,
				orderByDirection: sort ? (sort.split(' ')[1] as OrderByDirection) : undefined,
				searchText: searchText.length >= MIN_QUERY_LENGTH ? searchText : undefined,
				statuses: selectedStatuses || DEFAULT_STATUES,
				types: selectedServices,
			},
		});
	const { cursor, results } = projectsData?.specialProjects || { results: [] };

	const [{ fetching: countsFetching, data: countsData, error: countsError }] = usePollingQuery<{
		StaticReadyCount: Count;
		StaticRunningCount: Count;
	}>({
		pollInterval: queryPollInterval,
		query: COUNTS_QUERY,
	});

	const { StaticReadyCount = { count: 0 }, StaticRunningCount = { count: 0 } } = countsData || {};

	const fetching = projectsFetching || countsFetching;
	const error = projectsError || countsError;

	const onClearAll = () => {
		if (setFilters) {
			setFilters([
				...(filters || []).map((filter) => ({ key: filter.key, value: '' })),
				{ key: DEFAULT_QUERY_KEY, value: '' },
			]);
		}
	};

	const toteboardItems = [
		{
			label: messages.newProjects,
			primaryValue: StaticReadyCount.count,
		},
		{
			label: messages.inProgress,
			primaryValue: StaticRunningCount.count,
		},
	];

	const prevQuery = usePrevious(query);
	const prevAppliedFilters = usePrevious(appliedFilters);

	// Reset pagination when changing filters
	useEffect(() => {
		const hasQueryChanged =
			((query?.length || 0) >= MIN_QUERY_LENGTH || (prevQuery?.length || 0) >= MIN_QUERY_LENGTH) &&
			prevQuery !== query;

		const haveFiltersChanged = (appliedFilters || []).some((af) => {
			const prev = prevAppliedFilters?.find((paf) => paf.key === af.key);
			return !prev || prev?.label !== af.label;
		});

		if (setPaginationCursors && (hasQueryChanged || haveFiltersChanged)) {
			setPaginationCursors(() => []);
		}
	}, [query, prevQuery, appliedFilters, prevAppliedFilters]);

	const emptyStateMarkup = (
		<EmptySearchResult title={messages.specialProjectsNotFound} withIllustration />
	);

	const { selectedResources, allResourcesSelected, handleSelectionChange } =
		useIndexResourceState(results);

	const headings: [Heading, ...Heading[]] = [
		{ title: messages.projectId },
		{ title: messages.receivedAt },
		{ title: messages.service },
		{ title: messages.status },
		{ title: messages.store },
	];

	const rows = results.map((project, index) => {
		const { id } = project;
		return (
			<IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
				<ProjectId project={project} />
				<DateReceived project={project} />
				<TableType project={project} />
				<TableStatus project={project} />
				<TableMerchant project={project} />
			</IndexTable.Row>
		);
	});

	return error ? (
		<Error graphQLError={error} />
	) : (
		<Page fullWidth title={messages.specialProjects}>
			<Layout>
				<Layout.Section>
					<AutoRefresh
						pollingEnabled={pollingEnabled}
						togglePolling={togglePolling}
						discriminatorData={projectsData}
					/>
					<Toteboard items={toteboardItems} />
				</Layout.Section>
				<Layout.Section>
					<Card>
						<Card.Section>
							<Filters
								queryValue={query || undefined}
								queryPlaceholder={messages.filterSpecialProjects}
								onQueryChange={(value) => setFilters([{ key: DEFAULT_QUERY_KEY, value }])}
								onQueryClear={() => setFilters([{ key: DEFAULT_QUERY_KEY, value: '' }])}
								filters={filters || []}
								appliedFilters={appliedFilters}
								onClearAll={onClearAll}
							>
								<SortBy
									choices={sortChoices}
									selected={[sort]}
									onChange={(selected) =>
										setFilters([{ key: DEFAULT_SORT_KEY, value: selected[0] }])
									}
								/>
							</Filters>
						</Card.Section>
						<div className={styles.tableContainer}>
							<IndexTable
								emptyState={emptyStateMarkup}
								itemCount={results.length}
								selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
								onSelectionChange={handleSelectionChange}
								loading={fetching}
								headings={headings}
							>
								{rows}
							</IndexTable>
						</div>
						<Card.Section>
							<Pagination
								endCursor={cursor}
								cursors={paginationCursors}
								setCursors={setPaginationCursors}
								loading={fetching}
							/>
						</Card.Section>
					</Card>
				</Layout.Section>
			</Layout>
		</Page>
	);
}

interface Props {
	project: SpecialProject;
}

function ProjectId({ project }: Props): JSX.Element {
	return (
		<IndexTable.Cell>
			<Link url={routes.specialProject(project.id)} removeUnderline>
				{project.externalId}
			</Link>
		</IndexTable.Cell>
	);
}

function TableType({ project }: Props): JSX.Element {
	return (
		<IndexTable.Cell>
			<Type project={project} />
		</IndexTable.Cell>
	);
}

function TableStatus({ project }: Props): JSX.Element {
	return (
		<IndexTable.Cell>
			<Status project={project} showIssuesIcon={true} />
		</IndexTable.Cell>
	);
}

function TableMerchant({ project }: Props): JSX.Element {
	return (
		<IndexTable.Cell>
			<Merchant project={project} />
		</IndexTable.Cell>
	);
}

function DateReceived({ project }: Props): JSX.Element {
	return (
		<IndexTable.Cell>
			<DateTime date={project.dateReceived} />
		</IndexTable.Cell>
	);
}

function calculateDateFrom(selectedDateFrom: string[] | undefined) {
	if (!selectedDateFrom) {
		return undefined;
	}

	if (selectedDateFrom[0] === DateReceivedOptionValues.last90Days) {
		const ninetyDaysAgo = addDays(endOfDay(), -90);
		return ninetyDaysAgo;
	} else if (selectedDateFrom[0] === DateReceivedOptionValues.last60Days) {
		const sixtyDaysAgo = addDays(endOfDay(), -60);
		return sixtyDaysAgo;
	} else if (selectedDateFrom[0] === DateReceivedOptionValues.last30Days) {
		const thirtyDaysAgo = addDays(endOfDay(), -30);
		return thirtyDaysAgo;
	} else {
		return undefined;
	}
}
