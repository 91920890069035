import { useDebouncedValue } from '@shopify/react-hooks';
import {
	MutationResponse,
	QueryJobsV2Args,
	SortationConnection,
} from '@sixriver/fulfillment-api-schema';
import { Page, Layout, Button } from '@sixriver/lighthouse-web-community';
import { useMemo, useState } from 'react';
import { CombinedError, useMutation } from 'urql';

import { SORT_WALLS_QUERY, CREATE_WALL_MUTATION } from './SortWalls.graphql';
import { SortWallsTable } from './SortWallsTable';
import { SortWallsViews } from './SortWallsViews';
import { AutoRefresh } from '../../components/AutoRefresh';
import { Error } from '../../components/Error';
import { ErrorBannerWithDetails } from '../../components/ErrorBanner/ErrorBannerWithDetails';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { getPageSize } from '../../helpers/page-size';
import { MIN_QUERY_LENGTH } from '../../helpers/table';
import { startOfDay, endOfDay } from '../../helpers/time';
import { useFilters, useSetFilters } from '../../hooks/useFilters';
import { useLocalization } from '../../hooks/useLocalization';
import { usePolling } from '../../hooks/usePolling';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import { useToast } from '../../hooks/useToast';

export function SortWalls() {
	const { messages } = useLocalization();

	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	const { view = SortWallsViews.All, query } = useFilters();

	const setFilters = useSetFilters();

	const searchTextQuery = useDebouncedValue(query) || '';

	const searchText = searchTextQuery.length >= MIN_QUERY_LENGTH ? searchTextQuery : undefined;

	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();

	const countsFetching = false;
	const countsData = {
		CompletedTodayCount: { count: 0 },
		ExceptionsCount: { count: 0 },
		PickingCount: { count: 0 },
		ReadyToSortCount: { count: 0 },
		SortingCount: { count: 0 },
		UnassignedCount: { count: 0 },
	};
	const countsError = undefined;
	// const [{ fetching: countsFetching, data: countsData, error: countsError }] = usePollingQuery<
	// 	{
	// 		AllCount: Count;
	// 		UnassignedCount: Count;
	// 		PickingCount: Count;
	// 		ReadyToSortCount: Count;
	// 		SortingCount: Count;
	// 		CompletedTodayCount: Count;
	// 		ExceptionsCount: Count;
	// 	},
	// 	any
	// >({
	// 	context: useMemo(
	// 		() => ({
	// 			useWarehouseApi: shouldUseWarehouseApi,
	// 		}),
	// 		[shouldUseWarehouseApi],
	// 	),
	// 	pollInterval: queryPollInterval,
	// 	query: SORT_WALLS_COUNTS_QUERY,
	// 	variables: {
	// 		completedAtFrom: startOfDay(),
	// 		completedAtTo: endOfDay(),
	// 		searchText,
	// 		statuses: SortWallsViewStateMap[view as SortWallsViews],
	// 	},
	// });

	const [{ fetching: sortFetching, data: sortData, error: sortError }] = usePollingQuery<
		{ sortWalls: SortationConnection },
		QueryJobsV2Args
	>({
		context: useMemo(
			() => ({
				useWarehouseApi: true,
			}),
			[],
		),
		pollInterval: queryPollInterval,
		query: SORT_WALLS_QUERY,
		variables: {
			after: paginationCursors[0],
			completedAtFrom: view === SortWallsViews.CompletedToday ? startOfDay() : undefined,
			completedAtTo: view === SortWallsViews.CompletedToday ? endOfDay() : undefined,
			first: getPageSize(),
			searchText,
			statuses: [],
			// statuses: SortWallsViewStateMap[view as SortWallsViews],
		},
	});

	const { showToast } = useToast();

	const [isBannerErrorVisible, setIsBannerErrorVisible] = useState(false);
	const [mutationErrorTitle, setMutationErrorTitle] = useState('');
	const [mutationErrorContent, setMutationErrorContent] = useState('');
	const [mutationErrorDetails, setMutationErrorDetails] = useState<CombinedError | undefined>(
		undefined,
	);

	const [{ fetching: createWallLoading }, createWallMutation] = useMutation<{
		createSortWall: MutationResponse;
	}>(CREATE_WALL_MUTATION);

	const createWall = async () => {
		setIsBannerErrorVisible(false);
		setMutationErrorTitle('');
		setMutationErrorContent('');
		setMutationErrorDetails(undefined);

		const { data, error } = await createWallMutation();

		if (data?.createSortWall?.success) {
			setIsBannerErrorVisible(false);
			showToast(messages.wallCreated);
			return;
		}

		setIsBannerErrorVisible(true);

		if (error?.message?.includes('No jobs to allocate to Mobile Sort Wall')) {
			setMutationErrorTitle(messages.sortWallNotCreated);
			setMutationErrorContent(messages.noJobsToAllocate);
		} else if (error?.message || error?.graphQLErrors) {
			setMutationErrorDetails(error);
		}
	};

	const {
		UnassignedCount = { count: 0 },
		PickingCount = { count: 0 },
		ReadyToSortCount = { count: 0 },
		SortingCount = { count: 0 },
		CompletedTodayCount = { count: 0 },
		// ExceptionsCount = { count: 0 },
	} = countsData || {};

	const views = [
		{
			id: SortWallsViews.All,
			label: messages.all,
		},
		{
			id: SortWallsViews.Unassigned,
			label: messages.unassigned,
			metaLabel: UnassignedCount.count,
		},
		{
			id: SortWallsViews.Picking,
			label: messages.picking,
			metaLabel: PickingCount.count,
		},
		{
			id: SortWallsViews.ReadyToSort,
			label: messages.readyToSort,
			metaLabel: ReadyToSortCount.count,
		},
		{
			id: SortWallsViews.Sorting,
			label: messages.sorting,
			metaLabel: SortingCount.count,
		},
		{
			id: SortWallsViews.CompletedToday,
			label: messages.completedToday,
			metaLabel: CompletedTodayCount.count,
		},
		// {
		// 	id: SortWallsViews.Exceptions,
		// 	label: messages.exceptions,
		// 	metaLabel: ExceptionsCount.count,
		// },
	];

	const error = sortError || countsError;

	const fetching = sortFetching || countsFetching;

	return error ? (
		<Error graphQLError={error} />
	) : (
		<Page
			fullWidth
			title={messages.sortWalls}
			primaryAction={
				<Button primary loading={createWallLoading} onClick={() => void createWall()}>
					{messages.createWall}
				</Button>
			}
		>
			<Layout>
				<Layout.Section>
					<ErrorBannerWithDetails
						isVisible={isBannerErrorVisible}
						onDismiss={() => {
							setIsBannerErrorVisible(false);
						}}
						title={mutationErrorTitle}
						content={mutationErrorContent}
						details={mutationErrorDetails}
					/>
				</Layout.Section>
				<Layout.Section>
					<AutoRefresh
						pollingEnabled={pollingEnabled}
						togglePolling={togglePolling}
						discriminatorData={sortData}
					/>
				</Layout.Section>
				<Layout.Section>
					<SortWallsTable
						data={sortData}
						loading={fetching}
						paginationCursors={paginationCursors}
						setPaginationCursors={setPaginationCursors}
						views={views}
						selectedView={view}
						query={query}
						setFilters={setFilters}
					/>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
