import { IndexTable } from '@sixriver/lighthouse-web-community';
import { NoData } from '@sixriver/react-support';

import { AvailableMfp } from '../../api';

type WorkAreaCellProps = {
	device: AvailableMfp;
};

export function WorkAreaCell({ device }: WorkAreaCellProps) {
	return (
		<IndexTable.Cell>{device.currentPose?.mapChunk.displayName ?? <NoData />}</IndexTable.Cell>
	);
}
