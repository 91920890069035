import { useFetch } from '@hyper-fetch/react';

import { GlobalErrorType, countMapChunks } from '../../api';

export function useMapChunksCount(): {
	data?: { count: number } | null;
	error?: GlobalErrorType | null;
	fetching: boolean;
} {
	const { loading, data, error } = useFetch(countMapChunks);
	return { data, error, fetching: loading };
}

export function useIsWorkAreasEnabled(): boolean {
	const { data } = useMapChunksCount();
	if (data) {
		return data.count > 1;
	}
	return false;
}
