import { Toast as PolarisToast } from '@sixriver/lighthouse-web-community';
import { useContext } from 'react';

import { ToastContext, Toast as ToastType } from '../../providers/ToastProvider';

export function Toast() {
	const { activeToasts, hideToast } = useContext(ToastContext);

	return (
		<>
			{activeToasts.map((toast: ToastType) => (
				<PolarisToast
					key={toast.id}
					content={toast.content}
					onDismiss={() => hideToast(toast.id)}
					error={toast.error}
				/>
			))}
		</>
	);
}
