import { AvailableMfp } from '../../api';
import { Battery } from '../../components/Battery';
import { NoData } from '../../components/NoData';
import { useBatteryThresholds } from '../../hooks/useConfig';

interface BatteryLevelProps {
	device: AvailableMfp;
}

export function DeviceBattery({ device }: BatteryLevelProps): JSX.Element {
	const batteryThresholds = useBatteryThresholds();

	if (device.batteryLevel !== null && device.batteryLevel !== undefined) {
		return (
			<Battery
				level={device.batteryLevel}
				highThreshold={batteryThresholds.viable}
				lowThreshold={batteryThresholds.critical}
			/>
		);
	}

	return <NoData />;
}
