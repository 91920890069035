import { DateInterval } from '@hyper-fetch/core';

import { client } from './client';
import { MapChunk } from './map-manager';

export const countAvailableMfps = client.createRequest<{ count: number }>()({
	endpoint: `/cfs/ps-readonly-tc/v1/AvailableMfps/count`,
});

export enum DeviceType {
	Chuck = 'Chuck',
	PackoutTerminal = 'PackoutTerminal',
	SortationKiosk = 'SortationKiosk',
	TerminalOnWheels = 'TerminalOnWheels',
	Handheld = 'Handheld',
	Unknown = 'Unknown',
}

export enum DeviceState {
	InUse = 'InUse',
	NotInUse = 'NotInUse',
	Disconnected = 'Disconnected',
}

export type AvailableMfp = {
	id: string;
	modelId: string;
	userId: string;
	state: 'AVAILABLE' | 'AVAILABLE_REMOTE' | 'ALLOCATED' | 'ALLOCATED_REMOTE' | 'OFFLINE';
	batteryLevel: number;
	workspaceReservations: { jobId: string }[];
	currentPose?: { mapChunkId: string; mapChunk: MapChunk };
	updatedAt: Date;
};

export const getAvailableMfps = client.createRequest<AvailableMfp[]>()({
	cacheTime: DateInterval.hour,
	endpoint: `/cfs/ps-readonly-tc/v1/AvailableMfps`,
	method: 'GET',
});

export const getAvailableMfp = client.createRequest<AvailableMfp>()({
	cacheTime: DateInterval.hour,
	endpoint: `/cfs/ps-readonly-tc/v1/AvailableMfps/:id`,
	method: 'GET',
});
