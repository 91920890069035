import { gql } from 'graphql-tag';

import { PAGE_INFO_FRAGMENT } from '../../graphql';

export const PUTAWAY_JOBS_QUERY = gql`
	query PutawayJobs(
		$after: String
		$first: Int
		$cursor: String
		$limit: Float
		$searchText: String
		$statuses: [String!]
		$exceptionReasons: [String!]
		$hasExceptions: Boolean
		$orderBy: [OrderBy!]
	) {
		putawayJobs(
			after: $after
			first: $first
			cursor: $cursor
			limit: $limit
			searchText: $searchText
			statuses: $statuses
			exceptionReasons: $exceptionReasons
			hasExceptions: $hasExceptions
			orderBy: $orderBy
		) {
			edges {
				node {
					id
					type
					state
					externalId
					createdAt
					endedAt
					lastEventPersistedAt
					currentActor {
						user {
							type
							id
						}
						device {
							type
							id
						}
					}
					inputs {
						containerLabels {
							default
						}
						containerKind
						customerData
					}
					outputs {
						collectedContainerLabel
						takeoffDestination
						takenOffAt
					}
					lines {
						id
						type
						state
						externalId
						currentActor {
							user {
								type
								id
							}
							device {
								type
								id
							}
						}
						inputs {
							assetTypes
							amount
							customerData
						}
						outputs {
							totalCompleted
							transfers {
								location
								assetTypeId
								completedAmount
								exceptions
								capturedData {
									group
								}
							}
						}
					}
				}
			}
			pageInfo {
				...PageInfo
			}
		}
	}
	${PAGE_INFO_FRAGMENT}
`;

export const COUNT_PUTAWAY = gql`
	query CountPutaways(
		$cursor: String
		$limit: Float
		$searchText: String
		$statuses: [String!]
		$exceptionReasons: [String!]
		$hasExceptions: Boolean
		$orderBy: [OrderBy!]
	) {
		countPutaways(
			cursor: $cursor
			limit: $limit
			searchText: $searchText
			statuses: $statuses
			exceptionReasons: $exceptionReasons
			hasExceptions: $hasExceptions
			orderBy: $orderBy
		) {
			unassigned
			inProgress
			interrupted
			completed
			cancelled
		}
	}
`;

export const GET_EXCEPTION_REASONS = gql`
	query {
		getExceptions {
			reason
			count
		}
	}
`;
