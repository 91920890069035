import { ChevronRightMinor } from '@sixriver/lighthouse-icons';
import { Card, DisplayText, Icon, Stack, TextStyle } from '@sixriver/lighthouse-web-community';

import styles from './Toteboard.module.css';

interface ToteboardProps {
	items: Array<{
		label: string | React.ReactNode;
		tooltip?: string | React.ReactNode;
		primaryValue: React.ReactNode;
		secondaryValue?: JSX.Element;
		action?: () => void;
		underline?: boolean;
		icon?: boolean;
	}>;
}

export function Toteboard({ items }: ToteboardProps) {
	return (
		<div className={styles.toteboard}>
			{items.map((item) => {
				return (
					<div
						key={item.label as string}
						onClick={item.action}
						className={item.action ? styles.toteboardClickable : ''}
					>
						<Card>
							<Card.Section flush fullWidth>
								<Stack wrap={false} vertical={false} alignment="center">
									<Stack.Item fill>
										<Stack vertical spacing="tight" wrap>
											<Stack spacing="extraTight">
												<p className={item.underline ? styles.label : ''}>{item.label}</p>
												{item.tooltip}
											</Stack>
											<Stack alignment="baseline" wrap>
												<DisplayText size="medium">{item.primaryValue}</DisplayText>
												{item.secondaryValue && (
													<TextStyle variation="subdued">{item.secondaryValue}</TextStyle>
												)}
											</Stack>
										</Stack>
									</Stack.Item>
									<Stack.Item fill>
										<Stack distribution="trailing">
											{item.icon && <Icon source={ChevronRightMinor} color="base" />}
										</Stack>
									</Stack.Item>
								</Stack>
							</Card.Section>
						</Card>
					</div>
				);
			})}
		</div>
	);
}
