import { gql } from 'graphql-tag';

export const PRODUCT_QUERY = gql`
	query GetProduct($id: String!) {
		product(id: $id) {
			id
			customerIdentifier
			scanValues
			name
			kind
			image
			description
			length
			width
			height
			weight
			attributes
		}
	}
`;

/**
 * We need to support legacy fulfillment-api for a transitionary time period
 */
export const LEGACY_PRODUCT_QUERY = gql`
	query GetProduct($id: String!) {
		product(id: $id) {
			id
			customerIdentifier
			scanValues
			name
			kind
			image
			description
			length
			width
			height
			weight
			locations {
				location {
					id
					address
					type
				}
				inventory {
					liveQuantity
					totalPlannedAddQuantity
					totalPlannedRemoveQuantity
					subAssetDescriptorId
					subLocationDescriptorId
					conditions
					conflictReasons
					subLocation {
						definition {
							externalId
						}
					}
					subAssetType {
						definition {
							condition
							disposition
							lot
							expiresAt
						}
					}
				}
			}
			attributes {
				lot
				expiresAt
			}
		}
	}
`;
