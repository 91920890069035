import { Count } from '@sixriver/fulfillment-api-schema';
import { gql } from 'graphql-tag';

export interface ExceptionsCounts {
	ResolvableCount: Count;
	UnresolvableCount: Count;
	ResolvingCount: Count;
	ResolvedTodayCount: Count;
}

export const EXCEPTIONS_COUNT_QUERY = gql`
	query ExceptionsCount(
		$searchText: String
		$healPlanCompletedAtFrom: DateTime
		$healPlanCompletedAtTo: DateTime
	) {
		ResolvableCount: ordersV2Count(
			searchText: $searchText
			isHealable: true
			isShorted: true
			hasExceptions: true
			statuses: [Picked, Sorted]
		) {
			count
		}

		UnresolvableCount: ordersV2Count(
			searchText: $searchText
			isHealable: false
			isShorted: true
			hasExceptions: true
			statuses: [Picked, Sorted]
		) {
			count
		}

		ResolvingCount: ordersV2Count(
			searchText: $searchText
			hasExceptions: true
			statuses: [Picking]
		) {
			count
		}

		ResolvedTodayCount: ordersV2Count(
			isHealable: false
			isShorted: false
			hasExceptions: true
			healPlanCompletedAtFrom: $healPlanCompletedAtFrom
			healPlanCompletedAtTo: $healPlanCompletedAtTo
		) {
			count
		}
	}
`;
