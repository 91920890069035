import { UserRole } from '@sixriver/react-support';
import { Switch } from 'react-router-dom';

import { Mfa, MfaDataProvider } from '.';
import { Mfas } from './Mfas';
import { NewMfa } from './NewMfa';
import * as mfaRoutes from './routes';
import { ProtectedRoute } from '../../components/ProtectedRoute';

export function MfaEntryRoute() {
	return (
		<MfaDataProvider>
			<Switch>
				<ProtectedRoute exact path={mfaRoutes.mfas()}>
					<Mfas />
				</ProtectedRoute>
				<ProtectedRoute exact path={mfaRoutes.addMfa()} allowedRoles={[UserRole.Admin]}>
					<NewMfa />
				</ProtectedRoute>
				<ProtectedRoute exact path={mfaRoutes.mfa()} allowedRoles={[UserRole.Admin]}>
					<Mfa />
				</ProtectedRoute>
			</Switch>
		</MfaDataProvider>
	);
}
