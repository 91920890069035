import { gql } from 'graphql-tag';

export const PRODUCTS_QUERY = gql`
	query Products($cursor: String, $limit: Int, $searchText: String) {
		products(cursor: $cursor, limit: $limit, searchText: $searchText) {
			results {
				id
				customerIdentifier
				name
				description
			}
			cursor
		}
	}
`;

export const PRODUCT_QUERY = gql`
	query Product($id: String!) {
		product(id: $id) {
			id
			customerIdentifier
			name
			description
			image
		}
	}
`;

export const CREATED_BULK_ORDERS_QUERY = gql`
	query CreatedBulkOrders(
		$after: String
		$before: String
		$first: Int
		$last: Int
		$searchText: String
		$orderBy: BulkOrderByFields
		$orderByDirection: OrderByDirection
		$batchStates: [BulkOrderStatus!]
		$progressStates: [BulkOrderProgress!]
		$expectedShipDate: DateTime
	) {
		createdBulkOrders(
			after: $after
			before: $before
			first: $first
			last: $last
			searchText: $searchText
			orderBy: $orderBy
			orderByDirection: $orderByDirection
			batchStates: $batchStates
			progressStates: $progressStates
			expectedShipDate: $expectedShipDate
		) {
			edges {
				node {
					id
					jobFlowRuleId
					lpn
					ordersCollectedCount
					ordersPackedCount
					status
					progress
					assetTypes {
						id
						externalId
						name
						description
						unitsRequired
						unitsMoved
						unitsPacked
					}
					createdAt
					expectedShipDate
					merchant
				}
			}
		}
	}
`;

export const CUTOFF_DATES_QUERY = gql`
	query CutoffForBulkOrders {
		cutoffForBulkOrders {
			dates
		}
	}
`;

export const SUGGESTED_BULK_ORDERS_QUERY = gql`
	query {
		recommendedBulkOrders {
			ordersCollectedCount
			cutoff
			merchant
			assetTypes {
				id
				customerIdentifier
				name
				description
				scanValues
			}
		}
	}
`;

export const COUNTS_QUERY = gql`
	query CountBulkOrders($batchClosedAt: DateTime) {
		Total: bulkOrdersCount(progressStates: [Unassigned, InProgress, Complete]) {
			count
		}
		Unassigned: bulkOrdersCount(progressStates: [Unassigned]) {
			count
		}
		InProgress: bulkOrdersCount(progressStates: [InProgress]) {
			count
		}
		CompletedToday: bulkOrdersCount(progressStates: [Complete], batchClosedAt: $batchClosedAt) {
			count
		}
	}
`;

export const CREATE_BATCH_MUTATION = gql`
	mutation CreateBatch($input: BulkOrderJobFlowRuleInput!) {
		createBatch(input: $input) {
			success
		}
	}
`;

export const CLOSE_BULK_ORDER_MUTATION = gql`
	mutation closeBulkOrder($id: String!) {
		closeBulkOrder(id: $id) {
			success
		}
	}
`;

export const REPRINT_BATCH_BARCODE_MUTATION = gql`
	mutation reprintBatchBarcode($id: String!) {
		reprintBatchBarcode(id: $id) {
			success
		}
	}
`;
