import { QueryJobsV2Args } from '@sixriver/fulfillment-api-schema';
import { JobConnection } from '@sixriver/warehouse-api-graphql-types';
import { gql } from 'graphql-tag';
import { useMemo } from 'react';

import { useLocalization } from './useLocalization';
import { usePollingQuery } from './usePollingQuery';

export interface StatusCounts {
	[status: string]: number;
}

export function useJobsCounts(args: QueryJobsV2Args, visible?: boolean): StatusCounts {
	const { messages } = useLocalization();

	const statusCounts = {
		[messages.readyToSort]: 0,
		[messages.readyToPack]: 0,
		[messages.picking]: 0,
		[messages.sorting]: 0,
		[messages.packing]: 0,
		[messages.picked]: 0,
		[messages.sorted]: 0,
		[messages.packed]: 0,
		[messages.canceled]: 0,
		[messages.unknown]: 0,
		[messages.total]: 0,
	};

	const query = gql`
		query OutboundJobs(
			$mfpIds: [String!]
			$createdAtFrom: DateTime
			$after: String
			$first: Int
			$ids: [String!]
			$searchText: String
			$statuses: [JobStatus!]
			$isLate: Boolean
			$isShorted: Boolean
			$isAwaitingInventory: Boolean
			$isHealJob: Boolean
			$completedAtFrom: DateTime
			$completedAtTo: DateTime
			$expectedShipDateFrom: DateTime
			$expectedShipDateTo: DateTime
			$pickStrategies: [PickStrategy!]
			$orderBy: [JobOrderBy!]
			$workOrderTypes: [WorkOrderType!]
			$workAreaIds: [String!]
			$usedEquipmentTypes: [String!]
		) {
			jobs(
				mfpIds: $mfpIds
				after: $after
				first: $first
				ids: $ids
				searchText: $searchText
				statuses: $statuses
				isLate: $isLate
				isShorted: $isShorted
				isAwaitingInventory: $isAwaitingInventory
				isHealJob: $isHealJob
				completedAtFrom: $completedAtFrom
				completedAtTo: $completedAtTo
				expectedShipDateFrom: $expectedShipDateFrom
				expectedShipDateTo: $expectedShipDateTo
				createdAtFrom: $createdAtFrom
				pickStrategies: $pickStrategies
				orderBy: $orderBy
				workOrderTypes: $workOrderTypes
				workAreaIds: $workAreaIds
				isOrder: false
				usedEquipmentTypes: $usedEquipmentTypes
			) {
				edges {
					node {
						status
						isLate
						workflows {
							type
							status
						}
					}
				}
			}
		}
	`;

	const [{ data }] = usePollingQuery<{ jobs: JobConnection }>({
		context: useMemo(
			() => ({
				useWarehouseApi: true,
			}),
			[],
		),
		pause: !visible,
		pollInterval: 5000,
		query,
		variables: {
			...args,
		}, // 5 seconds
	});

	if (data) {
		data.jobs?.edges.map(({ node: job }) => {
			const { workflows = [] } = job;
			const pickWorkflow = workflows.find((workflow) => workflow.type === 'PICK');
			const sortWorkflow = workflows.find((workflow) => workflow.type === 'SORT');
			const packWorkflow = workflows.find((workflow) => workflow.type === 'PACK');

			if (job.status === 'Ready') {
				if (sortWorkflow?.status === 'READY') {
					statusCounts[messages.readyToSort] += 1;
				}

				if (packWorkflow?.status === 'READY') {
					statusCounts[messages.readyToPack] += 1;
				}
			}

			if (job.status === 'InProgress') {
				if (pickWorkflow?.status === 'IN_PROGRESS') {
					statusCounts[messages.picking] += 1;
				}

				if (sortWorkflow?.status === 'IN_PROGRESS') {
					statusCounts[messages.sorting] += 1;
				}

				if (packWorkflow?.status === 'IN_PROGRESS') {
					statusCounts[messages.picking] += 1;
				}
			}

			if (job.status === 'Done') {
				if (pickWorkflow?.status === 'DONE') {
					statusCounts[messages.picked] += 1;
				}

				if (sortWorkflow?.status === 'DONE') {
					statusCounts[messages.sorted] += 1;
				}

				if (packWorkflow?.status === 'DONE') {
					statusCounts[messages.packed] += 1;
				}
			}

			if (job.status === 'Canceled') {
				statusCounts[messages.canceled] += 1;
			}

			if (job.status === 'Unknown') {
				statusCounts[messages.unknown] += 1;
			}
		});
	}

	statusCounts[messages.total] = Object.values(statusCounts).reduce((acc, val) => (acc += val), 0);

	return statusCounts;
}
