import { Config } from '@sixriver/fulfillment-api-schema';
import { DocumentNode } from 'graphql';
import { gql } from 'graphql-tag';
import { CombinedError, useQuery } from 'urql';

export const CONFIG_QUERY: DocumentNode = gql`
	query Config {
		config: configuration {
			countJobsUrl
			moveJobsUrl
		}
	}
`;

export function useConfig(isAuthenticated = true): {
	config: Config | undefined;
	error: CombinedError | undefined;
	fetching: boolean;
} {
	const [{ error, fetching, data }] = useQuery<{
		config: Config;
	}>({
		pause: !isAuthenticated,
		query: CONFIG_QUERY,
		requestPolicy: 'cache-first',
	});

	return { config: data?.config, error, fetching };
}
