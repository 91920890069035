import { Card, Banner } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

interface BannerProps {
	isVisible?: boolean;
	onDismiss: () => void;
}

export function BulkCancelInProgressBanner({
	isVisible = false,
	onDismiss,
}: BannerProps): JSX.Element | null {
	const { messages } = useLocalization();

	if (!isVisible) {
		return null;
	}

	return (
		<Card.Section>
			<Banner
				status="info"
				onDismiss={onDismiss}
				action={{ content: messages.viewActionLog, url: routes.actionLog() }}
			>
				<p>{messages.cancelOrderSubmitted}</p>
			</Banner>
		</Card.Section>
	);
}
