import { Modal, TextContainer } from '@sixriver/lighthouse-web-community';
import { ReactNode } from 'react';

import { useLocalization } from '../../hooks/useLocalization';

export interface historyLimiterModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSubmit: () => void;
	message: string | ReactNode[];
}

export function OrderHistoryLimiterConfirmModal({
	isOpen: isModalOpen,
	onClose,
	onSubmit,
	message,
}: historyLimiterModalProps) {
	// Localization
	const { messages } = useLocalization();

	// Render
	return (
		<Modal
			open={isModalOpen}
			onClose={onClose}
			title={messages.viewMoreOrders}
			primaryAction={{
				content: messages.proceed,
				onAction: onSubmit,
			}}
			secondaryActions={[
				{
					content: messages.cancel,
					onAction: onClose,
				},
			]}
		>
			<Modal.Section>
				<TextContainer>
					<p>{message}</p>
				</TextContainer>
			</Modal.Section>
		</Modal>
	);
}
