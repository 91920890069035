import { Filters } from '@sixriver/lighthouse-web-community';

import { noop } from '../../helpers/noop';
import { useLocalization } from '../../hooks/useLocalization';

interface LinesFiltersProps {
	queryValue?: string;
	onQueryChange?: (value: string) => void;
	onClearAll?: () => void;
}

export function LinesFilter({
	queryValue,
	onQueryChange = noop,
	onClearAll = noop,
}: LinesFiltersProps): JSX.Element {
	const { messages } = useLocalization();
	return (
		<div style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingTop: '1rem' }}>
			<Filters
				queryValue={queryValue}
				queryPlaceholder={messages.filterOrders}
				onQueryChange={onQueryChange}
				onQueryClear={() => onQueryChange('')}
				filters={[]}
				onClearAll={onClearAll}
			></Filters>
		</div>
	);
}
