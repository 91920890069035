import {
	MutationResponse,
	Container,
	UpdateContainerInput,
	ContainerType,
	AddContainerInput,
	ContainerTypeWithFallback,
} from '@sixriver/fulfillment-api-schema';
import { Modal, Page, PageActions } from '@sixriver/lighthouse-web-community';
import { useCallback, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

import { ContainerForm } from './Container.form';
import {
	GET_CONTAINER_QUERY_V1,
	UPDATE_CONTAINER_MUTATION,
	DELETE_CONTAINER_MUTATION,
} from './Containers.graphql';
import { Error } from '../../components/Error';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import * as routes from '../../routes';

export function EditContainer(): JSX.Element {
	const { messages } = useLocalization();

	// Routing
	const history = useHistory();
	const {
		params: { containerId },
	} = useRouteMatch<{ containerId: string }>();

	// Toasts
	const { showToast } = useToast();

	// Queries
	const [{ fetching: fetchingContainer, data, error: getContainerError }] = useQuery<
		{ container: Container },
		{ id: string }
	>({
		pause: !containerId,
		query: GET_CONTAINER_QUERY_V1,
		requestPolicy: 'network-only', // Do not run this query unless a container id was provided
		variables: {
			id: containerId,
		},
	});

	const container = data?.container;

	// Mutations
	const [{ error: editContainerError }, editContainerMutation] = useMutation<
		{ editContainer: MutationResponse },
		{ input: UpdateContainerInput }
	>(UPDATE_CONTAINER_MUTATION);

	const [{ error: deleteContainerError, fetching: isDeleting }, deleteContainerMutation] =
		useMutation<{ deleteContainer: MutationResponse }, { id: string }>(DELETE_CONTAINER_MUTATION);

	// State
	const [modalVisible, setModalVisible] = useState(false);

	// Methods
	const onSubmit = useCallback(
		async (input) => {
			const { error } = await editContainerMutation({
				input: {
					...input,
					id: containerId,
				},
			});

			if (!error) {
				showToast(messages.dataSaved);
				history.push(routes.container(containerId));
			}
		},
		[editContainerMutation, containerId, showToast, history, messages],
	);

	const deleteContainer = async () => {
		const { error } = await deleteContainerMutation({ id: containerId });
		if (!error) {
			history.push(routes.containers());
		}

		setModalVisible(false);
	};

	// Render
	if (fetchingContainer) {
		return <></>;
	}

	if (!container) {
		return <Error message={messages.dataNotFound} />;
	}

	if (getContainerError) {
		return <Error graphQLError={getContainerError} />;
	}
	// eslint-disable-next-line no-console
	console.log('container', container);
	const formDefaults: AddContainerInput = {
		...container,

		materialType: container.attributes?.packageMaterialType,
		// enums
		type:
			container?.type === ContainerTypeWithFallback.Unknown
				? undefined
				: (container?.type as unknown as ContainerType),
	};

	return (
		<Page
			title={container.name}
			breadcrumbs={[{ url: routes.container(containerId) }]}
			fullWidth
			subtitle={messages.containerTypes[container.type || 'unknown']}
		>
			<ContainerForm
				mode="edit"
				data={formDefaults}
				error={editContainerError || deleteContainerError}
				onSubmit={onSubmit}
			/>
			<br />
			<PageActions
				secondaryActions={[
					{
						content: messages.deleteContainer,
						destructive: true,
						onAction: () => setModalVisible(true),
						outline: true,
					},
				]}
			/>
			<Modal
				open={modalVisible}
				onClose={() => setModalVisible(false)}
				title={messages.deleteContainer}
				primaryAction={{
					content: messages.deleteContainer,
					destructive: true,
					loading: isDeleting,
					onAction: () => void deleteContainer(),
				}}
				secondaryActions={[
					{
						content: messages.keepEditing,
						onAction: () => setModalVisible(false),
					},
				]}
			>
				<Modal.Section>{messages.confirmDeleteContainer}</Modal.Section>
			</Modal>
		</Page>
	);
}
