import { gql } from 'graphql-tag';

export const INDICATIONS_QUERY = gql`
	query (
		$dimension: String
		$indication: String
		$hoursBackward: Float
		$hoursForward: Float
		$carrierNames: [String!]
		$customerNames: [String!]
	) {
		indicationsByExpectedShipDate(
			dimension: $dimension
			indication: $indication
			hoursBackward: $hoursBackward
			hoursForward: $hoursForward
			carrierNames: $carrierNames
			customerNames: $customerNames
		) {
			expectedShipDate
			indication
			carrierName
			isTerminal
			count
		}
	}
`;

export const CARRIER_NAMES_QUERY = gql`
	query {
		uniqueCarrierNames
	}
`;

export const CUSTOMER_NAMES_QUERY = gql`
	query {
		uniqueCustomerNames
	}
`;

export const PICKING_BY_EQUIPMENT_QUERY = gql`
	query (
		$hoursBackward: Float
		$hoursForward: Float
		$carrierNames: [String!]
		$cutoffDates: [String!]
	) {
		pickingByEquipmentTypeAndExpectedShipDate(
			hoursBackward: $hoursBackward
			hoursForward: $hoursForward
			carrierNames: $carrierNames
			cutoffDates: $cutoffDates
		) {
			status
			expectedShipDate
			deviceClass
			lines
			units
			orders
		}
	}
`;

export const CARRIER_CUTOFF_QUERY = gql`
	query (
		$dimension: String
		$indication: String
		$hoursBackward: Float
		$hoursForward: Float
		$carrierNames: [String!]
		$customerNames: [String!]
	) {
		indicationsByExpectedShipDate(
			dimension: $dimension
			indication: $indication
			hoursBackward: $hoursBackward
			hoursForward: $hoursForward
			carrierNames: $carrierNames
			customerNames: $customerNames
		) {
			expectedShipDate
			carrierName
		}
	}
`;

export const ORDER_STATS_QUERY = gql`
	query (
		$isLate: Boolean
		$isShorted: Boolean
		$isAwaitingInventory: Boolean
		$expectedShipDateFrom: DateTime
		$expectedShipDateTo: DateTime
	) {
		orderStats(
			isLate: $isLate
			isShorted: $isShorted
			isAwaitingInventory: $isAwaitingInventory
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
		) {
			total
			lineStats {
				total
				totalDone
				totalQuantity
				totalAllocated
			}
			statusStats {
				key
				total
				lineStats {
					total
					totalDone
					totalQuantity
					totalAllocated
				}
			}
			lateStats {
				total
				lineStats {
					total
					totalDone
					totalQuantity
					totalAllocated
				}
			}
			expectedShipDateStats {
				key
				total
				statusStats {
					key
					total
					lineStats {
						total
						totalDone
						totalQuantity
						totalAllocated
					}
				}
				lineStats {
					total
					totalDone
					totalQuantity
					totalAllocated
				}
			}
		}
	}
`;
