import { IndexTable } from '@sixriver/lighthouse-web-community';

import { DeviceBattery } from './DeviceBattery';
import { AvailableMfp } from '../../api';

type BatteryCellProps = {
	device: AvailableMfp;
};

export function BatteryCell({ device }: BatteryCellProps) {
	return (
		<IndexTable.Cell>
			<DeviceBattery device={device} />
		</IndexTable.Cell>
	);
}
