import { PickWaves as PickWavesComponent } from '@sixriver/integration-visibility-app';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import * as routes from '../../routes';

export function PickWaves() {
	const history = useHistory();
	const onClickPickWaveId = useCallback(
		(id: string) => {
			history.push(routes.pickWave(id));
		},
		[history],
	);
	return <PickWavesComponent onClickPickWaveId={onClickPickWaveId} />;
}
