import { Text } from '@sixriver/lighthouse-web-community';
import { ReactNode } from 'react';

import { Step } from './Step';
import { useLocalization } from '../../../hooks/useLocalization';

export type ItemStatus = 'ignore' | 'unstarted' | 'started' | 'completed' | 'failed';

interface ProgressProps {
	deleteOldReservation: ItemStatus;
	freeNewMfa: ItemStatus;
	createReservation: ItemStatus;
}

export function Progress({ deleteOldReservation, freeNewMfa, createReservation }: ProgressProps) {
	const steps: ReactNode[] = [];

	const { messages } = useLocalization();

	if (deleteOldReservation !== 'ignore') {
		steps.push(
			<Step
				key="deleteOldReservation"
				status={deleteOldReservation}
				label={messages.mfas.assignLicenseModal.progress.deleteOldReservation[deleteOldReservation]}
			/>,
		);
	}

	if (freeNewMfa !== 'ignore') {
		steps.push(
			<Step
				key="freeNewMfa"
				status={freeNewMfa}
				label={messages.mfas.assignLicenseModal.progress.freeNewMfa[freeNewMfa]}
			/>,
		);
	}

	if (createReservation !== 'ignore') {
		steps.push(
			<Step
				key="createReservation"
				status={createReservation}
				label={messages.mfas.assignLicenseModal.progress.createReservation[createReservation]}
			/>,
		);
	}

	return (
		<div>
			<Text as="h3" variant="headingMd">
				{messages.mfas.assignLicenseModal.progress.title}
			</Text>
			<ul className="assignLicenseProgress">{steps}</ul>
		</div>
	);
}
