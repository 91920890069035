import {
	MutationResponse,
	MutationShipShortOrderV2Args,
	OrderV2,
} from '@sixriver/fulfillment-api-schema';
import {
	Modal,
	TextContainer,
	Banner,
	Stack,
	Button,
	TextStyle,
} from '@sixriver/lighthouse-web-community';
import { useCallback, useState } from 'react';
import { useMutation } from 'urql';

import { MARK_AS_COMPLETE_MUTATION } from './MarkComplete.graphql';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

interface Props {
	isOpen: boolean;
	order: OrderV2;
	onClose: () => void;
}
export function MarkAsCompleteModal({ isOpen: isModalOpen, onClose, order }: Props) {
	const { messages, translate } = useLocalization();

	const [hasSaveError, setHasSaveError] = useState<boolean>(false);

	// Mutations
	const [, markAsCompleteMutation] = useMutation<
		{ shipShortOrderV2: MutationResponse },
		MutationShipShortOrderV2Args
	>(MARK_AS_COMPLETE_MUTATION);

	const onMarkAsComplete = useCallback(async () => {
		const { data } = await markAsCompleteMutation(
			{ id: order.id },
			{ additionalTypenames: ['OrderV2'] },
		);
		if (data?.shipShortOrderV2.success) {
			onClose();
		} else {
			setHasSaveError(true);
		}
	}, [markAsCompleteMutation, onClose, order.id]);

	return (
		<Modal
			open={isModalOpen}
			onClose={onClose}
			title={messages.orderReviewTitle}
			primaryAction={{ content: messages.markAsComplete, onAction: () => void onMarkAsComplete() }}
			secondaryActions={[
				{
					content: messages.close,
					onAction: onClose,
				},
			]}
		>
			<Modal.Section>
				<TextContainer>
					{hasSaveError ? (
						<Banner status="critical">{messages.saveError}</Banner>
					) : (
						<Banner status="warning">{messages.orderReviewWarning}</Banner>
					)}
					<Stack vertical>
						<TextStyle>{messages.orderReviewSummary}</TextStyle>
						<Stack vertical spacing="extraTight">
							<TextStyle variation="strong">{messages.completed}</TextStyle>
							<TextStyle>
								{translate(messages.xOfYUnits, {
									x: order.canShipShort?.doneQuantity,
									y: order.canShipShort?.quantity,
								})}
							</TextStyle>
							;
						</Stack>
						<Stack vertical spacing="extraTight">
							<TextStyle variation="strong">{messages.exceptions}</TextStyle>
							<TextStyle>
								{translate(messages.countUnits, { count: order.canShipShort?.exceptionCount })}
							</TextStyle>
							;
						</Stack>
						{order.canShipShort?.unassignedCount ? (
							<Stack vertical spacing="extraTight">
								<TextStyle variation="strong">{messages.unassigned}</TextStyle>
								<TextStyle>
									{translate(messages.countUnits, { count: order.canShipShort?.unassignedCount })}
								</TextStyle>
								;
							</Stack>
						) : null}
						<Button plain url={routes.order(order.id)} onClick={onClose}>
							{messages.returnToOrderDetails}
						</Button>
					</Stack>
				</TextContainer>
			</Modal.Section>
		</Modal>
	);
}
