import { ProjectInstructions } from '@sixriver/fulfillment-api-schema';
import {
	Modal,
	TextContainer,
	Stack,
	Heading,
	TextStyle,
	Image,
} from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	step: ProjectInstructions;
	stepNumber: number;
	showStepNumber?: boolean;
}

export function InstructionalStep({ step, stepNumber, showStepNumber }: Props): JSX.Element {
	const { messages } = useLocalization();

	return (
		<Modal.Section>
			<TextContainer>
				<Stack vertical>
					{stepNumber === 1 ? <Heading>{messages.instructions}</Heading> : ''}
					{showStepNumber ? <TextStyle variation="strong">{`STEP ${stepNumber}`}</TextStyle> : null}
					<p>{step.text}</p>
				</Stack>
				{step.media.map((image, index) => {
					return (
						<Image
							key={index}
							source={image.url}
							alt={`${index + 1} on step ${stepNumber}`}
							width="33%"
							height="auto"
						/>
					);
				})}
			</TextContainer>
		</Modal.Section>
	);
}
