import styles from './RelativeDateTime.module.css';
import { useLocalization } from '../../hooks/useLocalization';
import { NoData } from '../NoData';

interface Props {
	date?: Date | string | number;
	mode?: 'deadline' | 'expectation';
	severity?: boolean;
}

export function RelativeDateTime({ date, mode, severity = true }: Props) {
	const { messages, translate, formatDateDiff } = useLocalization();

	if (date) {
		const dateDiff = formatDateDiff(date);
		const offsetMs = new Date(date).getTime() - Date.now();

		let message = dateDiff;
		let dataSeverity = severity ? 'info' : undefined;

		if (mode === 'deadline') {
			message = translate(messages.diffDue, { dateDiff }) as string;
			dataSeverity = severity ? getDeadlineSeverity(offsetMs) : undefined;
		} else if (mode === 'expectation') {
			message = translate(messages.diffExpected, { dateDiff }) as string;
		}

		return (
			<div className={styles.relativeDate} data-severity={dataSeverity}>
				{message}
			</div>
		);
	}

	return <NoData />;
}

function getDeadlineSeverity(offsetMs: number): 'critical' | 'warning' | 'info' {
	if (offsetMs < 0) {
		// deadline passed
		return 'critical';
	} else if (offsetMs < 3600000 * 2) {
		// deadline in less than 2 hours
		return 'warning';
	}

	return 'info';
}
