import {
	StorageLocation,
	StorageLocationSummary,
	StorageLocationType,
} from '@sixriver/fulfillment-api-schema';

export function getFullness(location: StorageLocation): number | null {
	if (location.type && !isFillableType(location.type)) {
		return null;
	}

	if (!location.products.length) {
		return null;
	}

	const products = location.products || [];
	const containerVolume = location.containerType?.volume || 0;
	const productVolume = products.reduce((total, { product, inventory }) => {
		return total + product.volume * (inventory.liveQuantity || 0);
	}, 0);
	const fullness = Math.max(0, productVolume / containerVolume);

	return isFinite(fullness) ? fullness : null;
}

export function getSummaryFullness(summary: StorageLocationSummary): number | null {
	if (summary.type && !isFillableType(summary.type)) {
		return null;
	}

	if (!summary.numAssetTypes) {
		return null;
	}

	return summary.fillFraction;
}

export function isFillableType(type: StorageLocationType): boolean {
	return (
		type !== StorageLocationType.Cart &&
		type !== StorageLocationType.Staging &&
		type !== StorageLocationType.Warehouse
	);
}

export function isPickableType(type: StorageLocationType): boolean {
	return type === StorageLocationType.Bin || type === StorageLocationType.Pallet;
}
