import { useFetch } from '@hyper-fetch/react';

import { GlobalErrorType, MapChunk, getMapChunks } from '../api';

export function useWorkAreas(): {
	data: MapChunk[];
	error?: GlobalErrorType | null;
	fetching: boolean;
} {
	const { loading, data, error } = useFetch(getMapChunks);
	return { data: data ?? [], error, fetching: loading };
}
