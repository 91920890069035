import { Banner } from '@sixriver/lighthouse-web-community';
import { useEffect, useState } from 'react';

import styles from './OfflineBanner.module.css';
import { useLocalization } from '../../hooks/useLocalization';

export function OfflineBanner() {
	const { messages } = useLocalization();
	const [offline, setOffline] = useState(false);

	const handleOffline = () => setOffline(true);
	const handleOnline = () => setOffline(false);

	useEffect(() => {
		window.addEventListener('offline', handleOffline);
		window.addEventListener('online', handleOnline);

		return () => {
			window.removeEventListener('offline', handleOffline);
			window.removeEventListener('online', handleOnline);
		};
	});

	return offline ? (
		<div className={styles.banner}>
			<Banner title={messages.browserOffline} status="warning">
				<p>{messages.checkConnection}</p>
			</Banner>
		</div>
	) : null;
}
