import {
	JobFlowRule as JobFlowRuleModel,
	JobFlowRuleInput,
	MutationResponse,
} from '@sixriver/fulfillment-api-schema';
import { Modal, Page, PageActions } from '@sixriver/lighthouse-web-community';
import { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

import JobFlowRuleForm from './JobFlowRuleForm';
import {
	CLOSE_JOB_FLOW_RULE_MUTATION,
	EDIT_JOB_FLOW_RULE_MUTATION,
	JOB_FLOW_RULE_QUERY,
} from './JobFlowRules.graphql';
import { Error } from '../../components/Error';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import * as routes from '../../routes';

export function EditJobFlowRule() {
	const {
		params: { jobFlowRuleId },
	} = useRouteMatch<{ jobFlowRuleId: string }>();

	const { messages } = useLocalization();

	const history = useHistory();

	const { showToast } = useToast();

	const [modalVisible, setModalVisible] = useState(false);

	const [{ fetching, data, error: loadJobFlowRuleError }] = useQuery<{
		jobFlowRule: JobFlowRuleModel;
	}>({
		query: JOB_FLOW_RULE_QUERY,
		variables: {
			id: jobFlowRuleId,
		},
	});

	const [{ error: editJobFlowRuleError }, editJobFlowRule] = useMutation<
		{ editJobFlowRule: MutationResponse },
		{ input: JobFlowRuleInput }
	>(EDIT_JOB_FLOW_RULE_MUTATION);

	const [{ error: closeJobFlowRuleError, fetching: isDeleting }, closeJobFlowRule] = useMutation<
		{ closeJobFlowRule: MutationResponse },
		{ id: string }
	>(CLOSE_JOB_FLOW_RULE_MUTATION);

	const onSubmit = async (input: JobFlowRuleInput) => {
		const { error } = await editJobFlowRule({ input });

		if (!error) {
			showToast(messages.dataSaved);

			history.push(routes.jobFlowRule(jobFlowRuleId));
		}
	};

	const deleteJobFlowRule = async () => {
		const { error } = await closeJobFlowRule({ id: jobFlowRuleId });

		setModalVisible(false);

		if (error) {
			showToast(messages.dataNotSaved, true);
			return;
		}

		history.push(routes.jobFlowRules());
	};

	const rule = data?.jobFlowRule;

	if (fetching || !rule) {
		return null;
	}

	if (loadJobFlowRuleError || closeJobFlowRuleError) {
		return <Error graphQLError={loadJobFlowRuleError || closeJobFlowRuleError} />;
	}

	const input: JobFlowRuleInput = {
		id: rule.id,
		rule: rule.rule,
		ruleDescription: rule.ruleDescription,
		ruleSubType: rule.ruleSubType,
	};

	return (
		<Page
			fullWidth
			title={rule.ruleDescription}
			breadcrumbs={[{ url: routes.jobFlowRule(rule.id) }]}
		>
			<JobFlowRuleForm mode="edit" data={input} error={editJobFlowRuleError} onSubmit={onSubmit} />
			<PageActions
				secondaryActions={[
					{
						content: messages.deleteJobFlowRule,
						destructive: true,
						onAction: () => setModalVisible(true),
						outline: true,
					},
				]}
			/>
			<Modal
				open={modalVisible}
				onClose={() => setModalVisible(false)}
				title={messages.deleteJobFlowRule}
				primaryAction={{
					content: messages.deleteJobFlowRule,
					destructive: true,
					loading: isDeleting,
					onAction: () => void deleteJobFlowRule(),
				}}
				secondaryActions={[
					{
						content: messages.keepEditing,
						onAction: () => setModalVisible(false),
					},
				]}
			>
				<Modal.Section>{messages.confirmDeleteJobFlowRule}</Modal.Section>
			</Modal>
		</Page>
	);
}
