import { DateInterval } from '@hyper-fetch/core';

import { client } from './client';

export const countMapChunks = client.createRequest<{ count: number }>()({
	cacheTime: DateInterval.hour,
	endpoint: `/cfs/map-manager/v1/MapChunks/count`,
});

export type MapChunk = {
	mapChunkId: string;
	displayName?: string;
};

export const getMapChunks = client.createRequest<MapChunk[]>()({
	cacheTime: DateInterval.hour,
	endpoint: `/cfs/map-manager/v1/MapChunks`,
	method: 'GET',
});
