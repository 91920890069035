import { PropsWithChildren } from 'react';
import {
	createClient,
	Provider,
	cacheExchange,
	fetchExchange,
	makeOperation,
	MapExchangeOpts,
	mapExchange,
	Client,
} from 'urql';

const mapExchangeOptions: MapExchangeOpts = {
	onOperation: (operation) => {
		if (operation.context.useWarehouseApi) {
			return makeOperation(operation.kind, operation, {
				url: `${import.meta.env.VITE_WAREHOUSE_API_URL}/graphql`,
			});
		}

		return operation;
	},
};

const client = createClient({
	exchanges: [mapExchange(mapExchangeOptions), cacheExchange, fetchExchange],
	fetchOptions: { credentials: 'include' },
	requestPolicy: 'cache-and-network',
	url: `${import.meta.env.VITE_FULFILLMENT_API_URL}/graphql`,
});

export function GraphQLProvider({ children }: PropsWithChildren<Record<never, any>>) {
	return <Provider value={client}>{children}</Provider>;
}

export const gqlClient: Client = client;
