import { useLocalization } from '../../hooks/useLocalization';

const THRESHOLD_HIGH = 85;
const THRESHOLD_LOW = 35;

const COLOR_HIGH = '#00a47c';
const COLOR_MEDIUM = '#b98900';
const COLOR_LOW = '#d72c0d';
const COLOR_MONO = '#333';

interface BatteryProps {
	level: number;
	highThreshold?: number;
	lowThreshold?: number;
	showLabel?: boolean;
	monochrome?: boolean;
}

export function Battery({
	level,
	highThreshold = THRESHOLD_HIGH,
	lowThreshold = THRESHOLD_LOW,
	monochrome = false,
}: BatteryProps) {
	const { formatPercent } = useLocalization();

	let stroke = '#babec3';
	let fill;
	let width;

	if (monochrome) {
		fill = COLOR_MONO;
	} else if (level >= highThreshold) {
		fill = COLOR_HIGH;
	} else if (level >= lowThreshold) {
		fill = COLOR_MEDIUM;
	} else {
		fill = COLOR_LOW;
	}

	if (level <= 3) {
		stroke = monochrome ? COLOR_MONO : COLOR_LOW;
		width = 0;
	} else {
		width = Math.max(2, Math.ceil(level * 0.01 * 14));
	}

	return (
		<span data-testid="battery">
			<svg width={30} height="16" style={{ verticalAlign: 'text-top' }}>
				<rect x="1" y="3" width="18" height="12" stroke={stroke} fill="transparent" rx="2" ry="2" />
				<rect x="19" y="5" width="3" height="8" fill={stroke} rx="1" ry="1" />
				<rect x="3" y="5" width={width} height="8" fill={fill} rx="1" ry="1" />
			</svg>
			<span>{formatPercent(level * 0.01)}</span>
		</span>
	);
}
