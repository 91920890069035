import { useEffect } from 'react';
import { AnyVariables, useQuery, UseQueryArgs, UseQueryResponse } from 'urql';

type UsePollingQueryArgs<Variables extends AnyVariables = AnyVariables, Data = any> = UseQueryArgs<
	Variables,
	Data
> & {
	pollInterval: number;
};

export function usePollingQuery<Data = any, Variables extends AnyVariables = AnyVariables>(
	args: UsePollingQueryArgs<Variables, Data>,
): UseQueryResponse<Data, Variables> {
	const query = useQuery(args);
	const [result, executeQuery] = query;

	useEffect(() => {
		if (args.pollInterval > 0 && args.pause !== true && !result.fetching && !result.stale) {
			const id = setTimeout(() => executeQuery(), args.pollInterval);
			return () => clearTimeout(id);
		}
		return;
	}, [args.pause, args.pollInterval, executeQuery, result.fetching, result.stale]);

	return query;
}
