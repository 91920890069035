import { ProductInput, MutationResponse } from '@sixriver/fulfillment-api-schema';
import { Page } from '@sixriver/lighthouse-web-community';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'urql';

import { ADD_PRODUCT_MUTATION } from './AddProduct.graphql';
import { ProductForm } from './ProductForm';
import { useLocalization } from '../../hooks/useLocalization';
import { BusyIndicatorContext } from '../../providers/BusyIndicatorProvider';
import * as routes from '../../routes';

export function AddProduct(): JSX.Element {
	const history = useHistory();
	const { messages } = useLocalization();
	const { lookBusy } = useContext(BusyIndicatorContext);

	const [{ error: addProductError }, addProductMutation] = useMutation<
		{ addProduct: MutationResponse },
		{ input: ProductInput }
	>(ADD_PRODUCT_MUTATION);

	const onSubmit = async (input: ProductInput) => {
		lookBusy(true);

		const { data } = await addProductMutation({ input });

		if (!data?.addProduct?.referenceId) {
			lookBusy(false);
			return;
		}

		setTimeout(() => {
			lookBusy(false);
			history.push(routes.product(data.addProduct.referenceId));
		}, 500);
	};

	return (
		<Page
			fullWidth
			title={messages.addProduct}
			breadcrumbs={[{ content: messages.products, url: routes.products() }]}
		>
			<ProductForm
				mode="add"
				onSubmit={onSubmit}
				error={addProductError}
				data={
					{
						customerIdentifier: '',
						description: '',
						height: 1,
						image: null,
						length: 1,
						name: '',
						weight: 1,
						width: 1,
					} as ProductInput
				}
			/>
		</Page>
	);
}
