import { useDebouncedValue } from '@shopify/react-hooks';
import {
	Count,
	ContainersPage,
	ContainerType,
	QueryContainersArgs,
	Container,
	OrderByDirection,
	ContainerOrderByFields,
	ContainerTypeWithFallback,
} from '@sixriver/fulfillment-api-schema';
import { CircleTickMajor } from '@sixriver/lighthouse-icons';
import {
	Page,
	Layout,
	Icon,
	Link,
	Stack,
	TextStyle,
	Tooltip,
	FilterInterface,
	ChoiceList,
	AppliedFilterInterface,
} from '@sixriver/lighthouse-web-community';
import { useState } from 'react';

import { COUNT_CONTAINERS_QUERY, GET_CONTAINERS_QUERY_V1 } from './Containers.graphql';
import { AutoRefresh } from '../../components/AutoRefresh';
import { DataTable } from '../../components/DataTable';
import { Error } from '../../components/Error';
import { NoData } from '../../components/NoData';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { getPageSize } from '../../helpers/page-size';
import { MIN_QUERY_LENGTH } from '../../helpers/table';
import { useIsPackoutEnabled } from '../../hooks/useConfig';
import { useFilters, useSetFilters } from '../../hooks/useFilters';
import { useLocalization } from '../../hooks/useLocalization';
import { usePolling } from '../../hooks/usePolling';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import * as routes from '../../routes';
import { createColumnsAndRows } from '../../utils';

const defaultView: ContainerType | 'all' = 'all';

export function ContainersV1() {
	const { messages, formatLength } = useLocalization();
	const packoutEnabled = useIsPackoutEnabled();

	// State
	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	// Filters & Sorting
	const {
		view = defaultView,
		query = '',
		sort = ContainerOrderByFields.Name + ' ' + OrderByDirection.Asc,
		inventoryState = 'all',
		// TODO: make visible once backend is complete
		// materialType,
	} = useFilters(['inventoryState']);

	const setFilters = useSetFilters();

	const searchText = useDebouncedValue(query) || '';
	const gqlSearchText = searchText.length >= MIN_QUERY_LENGTH ? searchText : undefined;
	// TODO: make visible once backend is complete
	// const materialTypes = config?.containerMaterialTypes ?? [];
	// const selectedMaterialTypes = materialType ? [materialType] : undefined;
	const [orderBy, orderByDirection] = sort
		? (sort.split(' ') as [ContainerOrderByFields, OrderByDirection])
		: [undefined, undefined];

	const sortChoices = [
		{
			label: messages.sortOptions.containerNameAsc,
			value: ContainerOrderByFields.Name + ' ' + OrderByDirection.Asc,
		},
		{
			label: messages.sortOptions.containerNameDesc,
			value: ContainerOrderByFields.Name + ' ' + OrderByDirection.Desc,
		},
	];

	const filters: FilterInterface[] = [
		{
			filter: (
				<ChoiceList
					title={messages.inventoryTracking}
					titleHidden
					choices={[
						{
							label: messages.inventoryTrackingStates.all,
							value: 'all',
						},
						{
							label: messages.inventoryTrackingStates.tracked,
							value: 'tracked',
						},
						{
							label: messages.inventoryTrackingStates.untracked,
							value: 'untracked',
						},
					]}
					selected={[inventoryState]}
					onChange={(selected) => {
						setFilters([{ key: 'inventoryState', value: selected[0] }]);
					}}
				/>
			),
			key: 'inventoryState',
			label: messages.inventoryTracking,
			shortcut: true,
		},
		// TODO: make visible once backend is complete
		// {
		// 	key: 'materialType',
		// 	label: messages.materialType,
		// 	filter: (
		// 		<ChoiceList
		// 			title={messages.materialType}
		// 			titleHidden
		// 			choices={materialTypes.map((materialType) => ({
		// 				label: materialType,
		// 				value: materialType,
		// 			}))}
		// 			selected={selectedMaterialTypes || []}
		// 			onChange={(selected) => {
		// 				setFilters([{ key: 'materialType', value: selected[0] }]);
		// 			}}
		// 		/>
		// 	),
		// 	shortcut: true,
		// },
	];

	const appliedFilters: AppliedFilterInterface[] = [
		...(inventoryState !== 'all'
			? [
					{
						key: 'inventoryState',
						label:
							inventoryState === 'tracked'
								? messages.inventoryTrackingStates.tracked
								: messages.inventoryTrackingStates.untracked,
						onRemove: () => setFilters([{ key: 'inventoryState', value: 'all' }]),
					},
			  ]
			: []),
		// TODO: make visible once backend is complete
		// ...(selectedMaterialTypes
		// 	? [
		// 			{
		// 				key: 'materialType',
		// 				label: selectedMaterialTypes[0],
		// 				onRemove: () => setFilters([{ key: 'materialType', value: '' }]),
		// 			},
		// 	  ]
		// 	: []),
	];

	// Polling
	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();

	// Queries
	const [{ fetching: countsFetching, data: countsData, error: countsError }] = usePollingQuery<{
		totalContainersCount: Count;
		pickingContainersCount: Count;
		shippingContainersCount: Count;
		storageContainersCount: Count;
	}>({
		pollInterval: queryPollInterval,
		query: COUNT_CONTAINERS_QUERY,
		variables: {
			isInventory: inventoryState === 'all' ? undefined : inventoryState === 'tracked',
			searchText: gqlSearchText,
		},
	});

	const { pickingContainersCount, shippingContainersCount } = countsData || {
		pickingContainersCount: { count: 0 },
		shippingContainersCount: { count: 0 },
		storageContainersCount: { count: 0 },
	};

	const [{ fetching: containersFetching, data: containersData, error: containersError }] =
		usePollingQuery<
			{
				containers: ContainersPage;
			},
			QueryContainersArgs
		>({
			pollInterval: queryPollInterval,
			query: GET_CONTAINERS_QUERY_V1,
			variables: {
				after: paginationCursors[0],
				isInventory: inventoryState === 'all' ? undefined : inventoryState === 'tracked',
				limit: getPageSize(),
				orderBy,
				orderByDirection,
				searchText: gqlSearchText,
				types:
					view === 'all'
						? [ContainerType.Picking, ContainerType.Shipping, ContainerType.Storage]
						: [view as ContainerType],
				// TODO: make visible once backend is complete
				// materialTypes: selectedMaterialTypes,
			},
		});

	const { results: containers = [], cursor } = containersData?.containers || {};

	// Views (Tabs)
	const views = !packoutEnabled
		? undefined
		: [
				{
					id: 'all',
					label: messages.allContainers,
				},
				{
					id: ContainerType.Picking,
					label: messages.pickingContainers,
					metaLabel: pickingContainersCount.count,
				},
				...(packoutEnabled
					? [
							{
								id: ContainerType.Shipping,
								label: messages.shippingContainers,
								metaLabel: shippingContainersCount.count,
							},
					  ]
					: []),
		  ];

	// DataTable values
	const { columns, rows } = createColumnsAndRows(
		[
			{
				heading: messages.name,
				render(container) {
					return <ContainerNameColumn container={container} />;
				},
				type: 'text',
			},
			{
				heading: messages.description,
				render(container) {
					return <ContainerDescriptionColumn container={container} />;
				},
				type: 'text',
			},
			{
				heading: messages.length,
				render(container) {
					return <>{formatLength(container.length)}</>;
				},
				type: 'text',
			},
			{
				heading: messages.width,
				render(container) {
					return <>{formatLength(container.width)}</>;
				},
				type: 'text',
			},
			{
				heading: messages.height,
				render(container) {
					return <>{formatLength(container.height)}</>;
				},
				type: 'text',
			},
			{
				heading: messages.weight,
				render(container) {
					return <ContainerWeightColumn container={container} />;
				},
				type: 'text',
			},
			{
				heading: messages.containerType,
				render(container) {
					return <ContainerTypeColumn container={container} />;
				},
				type: 'text',
			},
			{
				heading: messages.materialType,
				render(container) {
					return <ContainerMaterialTypeColumn container={container} />;
				},
				type: 'text',
			},
		],
		containers,
	);

	// Guards
	const error = containersError || countsError;
	const fetching = containersFetching || countsFetching;

	if (error) {
		return <Error graphQLError={error} />;
	}

	// Render
	return (
		<Page fullWidth title={messages.containers}>
			<Layout>
				<Layout.Section>
					<Stack distribution="trailing" alignment="trailing" spacing="extraLoose">
						{
							// TODO: uncomment if / when we want to re-enable adding containers through the bridge
							/* <Button
							plain
							monochrome
							removeUnderline
							disabled={!isUserAllowed([UserRole.Admin, UserRole.EmployeeManager])}
							icon={<Icon source={AddMajor} />}
							url={routes.addContainer()}
						>
							{messages.addContainer}
						</Button> */
						}

						<AutoRefresh
							pollingEnabled={pollingEnabled}
							togglePolling={togglePolling}
							discriminatorData={containersData}
						/>
					</Stack>
				</Layout.Section>
				<Layout.Section>
					<DataTable
						loading={fetching}
						columns={columns}
						rows={rows}
						pageInfo={{
							endCursor: cursor,
						}}
						filters={filters}
						appliedFilters={appliedFilters}
						setFilters={setFilters}
						paginationCursors={paginationCursors}
						setPaginationCursors={setPaginationCursors}
						views={views}
						selectedView={view}
						query={query}
						queryPlaceholder={messages.filterContainers}
						emptyStateHeading={messages.containersNotFound}
						sortChoices={sortChoices}
						sortValue={sort}
					/>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}

// Columns
interface ColumnProps {
	container: Container;
}

function ContainerNameColumn({ container }: ColumnProps): JSX.Element {
	const { messages } = useLocalization();

	return (
		<Stack vertical spacing="extraTight">
			<Link
				key={`container-${container.id}-name-link`}
				url={routes.container(container.id)}
				removeUnderline
			>
				{container.name}
			</Link>
			{container.enabled ? null : (
				<TextStyle variation="subdued">
					<small>{messages.containerInactive}</small>
				</TextStyle>
			)}
		</Stack>
	);
}

function ContainerDescriptionColumn({ container }: ColumnProps): JSX.Element {
	return container?.description !== container?.name ? (
		<TextStyle>{container.description}</TextStyle>
	) : (
		<NoData />
	);
}

function ContainerWeightColumn({ container }: ColumnProps): JSX.Element {
	const { formatWeight } = useLocalization();

	return container?.type === ContainerTypeWithFallback.Shipping ? (
		container?.weight > 0 ? (
			<TextStyle>{formatWeight(container.weight)}</TextStyle>
		) : (
			<NoData />
		)
	) : (
		<NoData />
	);
}

function ContainerTypeColumn({ container }: ColumnProps): JSX.Element {
	const { messages } = useLocalization();

	return (
		<Stack spacing="tight">
			<span>{messages.containerTypes[container.type]}</span>
			{container.inventory ? (
				<Tooltip content={messages.containerTracked}>
					<Icon source={CircleTickMajor} color="success" />
				</Tooltip>
			) : null}
		</Stack>
	);
}

function ContainerMaterialTypeColumn({ container }: ColumnProps): JSX.Element {
	return container?.attributes?.packageMaterialType ? (
		<TextStyle>{container.attributes.packageMaterialType}</TextStyle>
	) : (
		<NoData />
	);
}
