import { IndexTable } from '@sixriver/lighthouse-web-community';
import { DateTime } from '@sixriver/react-support';

import { AvailableMfp } from '../../api';

type LastActiveCellProps = {
	device: AvailableMfp;
};

export function LastActiveCell({ device }: LastActiveCellProps) {
	return (
		<IndexTable.Cell>
			<DateTime date={device.updatedAt} />
		</IndexTable.Cell>
	);
}
