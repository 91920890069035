import { Page, Layout, Stack } from '@sixriver/lighthouse-web-community';
import { useState } from 'react';

import { ResultSummary } from './ResultSummary';
import { useActionLog } from './useActionLog';
import { BulkCancelBatch } from '../../api';
import { AutoRefresh } from '../../components/AutoRefresh';
import { Column, DataTable } from '../../components/DataTable';
import {
	OptionValues,
	DateRange,
	DateRangeSelect,
	OptionLabelValue,
} from '../../components/DateRangeSelect/DateRangeSelect';
import { DateTime } from '../../components/DateTime';
import { Error } from '../../components/Error';
import { NoData } from '../../components/NoData';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { getMidnight } from '../../helpers/time';
import { useLocalization } from '../../hooks/useLocalization';
import { usePolling } from '../../hooks/usePolling';
import * as routes from '../../routes';

const defaultDateOption = OptionValues.last90Days;
const defaultDate = getMidnight(-90);

export function ActionLog() {
	const { messages } = useLocalization();

	// State
	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);
	const [startDate, setStartDate] = useState(defaultDate);
	const [selectedDateRangeOption, setSelectedDateRangeOption] = useState(defaultDateOption);

	// Polling
	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();

	// Queries
	const { fetching, data, error } = useActionLog({
		cursor: paginationCursors[0],
		limit: 50,
		poll: pollingEnabled,
		pollInterval: queryPollInterval,
		updatedAtFrom: startDate,
	});

	// Data
	const loggedEntries = data?.results || [];
	const cursor = data?.meta.cursor;

	// Page info
	const pageInfo = cursor ? { endCursor: cursor, hasNextPage: true } : {};

	// Columns
	const columns: Column[] = [
		// Removing name column until wis properly returns the user info
		//   See https://6river.atlassian.net/browse/ECL-38 for tracking info
		// {
		// 	type: 'text',
		// 	heading: "Name",
		// },
		{
			heading: messages.requestedAt,
			type: 'text',
		},
		{
			heading: messages.action,
			type: 'text',
		},
		{
			heading: messages.status,
			type: 'text',
		},
		{
			heading: messages.result,
			type: 'text',
		},
		// Re implement when working https://6river.atlassian.net/browse/HRZ-648
		// {
		// 	type: 'text',
		// 	heading: "Export",
		// },
	];

	// Rows
	const rows: React.ReactNode[][] = loggedEntries.map((log) => {
		return [
			// Removing name column until wis properly returns the user info
			//   See https://6river.atlassian.net/browse/ECL-38 for tracking info
			// <Name key={`name-${log.id}`}loggedAction={log} />,
			<CreatedAtDate key={`createdAt-${log.id}`} loggedAction={log} />,
			messages.cancelOrder,
			log.status ? (
				messages.bulkCancelStatuses[log.status as keyof typeof messages.bulkCancelStatuses]
			) : (
				<NoData />
			),
			<ResultSummary key={`results-${log.id}`} loggedAction={log} />,
		];
	});

	// Date Range
	const options: OptionValues[] = [
		OptionValues.today,
		OptionValues.yesterday,
		OptionValues.last30Days,
		OptionValues.last60Days,
		OptionValues.last90Days,
	];

	function handleDateRangeSelectionChanged(dateRange: DateRange, selectedOption: OptionLabelValue) {
		const { start } = dateRange;

		if (start) {
			const startDateMidnight: Date = new Date(start);
			startDateMidnight.setHours(0, 0, 0, 0);

			setStartDate(startDateMidnight);
			setSelectedDateRangeOption(selectedOption.value);
		}
	}

	// Guards
	const loading = fetching;

	// Render
	if (error) {
		return <Error message={error.message} />;
	}
	return (
		<Page
			breadcrumbs={[{ content: messages.orders, url: routes.orders() }]}
			fullWidth
			title={messages.actionLog}
		>
			<Layout>
				<Layout.Section>
					<Stack distribution="equalSpacing">
						<DateRangeSelect
							options={options}
							selectedOption={selectedDateRangeOption}
							onChange={handleDateRangeSelectionChanged}
						/>
						<AutoRefresh
							pollingEnabled={pollingEnabled}
							togglePolling={togglePolling}
							discriminatorData={data}
						/>
					</Stack>
				</Layout.Section>
				<Layout.Section>
					<DataTable
						columns={columns}
						rows={rows}
						loading={loading}
						pageInfo={pageInfo}
						paginationCursors={paginationCursors}
						setPaginationCursors={setPaginationCursors}
						emptyStateHeading={messages.logsNotFound}
					/>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}

interface Props {
	loggedAction: BulkCancelBatch;
}

function CreatedAtDate({ loggedAction }: Props): JSX.Element {
	return loggedAction.createdAt ? <DateTime date={loggedAction.createdAt} /> : <NoData />;
}
// Removing name column until wis properly returns the user info
//   See https://6river.atlassian.net/browse/ECL-38 for tracking info
// function Name({loggedAction}: Props): JSX.Element {
// 	return loggedAction.user?.name ? <>loggedAction.user?.name</> : <NoData />;
// }
