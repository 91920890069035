import { DeviceOrderByFields, OrderByDirection } from '@sixriver/fulfillment-api-schema';
import { Page, Layout, Card } from '@sixriver/lighthouse-web-community';
import { isEmpty, sortBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { DeviceOrderBy, DevicesFilters } from './DevicesFilters';
import { DeviceAndConfig, DevicesTable } from './DevicesTable';
import { DeviceViewTab, DeviceViewTabType, DevicesViewTabs } from './DevicesViewTabs';
import { DeviceState, DeviceType, MfpConfigExtended } from '../../api';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { getDeviceState, getDeviceType, useAvailableMfps } from '../../hooks/useAvailableMfps';
import { useIsWorkAreasEnabled, useMfpConfigs } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';
import { useMapChunks } from '../../hooks/useMapChunks';

export default function Devices(): JSX.Element {
	const isWorkAreasEnabled = useIsWorkAreasEnabled();
	const { messages } = useLocalization();
	const { fetching: availableMfpsFetching, data: availableMfps } = useAvailableMfps();
	const { fetching: mfpConfigsFetching, data: mfpConfigs } = useMfpConfigs();
	const { fetching: mapChunksFetching, data: mapChunks } = useMapChunks();
	const [filteredDevicesAndConfigs, setFilteredDevicesAndConfigs] = useState<DeviceAndConfig[]>([]);
	const [viewTabs, setViewTabs] = useState<DeviceViewTab[]>([{ id: 'All', label: messages.all }]);
	const [selectedViewTabId, setSelectedViewTabId] = useState<DeviceViewTabType>('All');
	const [query, setQuery] = useState('');
	const [selectedStates, setSelectedStates] = useState<DeviceState[]>([]);
	const [selectedMapChunkIds, setSelectedMapChunkIds] = useState<string[]>([]);
	const [selectedSort, setSelectedSort] = useState<DeviceOrderBy[]>([
		{ direction: OrderByDirection.Asc, field: DeviceOrderByFields.Name },
	]);

	const devicesAndConfigs = useMemo(() => {
		const mfpConfigsMap = mfpConfigs.reduce<Map<string, MfpConfigExtended>>((map, mfpConfig) => {
			map.set(mfpConfig.name, mfpConfig);
			return map;
		}, new Map());
		const devicesAndConfigs = availableMfps.map<DeviceAndConfig>((device) => {
			return {
				device,
				mfpConfig: mfpConfigsMap.get(device.id),
			};
		});
		return devicesAndConfigs;
	}, [availableMfps, mfpConfigs]);

	useEffect(() => {
		const newViewTabs = devicesAndConfigs.reduce<Map<DeviceType, DeviceViewTab>>(
			(prevViewTabs, { mfpConfig }) => {
				const deviceType = mfpConfig ? getDeviceType(mfpConfig) : DeviceType.Unknown;
				let label = messages.unknown;

				if (deviceType === DeviceType.Chuck) {
					label = messages.chucks;
				}

				if (deviceType === DeviceType.Handheld) {
					label = messages.handhelds;
				}

				if (deviceType === DeviceType.PackoutTerminal) {
					label = messages.packStations;
				}

				if (deviceType === DeviceType.SortationKiosk) {
					label = messages.sortWalls;
				}

				if (deviceType === DeviceType.TerminalOnWheels) {
					label = messages.terminalOnWheels;
				}

				if (deviceType === DeviceType.Unknown) {
					label = messages.unknown;
				}

				const prevViewTab: DeviceViewTab = prevViewTabs.get(deviceType) ?? {
					count: 0,
					id: deviceType,
					label,
				};
				prevViewTab.count = prevViewTab.count ? prevViewTab.count + 1 : 1;
				prevViewTabs.set(deviceType, prevViewTab);
				return prevViewTabs;
			},
			new Map(),
		);

		setViewTabs([{ id: 'All', label: messages.all }, ...newViewTabs.values()]);
	}, [
		devicesAndConfigs,
		messages.all,
		messages.chucks,
		messages.handhelds,
		messages.packStations,
		messages.sortWalls,
		messages.terminalOnWheels,
		messages.unknown,
	]);

	useEffect(() => {
		const devicesAndConfigsCopy = [...devicesAndConfigs];
		let matchingDevicesAndConfigs = devicesAndConfigsCopy.filter(({ device, mfpConfig }) => {
			let matches = true;
			const deviceType = mfpConfig ? getDeviceType(mfpConfig) : DeviceType.Unknown;
			const deviceState = getDeviceState(device);

			if (!isEmpty(query)) {
				matches = device.id.includes(query);
			}

			if (selectedViewTabId === DeviceType.Chuck && deviceType !== DeviceType.Chuck) {
				matches = false;
			}

			if (selectedViewTabId === DeviceType.Handheld && deviceType !== DeviceType.Handheld) {
				matches = false;
			}

			if (
				selectedViewTabId === DeviceType.PackoutTerminal &&
				deviceType !== DeviceType.PackoutTerminal
			) {
				matches = false;
			}

			if (
				selectedViewTabId === DeviceType.SortationKiosk &&
				deviceType !== DeviceType.SortationKiosk
			) {
				matches = false;
			}

			if (
				selectedViewTabId === DeviceType.TerminalOnWheels &&
				deviceType !== DeviceType.TerminalOnWheels
			) {
				matches = false;
			}

			if (selectedViewTabId === DeviceType.Unknown && deviceType !== DeviceType.Unknown) {
				matches = false;
			}

			if (!isEmpty(selectedStates)) {
				matches = selectedStates.includes(deviceState);
			}

			if (!isEmpty(selectedMapChunkIds)) {
				matches = device.currentPose?.mapChunkId
					? selectedMapChunkIds.includes(device.currentPose.mapChunkId)
					: false;
			}

			return matches;
		});

		let fieldName: string;
		const { direction, field } = selectedSort[0];
		switch (field) {
			case DeviceOrderByFields.Name:
				fieldName = 'id';
				break;
			case DeviceOrderByFields.BatteryLevel:
				fieldName = 'batteryLevel';
				break;
			case DeviceOrderByFields.LastUsed:
				fieldName = 'updatedAt';
				break;
		}

		matchingDevicesAndConfigs = sortBy(matchingDevicesAndConfigs, fieldName);

		if (direction === OrderByDirection.Desc) {
			matchingDevicesAndConfigs.reverse();
		}

		setFilteredDevicesAndConfigs(matchingDevicesAndConfigs);
	}, [
		devicesAndConfigs,
		query,
		selectedMapChunkIds,
		selectedSort,
		selectedStates,
		selectedViewTabId,
	]);

	const handleClearAllFilters = () => {
		setQuery('');
		setSelectedStates([]);
		setSelectedMapChunkIds([]);
	};

	return (
		<Page fullWidth title={messages.devices}>
			<Layout>
				<Layout.Section></Layout.Section>
				<Layout.Section>
					<Card>
						<div style={{ paddingBottom: '1rem' }}>
							<DevicesViewTabs
								viewTabs={viewTabs}
								selected={selectedViewTabId}
								onSelect={setSelectedViewTabId}
							/>
						</div>

						<div style={{ paddingBottom: '1rem', paddingLeft: '1rem', paddingRight: '1rem' }}>
							<DevicesFilters
								isWorkAreasEnabled={isWorkAreasEnabled}
								mapChunks={mapChunks}
								queryValue={query}
								onQueryValueChange={setQuery}
								selectedStates={selectedStates}
								onSelectedStatesChange={setSelectedStates}
								selectedMapChunkIds={selectedMapChunkIds}
								onSelectedMapChunkIdsChange={setSelectedMapChunkIds}
								selectedSort={selectedSort}
								onSortChange={setSelectedSort}
								onClearAll={handleClearAllFilters}
							/>
						</div>

						<DevicesTable
							isWorkAreasEnabled={isWorkAreasEnabled}
							loading={availableMfpsFetching || mfpConfigsFetching || mapChunksFetching}
							devicesAndConfigs={filteredDevicesAndConfigs}
						/>
					</Card>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
