import { InlineFrame } from '../../components/InlineFrame';

export function Tableau() {
	return (
		<InlineFrame
			title="Tableau"
			src="https://tableau.6river.tech/#/site/CustomerDashboards/views"
		></InlineFrame>
	);
}
