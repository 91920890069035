import {
	InventoryState,
	Product,
	StorageLocation,
	StorageLocationType,
} from '@sixriver/fulfillment-api-schema';
import { TextStyle } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../hooks/useLocalization';

interface StorageLocationInventoryRemainingProps {
	location: StorageLocation;
	product: Product;
	inventory: InventoryState;
}

export function StorageLocationInventoryRemaining({
	location,
	product,
	inventory,
}: StorageLocationInventoryRemainingProps) {
	const { messages, translate, formatNumber } = useLocalization();

	const containerVolume = location.containerType?.volume || 0;
	const maxFill = containerVolume / product.volume;
	const remainingFill = Math.max(0, maxFill - (inventory.liveQuantity ?? 0));

	if (
		location.type === StorageLocationType.Staging ||
		Number.isNaN(maxFill) ||
		!Number.isFinite(maxFill) ||
		Number.isNaN(remainingFill) ||
		!Number.isFinite(remainingFill)
	) {
		return null;
	}

	const totalUnits = Math.floor(maxFill || 0);
	const remainingUnits = Math.floor(remainingFill || 0);

	return (
		<TextStyle variation="subdued">
			{`${messages.totalCapacity}: ${translate(
				messages.countUnits,
				{ count: formatNumber(totalUnits) },
				totalUnits,
			)}`}
			<br />
			{`${messages.remainingCapacity}: ${translate(
				messages.countUnits,
				{
					count: formatNumber(remainingUnits),
				},
				remainingUnits,
			)}`}
		</TextStyle>
	);
}
