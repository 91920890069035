import { Count } from '@sixriver/fulfillment-api-schema';
import { gql } from 'graphql-tag';

export interface OutboundJobsCounts {
	TotalCount: Count;
	StaticTotalCount: Count;
	StaticUnassignedCount: Count;
	StaticPickingCount: Count;
	StaticPickedCount: Count;
	StaticSortableCount: Count;
	StaticSortingCount: Count;
	StaticSortedCount: Count;
	StaticPackableCount: Count;
	StaticPackingCount: Count;
	StaticPackedCount: Count;
	StaticInterruptedCount: Count;
	InterruptedCount: Count;
	LateCount: Count;
	ShortedCount: Count;
	AwaitingInventoryCount: Count;
}

export const OUTBOUND_JOBS_COUNT_QUERY = gql`
	query JobsCount(
		$ids: [String!]
		$searchText: String
		$statuses: [JobStatus!]
		$isLate: Boolean
		$isShorted: Boolean
		$isAwaitingInventory: Boolean
		$isHealJob: Boolean
		$expectedShipDateFrom: DateTime
		$expectedShipDateTo: DateTime
		$createdAtFrom: DateTime
		$pickStrategies: [PickStrategy!]
		$workOrderTypes: [WorkOrderType!]
		$usedEquipmentTypes: [String!]
		$workAreaIds: [String!]
	) {
		TotalCount: jobsCount(
			ids: $ids
			searchText: $searchText
			statuses: $statuses
			isLate: $isLate
			isShorted: $isShorted
			isAwaitingInventory: $isAwaitingInventory
			isHealJob: $isHealJob
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			createdAtFrom: $createdAtFrom
			pickStrategies: $pickStrategies
			workOrderTypes: $workOrderTypes
			isOrder: false
			usedEquipmentTypes: $usedEquipmentTypes
			workAreaIds: $workAreaIds
		) {
			count
		}

		StaticTotalCount: jobsCount(
			isOrder: false
			createdAtFrom: $createdAtFrom
			workAreaIds: $workAreaIds
		) {
			count
		}

		StaticUnassignedCount: jobsCount(
			statuses: [Unassigned]
			isOrder: false
			createdAtFrom: $createdAtFrom
			workAreaIds: $workAreaIds
		) {
			count
		}

		StaticPickingCount: jobsCount(
			statuses: [Picking]
			isOrder: false
			createdAtFrom: $createdAtFrom
			workAreaIds: $workAreaIds
		) {
			count
		}

		StaticPickedCount: jobsCount(
			statuses: [Picked]
			isOrder: false
			createdAtFrom: $createdAtFrom
			workAreaIds: $workAreaIds
		) {
			count
		}

		StaticSortableCount: jobsCount(
			statuses: [Sortable]
			isOrder: false
			createdAtFrom: $createdAtFrom
			workAreaIds: $workAreaIds
		) {
			count
		}

		StaticSortingCount: jobsCount(
			statuses: [Sorting]
			isOrder: false
			createdAtFrom: $createdAtFrom
			workAreaIds: $workAreaIds
		) {
			count
		}

		StaticSortedCount: jobsCount(
			statuses: [Sorted]
			isOrder: false
			createdAtFrom: $createdAtFrom
			workAreaIds: $workAreaIds
		) {
			count
		}

		StaticPackableCount: jobsCount(
			statuses: [Packable]
			isOrder: false
			createdAtFrom: $createdAtFrom
			workAreaIds: $workAreaIds
		) {
			count
		}

		StaticPackingCount: jobsCount(
			statuses: [Packing]
			isOrder: false
			createdAtFrom: $createdAtFrom
			workAreaIds: $workAreaIds
		) {
			count
		}

		StaticPackedCount: jobsCount(
			statuses: [Packed]
			isOrder: false
			createdAtFrom: $createdAtFrom
			workAreaIds: $workAreaIds
		) {
			count
		}

		StaticInterruptedCount: jobsCount(
			statuses: [Interrupted]
			isOrder: false
			createdAtFrom: $createdAtFrom
			workAreaIds: $workAreaIds
		) {
			count
		}

		InterruptedCount: jobsCount(
			ids: $ids
			searchText: $searchText
			statuses: [Interrupted]
			createdAtFrom: $createdAtFrom
			isOrder: false
			workAreaIds: $workAreaIds
		) {
			count
		}

		LateCount: jobsCount(
			ids: $ids
			searchText: $searchText
			isLate: true
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			createdAtFrom: $createdAtFrom
			pickStrategies: $pickStrategies
			isOrder: false
			workAreaIds: $workAreaIds
		) {
			count
		}

		ShortedCount: jobsCount(
			ids: $ids
			searchText: $searchText
			isShorted: true
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			createdAtFrom: $createdAtFrom
			pickStrategies: $pickStrategies
			isOrder: false
			workAreaIds: $workAreaIds
		) {
			count
		}

		AwaitingInventoryCount: jobsCount(
			ids: $ids
			searchText: $searchText
			isAwaitingInventory: true
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			createdAtFrom: $createdAtFrom
			pickStrategies: $pickStrategies
			isOrder: false
			workAreaIds: $workAreaIds
		) {
			count
		}
	}
`;
