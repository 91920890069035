import { DuplicateMinor } from '@sixriver/lighthouse-icons';
import { Button, Icon } from '@sixriver/lighthouse-web-community';

import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	text: string;
}

export function CopyButton({ text: textToCopy }: Props) {
	const { messages } = useLocalization();
	const { copyToClipboard } = useCopyToClipboard();

	return (
		<Button
			accessibilityLabel={messages.copyToClipboard}
			icon={<Icon source={DuplicateMinor} />}
			onClick={() => void copyToClipboard(textToCopy)}
			plain
			monochrome
			textAlign="left"
		>
			{textToCopy}
		</Button>
	);
}
