import { LineV2 } from '@sixriver/fulfillment-api-schema';
import { IndexTable, TextStyle } from '@sixriver/lighthouse-web-community';

import { NoData } from '../../NoData';

interface Props {
	line: LineV2;
}

export function WorkAreaCell({ line }: Props): JSX.Element {
	const workAreaName = line?.workArea?.name;
	return (
		<IndexTable.Cell>
			{workAreaName ? <TextStyle>{workAreaName}</TextStyle> : <NoData />}
		</IndexTable.Cell>
	);
}
