import { useDebouncedValue } from '@shopify/react-hooks';
import {
	BulkOrderByFields,
	BulkOrderConnection,
	OrderByDirection,
	BulkOrderProgress,
	BulkOrder,
	BulkOrderStatus,
	BulkOrderRecommendation,
	Count,
	Cutoff,
	BulkOrderProduct,
} from '@sixriver/fulfillment-api-schema';
import {
	Button,
	ChoiceList,
	FilterInterface,
	Layout,
	Link,
	Page,
	Stack,
} from '@sixriver/lighthouse-web-community';
import { useEffect, useState } from 'react';
import { useQuery } from 'urql';

import {
	CREATED_BULK_ORDERS_QUERY,
	SUGGESTED_BULK_ORDERS_QUERY,
	COUNTS_QUERY,
	CUTOFF_DATES_QUERY,
} from './BulkOrders.graphql';
import { BatchProgress } from '../../components/BatchProgress';
import { BatchState } from '../../components/BatchState';
import { BulkProduct } from '../../components/BulkOrderProduct';
import { CreateBatchModal } from '../../components/CreateBatchModal/CreateBatchModal';
import { CutoffFilter } from '../../components/CutoffFilter';
import { Column, DataTable } from '../../components/DataTable';
import { DateTime } from '../../components/DateTime';
import { Error } from '../../components/Error';
import { NoData } from '../../components/NoData';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { Toteboard } from '../../components/Toteboard';
import { ViewTabs } from '../../components/ViewTabs';
import { getPageSize } from '../../helpers/page-size';
import { MIN_QUERY_LENGTH } from '../../helpers/table';
import { startOfDay } from '../../helpers/time';
import { useFilters, useSetFilters, DEFAULT_VIEW_KEY } from '../../hooks/useFilters';
import { useLocalization } from '../../hooks/useLocalization';
import { usePolling } from '../../hooks/usePolling';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import * as routes from '../../routes';

const BATCH_STATE_FILTER_KEY = 'state';
const EARLIEST_CUTOFF_FILTER_KEY = 'earliestCutoff';
const ORDER_PROGRESS_FILTER_KEY = 'progress';

export function BulkOrders() {
	const { messages, formatDateTime, translate } = useLocalization();

	// State
	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	const views = [
		{
			id: 'Created',
			label: messages.createdBatches,
		},
		{
			id: 'Suggested',
			label: messages.suggestedBatches,
		},
	];

	// Filters
	const {
		view = 'Created',
		query,
		sort = view === 'Created'
			? BulkOrderByFields.CreatedAt + ' ' + OrderByDirection.Desc
			: BulkOrderByFields.Cutoff + ' ' + OrderByDirection.Desc,
		[BATCH_STATE_FILTER_KEY]: batchStateFilterValue,
		[EARLIEST_CUTOFF_FILTER_KEY]: earliestCutoffFilterValue,
		[ORDER_PROGRESS_FILTER_KEY]: orderProgressFilterValue,
	} = useFilters([BATCH_STATE_FILTER_KEY, EARLIEST_CUTOFF_FILTER_KEY, ORDER_PROGRESS_FILTER_KEY]);
	const setFilters = useSetFilters();

	const searchText = useDebouncedValue(query) || '';
	const actualSearchText = searchText.length >= MIN_QUERY_LENGTH ? searchText : undefined;
	const selectedBatchStates = batchStateFilterValue ? batchStateFilterValue.split(' ') : undefined;
	const selectedEarliestCutoffReceived = earliestCutoffFilterValue
		? new Date(earliestCutoffFilterValue)
		: undefined;

	const selectedOrderProgress = orderProgressFilterValue
		? orderProgressFilterValue.split(' ')
		: undefined;
	const [orderBy, orderByDirection] = sort
		? (sort.split(' ') as [BulkOrderByFields, OrderByDirection])
		: [undefined, undefined];

	// Queries
	const { queryPollInterval } = usePolling();

	const [
		{
			fetching: createdBulkOrdersFetching,
			data: createdBulkOrdersData,
			error: createdBulkOrdersError,
		},
	] = usePollingQuery<{
		createdBulkOrders: BulkOrderConnection;
	}>({
		pollInterval: queryPollInterval,
		query: CREATED_BULK_ORDERS_QUERY,
		variables: {
			after: paginationCursors[0],
			batchStates: selectedBatchStates,
			expectedShipDate: selectedEarliestCutoffReceived,
			first: getPageSize(),
			orderBy,
			orderByDirection,
			progressStates: selectedOrderProgress,
			searchText: actualSearchText,
		},
	});

	const [{ fetching: cutoffDatesFetching, data: cutoffDatesData, error: cutoffDatesError }] =
		useQuery<{ cutoffForBulkOrders: Cutoff }>({
			query: CUTOFF_DATES_QUERY,
		});

	const cutoffDates = cutoffDatesData?.cutoffForBulkOrders;

	const [
		{
			fetching: suggestedBulkOrdersFetching,
			data: suggestedBulkOrdersData,
			error: suggestedBulkOrdersError,
		},
	] = usePollingQuery<{
		recommendedBulkOrders: [BulkOrderRecommendation];
	}>({
		pollInterval: queryPollInterval,
		query: SUGGESTED_BULK_ORDERS_QUERY,
	});

	const midnight = startOfDay().toISOString();

	const [{ fetching: countsFetching, data: countsData, error: countsError }] = usePollingQuery<{
		Total: Count;
		Unassigned: Count;
		InProgress: Count;
		CompletedToday: Count;
	}>({
		pollInterval: queryPollInterval,
		query: COUNTS_QUERY,
		variables: {
			batchClosedAt: midnight,
		},
	});

	const fetching =
		createdBulkOrdersFetching ||
		suggestedBulkOrdersFetching ||
		countsFetching ||
		cutoffDatesFetching;
	const error =
		createdBulkOrdersError || suggestedBulkOrdersError || countsError || cutoffDatesError;
	const {
		Total = { count: 0 },
		Unassigned = { count: 0 },
		InProgress = { count: 0 },
		CompletedToday = { count: 0 },
	} = countsData || {};

	// Toteboard
	const toteboardItems = [
		{
			label: messages.all,
			primaryValue: <span>{Total.count}</span>,
		},
		{
			label: messages.unassigned,
			primaryValue: <span>{Unassigned.count}</span>,
		},
		{
			label: messages.inProgress,
			primaryValue: <span>{InProgress.count}</span>,
		},
		{
			label: messages.completedToday,
			primaryValue: <span>{CompletedToday.count}</span>,
		},
	];

	const filterAndSortSuggested = (
		orders: BulkOrderRecommendation[],
		orderBy: BulkOrderByFields | undefined,
		orderByDirection: OrderByDirection | undefined,
	) => {
		const filteredSuggestedOrders = searchText
			? orders.filter(
					(order) =>
						order.assetTypes[0]?.id.includes(searchText) ||
						order.assetTypes[0]?.name.includes(searchText) ||
						order.merchant?.includes(searchText) ||
						order.assetTypes[0]?.scanValues.some((scanValue) => scanValue.includes(searchText)),
			  )
			: orders;

		switch (orderBy) {
			case BulkOrderByFields.Cutoff:
				if (orderByDirection === OrderByDirection.Asc) {
					return filteredSuggestedOrders.sort(
						(a: BulkOrderRecommendation, b: BulkOrderRecommendation) =>
							b.cutoff.localeCompare(a.cutoff),
					);
				}
				return filteredSuggestedOrders.sort(
					(a: BulkOrderRecommendation, b: BulkOrderRecommendation) =>
						a.cutoff.localeCompare(b.cutoff),
				);
			case BulkOrderByFields.Merchant:
				if (orderByDirection === OrderByDirection.Asc) {
					return filteredSuggestedOrders.sort(
						(a: any, b: any) => (a.merchant > b.merchant && 1) || -1,
					);
				}
				return filteredSuggestedOrders.sort(
					(a: any, b: any) => (b.merchant > a.merchant && 1) || -1,
				);
			default:
				return filteredSuggestedOrders.sort(
					(a: BulkOrderRecommendation, b: BulkOrderRecommendation) =>
						b.cutoff.localeCompare(a.cutoff),
				);
		}
	};

	const { pageInfo = {}, edges: createdEdges = [] } =
		createdBulkOrdersData?.createdBulkOrders || {};
	const suggestedEdges = suggestedBulkOrdersData?.recommendedBulkOrders
		? filterAndSortSuggested(
				suggestedBulkOrdersData?.recommendedBulkOrders,
				orderBy,
				orderByDirection,
		  )
		: [];

	const filters: FilterInterface[] = [
		...(view === 'Created'
			? [
					{
						filter: (
							<ChoiceList
								title={messages.batchState}
								titleHidden
								choices={[
									{ label: messages.collecting, value: BulkOrderStatus.Open },
									{ label: messages.closed, value: BulkOrderStatus.Closed },
								]}
								selected={selectedBatchStates || []}
								onChange={(selected) => {
									setFilters([{ key: BATCH_STATE_FILTER_KEY, value: selected[0] }]);
								}}
							/>
						),
						key: BATCH_STATE_FILTER_KEY,
						label: messages.batchState,
						shortcut: true,
					},
					{
						filter: (
							<CutoffFilter
								onChange={(selected) =>
									setFilters([{ key: EARLIEST_CUTOFF_FILTER_KEY, value: selected }])
								}
								value={earliestCutoffFilterValue}
								cutoffDates={cutoffDates}
							/>
						),
						key: EARLIEST_CUTOFF_FILTER_KEY,
						label: messages.earliestCutoff,
						shortcut: true,
					},
					{
						filter: (
							<ChoiceList
								title={messages.orderProgress}
								titleHidden
								choices={[
									{ label: messages.unassigned, value: BulkOrderProgress.Unassigned },
									{ label: messages.inProgress, value: BulkOrderProgress.InProgress },
									{ label: messages.completed, value: BulkOrderProgress.Complete },
								]}
								selected={selectedOrderProgress || []}
								onChange={(selected) => {
									setFilters([{ key: ORDER_PROGRESS_FILTER_KEY, value: selected.join(' ') }]);
								}}
								allowMultiple
							/>
						),
						key: ORDER_PROGRESS_FILTER_KEY,
						label: messages.orderProgress,
						shortcut: true,
					},
			  ]
			: []),
	];
	const appliedFilters = [
		...(selectedBatchStates
			? [
					{
						key: BATCH_STATE_FILTER_KEY,
						label: selectedBatchStates?.[0] === 'Open' ? messages.collecting : messages.closed,
						onRemove: () => {
							setFilters([{ key: BATCH_STATE_FILTER_KEY, value: '' }]);
						},
					},
			  ]
			: []),
		...(selectedEarliestCutoffReceived
			? [
					{
						key: EARLIEST_CUTOFF_FILTER_KEY,
						label: formatDateTime(earliestCutoffFilterValue!),
						onRemove: () => {
							setFilters([{ key: EARLIEST_CUTOFF_FILTER_KEY, value: '' }]);
						},
					},
			  ]
			: []),
		...(selectedOrderProgress
			? [
					{
						key: ORDER_PROGRESS_FILTER_KEY,
						label: selectedOrderProgress
							.map((key: any) => {
								return (
									{
										Complete: messages.completed,
										InProgress: messages.inProgress,
										Unassigned: messages.unassigned,
									} as any
								)[key];
							})
							.join(', '),
						onRemove: () => {
							setFilters([{ key: ORDER_PROGRESS_FILTER_KEY, value: '' }]);
						},
					},
			  ]
			: []),
	];

	const sortChoices = [
		...(view === 'Created'
			? [
					{
						label: messages.sortOptions.dateCreatedAsc,
						value: BulkOrderByFields.CreatedAt + ' ' + OrderByDirection.Asc,
					},
					{
						label: messages.sortOptions.dateCreatedDesc,
						value: BulkOrderByFields.CreatedAt + ' ' + OrderByDirection.Desc,
					},
					{
						label: messages.sortOptions.batchIdAsc,
						value: BulkOrderByFields.BatchId + ' ' + OrderByDirection.Asc,
					},
					{
						label: messages.sortOptions.batchIdDesc,
						value: BulkOrderByFields.BatchId + ' ' + OrderByDirection.Desc,
					},
			  ]
			: []),
		{
			label: messages.sortOptions.cutoffAsc,
			value: BulkOrderByFields.Cutoff + ' ' + OrderByDirection.Asc,
		},
		{
			label: messages.sortOptions.cutoffDesc,
			value: BulkOrderByFields.Cutoff + ' ' + OrderByDirection.Desc,
		},
		{
			label: messages.sortOptions.storeAsc,
			value: BulkOrderByFields.Merchant + ' ' + OrderByDirection.Asc,
		},
		{
			label: messages.sortOptions.storeDsc,
			value: BulkOrderByFields.Merchant + ' ' + OrderByDirection.Desc,
		},
	];

	// Columns
	const columns: Column[] = [
		...(view === 'Created'
			? ([
					{ heading: messages.batchId, type: 'text' },
					{ heading: messages.orders, type: 'text' },
					{ heading: messages.unprocessedUnits, type: 'text' },
					{ heading: messages.batchState, type: 'text' },
					{ heading: messages.progress, type: 'text' },
					{ heading: messages.product, type: 'text' },
					{ heading: messages.createdAt, type: 'text' },
					{ heading: messages.earliestCutoff, type: 'text' },
					{ heading: messages.store, type: 'text' },
			  ] as Column[])
			: []),
		...(view === 'Suggested'
			? ([
					{ heading: messages.ordersCollected, type: 'text' },
					{ heading: messages.earliestCutoff, type: 'text' },
					{ heading: messages.product, type: 'text' },
					{ heading: messages.store, type: 'text' },
					{ heading: messages.createBatch, type: 'text' },
			  ] as Column[])
			: []),
	];

	const [isCreateBatchModalOpen, setIsCreateBatchModalOpen] = useState(false);
	const [selectedSuggestedBatch, setSelectedSuggestedBatch] = useState<
		BulkOrderRecommendation | undefined
	>(undefined);

	useEffect(() => {
		if (view === 'Created') {
			setSelectedSuggestedBatch(undefined);
		}
	}, [view]);

	const closeCreateBatchModal = () => {
		setIsCreateBatchModalOpen(false);
		setSelectedSuggestedBatch(undefined);
	};
	const openCreateBatchModal = () => {
		setIsCreateBatchModalOpen(true);
	};

	const rows =
		view === 'Created'
			? createdEdges.map((edge: any) => {
					const bulkOrder = edge.node;
					const {
						id,
						lpn,
						ordersCollectedCount,
						ordersPackedCount,
						status,
						progress,
						assetTypes,
						createdAt,
						expectedShipDate,
						merchant,
					}: BulkOrder = bulkOrder;
					const unitsLeft = assetTypes.length
						? (assetTypes[0]?.unitsRequired || 0) - (assetTypes[0]?.unitsMoved || 0)
						: 0;
					return [
						<Link
							url={routes.bulkOrder(bulkOrder.id as string)}
							key={`name-${lpn}`}
							removeUnderline
						>
							{lpn}
						</Link>,
						<Stack spacing="loose" wrap={false} key={`${id}-orders`}>
							<div>
								{translate(
									messages.countCollected,
									{ count: <b>{ordersCollectedCount}</b> },
									ordersCollectedCount,
								)}
							</div>
							<div>
								{translate(
									messages.countPacked,
									{ count: <b>{ordersPackedCount}</b> },
									ordersPackedCount,
								)}
							</div>
						</Stack>,
						<div key={`${id}-units`}>{unitsLeft}</div>,
						<BatchState key={`batchState-${id}`} batchState={status} />,
						<BatchProgress key={`batchProgress-${id}`} batchProgress={progress || undefined} />,
						assetTypes.length ? (
							<BulkProduct key={`product-${id}`} product={assetTypes[0]} />
						) : (
							<NoData />
						),
						<DateTime key={`created-${createdAt}`} date={createdAt} />,
						<DateTime key={`cutoff-${expectedShipDate}`} date={expectedShipDate} />,
						<div key={`merchant-${merchant}`}>{merchant ? merchant : <NoData />}</div>,
					];
			  })
			: suggestedEdges.map((suggestedOrder) => {
					const { ordersCollectedCount, assetTypes, cutoff, merchant }: BulkOrderRecommendation =
						suggestedOrder;

					return [
						<div key={`count-${ordersCollectedCount}`}>{ordersCollectedCount}</div>,
						<DateTime key={`cutoff-${cutoff}`} date={cutoff} />,
						<BulkProduct
							key={`product-${assetTypes.map((product) => product.id)}`}
							product={assetTypes[0] as BulkOrderProduct}
						/>,
						<div key={`merchant-${merchant}`}>{merchant ? merchant : <NoData />}</div>,
						<Button
							key={`createBatch-${assetTypes.map((product) => product.id)}`}
							onClick={() => {
								setIsCreateBatchModalOpen(true);
								setSelectedSuggestedBatch(suggestedOrder);
							}}
						>
							{messages.create}
						</Button>,
					];
			  });

	if (error) {
		return <Error graphQLError={error} />;
	}

	return (
		<Page
			fullWidth
			title={messages.bulkOrders}
			primaryAction={<Button onClick={openCreateBatchModal}>{messages.createCustomBatch}</Button>}
		>
			<Layout>
				<Layout.Section>
					<ViewTabs
						tabs={views}
						selected={view || views?.[0]?.id}
						onSelect={(id: any) => setFilters([{ key: DEFAULT_VIEW_KEY, value: id }])}
					/>{' '}
				</Layout.Section>
				<Layout.Section>
					{view === 'Created' ? <Toteboard items={toteboardItems} /> : []}
				</Layout.Section>
				<Layout.Section>
					<DataTable
						loading={fetching}
						columns={columns}
						rows={rows}
						pageInfo={pageInfo}
						filters={view === 'Created' ? filters : []}
						appliedFilters={view === 'Created' ? appliedFilters : []}
						setFilters={setFilters}
						paginationCursors={paginationCursors}
						setPaginationCursors={setPaginationCursors}
						query={query}
						queryPlaceholder={messages.searchBulkOrders}
						sortChoices={sortChoices}
						sortValue={sort}
						selectedView={view}
					/>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
			<CreateBatchModal
				isOpen={isCreateBatchModalOpen}
				onClose={closeCreateBatchModal}
				batch={selectedSuggestedBatch || undefined}
			/>
		</Page>
	);
}
