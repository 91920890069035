import { Button, Form, Modal, TextField } from '@sixriver/lighthouse-web-community';
import { useCallback, useState } from 'react';

import { useLocalization } from '../../../hooks/useLocalization';
import { Mfa, useMfas } from '../providers/Mfas';

interface EditNotesModalProps {
	open: boolean;
	onDismiss: () => void;
	onEdited: () => void;
	mfa: Mfa;
}
export function EditNotesModal({ open, onDismiss, onEdited, mfa }: EditNotesModalProps) {
	const { updateMfaNotes } = useMfas();
	const { messages, translate } = useLocalization();

	const [notes, setNotes] = useState(mfa.notes || '');
	const [submitting, setSubmitting] = useState(false);

	const onSubmit = useCallback(async () => {
		setSubmitting(true);

		await updateMfaNotes(mfa, notes);

		onEdited();
		setSubmitting(false);
	}, [mfa, notes, onEdited, updateMfaNotes]);

	return (
		<Modal
			open={open}
			onClose={onDismiss}
			title={translate(messages.mfa.editNotes.title, { name: mfa.name })}
			secondaryActions={[
				{
					content: messages.mfa.editNotes.cancel,
					onAction: onDismiss,
				},
			]}
		>
			<Modal.Section>
				<Form onSubmit={onSubmit}>
					<TextField
						value={notes}
						maxLength={250}
						autoComplete="off"
						showCharacterCount
						onChange={setNotes}
						label={messages.mfa.editNotes.label}
					/>
					<Button primary submit loading={submitting}>
						{messages.mfa.editNotes.submit}
					</Button>
				</Form>
			</Modal.Section>
		</Modal>
	);
}
