import { Banner, Card, Layout, Page, Stack, TextStyle } from '@sixriver/lighthouse-web-community';
import { ContainerType, ContainerTypeType } from '@sixriver/warehouse-api-graphql-types';
import { useContext, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from 'urql';

import { GET_CONTAINER_QUERY_V2 } from './Containers.graphql';
import { CardDetails } from '../../components/CardDetails';
import { Error } from '../../components/Error';
import { NoData } from '../../components/NoData';
import { useLocalization } from '../../hooks/useLocalization';
import { BusyIndicatorContext } from '../../providers/BusyIndicatorProvider';
import * as routes from '../../routes';

interface Params {
	containerId: string;
}

export function ContainerDetailsV2() {
	const { lookBusy } = useContext(BusyIndicatorContext);
	const { messages, formatLength, formatVolume, formatWeight } = useLocalization();

	// Routing
	const {
		params: { containerId },
	} = useRouteMatch<Params>();

	// Queries
	const [{ fetching, error, data }] = useQuery<{ getContainer: ContainerType }, { id: string }>({
		context: useMemo(
			() => ({
				useWarehouseApi: true,
			}),
			[],
		),
		query: GET_CONTAINER_QUERY_V2,
		requestPolicy: 'network-only',
		variables: {
			id: containerId,
		},
	});

	const container = data?.getContainer;

	if (lookBusy(fetching && !container)) {
		return null;
	}

	if (error || !container) {
		return <Error graphQLError={error} message={error ? undefined : messages.dataNotFound} />;
	}

	// computed
	const numberOfContainerSubsections = (container.columns || 1) * (container.rows || 1);
	const containerHasSubsections = numberOfContainerSubsections > 1;
	const wholeContainerVolume = container.cubeVolume * numberOfContainerSubsections;
	const wholeContainerDimensions = {
		height: container.height,
		length: container.length * (container.rows || 1),
		width: container.width * (container.columns || 1),
	};

	// Render
	return (
		<Page
			title={container?.externalId || ((<NoData />) as unknown as string)}
			subtitle={container ? messages.containerTypes[container.type] : undefined}
			/**
			 * TODO: uncomment if / when we want to re-enable editing of containers through the bridge
			 */
			/* primaryAction={
				<Link removeUnderline url={routes.editContainer(container.id)}>
					{messages.edit}
				</Link>
			} */
		>
			<Layout>
				{/* Container Details */}
				<Layout.Section>
					<Card title={messages.containerDetails} sectioned>
						<CardDetails
							loading={fetching}
							primary={[
								{
									content: messages.containerTypes[container?.type] || <NoData />,
									label: messages.containerType,
								},
								{
									content: container.name || <NoData />,
									label: messages.containerName,
								},
								{
									content: container.externalId || <NoData />,
									label: messages.externalId,
								},
								{
									content:
										!container?.description || container?.description === container?.name ? (
											<NoData />
										) : (
											container?.description
										),
									label: messages.description,
								},
								...(container.type === ContainerTypeType.Shipping
									? [
											{
												content: container?.barcode || <NoData />,
												label: messages.barcode,
											},
									  ]
									: []),
								...(container.type === ContainerTypeType.Shipping
									? [
											{
												content: (
													<Stack spacing="extraTight">
														<input type="checkbox" checked={container.enabled} disabled />
														<span>{container.enabled ? messages.active : messages.inactive}</span>
													</Stack>
												),
												label: messages.status,
											},
											{
												content: (
													<Stack spacing="extraTight">
														<input type="checkbox" checked={container.inventory} disabled />
														<span>
															{container.inventory ? messages.enabled : messages.disabled}
														</span>
													</Stack>
												),
												label: messages.trackAsInventory,
											},
									  ]
									: []),
								{
									content: container.attributes?.packageMaterialType,
									label: messages.materialType,
								},
							]}
						/>
						{container.inventory ? (
							<>
								<br />
								<Banner
									secondaryAction={{
										content: messages.viewInventoryLevels,
										url: routes.product(container.id),
									}}
									status="info"
								>
									<p>{messages.containerTracked}</p>
								</Banner>
							</>
						) : null}
					</Card>
				</Layout.Section>

				{/* Dimensions */}
				<Layout.Section secondary>
					<Card title={messages.containerDimensions}>
						<Card.Section
							title={
								containerHasSubsections ? (
									<TextStyle variation="strong">{messages.wholeContainer}</TextStyle>
								) : null
							}
						>
							<CardDetails
								primary={[
									{
										content: formatLength(wholeContainerDimensions.length),
										label: messages.length,
									},
									{
										content: formatLength(wholeContainerDimensions.width),
										label: messages.width,
									},
									{
										content: formatLength(wholeContainerDimensions.height),
										label: messages.height,
									},
									{
										content: formatVolume(wholeContainerVolume),
										label: messages.volume,
									},
									...(container?.type === ContainerTypeType.Shipping
										? [
												{
													content:
														(container?.weight || 0) > 0 ? (
															formatWeight(container?.weight)
														) : (
															<NoData />
														),
													label: messages.weight,
												},
										  ]
										: []),
								]}
							/>
						</Card.Section>

						{/* Container subsections info */}
						{containerHasSubsections ? (
							<>
								<hr style={{ width: '100%' }} />
								<Card.Section
									title={<TextStyle variation="strong">{messages.subsection}</TextStyle>}
								>
									<CardDetails
										primary={[
											{
												content: container?.columns || <NoData />,
												label: messages.columns,
											},
											{
												content: container?.rows || <NoData />,
												label: messages.rows,
											},
										]}
									/>
								</Card.Section>
								<hr />
								<Card.Section
									title={<TextStyle variation="strong">{messages.subsectionDimensions}</TextStyle>}
								>
									<CardDetails
										primary={[
											{
												content: formatLength(container?.length),
												label: messages.length,
											},
											{
												content: formatLength(container?.width),
												label: messages.width,
											},
											{
												content: formatLength(container?.height),
												label: messages.height,
											},
											{
												content: formatVolume(container.cubeVolume),
												label: messages.volume,
											},
										]}
									/>
								</Card.Section>
							</>
						) : null}
					</Card>
				</Layout.Section>
			</Layout>
		</Page>
	);
}
