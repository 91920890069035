import { JobConnection } from '@sixriver/fulfillment-api-schema';
import { gql } from 'graphql-tag';

import { PAGE_INFO_FRAGMENT } from '../../graphql';

export interface JobsData {
	jobs: JobConnection;
}

export const OUTBOUND_JOBS_QUERY = gql`
	query OutboundJobs(
		$after: String
		$first: Int
		$ids: [String!]
		$searchText: String
		$statuses: [JobStatus!]
		$isLate: Boolean
		$isShorted: Boolean
		$isAwaitingInventory: Boolean
		$isHealJob: Boolean
		$completedAtFrom: DateTime
		$completedAtTo: DateTime
		$expectedShipDateFrom: DateTime
		$expectedShipDateTo: DateTime
		$createdAtFrom: DateTime
		$pickStrategies: [PickStrategy!]
		$orderBy: [JobOrderBy!]
		$workOrderTypes: [WorkOrderType!]
		$workAreaIds: [String!]
		$usedEquipmentTypes: [String!]
	) {
		jobs(
			after: $after
			first: $first
			ids: $ids
			searchText: $searchText
			statuses: $statuses
			isLate: $isLate
			isShorted: $isShorted
			isAwaitingInventory: $isAwaitingInventory
			isHealJob: $isHealJob
			completedAtFrom: $completedAtFrom
			completedAtTo: $completedAtTo
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			createdAtFrom: $createdAtFrom
			pickStrategies: $pickStrategies
			orderBy: $orderBy
			workOrderTypes: $workOrderTypes
			workAreaIds: $workAreaIds
			isOrder: false
			usedEquipmentTypes: $usedEquipmentTypes
		) {
			edges {
				node {
					id
					externalId
					pickStrategy
					createdAt
					expectedShipDate
					externalContainerId
					status
					isLate
					isShorted
					type
					isHealJob
					isAwaitingInventory
					# mfp {
					# 	name
					# }
					totalLines {
						count
					}
					totalLineQuantities {
						completedLines
						totalUnits
						pickedUnits
					}
					eligiblePickDeviceClasses
					# usedPickDeviceClasses
					workflows {
						type
						status
					}
					orders {
						id
						isHealable
						pickingJobs {
							id
						}
					}
				}
			}
			pageInfo {
				...PageInfo
			}
		}
	}
	${PAGE_INFO_FRAGMENT}
`;
