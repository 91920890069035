import { TextStyle } from '@sixriver/lighthouse-web-community';

import { NoData } from '../../components/NoData';
import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	lines: number;
	jobs: number;
}
export function ActiveExceptions({ lines, jobs }: Props): JSX.Element {
	const { messages, translate } = useLocalization();
	if (lines > 0) {
		let text = translate(messages.countLines, { count: lines });
		if (jobs > 0) {
			text = text + `, ${translate(messages.countJobs, { count: jobs })}`;
		}
		return <TextStyle>{text}</TextStyle>;
	}

	return <NoData />;
}
