import { noop } from 'lodash';

import { ViewTabs } from '../../components/ViewTabs';
import { useLocalization } from '../../hooks/useLocalization';

export enum ExceptionsTab {
	Resolvable = 'resolvable',
	Unresolvable = 'unresolvable',
	Resolving = 'resolving',
	Resolved = 'resolved',
}

export interface ExceptionsTabsProps {
	resolvableCount: number;
	unresolvableCount: number;
	resolvingCount: number;
	resolvedTodayCount: number;
	selected: string;
	onSelect?: (tab: ExceptionsTab) => void;
}

export function ExceptionsTabs({
	resolvableCount,
	unresolvableCount,
	resolvingCount,
	resolvedTodayCount,
	selected,
	onSelect = noop,
}: ExceptionsTabsProps) {
	const { messages } = useLocalization();

	const tabs = [
		{
			id: ExceptionsTab.Resolvable,
			label: messages.resolvable,
			metaLabel: resolvableCount,
		},
		{
			id: ExceptionsTab.Unresolvable,
			label: messages.unresolvable,
			metaLabel: unresolvableCount,
		},
		{
			id: ExceptionsTab.Resolving,
			label: messages.resolving,
			metaLabel: resolvingCount,
		},
		{
			id: ExceptionsTab.Resolved,
			label: messages.resolvedToday,
			metaLabel: resolvedTodayCount,
		},
	];

	return <ViewTabs tabs={tabs} selected={selected} onSelect={onSelect} />;
}
