import { GoalStates } from '@sixriver/fulfillment-api-schema';
import { Layout, Page, Stack } from '@sixriver/lighthouse-web-community';
import type { PutawayContainer } from '@sixriver/warehouse-api-graphql-types';
import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from 'urql';

import { Details } from './Details';
import { Lines } from './Lines';
import { Progress } from './Progress';
import { PUTAWAY_JOB_QUERY } from './PutawayJob.graphql';
import { Error } from '../../components/Error';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { useLocalization } from '../../hooks/useLocalization';
import { PutawayJobStatus } from '../PutawayJobs/PutawayJobsTable/PutawayJobStatus';

export function PutawayJob() {
	const { messages } = useLocalization();

	const {
		params: { putawayJobId },
	} = useRouteMatch<{ putawayJobId: string }>();

	// query logic
	const [{ data, error, fetching }] = useQuery<{ putawayJob: PutawayContainer }>({
		context: useMemo(
			() => ({
				useWarehouseApi: true,
			}),
			[],
		),
		query: PUTAWAY_JOB_QUERY,
		variables: {
			id: putawayJobId,
		},
	});

	const { putawayJob } = data || {};

	if (error) {
		return <Error graphQLError={error} />;
	}

	if (!fetching && !putawayJob) {
		return <Error heading={messages.putawayJobNotFound} />;
	}

	return (
		<Page
			title={putawayJob?.inputs.containerLabels.default || ''}
			titleMetadata={
				<Stack spacing="extraTight">
					<PutawayJobStatus status={putawayJob?.state as GoalStates} />
					{/* {numberOfExceptions ? <Badge status="critical">{messages.exception}</Badge> : null} */}
				</Stack>
			}
		>
			<Layout>
				<Layout.Section>
					<Details putawayJob={putawayJob} />
				</Layout.Section>
				<Layout.Section>
					<Progress putawayJob={putawayJob} />
				</Layout.Section>
				<Layout.Section>
					<Lines putawayJob={putawayJob} />
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
