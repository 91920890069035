import { OrderV2Connection } from '@sixriver/fulfillment-api-schema';
import { gql } from 'graphql-tag';

import { PAGE_INFO_FRAGMENT } from '../../graphql';

export interface OrdersData {
	getOrders: OrderV2Connection;
}

export const ORDERS_QUERY = gql`
	query Orders(
		$after: String
		$first: Int
		$ids: [String!]
		$searchText: String
		$statuses: [JobStatus!]
		$equipmentTypes: [String!]
		$isLate: Boolean
		$isShorted: Boolean
		$isAwaitingInventory: Boolean
		$isServicedByHealJobs: Boolean
		$completedAtFrom: DateTime
		$completedAtTo: DateTime
		$expectedShipDateFrom: DateTime
		$expectedShipDateTo: DateTime
		$createdAtFrom: DateTime
		$pickStrategies: [PickStrategy!]
		$orderBy: [JobOrderBy!]
		$workOrderTypes: [WorkOrderType!]
		$workAreaIds: [String!]
		$jobFlowRuleLpn: String
		$bulkOrderProjectionId: String
	) {
		getOrders(
			after: $after
			first: $first
			ids: $ids
			searchText: $searchText
			statuses: $statuses
			equipmentTypes: $equipmentTypes
			isLate: $isLate
			isShorted: $isShorted
			isAwaitingInventory: $isAwaitingInventory
			isServicedByHealJobs: $isServicedByHealJobs
			completedAtFrom: $completedAtFrom
			completedAtTo: $completedAtTo
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			createdAtFrom: $createdAtFrom
			pickStrategies: $pickStrategies
			orderBy: $orderBy
			workOrderTypes: $workOrderTypes
			workAreaIds: $workAreaIds
			jobFlowRuleLpn: $jobFlowRuleLpn
			bulkOrderProjectionId: $bulkOrderProjectionId
		) {
			edges {
				node {
					id
					stagingIdentifier
					externalId
					pickStrategy
					createdAt
					expectedShipDate
					isLate
					isAwaitingInventory
					isShorted
					isHealable
					status
					lines {
						edges {
							node {
								quantity
								doneQuantity
								customer {
									name
								}
							}
						}
					}
					totalLines {
						count
					}
					totalLineQuantities {
						totalUnits
					}
					servicedBy {
						id
						stagingIdentifier
					}
					pickingJobs {
						id
						isHealJob
						lines {
							edges {
								node {
									id
									quantity
									doneQuantity
									status
									completedAt
								}
							}
						}
					}
					healJobs {
						type
						status
						pickStrategy
						createdAt
						completedAt
					}
					isTerminal
					workflows {
						type
						status
					}
				}
			}
			pageInfo {
				...PageInfo
			}
		}
	}
	${PAGE_INFO_FRAGMENT}
`;
