import { gql } from 'graphql-tag';

export const BULK_ORDER_QUERY = gql`
	query GetBulkOrder($id: String!) {
		bulkOrderById(id: $id) {
			id
			lpn
			jobFlowRuleId
			ordersCollectedCount
			ordersPackedCount
			status
			progress
			locations {
				id
				address
				description
				externalAisleId
				units
			}
			assetTypes {
				id
				externalId
				name
				description
				image
				unitsRequired
				unitsMoved
				unitsPacked
			}
			createdAt
			expectedShipDate
			merchant
			description
			updatedAt
		}
	}
`;
