import { Modal, Text } from '@sixriver/lighthouse-web-community';
import { useCallback, useState } from 'react';

import { useLocalization } from '../../../hooks/useLocalization';
import { Reservation, useReservations } from '../providers/Reservations';

interface RemoveMfaLicenseModalProps {
	onDismiss: () => void;
	open: boolean;
	reservation: Reservation;
}

export function RemoveMfaLicenseModal({
	onDismiss,
	open,
	reservation,
}: RemoveMfaLicenseModalProps) {
	const { messages } = useLocalization();

	const { expireReservation, fetchExpiredReservations } = useReservations();
	const [loading, setLoading] = useState(false);

	const onConfirm = useCallback(async () => {
		try {
			setLoading(true);
			await expireReservation(reservation);
			void fetchExpiredReservations(reservation.deviceId, 5);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e);
		} finally {
			setLoading(false);
			onDismiss();
		}
	}, [expireReservation, fetchExpiredReservations, onDismiss, reservation]);

	return (
		<Modal
			open={open}
			onClose={onDismiss}
			title={messages.mfa.license.remove.title}
			primaryAction={{
				content: messages.mfa.license.remove.confirm,
				destructive: true,
				loading,
				onAction: onConfirm,
			}}
			secondaryActions={[
				{
					content: messages.mfa.license.remove.cancel,
					onAction: onDismiss,
				},
			]}
		>
			<Modal.Section>
				<Text variant="bodyMd" as="p">
					{messages.mfa.license.remove.details}
				</Text>
				<br />
				<Text variant="bodyMd" as="p">
					{messages.mfa.license.remove.impact}
				</Text>
			</Modal.Section>
		</Modal>
	);
}
