import { Card, Link } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

interface ContainerSummaryProps {
	parentName: string;
	numSKUs?: number;
	locationId: string;
}

export function ContainerSummaryCard({ parentName, numSKUs, locationId }: ContainerSummaryProps) {
	const { messages, translate } = useLocalization();
	return (
		<Card title={messages.containerSummary}>
			<Card.Section>
				{messages.nestedWithin} <Link url={routes.location(locationId)}>{parentName}</Link>
			</Card.Section>
			<Card.Section>{translate(messages.countInUseBySkus, { count: numSKUs })}</Card.Section>
		</Card>
	);
}
