import { Count, LineQuantities } from '@sixriver/fulfillment-api-schema';
import { IndexTable, Stack } from '@sixriver/lighthouse-web-community';

import { OrderQuantityV2 } from '../../../components/OrderQuantityV2';

interface QuantityCellProps {
	totalLines: Count;
	totalLineQuantities: LineQuantities;
}

export function QuantityCell({ totalLines, totalLineQuantities }: QuantityCellProps) {
	return (
		<IndexTable.Cell>
			<Stack vertical spacing="extraTight">
				<OrderQuantityV2 totalLines={totalLines} totalLineQuantities={totalLineQuantities} />
			</Stack>
		</IndexTable.Cell>
	);
}
