import { ChoiceList } from '@sixriver/lighthouse-web-community';
import { noop } from 'lodash';

import { useIsAutoHealingEnabled, useIsExternalInventoryEnabled } from '../../../hooks/useConfig';
import { useLocalization } from '../../../hooks/useLocalization';

export enum Alert {
	Late = 'late',
	Exception = 'exception',
	AwaitingInventory = 'awaiting-inventory',
	IsResolution = 'is-resolution',
}

interface AlertsFilterProps {
	selected?: Alert[];
	onChange?: (selected: Alert[], name: string) => void;
}

export function AlertsFilter({ selected = [], onChange = noop }: AlertsFilterProps) {
	const { messages } = useLocalization();
	const isExternalInventoryEnabled = useIsExternalInventoryEnabled();
	const isAutoHealingEnabled = useIsAutoHealingEnabled();
	const choices = [
		{
			label: messages.late,
			value: Alert.Late,
		},
	];

	if (isAutoHealingEnabled) {
		choices.push({
			label: messages.resolutionJobs,
			value: Alert.IsResolution,
		});
	}

	choices.push({
		label: messages.exception,
		value: Alert.Exception,
	});

	if (isExternalInventoryEnabled) {
		choices.push({
			label: messages.awaitingInventory,
			value: Alert.AwaitingInventory,
		});
	}

	return (
		<ChoiceList
			title={messages.issues}
			titleHidden
			choices={choices}
			selected={selected}
			onChange={onChange}
			allowMultiple
		/>
	);
}
