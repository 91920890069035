import { ExternalMinor } from '@sixriver/lighthouse-icons';
import { Button } from '@sixriver/lighthouse-web-community';
import { useEffect } from 'react';

import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	target: HTMLDivElement | null;
	onToggle(fullScreen: boolean): void;
}

/**
 * This button invokes the browser's full screen mode and listens for changes
 * to the full screen state, passing the state back to the parent.
 */
export function FullScreenButton({ target, onToggle }: Props) {
	const { messages } = useLocalization();

	const handleFullScreenChange = () => onToggle(document.fullscreenElement !== null);

	useEffect(() => {
		document.addEventListener('fullscreenchange', handleFullScreenChange);

		return () => {
			document.removeEventListener('fullscreenchange', handleFullScreenChange);
		};
	});

	return (
		<Button
			plain
			monochrome
			removeUnderline
			id="fsButton"
			role="button"
			icon={ExternalMinor}
			onClick={() => void target?.requestFullscreen({ navigationUI: 'hide' })}
		>
			{messages.dashboard}
		</Button>
	);
}
