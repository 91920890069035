import { gql } from 'graphql-tag';

import { PAGE_INFO_FRAGMENT } from '../../graphql';

export const PRODUCTS_QUERY = gql`
	query Products(
		$cursor: String
		$after: String
		$limit: Float
		$first: Int
		$searchText: String
		$type: StorageLocationType
		$orderBy: AssetTypeOrderByFields
		$orderByDirection: OrderByDirection
		$kinds: [String!]
		$isLotTracked: Boolean
		$isExpirationTracked: Boolean
	) {
		products(
			cursor: $cursor
			limit: $limit
			after: $after
			searchText: $searchText
			first: $first
			type: $type
			orderBy: $orderBy
			orderByDirection: $orderByDirection
			kinds: $kinds
			isLotTracked: $isLotTracked
			isExpirationTracked: $isExpirationTracked
		) {
			edges {
				node {
					id
					customerIdentifier
					name
					description
					isContainerType
					identifiers {
						label
						order
						validationStrategy
						allowedValues
						grabQuantity
					}
					attributes
				}
			}
			pageInfo {
				...PageInfo
			}
		}
	}
	${PAGE_INFO_FRAGMENT}
`;

/**
 * this is only used if inventory is enabled so we do not need a v2 of this
 */
export const COUNTS_QUERY = gql`
	query ProductByLocationCounts($searchText: String) {
		productTypeCountsByLocationType(searchText: $searchText) {
			type
			count
		}
	}
`;
