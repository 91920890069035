import * as Sentry from '@sentry/react';
import { EmptyState, Action } from '@sixriver/lighthouse-web-community';
import { useEffect } from 'react';
import { CombinedError } from 'urql';

import { useLocalization } from '../../hooks/useLocalization';
import errorImage from '../../images/error.svg';

enum ErrorType {
	Connection,
}

interface Props {
	graphQLError?: CombinedError;
	heading?: string;
	message?: string;
	buttonText?: string;
	action?: Action;
	image?: string;
}

export function Error({ graphQLError, heading, message, action, image }: Props) {
	const { messages, translate } = useLocalization();

	const type =
		!navigator.onLine && !graphQLError?.graphQLErrors && !graphQLError?.networkError
			? ErrorType.Connection
			: undefined;

	function getHeading(type?: ErrorType): string {
		if (type === ErrorType.Connection) {
			return messages.connectionInterrupted;
		}

		return messages.problemLoadingPage;
	}

	function getMessage(type?: ErrorType): string {
		if (type === ErrorType.Connection) {
			return messages.checkNetwork;
		}

		return translate(messages.technicalProblem, {
			problem: graphQLError?.message || '?',
		}) as string;
	}

	useEffect(() => {
		if (heading || graphQLError || message) {
			Sentry.captureException({ graphQLError, heading, message });
		}
	}, [heading, graphQLError, message]);

	return (
		<EmptyState
			heading={heading || getHeading(type)}
			action={
				action || {
					content: messages.reloadPage,
					onAction: () => {
						window.location.reload();
					},
				}
			}
			image={image || errorImage}
		>
			<p style={{ wordBreak: 'break-word' }}>{message || getMessage(type)}</p>
		</EmptyState>
	);
}
