import { LineV2 } from '@sixriver/fulfillment-api-schema';
import { IndexTable, TextStyle } from '@sixriver/lighthouse-web-community';

import { NoData } from '../../NoData';

interface Props {
	line: LineV2;
}

export function AssociatesCell({ line }: Props): JSX.Element {
	const associates = line.tasks.reduce((names, task) => {
		const name = task.user?.name ?? task.user?.email ?? task.user?.badge;
		if (name) {
			return names.add(name);
		}
		return names;
	}, new Set<string>());

	if (associates.size) {
		return (
			<IndexTable.Cell>
				<TextStyle>{Array.from(associates).join(', ')}</TextStyle>
			</IndexTable.Cell>
		);
	}
	return (
		<IndexTable.Cell>
			<NoData />
		</IndexTable.Cell>
	);
}
