import { useDebouncedValue, usePrevious } from '@shopify/react-hooks';
import {
	Cutoff,
	DatePrecision,
	JobOrderBy,
	JobOrderByFields,
	MutationResponse,
	OrderByDirection,
	OrderV2Status,
	PickStrategy,
	QueryCutoffForOrdersV2Args,
	QueryOrdersV2Args,
	WorkOrderType,
} from '@sixriver/fulfillment-api-schema';
import { Page, Layout, Link, Stack, Card, Pagination } from '@sixriver/lighthouse-web-community';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'urql';

import { BulkCancelFailedBanner } from './BulkCancelFailedBanner';
import { BulkCancelInProgressBanner } from './BulkCancelInProgressBanner';
import { OrdersData, ORDERS_QUERY } from './Orders.graphql';
import { CANCEL_ORDERS_MUTATION } from './OrdersCancel.graphql';
import { OrdersCounts, ORDERS_COUNT_QUERY } from './OrdersCounts.graphql';
import { CUTOFF_DATES_QUERY } from './OrdersCutoffs.graphql';
import { OrdersFilters, Alert } from './OrdersFilters';
import { OrdersTable } from './OrdersTable';
import { OrdersTabs, OrdersTab } from './OrdersTabs';
import { OrdersToteboard } from './OrdersToteboard';
import { PickWavesLogButton } from './PickWavesLogButton';
import { AutoRefresh } from '../../components/AutoRefresh';
import { DateRangeFilter } from '../../components/DateRangeFilter';
import { OptionValues } from '../../components/DateRangeSelect';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { WorkAreaMenu } from '../../components/WorkAreaMenu';
import { getPageSize } from '../../helpers/page-size';
import { MIN_QUERY_LENGTH } from '../../helpers/table';
import { getMidnight } from '../../helpers/time';
import {
	useIsExternalInventoryEnabled,
	useIsOrderTagEnabled,
	useIsSortationEnabled,
	useIsWorkAreasEnabled,
	useJobAllocationMethods,
} from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';
import { usePickStrategies } from '../../hooks/usePickStrategies';
import { usePolling } from '../../hooks/usePolling';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import {
	useQueryState,
	useArrayQueryState,
	useSortQueryState,
	useDateQueryState,
} from '../../hooks/useQueryState';
import { useWorkAreas } from '../../hooks/useWorkAreas';
import * as routes from '../../routes';

const MIDNIGHT_TODAY = getMidnight();
const MIDNIGHT_TOMORROW = getMidnight(1);
const DEFAULT_DATE = getMidnight(-7);
const SEARCH_TEXT_KEY = 'query';
const CUTOFF_DATE_KEY = 'cutoff';
const IDS_KEY = 'ids';
const PICK_STRATEGY_KEY = 'pickStrategies';
const HISTORY_DATE_LIMITER_KEY = 'historyDateLimiter';
const PAGINATION_CURSORS_KEY = 'paginationCursors';
const EQUIPMENT_TYPES_KEY = 'equipmentTypes';
const ALERTS_KEY = 'alerts';
const STATUSES_KEY = 'statuses';
const SORT_KEY = 'sort';
const WORK_ORDER_TYPES_KEY = 'workOrderTypes';
const WORK_AREA_IDS = 'workAreaIds';
const BATCH_ID_KEY = 'batchId';
const BULK_ORDER_PROJECTION_ID_KEY = 'bulkOrderProjectionId';
const DEFAULT_SORT: JobOrderBy = {
	direction: OrderByDirection.Desc,
	field: JobOrderByFields.CreatedAt,
};

export function Orders() {
	const { messages } = useLocalization();
	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();
	const [searchText, setSearchText] = useQueryState(SEARCH_TEXT_KEY, '');
	const [selectedTab, setSelectedTab] = useState<OrdersTab>(OrdersTab.All);
	const [selectedStatuses, setSelectedStatuses] = useArrayQueryState<OrderV2Status[]>(
		STATUSES_KEY,
		[],
	);
	const [selectedAlerts, setSelectedAlerts] = useArrayQueryState<Alert[]>(ALERTS_KEY, []);
	const [selectedEquipmentTypes, setSelectedEquipmentTypes] = useArrayQueryState<string[]>(
		EQUIPMENT_TYPES_KEY,
		[],
	);
	const { data: workAreas } = useWorkAreas();
	const [selectedSort, setSelectedSort] = useSortQueryState<JobOrderBy[]>(SORT_KEY, [DEFAULT_SORT]);
	const [selectedCutoffDate, setSelectedCutoffDate] = useDateQueryState<Date | undefined>(
		CUTOFF_DATE_KEY,
		undefined,
	);
	const [selectedPickStrategies, setselectedPickStrategies] = useArrayQueryState<PickStrategy[]>(
		PICK_STRATEGY_KEY,
		[],
	);
	const [selectedWorkOrderTypes, setselectedWorkOrderTypes] = useArrayQueryState<WorkOrderType[]>(
		WORK_ORDER_TYPES_KEY,
		[],
	);
	const [paginationCursors, setPaginationCursors] = useArrayQueryState<string[]>(
		PAGINATION_CURSORS_KEY,
		[],
	);
	const [selectedWorkAreaIds, setSelectedWorkAreaIds] = useArrayQueryState<string[]>(
		WORK_AREA_IDS,
		[],
	);
	const [batchId, setBatchId] = useQueryState(BATCH_ID_KEY, undefined);
	const [bulkOrderProjectionId, setBulkOrderProjectionId] = useQueryState(
		BULK_ORDER_PROJECTION_ID_KEY,
		undefined,
	);
	const [isBulkCancelInProgress, setIsBulkCancelInProgress] = useState<boolean>(false);
	const [didBulkCancelFail, setDidBulkCancelFail] = useState<boolean>(false);
	const [historyDateLimiter, setHistoryDateLimiter] = useDateQueryState(
		HISTORY_DATE_LIMITER_KEY,
		DEFAULT_DATE,
	);
	const [ids, setIds] = useArrayQueryState<string[]>(IDS_KEY, []);
	const jobAllocationMethods = useJobAllocationMethods();
	const availablePickStrategies = usePickStrategies(jobAllocationMethods);
	const showPickStrategy = availablePickStrategies.length > 1;
	const showWorkArea = useIsWorkAreasEnabled();
	const showTag = useIsOrderTagEnabled();
	const showSortWall = useIsSortationEnabled();
	const debouncedSearchText = useDebouncedValue(searchText);
	const isExternalInventoryEnabled = useIsExternalInventoryEnabled();

	/**
	 * Queries
	 */
	const queryParamsForOrdersAndCounts: QueryOrdersV2Args = useMemo(
		() => ({
			bulkOrderProjectionId,
			createdAtFrom: historyDateLimiter,
			equipmentTypes: selectedEquipmentTypes,
			expectedShipDateFrom: selectedCutoffDate ?? undefined,
			expectedShipDateTo: selectedCutoffDate
				? new Date(selectedCutoffDate.getTime() + 60 * 1000)
				: undefined,
			ids,
			isAwaitingInventory:
				(selectedAlerts.length > 0 && selectedAlerts.includes(Alert.AwaitingInventory)) ||
				undefined,
			isLate: (selectedAlerts.length > 0 && selectedAlerts.includes(Alert.Late)) || undefined,
			isServicedByHealJobs:
				(selectedAlerts.length > 0 && selectedAlerts.includes(Alert.HasResolutions)) || undefined,
			isShorted:
				(selectedAlerts.length > 0 && selectedAlerts.includes(Alert.Exception)) || undefined,
			jobFlowRuleLpn: batchId ?? undefined,
			pickStrategies: selectedPickStrategies.length > 0 ? selectedPickStrategies : undefined,
			searchText: debouncedSearchText.length >= MIN_QUERY_LENGTH ? debouncedSearchText : undefined,
			statuses: selectedStatuses.includes(OrderV2Status.Completed)
				? [
						...selectedStatuses.filter((status) => status !== OrderV2Status.Completed),
						OrderV2Status.Picked,
						OrderV2Status.Sorted,
						OrderV2Status.Packed,
				  ]
				: selectedStatuses,
			workAreaIds: selectedWorkAreaIds.length > 0 ? selectedWorkAreaIds : undefined,
			workOrderTypes: selectedWorkOrderTypes.length > 0 ? selectedWorkOrderTypes : undefined,
		}),
		[
			debouncedSearchText,
			historyDateLimiter,
			ids,
			selectedAlerts,
			selectedEquipmentTypes,
			selectedCutoffDate,
			selectedPickStrategies,
			selectedStatuses,
			selectedWorkAreaIds,
			selectedWorkOrderTypes,
			batchId,
			bulkOrderProjectionId,
		],
	);

	const [{ data: countData }] = usePollingQuery<OrdersCounts, QueryOrdersV2Args>({
		context: useMemo(
			() => ({
				useWarehouseApi: true,
			}),
			[],
		),
		pollInterval: queryPollInterval,
		query: ORDERS_COUNT_QUERY,
		variables: {
			...queryParamsForOrdersAndCounts,
			completedAtFrom: MIDNIGHT_TODAY,
			completedAtTo: MIDNIGHT_TOMORROW,
		},
	});

	const totalCount = countData?.TotalCount.total ?? 0;
	const totalPages = Math.max(Math.ceil(totalCount / getPageSize()), 1);

	const [{ fetching: fetchingOrders, data: ordersData }] = usePollingQuery<
		OrdersData,
		QueryOrdersV2Args
	>({
		context: useMemo(
			() => ({
				useWarehouseApi: true,
			}),
			[],
		),
		pollInterval: queryPollInterval,
		query: ORDERS_QUERY,
		variables: {
			...queryParamsForOrdersAndCounts,
			after: paginationCursors[0],
			first: getPageSize(),
			orderBy: selectedSort,
		},
	});

	const [{ data: cutoffDatesData }] = usePollingQuery<
		{ cutoffForOrdersV2: Cutoff },
		QueryCutoffForOrdersV2Args
	>({
		pollInterval: queryPollInterval,
		query: CUTOFF_DATES_QUERY,
		variables: {
			createdAtFrom: historyDateLimiter,
			datePrecision: DatePrecision.Minute,
		},
	});

	const ordersPageInfo = ordersData?.getOrders.pageInfo;
	const orders = ordersData?.getOrders.edges;
	const cutoffDates = cutoffDatesData?.cutoffForOrdersV2.dates.map(
		(date) => new Date(date as string),
	);

	/**
	 * Mutations
	 * */
	// TODO: how should we handle query and mutation errors
	const [, cancelOrdersMutation] = useMutation<
		{ cancelOrders: MutationResponse },
		{ ids: string[] }
	>(CANCEL_ORDERS_MUTATION);

	/**
	 * Filter functions
	 */
	const handleStatusesRemove = useCallback(() => {
		setSelectedStatuses([]);
		setSelectedTab(OrdersTab.All);
	}, [setSelectedStatuses, setSelectedTab]);
	const handleAlertsRemove = useCallback(() => {
		setSelectedAlerts([]);
		setSelectedTab(OrdersTab.All);
	}, [setSelectedAlerts, setSelectedTab]);
	const handleEquipmentTypesRemove = useCallback(() => {
		setSelectedEquipmentTypes([]);
		setSelectedTab(OrdersTab.All);
	}, [setSelectedEquipmentTypes, setSelectedTab]);
	const handleCutoffDateRemove = useCallback(() => {
		setSelectedCutoffDate(undefined);
	}, [setSelectedCutoffDate]);
	const handlePickStrategyRemove = useCallback(() => {
		setselectedPickStrategies([]);
	}, [setselectedPickStrategies]);
	const handleWorkOrderTypeRemove = useCallback(() => {
		setselectedWorkOrderTypes([]);
	}, [setselectedWorkOrderTypes]);
	const handleIdsRemove = useCallback(() => {
		setIds([]);
	}, [setIds]);
	const handleWorkAreaRemove = useCallback(() => {
		setSelectedWorkAreaIds([]);
	}, [setSelectedWorkAreaIds]);
	const handleBatchIdRemove = useCallback(() => {
		setBatchId(undefined);
		setBulkOrderProjectionId(undefined);
	}, [setBatchId, setBulkOrderProjectionId]);

	const handleClearAllFilters = useCallback(() => {
		setSelectedStatuses([]);
		setSelectedAlerts([]);
		setSelectedEquipmentTypes([]);
		setSelectedCutoffDate(undefined);
		setselectedPickStrategies([]);
		setselectedWorkOrderTypes([]);
		// TODO should this clear cutoff date filter when switching tabs?
		// TODO should this clear pick strategy filter when switching tabs?
	}, [
		setSelectedAlerts,
		setSelectedEquipmentTypes,
		setSelectedCutoffDate,
		setSelectedStatuses,
		setselectedPickStrategies,
		setselectedWorkOrderTypes,
	]);

	const handleChangeHistoryLimiter = useCallback(
		(date: Date) => {
			// clearing cutoff filter because the list is dynamically determined by history limitor range
			handleCutoffDateRemove();
			setHistoryDateLimiter(date);
		},
		[handleCutoffDateRemove, setHistoryDateLimiter],
	);

	useEffect(() => {
		// don't do this here! it prevents linking into this page --a.m.
		// handleClearAllFilters();

		switch (selectedTab) {
			case OrdersTab.AwaitingInventory:
				setSelectedAlerts([Alert.AwaitingInventory]);
				return;
			case OrdersTab.Late:
				setSelectedAlerts([Alert.Late]);
				return;
			case OrdersTab.Exceptions:
				setSelectedAlerts([Alert.Exception]);
				return;
			case OrdersTab.Interrupted:
				setSelectedStatuses([OrderV2Status.Interrupted]);
				return;
		}
	}, [selectedTab, setSelectedAlerts, setSelectedStatuses]);

	/**
	 * Pagination
	 * */
	const handleOnNextPage = useCallback(() => {
		window.scrollTo(0, 0);
		const endCursor = ordersPageInfo?.endCursor ?? undefined;
		if (endCursor) {
			setPaginationCursors([endCursor].concat(paginationCursors));
		}
	}, [ordersPageInfo?.endCursor, paginationCursors, setPaginationCursors]);

	const handleOnPreviousPage = useCallback(() => {
		window.scrollTo(0, 0);
		setPaginationCursors(paginationCursors.slice(1));
	}, [paginationCursors, setPaginationCursors]);

	// Previous filters
	const prevQueryParams = usePrevious(queryParamsForOrdersAndCounts);
	useEffect(() => {
		// Reset pagination when changing views or filters
		if (!isEqual(prevQueryParams, queryParamsForOrdersAndCounts)) {
			setPaginationCursors([]);
		}
	}, [prevQueryParams, queryParamsForOrdersAndCounts, setPaginationCursors]);

	/**
	 * Bulk methods
	 */
	const submitBulkCancelRequest = useCallback(
		async (selectedOrderIds: string[]) => {
			const { error, data } = await cancelOrdersMutation({ ids: selectedOrderIds });

			// Error
			if (error || !data?.cancelOrders?.success) {
				setIsBulkCancelInProgress(false);
				setDidBulkCancelFail(true);
			}

			// Success
			if (!error && data?.cancelOrders?.success) {
				setDidBulkCancelFail(false);
			}
		},
		[cancelOrdersMutation],
	);

	return (
		<>
			<Page
				fullWidth
				title={messages.orders}
				primaryAction={
					showWorkArea ? (
						<WorkAreaMenu
							workAreas={workAreas}
							selectedIds={selectedWorkAreaIds || []}
							onChange={(selected: string[]) => {
								setSelectedWorkAreaIds(selected);
							}}
						/>
					) : null
				}
			>
				<Layout>
					<Layout.Section>
						<Stack distribution="equalSpacing">
							<DateRangeFilter
								options={[
									OptionValues.today,
									OptionValues.last3Days,
									OptionValues.last7Days,
									OptionValues.last30Days,
									OptionValues.last90Days,
									OptionValues.last180Days,
								]}
								selectedOption={historyDateLimiter}
								title={messages.receivedAt}
								onChange={handleChangeHistoryLimiter}
								message={messages.confirmOrderHistoryLength}
							/>

							<Stack distribution="trailing">
								<PickWavesLogButton />

								<Link monochrome url={routes.actionLog()}>
									{messages.actionLog}
								</Link>

								<AutoRefresh
									pollingEnabled={pollingEnabled}
									togglePolling={togglePolling}
									discriminatorData={{}}
								/>
							</Stack>
						</Stack>
					</Layout.Section>

					<Layout.Section>
						<OrdersToteboard
							totalCount={countData?.StaticTotalCount.count}
							unassignedCount={countData?.StaticUnassignedCount.count}
							inProgressCount={
								(countData?.StaticReadyCount.count || 0) +
								(countData?.StaticInProgressCount.count || 0)
							}
							completedCount={countData?.StaticCompletedCount.count}
							cancelledCount={countData?.StaticCanceledCount.count}
							interruptedCount={countData?.StaticInterruptedCount.count}
							assignedToBatchCount={0}
							assignedToSortWallCount={countData?.StaticAssignedToSortWallCount.count}
						/>
					</Layout.Section>

					<Layout.Section>
						<Card>
							<div style={{ paddingBottom: '2rem' }}>
								<OrdersTabs
									tabs={
										[
											OrdersTab.All,
											OrdersTab.Late,
											OrdersTab.Exceptions,
											...(isExternalInventoryEnabled ? [OrdersTab.AwaitingInventory] : []),
										] as OrdersTab[]
									}
									lateCount={countData?.TotalCount.late ?? 0}
									exceptionsCount={countData?.TotalCount.shorted ?? 0}
									awaitingInventoryCount={countData?.TotalCount.awaitingInventory ?? 0}
									interruptedCount={countData?.TotalCount.interrupted ?? 0}
									selected={selectedTab}
									onSelect={setSelectedTab}
								/>
							</div>

							<div style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
								<OrdersFilters
									queryValue={searchText}
									onClearAll={handleClearAllFilters}
									onQueryChange={setSearchText}
									selectedSort={selectedSort}
									onSortChange={setSelectedSort}
									selectedCutoffDate={selectedCutoffDate}
									cutoffDates={cutoffDates}
									onCutoffChange={setSelectedCutoffDate}
									onCutoffRemove={handleCutoffDateRemove}
									selectedStatuses={selectedStatuses}
									onStatusChange={setSelectedStatuses}
									onStatusRemove={handleStatusesRemove}
									selectedAlerts={selectedAlerts}
									onAlertChange={setSelectedAlerts}
									onAlertRemove={handleAlertsRemove}
									selectedEquipmentTypes={selectedEquipmentTypes}
									onEquipmentTypesChange={setSelectedEquipmentTypes}
									onEquipmentTypesRemove={handleEquipmentTypesRemove}
									showPickStrategy={showPickStrategy}
									selectedPickStrategies={selectedPickStrategies}
									availablePickStrategies={availablePickStrategies}
									onPickStrategyChange={setselectedPickStrategies}
									onPickStrategyRemove={handlePickStrategyRemove}
									selectedWorkOrderTypes={selectedWorkOrderTypes}
									onWorkOrderTypeChange={setselectedWorkOrderTypes}
									onWorkOrderTypeRemove={handleWorkOrderTypeRemove}
									ids={ids}
									onIdsRemove={handleIdsRemove}
									selectedWorkAreaIds={selectedWorkAreaIds}
									workAreas={workAreas}
									onWorkAreaRemove={handleWorkAreaRemove}
									batchId={batchId}
									onBatchIdRemove={handleBatchIdRemove}
								/>
							</div>

							<BulkCancelInProgressBanner
								isVisible={isBulkCancelInProgress}
								onDismiss={() => setIsBulkCancelInProgress(false)}
							/>
							<BulkCancelFailedBanner
								isVisible={didBulkCancelFail}
								onDismiss={() => setDidBulkCancelFail(false)}
							/>

							<div style={{ padding: '1rem' }}>
								{`${totalCount} ${
									totalCount === 1 ? messages.result.toLowerCase() : messages.results.toLowerCase()
								}`}
							</div>

							<OrdersTable
								loading={fetchingOrders}
								data={orders}
								showPickStrategyColumn={showPickStrategy}
								showOrderTagColumn={showTag}
								showWorkAreaColumn={showWorkArea}
								pageInfo={ordersPageInfo}
								showSortWallColumn={showSortWall}
								onBulkCancel={submitBulkCancelRequest}
							/>
							<Card.Section>
								<Stack distribution="center">
									<Pagination
										label={`${paginationCursors.length + 1} ${messages.of} ${totalPages}`}
										hasNext={ordersPageInfo?.hasNextPage}
										hasPrevious={ordersPageInfo?.hasPreviousPage}
										onNext={handleOnNextPage}
										onPrevious={handleOnPreviousPage}
									/>
								</Stack>
							</Card.Section>
						</Card>
					</Layout.Section>

					<Layout.Section>
						<TimezoneFooter />
					</Layout.Section>
				</Layout>
			</Page>
		</>
	);
}
