import { noop } from 'lodash';

import { ViewTabs, Tab } from '../../components/ViewTabs';
import { useLocalization } from '../../hooks/useLocalization';

export enum OrdersTab {
	All = 'all',
	Late = 'late',
	Exceptions = 'exceptions',
	AwaitingInventory = 'awaiting-inventory',
	Interrupted = 'interrupted',
}

export interface OrdersTabsProps {
	tabs: OrdersTab[];
	lateCount: number;
	exceptionsCount: number;
	awaitingInventoryCount: number;
	interruptedCount: number;
	selected: string;
	onSelect?: (tab: OrdersTab) => void;
}

export function OrdersTabs({
	tabs,
	lateCount,
	exceptionsCount,
	awaitingInventoryCount,
	interruptedCount,
	selected,
	onSelect = noop,
}: OrdersTabsProps) {
	const { messages } = useLocalization();
	const showTabs = tabs
		.map((tab) => {
			switch (tab) {
				case OrdersTab.All:
					return {
						id: OrdersTab.All,
						label: messages.all,
					};
				case OrdersTab.Late:
					return {
						id: OrdersTab.Late,
						label: messages.late,
						metaLabel: lateCount,
					};
				case OrdersTab.Exceptions:
					return {
						id: OrdersTab.Exceptions,
						label: messages.exceptions,
						metaLabel: exceptionsCount,
					};
				case OrdersTab.AwaitingInventory:
					return {
						id: OrdersTab.AwaitingInventory,
						label: messages.awaitingInventory,
						metaLabel: awaitingInventoryCount,
					};
				case OrdersTab.Interrupted:
					return {
						id: OrdersTab.Interrupted,
						label: messages.interrupted,
						metaLabel: interruptedCount,
					};
				default:
					return null;
			}
		})
		.filter((tab) => !!tab);

	return <ViewTabs tabs={showTabs as Tab[]} selected={selected} onSelect={onSelect} />;
}
