import { AlertMinor } from '@sixriver/lighthouse-icons';
import { Badge, Icon } from '@sixriver/lighthouse-web-community';

import styles from './SpecialProjects.module.css';
import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	showIcon?: boolean;
}

export function IssuesBadge({ showIcon }: Props) {
	const { messages } = useLocalization();

	return showIcon ? (
		<div className={styles.iconBadgeContainer}>
			<Icon source={AlertMinor} color="critical" />
		</div>
	) : (
		<Badge status="critical">{messages.issues}</Badge>
	);
}
