import { BulkOrder } from '@sixriver/fulfillment-api-schema';
import { Card, EmptySearchResult, Link, Stack } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../../hooks/useLocalization';
import * as routes from '../../../routes';
import styles from '../Cards.module.css';

interface Props {
	order?: BulkOrder;
}

export function StagingLocations({ order }: Props): JSX.Element {
	const { messages } = useLocalization();

	return (
		<Card title={messages.stagingLocations}>
			<Card.Section>
				<Stack vertical={false} distribution={'fillEvenly'}>
					<p className={styles.header}>{messages.location}</p>
					<p className={styles.header}>{messages.units}</p>
				</Stack>
			</Card.Section>
			{!order?.locations.length ? (
				<Card.Section>
					<EmptySearchResult
						title={messages.locationsEmptyTitle}
						description={messages.bulkOrderWorkNotStarted}
					/>
				</Card.Section>
			) : (
				order?.locations.map((location) => (
					<>
						<Card.Section>
							<Stack vertical={false} distribution={'fillEvenly'}>
								<Link url={routes.location(location.id)} removeUnderline>
									{location.address}
								</Link>
								<p>{location.units}</p>
							</Stack>
						</Card.Section>
					</>
				))
			)}
		</Card>
	);
}
