import { Error } from '../../components/Error';
import { InlineFrame } from '../../components/InlineFrame';
import { useConfig } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';

export function Count() {
	const { messages } = useLocalization();
	const { config } = useConfig();

	if (config?.countJobsUrl) {
		return <InlineFrame title={messages.dashCountJobs} src={config.countJobsUrl} />;
	}

	return <Error />;
}
