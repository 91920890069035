import { useDebouncedValue } from '@shopify/react-hooks';
import { StorageLocationType, OrderByDirection } from '@sixriver/fulfillment-api-schema';
import { AddMajor } from '@sixriver/lighthouse-icons';
import { Button, Page, Layout, Stack, Icon } from '@sixriver/lighthouse-web-community';
import { UserRole, useAuth } from '@sixriver/react-support';
import { AssetTypeConnection, AssetTypeOrderByFields } from '@sixriver/warehouse-api-graphql-types';
import { useMemo, useState } from 'react';

import { PRODUCTS_QUERY } from './Products.graphql';
import { ProductsTable } from './ProductsTable';
import { AutoRefresh } from '../../components/AutoRefresh';
import { Error } from '../../components/Error';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { getPageSize } from '../../helpers/page-size';
import { MIN_QUERY_LENGTH } from '../../helpers/table';
import { useFilters, useSetFilters } from '../../hooks/useFilters';
import { useLocalization } from '../../hooks/useLocalization';
import { usePolling } from '../../hooks/usePolling';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import * as routes from '../../routes';

const PRODUCT_TYPE = 'productType';
const SKU_ATTRIBUTES = 'skuAttributes';
enum SkuAttributesOptionValues {
	'lotTracked' = 'lotTracked',
	'expirationTracked' = 'expirationTracked',
}

export function Products() {
	const { messages } = useLocalization();

	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	const defaultSort = AssetTypeOrderByFields.ExternalId + ' ' + OrderByDirection.Asc;

	const {
		view,
		query,
		sort = defaultSort,
		productType = 'all',
		[SKU_ATTRIBUTES]: skuAttributes,
	} = useFilters([PRODUCT_TYPE, SKU_ATTRIBUTES]);
	const selectedSkuAttributes = skuAttributes ? skuAttributes.split(' ') : undefined;

	const setFilters = useSetFilters();

	const searchText = useDebouncedValue(query) || '';

	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();

	const [{ fetching: productsFetching, data: productsData, error: productsError }] =
		usePollingQuery<{ products: AssetTypeConnection }>({
			context: useMemo(
				() => ({
					useWarehouseApi: true,
				}),
				[],
			),
			pollInterval: queryPollInterval,
			query: PRODUCTS_QUERY,
			variables: {
				after: paginationCursors[0],
				cursor: paginationCursors[0],
				first: getPageSize(),

				isExpirationTracked: selectedSkuAttributes?.includes(
					SkuAttributesOptionValues.expirationTracked,
				),

				isLotTracked: selectedSkuAttributes?.includes(SkuAttributesOptionValues.lotTracked),

				// Expected args for endpoint /v1/inventory-states/asset-location-type-summary for param containerTypeType
				// See fulfillment-api arg kinds in ProductSummary.args.ts for description
				kinds: productType === 'all' ? undefined : productType === 'shipping' ? ['shipping'] : [''],

				orderBy: sort ? (sort.split(' ')[0] as AssetTypeOrderByFields) : undefined,

				orderByDirection: sort ? (sort.split(' ')[1] as OrderByDirection) : undefined,
				searchText: searchText.length >= MIN_QUERY_LENGTH ? searchText : undefined,
				type: view === 'all' ? undefined : (view as StorageLocationType),
			},
		});

	const { isUserAllowed } = useAuth();

	const sorts = [
		// Legacy used "Item" in the UI and "CustomerIdentifier" in the GQL for externalId
		{
			label: messages.sortOptions.itemAsc,
			value: AssetTypeOrderByFields.ExternalId + ' ' + OrderByDirection.Asc,
		},
		{
			label: messages.sortOptions.itemDesc,
			value: AssetTypeOrderByFields.ExternalId + ' ' + OrderByDirection.Desc,
		},
		// Legacy used "Identifier" as the UI text for sorting by name
		{
			label: messages.sortOptions.nameAsc,
			value: AssetTypeOrderByFields.Name + ' ' + OrderByDirection.Asc,
		},
		{
			label: messages.sortOptions.nameDesc,
			value: AssetTypeOrderByFields.Name + ' ' + OrderByDirection.Desc,
		},
		{
			label: messages.sortOptions.descriptionAsc,
			value: AssetTypeOrderByFields.Description + ' ' + OrderByDirection.Asc,
		},
		{
			label: messages.sortOptions.descriptionDesc,
			value: AssetTypeOrderByFields.Description + ' ' + OrderByDirection.Desc,
		},
	];

	return productsError ? (
		<Error graphQLError={productsError} />
	) : (
		<Page fullWidth title={messages.products}>
			<Layout>
				<Layout.Section>
					<Stack distribution="trailing" alignment="trailing" spacing="extraLoose">
						<Button
							plain
							monochrome
							removeUnderline
							disabled={!isUserAllowed([UserRole.Admin])}
							icon={<Icon source={AddMajor} />}
							url={routes.addProduct()}
						>
							{messages.addProduct}
						</Button>

						<AutoRefresh
							pollingEnabled={pollingEnabled}
							togglePolling={togglePolling}
							discriminatorData={productsData?.products}
						/>
					</Stack>
				</Layout.Section>
				<Layout.Section>
					<ProductsTable
						loading={productsFetching}
						data={productsData?.products}
						paginationCursors={paginationCursors}
						setPaginationCursors={setPaginationCursors}
						setFilters={setFilters}
						query={query}
						selectedView={(view as StorageLocationType) || 'all'}
						sorts={sorts}
						selectedSort={sort}
					/>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
