import { Badge, Link, Select, Stack } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../../hooks/useLocalization';
import { useMfas } from '../providers/Mfas';
import { useReservations } from '../providers/Reservations';
import { useResources } from '../providers/Resources';
import * as mfaRoutes from '../routes';

interface ResourceSelectorProps {
	resource: string;
	setResource: (resource: string) => void;
}

export function ResourceSelector({ resource, setResource }: ResourceSelectorProps) {
	const { resources } = useResources();
	const { reservations } = useReservations();
	const { mfas } = useMfas();
	const { messages, translate } = useLocalization();

	const resourceOptions = resources
		.filter((resource) => resource.type === 'vhandheld')
		.map((r) => ({ label: r.name, value: r.id }))
		// If a resource already has a reservation, add a note to the label
		.map((r) => {
			const reservation = reservations.find((res) => res.resource.id === r.value);
			if (reservation) {
				return {
					...r,
					label: `⚠ ${r.label}`,
				};
			}
			return r;
		})
		.sort((a, b) => a.label.localeCompare(b.label));

	const currentReservation = reservations.find((res) => res.resource.id === resource);
	const currentMfa = mfas.find((mfa) => mfa.deviceId === currentReservation?.deviceId);

	return (
		<Stack vertical spacing="tight">
			<Select
				placeholder={messages.mfas.assignLicenseModal.resource.placeholder}
				label={messages.mfas.assignLicenseModal.resource.label}
				options={resourceOptions}
				onChange={setResource}
				value={resource}
			/>
			{resource && currentReservation ? (
				<span>
					<Badge status="warning">{messages.mfas.assignLicenseModal.resource.warning.badge}</Badge>
					{translate(messages.mfas.assignLicenseModal.resource.warning.message, {
						link: <Link url={mfaRoutes.mfa(currentMfa?.deviceId)}>{currentMfa?.name}</Link>,
					})}
				</span>
			) : null}
		</Stack>
	);
}
