import { IndexTable } from '@sixriver/lighthouse-web-community';
import type { PutawayConnection } from '@sixriver/warehouse-api-graphql-types';

import { useLocalization } from '../../../hooks/useLocalization';

export function UnitsCell({
	lines,
}: {
	lines: PutawayConnection['edges'][number]['node']['lines'];
}) {
	const { messages, translate } = useLocalization();

	// reduce lines, track completedCount and totalCount per line
	const totalCompletedCount = lines.reduce((acc: number, line) => {
		return acc + line.outputs.totalCompleted;
	}, 0);

	const totalTotalCount = lines.reduce((acc: number, line) => {
		return acc + line.inputs.amount;
	}, 0);

	return (
		<IndexTable.Cell>
			{translate(messages.xOfY, {
				x: totalCompletedCount,
				y: totalTotalCount,
			})}
		</IndexTable.Cell>
	);
}
