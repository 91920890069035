import { Card } from '@sixriver/lighthouse-web-community';
import { useState } from 'react';

import { useLocalization } from '../../../hooks/useLocalization';
import { AssignLicenseModal } from '../AssignLicense/AssignLicense';

interface NoLicenseProps {
	mfaId: string;
}

export function NoLicense({ mfaId }: NoLicenseProps) {
	const { messages } = useLocalization();

	const [assignLicenseOpen, setAssignLicenseOpen] = useState(false);

	return (
		<>
			<AssignLicenseModal
				mfaId={mfaId}
				open={assignLicenseOpen}
				onDismiss={() => setAssignLicenseOpen(false)}
			/>
			<Card
				title={messages.mfa.license.title}
				primaryFooterAction={{
					content: messages.mfa.license.add,
					onAction: () => setAssignLicenseOpen(true),
				}}
			>
				<Card.Section>{messages.mfa.license.noLicenses}</Card.Section>
			</Card>
		</>
	);
}
