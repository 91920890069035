import { Listbox, Tooltip } from '@sixriver/lighthouse-web-community';
import { useMemo } from 'react';

import styles from './ChuckJobsProgressBar.module.css';
import { StatusCounts } from '../../hooks/useJobsCounts';
import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	counts: StatusCounts;
}

export function ChuckJobsProgressBar({ counts }: Props) {
	const { messages } = useLocalization();
	const total = counts[messages.total] || 0;
	const statusMap = useMemo(() => {
		return {
			[messages.readyToSort]: { color: '#E8548F' },
			[messages.readyToPack]: { color: '#D100D1' },
			[messages.picking]: { color: 'green' },
			[messages.sorting]: { color: '#DC1E6A' },
			[messages.packing]: { color: '#BD00BD' },
			[messages.picked]: { color: 'blue' },
			[messages.sorted]: { color: '#B81959' },
			[messages.packed]: { color: '#750075' },
			[messages.canceled]: { color: 'red' },
			[messages.unknown]: { color: '#949489' },
		};
	}, [
		messages.packed,
		messages.picked,
		messages.picking,
		messages.sorted,
		messages.readyToSort,
		messages.readyToPack,
		messages.sorting,
		messages.packing,
		messages.canceled,
		messages.unknown,
	]);

	let progressBarLegend: { status: string; color: string }[] = [];
	progressBarLegend = counts
		? Object.entries(counts)
				.filter(([k, v]) => k !== messages.total && v > 0)
				.map(([k]) => [{ color: statusMap[k]?.color, status: k }])
				.flat()
		: [];

	return (
		<>
			{counts && counts[messages.total] > 0 && (
				<Tooltip
					content={
						<Listbox>
							{progressBarLegend.map(({ color, status }) => {
								return (
									<div key={status} className={styles.legendKeyValues}>
										<div style={{ backgroundColor: color, height: '10px', width: '10px' }} />
										<div>{`${counts[status]} ${status}`}</div>
									</div>
								);
							})}
						</Listbox>
					}
					preferredPosition="below"
				>
					<div className={styles.multiProgressBar}>
						{Object.entries(counts)
							.filter(([k, v]) => k !== messages.total && v > 0)
							.map(([k, v]: any) => {
								return (
									<div
										key={k}
										className={styles.progressBar}
										style={{
											backgroundColor: statusMap[k].color,
											minWidth: '3ch',
											width: `${(v / total) * 100}%`,
										}}
									>
										{`${Math.round((v / total) * 100)}%`}
									</div>
								);
							})}
					</div>
				</Tooltip>
			)}
		</>
	);
}
