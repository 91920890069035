import { Job } from '@sixriver/fulfillment-api-schema';
import { IndexTable } from '@sixriver/lighthouse-web-community';

import { OutboundJobStatusBadgeV2 } from '../../../components/OutboundJobStatusBadge/OutboundJobStatusBadgeV2';

interface StatusCellProps {
	job: Job;
}

export function StatusCell({ job }: StatusCellProps) {
	return (
		<IndexTable.Cell>
			<OutboundJobStatusBadgeV2 job={job} />
		</IndexTable.Cell>
	);
}
