import { WorkOrderProject as WorkOrderProjectAPI } from '@sixriver/fulfillment-api-schema';

import { Product } from './Product';
import { Column, DataTable } from '../../components/DataTable';
import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	loading?: boolean;
	workOrder: WorkOrderProjectAPI;
}

export function WorkOrderProject({ loading, workOrder }: Props): JSX.Element | null {
	const { messages } = useLocalization();

	const columns: Column[] = [
		{ heading: messages.sku, type: 'text' },
		{ heading: messages.quantity, type: 'text' },
	];

	const rows =
		workOrder.products?.map((component) => {
			return [<Product key={`${component.id}-part`} product={component} />, component.eachQuantity];
		}) || [];

	return rows.length > 0 ? (
		<DataTable title={messages.products} loading={loading} columns={columns} rows={rows} />
	) : null;
}
