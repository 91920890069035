import { Modal, TextContainer } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
	loading?: boolean;
}

export function CloseBulkOrderModal({ isOpen, onClose, onConfirm, loading }: Props) {
	const { messages } = useLocalization();

	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			title={messages.closeBatch}
			primaryAction={{
				content: messages.closeBatch,
				loading,
				onAction: onConfirm,
			}}
			secondaryActions={[
				{
					content: messages.cancel,
					onAction: onClose,
				},
			]}
		>
			<Modal.Section>
				<TextContainer>
					<p>{messages.confirmBulkOrderCloseBatch}</p>
				</TextContainer>
			</Modal.Section>
		</Modal>
	);
}
