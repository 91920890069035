import { Button, Image, Stack } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../hooks/useLocalization';
import { transformImageUrl } from '../../utils';

interface Props {
	file?: File;
	imgURL?: string;
	validImageTypes?: string[];
	onRemove: () => void;
}

export function UploadedImage({
	file,
	validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/svg', 'image/jpg'],
	imgURL,
	onRemove,
}: Props) {
	const { messages } = useLocalization();

	const imgSrc = file
		? validImageTypes.includes(file.type)
			? window.URL.createObjectURL(file)
			: undefined
		: transformImageUrl(imgURL);

	// guards
	if (!imgSrc) {
		return null;
	}

	// render
	return (
		<Stack vertical alignment="center" spacing="loose">
			<Image width={200} alt={file?.name || ''} source={imgSrc} />
			<Button onClick={onRemove}>{messages.remove}</Button>
		</Stack>
	);
}
