import { gql } from 'graphql-tag';

export const CANCEL_JOB_MUTATION = gql`
	mutation CancelJob($projectionId: String!) {
		cancelSortWall(projectionId: $projectionId) {
			success
		}
	}
`;

export const REPRINT_LABELS_MUTATION = gql`
	mutation ReprintLabels($projectionId: String!) {
		printSortationLabels(projectionId: $projectionId) {
			success
		}
	}
`;

export const SORT_WALL_QUERY = gql`
	query GetSortWall($id: String!) {
		job(id: $id) {
			id
			status
			batchId
			expectedShipDate
			isShorted
			wall
			totalLineQuantities {
				pickedUnits
				inProgressUnits
				unassignedUnits
				completedLines
				totalUnits
				unassignedUnits
			}
			totalLines {
				count
			}
			lines {
				edges {
					node {
						id
						status
						quantity
						doneQuantity
						# storageLocation {
						# 	id
						# 	externalAisleId
						# 	address
						# }
						productId
						productName
						productDescription
						productCustomerIdentifier
					}
				}
			}
			servicing {
				id
			}
			servicedBy {
				id
			}
			sortations {
				edges {
					node {
						mfpId
						userId
						createdAt
						updatedAt
						completedAt
						wallDisplayId
						wallExternalId
					}
				}
			}
		}
	}
`;

// export const SORT_WALL_QUERY = gql`
// 	query GetSortWall($id: String!) {
// 		job(id: $id) {
// 			id
// 			status
// 			# wall
// 			batchId
// 			# kiosk
// 			# isCancelable
// 			expectedShipDate
// 			# takeoffDestination
// 			isShorted
// 			createdAt
// 			updatedAt
// 			# timeline {
// 			# 	createdAt
// 			# 	updatedAt
// 			# 	completedAt
// 			# 	__typename
// 			# }
// 			totalLineQuantities {
// 				pickedUnits
// 				inProgressUnits
// 				unassignedUnits
// 				completedLines
// 				totalUnits
// 				unassignedUnits
// 			}
// 			totalLines {
// 				count
// 			}
// 			lines {
// 				id
// 				status
// 				quantity
// 				doneQuantity
// storageLocation {
// 	id
// 	externalAisleId
// 	address
// }
// productId
// productName
// productDescription
// productCustomerIdentifier
// completedAt
// 				__typename
// 			}
// 			linesExceptionsCount {
// 				count
// 			}
// 			pickingJobs {
// 				id
// 				status
// 				isShorted
// 				# exceptions {
// 				# 	id
// 				# 	availableHealStrategies
// 				# 	previousHealStrategies
// 				# }
// 				lines {
// 					id
// 					status
// 					quantity
// 					doneQuantity
// 					completedAt
// 					workArea {
// 						id
// 						name
// 					}
// 					storageLocation {
// 						id
// 						workArea {
// 							id
// 						}
// 						address
// 						__typename
// 					}
// 					__typename
// 				}
// 				totalLines {
// 					count
// 				}
// 				totalLineQuantities {
// 					pickedUnits
// 					completedLines
// 					totalUnits
// 				}
// 				externalContainerId
// 				createdAt
// 				# mfp {
// 				# 	name
// 				# 	__typename
// 				# }
// 				# packout {
// 				# 	packStation {
// 				# 		name
// 				# 		__typename
// 				# 	}
// 				# 	__typename
// 				# }
// 				__typename
// 			}
// 			pickingJobsExceptionsCount {
// 				count
// 			}
// 			servicing {
// 				id
// 				type
// 				status
// 				externalId
// 				externalContainerId
// 				# slot
// 				expectedShipDate
// 				createdAt
// 				lines {
// 					id
// 					status
// 					quantity
// 					doneQuantity
// 					completedAt
// 					customer {
// 						id
// 						name
// 					}
// 					__typename
// 				}
// 				# exceptions {
// 				# 	id
// 				# 	isHealable
// 				# 	availableHealStrategies
// 				# 	previousHealStrategies
// 				# }
// 				__typename
// 			}
// 			__typename
// 		}
// 	}
// `;
