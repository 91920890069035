import { Toteboard } from '../../components/Toteboard';
import { useIsPackoutEnabled, useIsSortationEnabled } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';

export interface OrdersToteboardProps {
	totalCount?: number;
	unassignedResolutionCount?: number;
	pickingCount?: number;
	pickedCount?: number;
	readyToPackCount?: number;
	packingCount?: number;
	packedCount?: number;
	interruptedCount?: number;
	readyToSortCount?: number;
	sortingCount?: number;
	sortededCount?: number;
}

export function OutboundJobsToteboard({
	totalCount = 0,
	pickingCount = 0,
	pickedCount = 0,
	readyToPackCount = 0,
	packingCount = 0,
	packedCount = 0,
	interruptedCount = 0,
	readyToSortCount = 0,
	sortingCount = 0,
	sortededCount = 0,
}: OrdersToteboardProps) {
	const { messages, formatNumber } = useLocalization();

	const isSortEnabled = useIsSortationEnabled();
	const isPackoutEnabled = useIsPackoutEnabled();
	const toteboardItems = [
		{
			label: messages.totalJobs,
			primaryValue: <span>{formatNumber(totalCount)}</span>,
		},
		{
			label: messages.picking,
			primaryValue: <span>{formatNumber(pickingCount)}</span>,
		},
		{
			label: messages.picked,
			primaryValue: <span>{formatNumber(pickedCount)}</span>,
		},
		...(isSortEnabled
			? [
					{
						label: messages.readyToSort,
						primaryValue: <span>{formatNumber(readyToSortCount)}</span>,
					},
					{
						label: messages.sorting,
						primaryValue: <span>{formatNumber(sortingCount)}</span>,
					},
					{
						label: messages.sorted,
						primaryValue: <span>{formatNumber(sortededCount)}</span>,
					},
			  ]
			: []),
		...(isPackoutEnabled
			? [
					{
						label: messages.readyToPack,
						primaryValue: <span>{formatNumber(readyToPackCount)}</span>,
					},
					{
						label: messages.packing,
						primaryValue: <span>{formatNumber(packingCount)}</span>,
					},
					{
						label: messages.packed,
						primaryValue: <span>{formatNumber(packedCount)}</span>,
					},
			  ]
			: []),
		{
			label: messages.interrupted,
			primaryValue: <span>{formatNumber(interruptedCount)}</span>,
		},
	];

	return <Toteboard items={toteboardItems} />;
}
