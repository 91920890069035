import {
	MutationCancelSortWallArgs,
	MutationPrintSortationLabelsArgs,
	QuerySortationByJobIdArgs,
	Sortation,
} from '@sixriver/fulfillment-api-schema';
import { PrintMajor } from '@sixriver/lighthouse-icons';
import { Page, Layout, Modal, Stack, TextContainer } from '@sixriver/lighthouse-web-community';
import { useMemo, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useMutation } from 'urql';

import { SORT_WALL_QUERY, CANCEL_JOB_MUTATION, REPRINT_LABELS_MUTATION } from './SortWall.graphql';
import { SortWallBreakdown } from './SortWallBreakdown';
import { SortWallDetails } from './SortWallDetails';
import { AutoRefresh } from '../../components/AutoRefresh';
import { Error } from '../../components/Error';
import { ErrorBanner } from '../../components/ErrorBanner';
import { SortationStatusBadge } from '../../components/SortationStatusBadge';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { useLocalization } from '../../hooks/useLocalization';
import { usePolling } from '../../hooks/usePolling';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import { usePrintSortWallLabel } from '../../hooks/usePrintLabelButton';
import { useToast } from '../../hooks/useToast';
import * as routes from '../../routes';

export function SortWall() {
	const { messages, translate } = useLocalization();

	const {
		params: { sortWallId },
	} = useRouteMatch<{ sortWallId: string }>();

	const history = useHistory();

	const { showToast } = useToast();

	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();

	const [{ fetching: sortWallFetching, error: sortWallError, data: sortWallData }] =
		usePollingQuery<{ job: Sortation }, QuerySortationByJobIdArgs>({
			context: useMemo(
				() => ({
					useWarehouseApi: true,
				}),
				[],
			),
			pollInterval: queryPollInterval,
			query: SORT_WALL_QUERY,
			variables: {
				id: sortWallId,
			},
		});

	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

	const closeCancelModal = () => {
		setIsCancelModalOpen(false);
	};

	const openCancelModal = () => {
		setIsCancelModalOpen(true);
	};

	const [{ fetching: cancelLoading }, cancelMutation] = useMutation<
		{ cancelSortWall: Response },
		MutationCancelSortWallArgs
	>(CANCEL_JOB_MUTATION);

	const [{ fetching: printLoading }, printMutation] = useMutation<
		{ printSortationLabels: Response },
		MutationPrintSortationLabelsArgs
	>(REPRINT_LABELS_MUTATION);

	const sortWall = sortWallData?.job;

	const [isBannerErrorVisible, setIsBannerErrorVisible] = useState(false);

	const { buttonText: printLabelButtonText, onLabelPrintSuccess } = usePrintSortWallLabel({
		disabled: sortWallFetching,
		id: sortWall?.id,
	});

	const printLabels = async () => {
		setIsBannerErrorVisible(false);

		if (!sortWall) {
			return;
		}

		try {
			const { error } = await printMutation({ projectionId: sortWall.id });

			if (error) {
				throw error;
			}

			onLabelPrintSuccess();

			showToast(messages.labelsPrinted);
		} catch (e) {
			setIsBannerErrorVisible(true);
		}
	};

	const cancelJob = async () => {
		setIsBannerErrorVisible(false);

		if (!sortWall) {
			return;
		}

		try {
			const { error } = await cancelMutation({ projectionId: sortWall.id });

			closeCancelModal();

			if (error) {
				throw error;
			}

			showToast(messages.sortWallCanceled);
			history.push(routes.sortWalls());
		} catch (e) {
			setIsBannerErrorVisible(true);
		}
	};

	if (sortWallError) {
		return <Error graphQLError={sortWallError} />;
	}

	if (!sortWallFetching && !sortWall) {
		return <Error heading={messages.sortWallNotFound} />;
	}

	const sortations = (sortWall as any)?.sortations.edges ?? [];
	const sortation = sortations[0]?.node ?? {};

	return (
		<>
			<Page
				title={
					sortWallFetching
						? ''
						: (translate(messages.sortWallByName, { name: sortation.wallDisplayId }) as string)
				}
				titleMetadata={
					<Stack spacing="extraTight">
						<SortationStatusBadge sortationStatus={sortWall?.status as any} isEmptyIfNoData />
					</Stack>
				}
				secondaryActions={[
					{
						content: printLabelButtonText,
						disabled: sortWallFetching,
						icon: PrintMajor,
						loading: printLoading,
						onAction: () => void printLabels(),
					},
					{
						content: messages.cancelWall,
						disabled: !sortWall?.isCancelable || sortWallFetching,
						onAction: openCancelModal,
					},
				]}
			>
				<AutoRefresh
					pollingEnabled={pollingEnabled}
					togglePolling={togglePolling}
					discriminatorData={sortWallData}
				/>

				<Layout>
					<Layout.Section>
						<ErrorBanner
							isVisible={isBannerErrorVisible}
							onDismiss={() => {
								setIsBannerErrorVisible(false);
							}}
						/>
					</Layout.Section>

					<Layout.Section>
						<SortWallDetails loading={sortWallFetching} sortWall={sortWall} />
					</Layout.Section>

					<Layout.Section>
						<SortWallBreakdown loading={sortWallFetching} sortWall={sortWall} />
					</Layout.Section>

					{/* <Layout.Section>
						<SortWallLinesTableV2 jobId={sortWallId} loading={sortWallFetching} />
					</Layout.Section>

					<Layout.Section>
						<SortWallOrdersTable
							sortWall={sortWall}
							orders={sortWall?.customerOrdersV2}
							loading={sortWallFetching}
						/>
					</Layout.Section>

					<Layout.Section>
						<SortWallPickingJobsTable
							sortWall={sortWall}
							pickingJobs={sortWall?.pickingJobsV2}
							loading={sortWallFetching}
						/>
					</Layout.Section> */}

					<Layout.Section>
						<TimezoneFooter />
					</Layout.Section>
				</Layout>
			</Page>
			<Modal
				open={isCancelModalOpen}
				onClose={closeCancelModal}
				title={messages.cancelSortWall}
				primaryAction={{
					content: messages.cancelSortWall,
					destructive: true,
					loading: cancelLoading,
					onAction: () => void cancelJob(),
				}}
				secondaryActions={[
					{
						content: messages.keepSortWall,
						onAction: closeCancelModal,
					},
				]}
			>
				<Modal.Section>
					<TextContainer>
						<p>{messages.cancelSortWallConfirm}</p>
					</TextContainer>
				</Modal.Section>
			</Modal>
		</>
	);
}
