import { UserRole, useAuth } from '@sixriver/react-support';
import { ReactNode } from 'react';

import { NoAccess } from './NoAccess';

export interface AccessControlProps {
	children: ReactNode;
	allowedRoles?: UserRole[];
	noAccess?: ReactNode;
}

export function AccessControl({
	children,
	allowedRoles,
	noAccess = <NoAccess />,
}: AccessControlProps) {
	const { isUserAllowed } = useAuth();

	if (!allowedRoles || isUserAllowed(allowedRoles)) {
		return <>{children}</>;
	}

	return <>{noAccess}</>;
}
