import {
	GoalStates,
	KittingProject as KittingProjectApi,
	MutationResponse,
	SpecialProject as ApiSpecialProject,
	SpecialProjectType,
	WorkLogInput,
	WorkOrderProject as WorkOrderProjectApi,
} from '@sixriver/fulfillment-api-schema';
import {
	Banner,
	Card,
	Heading,
	Layout,
	Page,
	Spinner,
	Stack,
} from '@sixriver/lighthouse-web-community';
import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { CombinedError, useMutation } from 'urql';

import { Instructions } from './Instructions';
import { KittingProject } from './KittingProject';
import Merchant from './Merchant';
import { SpecialProjectReviewModal } from './SpecialProjectReviewModal';
import { ADD_TIME_LOG_MUTATION, SPECIAL_PROJECT_QUERY } from './SpecialProjects.graphql';
import Status from './Status';
import { TimeLogged } from './TimeLogged';
import Type from './Type';
import { WorkLog } from './WorkLog';
import { WorkLogAddEditModal } from './WorkLogAddEditModal';
import { WorkOrderProject } from './WorkOrderProject';
import { AutoRefresh } from '../../components/AutoRefresh';
import { CardDetails } from '../../components/CardDetails';
import { DateTime } from '../../components/DateTime';
import { Error } from '../../components/Error';
import { ViewTabs } from '../../components/ViewTabs';
import { useFilters, useSetFilters } from '../../hooks/useFilters';
import { useLocalization } from '../../hooks/useLocalization';
import { usePolling } from '../../hooks/usePolling';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import { useToast } from '../../hooks/useToast';

export interface RouteMatchParams {
	projectId: string;
}
export function SpecialProject(): JSX.Element {
	const { messages, translate } = useLocalization();

	const {
		params: { projectId },
	} = useRouteMatch<RouteMatchParams>();

	const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
	const [isReviewModalOpen, setIsReviewModalOpen] = useState<boolean>(false);
	const [isFAIErrorBannerOpen, setisFAIErrorBannerOpen] = useState<boolean>(false);
	const [formError, setFormError] = useState<CombinedError | undefined>(undefined);

	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();

	const { showToast } = useToast();

	const { tab } = useFilters(['tab']);
	const setFilters = useSetFilters();

	// Views
	const views = [
		{
			id: 'overview',
			label: messages.overview,
		},
		{
			id: 'workLog',
			label: messages.workLog,
		},
	];

	// Data
	const [{ fetching, data, error: queryError }] = usePollingQuery<
		{ specialProject: ApiSpecialProject },
		{ id: string }
	>({
		pollInterval: queryPollInterval,
		query: SPECIAL_PROJECT_QUERY,
		variables: {
			id: projectId,
		},
	});

	const project = data?.specialProject;

	const canComplete = project ? isInACompleteableStatus(project) : false;
	const isComplete = project?.status === GoalStates.Complete;

	// Mutations
	const [{ error: addTimeLogError }, addTimeLogMutation] = useMutation<{
		addTimeLog: MutationResponse;
	}>(ADD_TIME_LOG_MUTATION);

	// Blow away form errors so we don't show stale information. This sucks
	useEffect(() => {
		setFormError(addTimeLogError);
	}, [addTimeLogError]);

	// Methods
	const onMarkAsCompleteClick = () => {
		if (project && needsInspection(project)) {
			setisFAIErrorBannerOpen(true);
		} else {
			setIsReviewModalOpen(true);
		}
	};

	const submitWorkLog = async (input: WorkLogInput) => {
		const { error } = await addTimeLogMutation(
			{ input },
			{ additionalTypenames: ['WorkLogPage', 'WorkLog', 'KittingProject', 'WorkOrderProject'] },
		);

		if (!error) {
			showToast(messages.dataSaved);
			setIsEditModalOpen(false);
		}
	};

	if (queryError) {
		return <Error graphQLError={queryError} />;
	}

	if (fetching) {
		return <Spinner />;
	}

	if (!project) {
		return <Error message={messages.projectNotFound} />;
	}

	return (
		<Page
			fullWidth
			title={
				translate(messages.specialProjectByExternalId, {
					externalId: project.externalId,
				}) as string
			}
			titleMetadata={
				<div data-testid="header">
					<Stack spacing="extraTight">
						<Status project={project} />
					</Stack>
				</div>
			}
			primaryAction={{
				content: messages.markAsComplete,
				disabled: !canComplete,
				onAction: onMarkAsCompleteClick,
				primary: false,
			}}
			secondaryActions={[
				{
					content: messages.logWork,
					disabled: isComplete,
					onAction: () => setIsEditModalOpen(true),
				},
			]}
		>
			<Layout>
				<Layout.Section>
					<AutoRefresh
						pollingEnabled={pollingEnabled}
						togglePolling={togglePolling}
						discriminatorData={data}
					/>
				</Layout.Section>
				<Layout.Section>
					<ViewTabs
						tabs={views}
						selected={tab || views?.[0]?.id}
						onSelect={(id) => {
							setFilters([{ key: 'tab', value: id }]);
						}}
					/>
				</Layout.Section>
				{isFAIErrorBannerOpen ? (
					<Layout.Section>
						<Banner
							title={messages.faiErrorTitle}
							status="warning"
							onDismiss={() => setisFAIErrorBannerOpen(false)}
						>
							<p>{messages.faiErrorMessage}</p>
						</Banner>
					</Layout.Section>
				) : undefined}
				{tab === 'workLog' ? (
					<Layout.Section>
						<WorkLog
							queryPollInterval={queryPollInterval}
							projectId={projectId}
							projectType={project.type}
							totalTime={project.totalTimeLogged}
							isProjectComplete={isComplete}
						/>
					</Layout.Section>
				) : (
					<>
						<Layout.Section>
							<Card title={messages.details}>
								<Card.Section>
									<CardDetails
										loading={fetching}
										primary={[
											{
												content: <Type project={project} />,
												label: messages.service,
											},
											{
												content: <Merchant project={project} />,
												label: messages.store,
											},
											{
												content: <DateTime date={project?.dateReceived} />,
												label: messages.receivedAt,
											},
										]}
									/>
								</Card.Section>
								<Card.Section>
									<CardDetails
										loading={fetching}
										primary={[
											{
												content: <TimeLogged timeInMs={project.totalTimeLogged} />,
												label: messages.timeLogged,
											},
										]}
									/>
								</Card.Section>
							</Card>
							{project.type === SpecialProjectType.Kitting ? (
								<KittingProject loading={fetching} kit={project as KittingProjectApi} />
							) : (
								<WorkOrderProject loading={fetching} workOrder={project as WorkOrderProjectApi} />
							)}
						</Layout.Section>
						<Layout.Section secondary>
							<Card>
								<Card.Section>
									<Stack distribution="equalSpacing">
										<Heading>{messages.instructions}</Heading>
									</Stack>
								</Card.Section>
								<Card.Section>
									<Instructions project={project as KittingProjectApi} />
								</Card.Section>
							</Card>
						</Layout.Section>
					</>
				)}
			</Layout>
			<SpecialProjectReviewModal
				isOpen={isReviewModalOpen}
				onClose={() => setIsReviewModalOpen(false)}
				project={project}
			/>
			{isEditModalOpen ? (
				<WorkLogAddEditModal
					onClose={() => {
						setIsEditModalOpen(false);
						setFormError(undefined);
					}}
					onSubmit={submitWorkLog}
					data={{
						dateLogged: new Date(),
						goalId: projectId,
						id: null,
						task: '',
						timeLogged: 0,
						userId: '',
					}}
					error={formError}
					mode="add"
					projectType={project.type}
				/>
			) : null}
		</Page>
	);
}

function isInACompleteableStatus(project: ApiSpecialProject): boolean {
	const completeableStatuses = [GoalStates.Running];

	if (!project.status) {
		return false;
	}

	return completeableStatuses.includes(project.status);
}

function needsInspection(project: ApiSpecialProject): boolean {
	if (project.type === SpecialProjectType.Kitting) {
		return (project as KittingProjectApi).product.needsInspection;
	}

	return false;
}
