import { Layout, Page, Tag, TextStyle } from '@sixriver/lighthouse-web-community';
import type { StorageLocation, StorageLocationType } from '@sixriver/warehouse-api-graphql-types';
import { useContext, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { LocationInfo } from './LocationInfoV2';
import { LOCATION_QUERY_V2 } from './Locations.graphql';
import { AutoRefresh } from '../../components/AutoRefresh';
import { Error } from '../../components/Error';
import { useIsWorkAreasEnabled } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';
import { usePolling } from '../../hooks/usePolling';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import { BusyIndicatorContext } from '../../providers/BusyIndicatorProvider';
import * as routes from '../../routes';

export function LocationV2() {
	const { messages } = useLocalization();
	const { lookBusy } = useContext(BusyIndicatorContext);
	const isWorkAreaEnabled = useIsWorkAreasEnabled();

	// Routing
	const {
		params: { locationId },
	} = useRouteMatch<{ locationId: string }>();

	// Polling
	const { pollingEnabled, queryPollInterval, togglePolling } = usePolling();

	// Queries
	const [{ error: queryLocationError, data, fetching }] = usePollingQuery<
		{ storageLocation: StorageLocation },
		{ id: string }
	>({
		context: useMemo(
			() => ({
				useWarehouseApi: true,
			}),
			[],
		),
		pollInterval: queryPollInterval,
		query: LOCATION_QUERY_V2,
		variables: {
			id: locationId,
		},
	});

	const location = data?.storageLocation;

	if (lookBusy(fetching && !location)) {
		return null;
	}

	if (queryLocationError || !location) {
		return (
			<Error
				graphQLError={queryLocationError}
				message={queryLocationError ? undefined : messages.locationNotFound}
			/>
		);
	}

	// Render
	return (
		<>
			<Page fullWidth>
				<Layout>
					{/* TODO: breadcrumb widget */}
					<Layout.Section>
						<Tag url={routes.locations()}>{messages.allLocations}</Tag>
						<TextStyle variation="subdued"> &#62; </TextStyle>
						{location.address}
					</Layout.Section>
				</Layout>
			</Page>
			<Page
				fullWidth
				title={location.address}
				subtitle={
					location.type ? messages.locationTypes[location.type as StorageLocationType] : '—'
				}
				additionalNavigation={
					<AutoRefresh
						discriminatorData={data}
						pollingEnabled={pollingEnabled}
						togglePolling={togglePolling}
					/>
				}
			>
				<Layout>
					<Layout.Section secondary>
						<LocationInfo location={location} isWorkAreaEnabled={isWorkAreaEnabled} />
					</Layout.Section>
				</Layout>
			</Page>
		</>
	);
}
