import { PickWave as PickWaveComponent } from '@sixriver/integration-visibility-app';
import { useRouteMatch } from 'react-router-dom';

interface PickWaveRouteMatchParams {
	pickWaveId: string;
}

export function PickWave() {
	const {
		params: { pickWaveId },
	} = useRouteMatch<PickWaveRouteMatchParams>();
	return <PickWaveComponent pickWaveId={pickWaveId} />;
}
