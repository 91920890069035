import { Card, Stack, TextStyle } from '@sixriver/lighthouse-web-community';

import { EmployeeEvent } from './Employee';
import { useLocalization } from '../../hooks/useLocalization';

export function EmployeeTimestampsCard({ timestamps }: { timestamps: EmployeeEvent[] }) {
	const { messages, formatDateTime } = useLocalization();

	// Render
	return (
		<Card title={messages.timestamps} sectioned>
			<Card.Section flush>
				<Stack vertical spacing="loose">
					{timestamps.map((event) => {
						return (
							<Stack vertical key={`${event.type}-${event.date.toISOString()}`} spacing="tight">
								<TextStyle variation="strong">
									{event.type === 'created' ? messages.createdAt : messages.updatedAt}
								</TextStyle>
								<TextStyle>{formatDateTime(event.date)}</TextStyle>
							</Stack>
						);
					})}
				</Stack>
			</Card.Section>
		</Card>
	);
}
