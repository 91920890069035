import { gql } from 'graphql-tag';

import { PAGE_INFO_FRAGMENT } from '../../graphql';

// Queries
export const GET_EMPLOYEE_ROLES_QUERY = gql`
	query {
		roles
	}
`;

export const EMPLOYEE_BY_ID_QUERY = gql`
	query getEmployee($id: String!) {
		user(id: $id) {
			id
			email
			name
			locale
			badge
			roles
			isActive
			lastLogin
			createdAt
			updatedAt
			phone
			tags {
				name
			}
		}
	}
`;
export const EMPLOYEE_BY_EMAIL_QUERY = gql`
	query getEmployee($email: String!) {
		userByEmail(email: $email) {
			id
			email
			name
			locale
			badge
			roles
			isActive
			lastLogin
			createdAt
			updatedAt
			phone
			tags {
				name
			}
		}
	}
`;

export const EMPLOYEES_QUERY = gql`
	query Employees(
		$type: UserActivityType!
		$searchText: String!
		$first: Int
		$last: Int
		$before: String
		$after: String
	) {
		users(
			type: $type
			searchText: $searchText
			first: $first
			last: $last
			before: $before
			after: $after
		) {
			edges {
				node {
					id
					name
					locale
					badge
					roles
					updatedAt
					lastLogin
					email
					tags {
						name
					}
				}
			}
			pageInfo {
				...PageInfo
			}
		}
	}
	${PAGE_INFO_FRAGMENT}
`;

export const COUNTS_QUERY = gql`
	query CountEmployees {
		ActiveCount: usersCount(type: active) {
			count
		}
		InactiveCount: usersCount(type: inactive) {
			count
		}
	}
`;

export const CHECK_BADGE_BARCODE_EXISTS_QUERY = gql`
	query checkBadgeBarcodeExists($badgeBarcode: String!) {
		badgeBarcodeExists(badgeBarcode: $badgeBarcode)
	}
`;

export const GET_TAGS_QUERY = gql`
	query getTags {
		searchTags(searchTerm: "") {
			name
		}

		getPopularTags(limit: 4) {
			name
		}
	}
`;

export const SEARCH_TAGS_QUERY = gql`
	query searchTags($searchTerm: String!) {
		searchTags(searchTerm: $searchTerm) {
			name
		}
	}
`;

export const GET_POPULAR_TAGS_QUERY = gql`
	query getPopularTags {
		getPopularTags(limit: 10) {
			name
		}
	}
`;

// Mutations
export const CREATE_NEW_EMPLOYEE_MUTATION = gql`
	mutation createUser($input: NewUserInput!) {
		createUser(input: $input) {
			success
			referenceId
		}
	}
`;

export const CREATE_NEW_TAG_MUTATION = gql`
	mutation createTag($input: NewTagInput!) {
		createTag(input: $input) {
			name
		}
	}
`;

export const ADD_TAG_TO_USER_MUTATION = gql`
	mutation addTagToUser($userId: String!, $tag: String!) {
		addTagToUser(userId: $userId, tag: $tag) {
			tags {
				name
			}
		}
	}
`;

export const REMOVE_TAG_FROM_USER_MUTATION = gql`
	mutation removeTagFromUser($userId: String!, $tag: String!) {
		removeTagFromUser(userId: $userId, tag: $tag) {
			tags {
				name
			}
		}
	}
`;

export const UPDATE_EMPLOYEE_MUTATION = gql`
	mutation updateUser($input: UpdateUserInput!) {
		updateUser(input: $input) {
			success
			referenceId
		}
	}
`;

export const DEACTIVATE_EMPLOYEE_MUTATION = gql`
	mutation deactivateEmployee($userId: String!) {
		deactivateUser(userId: $userId) {
			success
		}
	}
`;

export const REACTIVATE_EMPLOYEE_MUTATION = gql`
	mutation reactivateEmployee($userId: String!) {
		reactivateUser(userId: $userId) {
			success
		}
	}
`;
