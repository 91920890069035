import { IndexTable } from '@sixriver/lighthouse-web-community';

import styles from './OutboundJobsTable.module.css';
import { NoData } from '../../../components/NoData';
import { useLocalization } from '../../../hooks/useLocalization';
import CHUCK_IMG from '../../../images/chuck.svg';
import EQUIPMENT_IMG from '../../../images/equipment.svg';

interface DeviceCellProps {
	deviceName?: string;
	deviceClasses?: string[];
}

export function DeviceCell({ deviceName, deviceClasses }: DeviceCellProps) {
	const { messages } = useLocalization();
	const isEquipment = deviceClasses && deviceClasses.length > 0;
	const displayName = isEquipment ? new Set(deviceClasses) : deviceName;

	const src = isEquipment ? EQUIPMENT_IMG : CHUCK_IMG;
	const alt = isEquipment ? messages.equipment : messages.chuck;

	return (
		<IndexTable.Cell>
			<tr>
				<td>
					{displayName ? <img src={src} className={styles.equipment} alt={alt} /> : ''}
					{displayName ?? <NoData />}
				</td>
			</tr>
		</IndexTable.Cell>
	);
}
