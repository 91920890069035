import { useIsWarehouseApiEnabled } from '../useConfig';
import { useQueryState } from '../useQueryState';

const USE_WAREHOUSE_API_KEY = 'w-api';

export function useWarehouseApi(): boolean {
	const warehouseApiEnabled = useIsWarehouseApiEnabled();
	const [shouldUseWarehouseApi] = useQueryState<boolean>(
		USE_WAREHOUSE_API_KEY,
		warehouseApiEnabled,
		(value: string) => value.toLowerCase() === 'true',
		(value: boolean) => `${value}`,
	);
	return shouldUseWarehouseApi;
}
