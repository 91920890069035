import {
	MutationResponse,
	StorageLocation,
	StorageLocationInput,
	StorageLocationType,
} from '@sixriver/fulfillment-api-schema';
import { Modal, Page, PageActions } from '@sixriver/lighthouse-web-community';
import { useContext, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

import { StorageLocationForm } from './Location.form';
import {
	DELETE_LOCATION_MUTATION,
	EDIT_LOCATION_MUTATION,
	GET_LOCATION_QUERY,
} from './Locations.graphql';
import { Error } from '../../components/Error';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import { BusyIndicatorContext } from '../../providers/BusyIndicatorProvider';
import * as routes from '../../routes';

export function EditStorageLocation(): JSX.Element {
	const history = useHistory();
	const { showToast } = useToast();
	const { messages } = useLocalization();
	const { lookBusy } = useContext(BusyIndicatorContext);
	const [modalVisible, setModalVisible] = useState(false);

	const {
		params: { locationId },
	} = useRouteMatch<{ locationId: string }>();

	// Queries
	const [{ fetching: fetchingLocation, data, error: getLocationError }] = useQuery<
		{ location: StorageLocation },
		{ id: string }
	>({
		pause: !locationId,
		query: GET_LOCATION_QUERY,
		requestPolicy: 'network-only',
		// Do not run this query unless a location id was provided
		variables: {
			id: locationId,
		},
	});

	// Mutations
	const [{ error: editStorageLocationError }, editStorageLocationMutation] = useMutation<
		{ editLocation: MutationResponse },
		{ input: StorageLocationInput }
	>(EDIT_LOCATION_MUTATION);

	const [{ error: deleteLocationError, fetching: isDeleting }, deleteLocationMutation] =
		useMutation<{ deleteLocation: MutationResponse }, { id: string }>(DELETE_LOCATION_MUTATION);

	const onEdit = async (input: StorageLocationInput) => {
		lookBusy(true);

		const { error } = await editStorageLocationMutation({ input });

		if (error) {
			lookBusy(false);
			return;
		}

		setTimeout(() => {
			lookBusy(false);
			showToast(messages.dataSaved);
			history.push(routes.location(locationId));
		}, 500);
	};

	const onDelete = async () => {
		lookBusy(true);
		setModalVisible(false);

		const { error } = await deleteLocationMutation({ id: locationId });

		if (error) {
			lookBusy(false);
			return;
		}

		setTimeout(() => {
			lookBusy(false);
			history.push(routes.locations());
		}, 500);
	};

	if (lookBusy(fetchingLocation && !data?.location)) {
		return null;
	}

	if (getLocationError) {
		return <Error graphQLError={getLocationError} />;
	}

	return (
		<Page
			fullWidth
			title={data.location.address}
			subtitle={messages.locationTypes[data.location.type || 'unknown']}
			breadcrumbs={[{ content: messages.locations, url: routes.location(locationId) }]}
		>
			<StorageLocationForm
				mode="edit"
				onSubmit={onEdit}
				error={editStorageLocationError || deleteLocationError}
				data={
					{
						address: data.location.address,
						containerTypeId: data.location.containerType?.id,
						description: data.location.description,
						// replace `null` with the empty string so it aligns with the visual "None"
						// option. The selection mapping is also setup in Location.form.tsx.
						externalAisleId: data.location.externalAisleId ?? '',

						id: data.location.id,

						type: data.location.type as StorageLocationType,

						x: data.location.coordinates?.x || 0,
						y: data.location.coordinates?.y || 0,
						z: data.location.coordinates?.z || 0,
					} as StorageLocationInput
				}
			/>
			<br />
			<PageActions
				secondaryActions={[
					{
						content: messages.deleteLocation,
						destructive: true,
						onAction: () => setModalVisible(true),
						outline: true,
					},
				]}
			/>
			<Modal
				open={modalVisible}
				title={messages.deleteLocation}
				onClose={() => setModalVisible(false)}
				primaryAction={{
					content: messages.deleteLocation,
					destructive: true,
					loading: isDeleting,
					onAction: () => void onDelete(),
				}}
				secondaryActions={[
					{
						content: messages.keepEditing,
						onAction: () => setModalVisible(false),
					},
				]}
			>
				<Modal.Section>{messages.confirmDeleteLocation}</Modal.Section>
			</Modal>
		</Page>
	);
}
