import '@sixriver/lighthouse-web-community/dist/css/bundled.css';

import { StrictMode } from 'react';
import { render } from 'react-dom';

import { App } from './app';
import { AppProvider } from './providers/AppProvider';

import './setup-sentry';

render(
	<StrictMode>
		<AppProvider>
			<App />
		</AppProvider>
	</StrictMode>,
	document.getElementById('root'),
);
