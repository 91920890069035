import { gql } from 'graphql-tag';

export const LINES_QUERY = gql`
	query GetLines($id: String!, $searchText: String) {
		job(id: $id) {
			lines(searchText: $searchText) {
				edges {
					node {
						id
						# jobId
						status
						quantity
						doneQuantity
						completedAt
						isShorted
						tasks {
							type
							sourceLocationId
							sourceLocationAddress
							# user {
							# 	name
							# 	email
							# 	badge
							# }
							isUserDirectedProduct
							isUserDirectedSourceLocation
							isShorted
							quantity
							doneQuantity
						}
						productId
						productName
						productCustomerIdentifier
						productDescription
						# workArea {
						# 	name
						# }
						storageLocation {
							address
						}
					}
				}
			}
		}
	}
`;
