import { Frame as PolarisFrame, TopBar } from '@sixriver/lighthouse-web-community';
import { PropsWithChildren, useCallback, useState } from 'react';

import { Nav } from '../components/Nav';
import { OfflineBanner } from '../components/OfflineBanner';
import { UserMenu } from '../components/UserMenu';
import sixriverLogo from '../images/logo.svg';

export function Frame({ children }: PropsWithChildren<Record<never, any>>): JSX.Element {
	const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
	const toggleMobileNavigationActive = useCallback(
		() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive),
		[],
	);

	const logo = {
		accessibilityLabel: '6 River Systems',
		topBarSource: sixriverLogo,
		width: 200,
	};

	return (
		<PolarisFrame
			navigation={<Nav />}
			logo={logo}
			topBar={
				<TopBar
					showNavigationToggle
					onNavigationToggle={toggleMobileNavigationActive}
					userMenu={<UserMenu />}
				/>
			}
			showMobileNavigation={mobileNavigationActive}
			onNavigationDismiss={toggleMobileNavigationActive}
		>
			<OfflineBanner />
			{children}
		</PolarisFrame>
	);
}
