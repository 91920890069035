import { Count, JobsCount } from '@sixriver/fulfillment-api-schema';
import { gql } from 'graphql-tag';

export interface OrdersCounts {
	TotalCount: JobsCount;
	StaticTotalCount: Count;
	StaticUnassignedCount: Count;
	StaticReadyCount: Count;
	StaticInProgressCount: Count;
	StaticAssignedToSortWallCount: Count;
	StaticCanceledCount: Count;
	StaticCompletedCount: Count;
	StaticInterruptedCount: Count;
}

export const ORDERS_COUNT_QUERY = gql`
	query OrdersCount(
		$ids: [String!]
		$searchText: String
		$equipmentTypes: [String!]
		$statuses: [JobStatus!]
		$isLate: Boolean
		$isShorted: Boolean
		$isAwaitingInventory: Boolean
		$isServicedByHealJobs: Boolean
		$expectedShipDateFrom: DateTime
		$expectedShipDateTo: DateTime
		$completedAtFrom: DateTime
		$completedAtTo: DateTime
		$createdAtFrom: DateTime
		$pickStrategies: [PickStrategy!]
		$workOrderTypes: [WorkOrderType!]
		$workAreaIds: [String!]
		$jobFlowRuleLpn: String
		$bulkOrderProjectionId: String
	) {
		TotalCount: ordersCount(
			ids: $ids
			searchText: $searchText
			statuses: $statuses
			equipmentTypes: $equipmentTypes
			isLate: $isLate
			isShorted: $isShorted
			isAwaitingInventory: $isAwaitingInventory
			isServicedByHealJobs: $isServicedByHealJobs
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			createdAtFrom: $createdAtFrom
			pickStrategies: $pickStrategies
			workOrderTypes: $workOrderTypes
			workAreaIds: $workAreaIds
			jobFlowRuleLpn: $jobFlowRuleLpn
			bulkOrderProjectionId: $bulkOrderProjectionId
		) {
			completed
			interrupted
			cancelled
			awaitingInventory
			late
			shorted
			unassigned
			assignedToSortWall
			assignedToBatch
			unknown
			inProgress
			total
			orders
		}

		StaticTotalCount: ordersCount(
			isOrder: true
			createdAtFrom: $createdAtFrom
			workAreaIds: $workAreaIds
		) {
			count
		}

		StaticUnassignedCount: ordersCount(
			statuses: [Unassigned]
			isOrder: true
			createdAtFrom: $createdAtFrom
			workAreaIds: $workAreaIds
		) {
			count
		}

		StaticReadyCount: ordersCount(
			statuses: [Ready]
			isOrder: true
			createdAtFrom: $createdAtFrom
			workAreaIds: $workAreaIds
		) {
			count
		}

		StaticInProgressCount: ordersCount(
			statuses: [InProgress]
			isOrder: true
			createdAtFrom: $createdAtFrom
			workAreaIds: $workAreaIds
		) {
			count
		}

		StaticAssignedToSortWallCount: ordersCount(
			statuses: [AssignedToSortWall]
			isOrder: true
			createdAtFrom: $createdAtFrom
			workAreaIds: $workAreaIds
		) {
			count
		}

		StaticCanceledCount: ordersCount(
			statuses: [Canceled]
			isOrder: true
			createdAtFrom: $createdAtFrom
			workAreaIds: $workAreaIds
		) {
			count
		}

		StaticCompletedCount: ordersCount(
			statuses: [Done]
			isOrder: true
			createdAtFrom: $createdAtFrom
			completedAtFrom: $completedAtFrom
			completedAtTo: $completedAtTo
			workAreaIds: $workAreaIds
		) {
			count
		}

		StaticInterruptedCount: ordersCount(
			statuses: [Interrupted]
			isOrder: true
			createdAtFrom: $createdAtFrom
			workAreaIds: $workAreaIds
		) {
			count
		}
	}
`;
