import { Spinner } from '@sixriver/lighthouse-web-community';
import { useAuth } from '@sixriver/react-support';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import styles from './Logout.module.css';
import * as routes from '../../routes';

export function Logout(): JSX.Element {
	const { logout } = useAuth();
	const history = useHistory();

	useEffect(() => {
		const controller = new AbortController();
		const { signal } = controller;

		const doLogout = async () => {
			await logout(signal);
			history.push(routes.login());
		};
		doLogout();

		return () => {
			controller.abort();
		};
	}, [history, logout]);

	return (
		<div className={styles.spinner}>
			<Spinner />
		</div>
	);
}
