import { OrderV2 } from '@sixriver/fulfillment-api-schema';
import { Banner, Heading, TextStyle } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	order?: OrderV2;
}
export function ShippedShortBanner({ order }: Props): JSX.Element | null {
	const { messages } = useLocalization();

	return order?.isShippedShort ? (
		<Banner status="warning">
			<Heading>{messages.shippedShortBannerHeading}</Heading>
			<TextStyle>{messages.shippedShortBannerBody}</TextStyle>
		</Banner>
	) : null;
}
