import { StorageLocation, Product, InventoryState } from '@sixriver/fulfillment-api-schema';
import { Link, Stack, TextStyle } from '@sixriver/lighthouse-web-community';

import { StorageLocationInventoryRemaining } from './StorageLocationInventoryRemaining';
import { NoData } from '../../components/NoData';

interface LocationProductSummaryProps {
	location: StorageLocation;
	product: Product;
	inventory: InventoryState;
	productURL: string;
	barcodeListLabel: string;
}

export function LocationProductSummary(props: LocationProductSummaryProps) {
	const barcodeList = props.product.scanValues?.join(', ');
	return (
		<div style={{ whiteSpace: 'pre-wrap' }} key="inventory">
			<Stack wrap vertical={true} spacing="extraTight" distribution="leading">
				<Stack.Item>
					<Link url={props.productURL}>
						<TextStyle variation="strong">{props.product.name}</TextStyle>
					</Link>
				</Stack.Item>

				<Stack.Item>
					{`${props.barcodeListLabel}: `}{' '}
					<TextStyle variation="strong">{barcodeList || <NoData />}</TextStyle>
				</Stack.Item>

				<Stack.Item>
					<StorageLocationInventoryRemaining
						location={props.location}
						product={props.product}
						inventory={props.inventory}
					/>
				</Stack.Item>
			</Stack>
		</div>
	);
}
