import { LineQuantities } from '@sixriver/fulfillment-api-schema';
import { IndexTable } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../../hooks/useLocalization';

export function UnitsPickedCell({ totalLineQuantities }: { totalLineQuantities: LineQuantities }) {
	const { messages, translate } = useLocalization();

	const picked = totalLineQuantities?.pickedUnits;
	const total = totalLineQuantities?.totalUnits;

	return (
		<IndexTable.Cell>
			<p>{translate(messages.pickedOfTotal, { picked, total })}</p>
		</IndexTable.Cell>
	);
}
