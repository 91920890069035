import { LineV2 } from '@sixriver/fulfillment-api-schema';
import { IndexTable } from '@sixriver/lighthouse-web-community';

import { NoData } from '../../../components/NoData';

interface Props {
	lines: { node: LineV2 }[];
}

export function WorkAreaCell({ lines }: Props) {
	const uniqueWorkAreaNames = lines.reduce((workAreas, { node: line }) => {
		if (line.workArea?.name) {
			workAreas.add(line.workArea.name);
		}
		return workAreas;
	}, new Set<string>());

	return (
		<IndexTable.Cell>
			{uniqueWorkAreaNames.size > 0 ? (
				<div>
					{Array.from(uniqueWorkAreaNames)
						.map((areaName) => areaName)
						.join(', ')}
				</div>
			) : (
				<NoData />
			)}
		</IndexTable.Cell>
	);
}
