import { Map as MapElement, TagList, ZoomControl } from '@sixriver/map-element';
import { MapStack } from '@sixriver/map-io';
import { useEffect, useRef, useState } from 'react';

import { Chuck } from './Chuck.type';
import { asChuckIcon } from './ChuckIcon';
import { isVisible } from './ChuckUtils';
import { mapFeatureTypes, FeatureTypeMap } from './FeatureType';
import styles from './FloorView.module.css';
import { FullScreenHeader } from './FullScreenHeader';
import { FullScreenOptions, SortMethod } from './FullScreenOptions';
import { FullScreenTable } from './FullScreenTable';
import { useIsHighPriorityChuckEnabled } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	chucks: Chuck[];
	map: MapStack;
	tags: TagList;
	title?: string;
}

/**
 * This is the full-screen, non-interactive version of the Floor View.
 */
export function FullScreenMode({ chucks, map, tags, title }: Props) {
	const { formatTime } = useLocalization();
	const isUsingHighPriority = useIsHighPriorityChuckEnabled();
	const [sortMethod, setSortMethod] = useState<SortMethod>(
		isUsingHighPriority ? 'priority' : 'idleTime',
	);
	const featureTypes = useRef<FeatureTypeMap>(new Map());

	useEffect(() => {
		featureTypes.current = mapFeatureTypes(map);
	}, [map]);

	return (
		<>
			<div className={styles.fsHeaderPanel}>
				<div className={styles.fsInfo}>
					<div className={styles.fsTime}>{formatTime(new Date())}</div>
					<div className={styles.fsTitle}>{title}</div>
				</div>
				<FullScreenHeader chucks={chucks} featureTypes={featureTypes.current} />
			</div>
			<div className={styles.fsMapPanel}>
				<MapElement
					mapStack={map}
					chucks={chucks.filter(isVisible).map((chuck) => asChuckIcon(chuck, true))}
					showWorkflowPoint
					showImpassable
					enablePanControl
					tags={tags}
				>
					<ZoomControl position="bottomright" />
				</MapElement>
				<div className={styles.fsTablePanel}>
					<FullScreenTable chucks={chucks} sortMethod={sortMethod} />
					<FullScreenOptions sortMethod={sortMethod} onSort={setSortMethod} />
				</div>
			</div>
		</>
	);
}
