import { LineV2 } from '@sixriver/fulfillment-api-schema';
import { IndexTable, TextStyle } from '@sixriver/lighthouse-web-community';

import { NoData } from '../../NoData';

interface Props {
	line: LineV2;
}

export function ProductNameCell({ line }: Props): JSX.Element {
	return (
		<IndexTable.Cell>
			{line?.productName ? <TextStyle>{line.productName}</TextStyle> : <NoData />}
		</IndexTable.Cell>
	);
}
