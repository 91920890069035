/**
 * Transform an image URL from an intermediate form to a form that is usable in the browser.
 * @see Based on `@sixriver/asset-image`: {@url https://github.com/6RiverSystems/asset-image}
 */
import placeholderImg from '../images/placeholder.svg';

export const transformImageUrl = (imageUrl: string | null | undefined): string => {
	if (!imageUrl) {
		return placeholderImg;
	}

	const cloudinaryBaseUrl = 'https://res.cloudinary.com/six-river/image/upload/t_am-editor,f_auto/';

	// Handle relative URLs (legacy, mostly used in development environments)
	if (!imageUrl.startsWith('http')) {
		return `${cloudinaryBaseUrl}/${imageUrl}`;
	}

	return imageUrl;
};
