import {
	ChatMajor,
	CircleLeftMajor,
	CircleRightMajor,
	DataVisualizationMajor,
	DigitalMediaReceiverMajor,
	DnsSettingsMajor,
	InventoryMajor,
	ListMajor,
	LocationMajor,
	MobileMajor,
	// MergeMinor, needs lighthouse v2.15.0+
	ProductsMajor,
	ProfileMajor,
	RiskMajor,
} from '@sixriver/lighthouse-icons';
import { Button, Navigation } from '@sixriver/lighthouse-web-community';
import { UserRole, useAuth } from '@sixriver/react-support';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './Nav.module.css';
import {
	useConfig,
	useExperimentalFlags,
	useIsPutawayEnabled,
	useIsSortationEnabled,
} from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';
import { useShowExceptionPage } from '../../hooks/useShowExceptionPage';
import { useWarehouseApi } from '../../hooks/useWarehouseApi';
import * as mfaRoutes from '../../pages/MobileFulfillmentApp/routes';
import * as routes from '../../routes';

// temp until upgrade to lighthouse v2.15.0+ and then delete this and use MergeMinor from lighthouse
function MergeMinor() {
	return (
		<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
			<path
				// eslint-disable-next-line max-len
				d="M10 2a1 1 0 0 0-.717.303l-5 5.143A1 1 0 0 0 5.717 8.84L9 5.463V12a1 1 0 0 0 .293.707l5 5a1 1 0 0 0 1.414-1.414L11 11.586V5.463l3.283 3.377a1 1 0 0 0 1.434-1.394l-5-5.143A1 1 0 0 0 10 2ZM7.707 14.293a1 1 0 0 0-1.414 0l-2 2a1 1 0 1 0 1.414 1.414l2-2a1 1 0 0 0 0-1.414Z"
				fill="#5C5F62"
			/>
		</svg>
	);
}

export function Nav() {
	const { messages } = useLocalization();
	const { pathname } = useLocation();
	const { isUserAllowed } = useAuth();
	const showExceptionPage = useShowExceptionPage();
	const usingWarehouseApi = useWarehouseApi();
	const { config } = useConfig();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	const isSelected = (paths: string[]) => {
		return paths.some((path) => pathname.startsWith(path));
	};

	const isSortationEnabled = useIsSortationEnabled();
	const experimentalFlags = useExperimentalFlags();
	const isPutawayEnabled = useIsPutawayEnabled();

	const main = [
		{
			icon: CircleLeftMajor,
			label: messages.outbound,
			selected: isSelected([
				routes.fulfillmentTracker(),
				routes.orders(),
				routes.outboundJobs(),
				// routes.bulkOrders(), // SFN leftovers
				routes.sortWalls(),
			]),
			subNavigationItems: [
				{
					label: messages.orders,
					selected: isSelected([routes.orders()]),
					url: routes.orders(),
				},
				{
					label: messages.outboundJobs,
					selected: isSelected([routes.outboundJobs()]),
					url: routes.outboundJobs(),
				},
				...(isSortationEnabled
					? [
							{
								label: messages.sortWalls,
								selected: isSelected([routes.sortWalls()]),
								url: routes.sortWalls(),
							},
					  ]
					: []),
				...(experimentalFlags.includes('FULFILLMENT_TRACKER')
					? [
							{
								label: messages.fulfillmentTracker,
								selected: isSelected([routes.fulfillmentTracker()]),
								url: routes.fulfillmentTracker(),
							},
					  ]
					: []),
			],
			url: routes.orders(),
		},
		...(isPutawayEnabled
			? [
					{
						icon: CircleRightMajor,
						label: messages.inbound,
						selected: isSelected([routes.putawayJobs()]),
						url: routes.putawayJobs(),
					},
			  ]
			: []),
		{
			icon: ProductsMajor,
			label: messages.products,
			selected: isSelected([
				routes.containers(),
				routes.products(),
				routes.countJobs(),
				routes.moveJobs(),
			]),
			subNavigationItems: [
				{
					label: messages.containers,
					selected: isSelected([routes.containers()]),
					url: routes.containers(),
				},
				...(config?.countJobsUrl
					? [
							{
								label: messages.dashCountJobs,
								selected: isSelected([routes.countJobs()]),
								url: routes.countJobs(),
							},
					  ]
					: []),
				...(config?.moveJobsUrl
					? [
							{
								label: messages.dashMoveJobs,
								selected: isSelected([routes.moveJobs()]),
								url: routes.moveJobs(),
							},
					  ]
					: []),
			],
			url: routes.products(),
		},
		{
			icon: LocationMajor,
			label: messages.locations,
			selected: isSelected([routes.locations()]),
			url: routes.locations(),
		},
		...(isUserAllowed([UserRole.Admin])
			? [
					{
						icon: ProfileMajor,
						label: messages.employees,
						selected: isSelected([routes.employees()]),
						url: routes.employees(),
					},
			  ]
			: []),
		{
			icon: DigitalMediaReceiverMajor,
			label: messages.devices,
			selected: isSelected([routes.devices()]),
			url: routes.devices(),
		},
		{
			icon: MobileMajor,
			label: messages.mfas.title,
			selected: isSelected([mfaRoutes.mfas()]),
			url: mfaRoutes.mfas(),
		},
		...(showExceptionPage
			? [
					{
						icon: RiskMajor,
						label: messages.exceptions,
						selected: isSelected([routes.exceptions()]),
						url: routes.exceptions(),
					},
			  ]
			: []),
	];

	const tools = [
		{
			icon: InventoryMajor,
			label: messages.floorView,
			selected: isSelected([routes.floorView()]),
			url: routes.floorView(),
		},
		{
			icon: DataVisualizationMajor,
			label: messages.analytics,
			selected: isSelected([routes.analytics()]),
			url: routes.analytics(),
		},
		{
			icon: ChatMajor,
			label: messages.support,
			selected: isSelected([routes.support()]),
			url: routes.support(),
		},
		{
			icon: DnsSettingsMajor,
			label: messages.allocationRules,
			selected: isSelected([routes.allocationRules()]),
			url: routes.allocationRules(),
		},
		...(experimentalFlags.includes('JOB_FLOW_RULES')
			? [
					{
						icon: MergeMinor,
						label: messages.jobFlowRules,
						selected: isSelected([routes.jobFlowRules()]),
						url: routes.jobFlowRules(),
					},
			  ]
			: []),
		{
			icon: ListMajor,
			label: messages.messageLogs,
			selected: isSelected([routes.deliveryLog()]),
			url: routes.deliveryLog(),
		},
	];

	return (
		<Navigation location="/">
			<Navigation.Section items={main} />
			<Navigation.Section title={messages.tools} items={tools} />
			<div className={styles.feedbackLinkContainer}>
				<Button primary external url="https://www.surveymonkey.com/r/6RSBridgeFeedback">
					{messages.feedback}
				</Button>
			</div>
			<small className={styles.version}>
				{APP_VERSION} ({usingWarehouseApi ? 'W' : 'F'})
			</small>
		</Navigation>
	);
}
